import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.2, // With 1.0 sample rate, we have reached limit of 10K transactions / month
  release: process.env.REACT_APP_VERSION,
	ignoreErrors: ["Invalid PDF structure"], // impossible to catch pdf-js service worker error
});


const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
