/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { useMemo } from "react";
import { TimeLabel } from "./TimeLabel";

export const SchedulerEventWeek = ({
  event,
  hasBodyElements,
  teamElements,
  workOrderContainerCss,
  colorStyle,
}) => {
  const { start, end, allDay, title, city, clientName } = event ?? {};

  const descriptionTooltip = useMemo(
    () => `${title} ${clientName || ""}${city ? ` - ${city}` : ""}`,
    [title, city, clientName]
  );
  return (
    <section css={workOrderContainerCss} style={colorStyle}>
      <TimeLabel start={start} end={end} allDay={allDay} event={event} period="week" />
      <section
        css={[
          tw`flex flex-row space-x-1 flex-shrink max-w-full truncate pl-1 py-0 [line-height: 0em]`,
          !hasBodyElements && tw`max-h-9`,
        ]}
      >
        <div
          title={descriptionTooltip}
          css={[
            event?.status === "Terminé" || event?.status === "Non terminé"
              ? tw`text-white`
              : tw`text-gray-900 items-center`,
            tw`text-xs flex-nowrap whitespace-nowrap`,
          ]}
        >
          {title}
        </div>
        {teamElements}
      </section>
    </section>
  );
};