import { MbscCalendarEvent } from "@mobiscroll/react";
import { Client } from "./Client";
import { DrivingLicense } from "./DrivingLicense";
import { MongoDocument } from "./MongoDocument";
import { Person } from "./Person";
import { Site } from "./Site";
import { Vehicle } from "./Vehicle";
import { WorkOrderStatus } from "./WorkOrderStatus";

export const isWorkOrder = (obj: unknown): obj is WorkOrder => {
  return obj && (obj as unknown)["title"] !== undefined && ((obj as unknown)["start"] !== undefined || (obj as unknown)["allDay"] !== undefined);
};
export const isWorkOrderList = (obj: unknown): obj is { list: WorkOrder[] } => {
  return obj && Array.isArray(obj["list"]) && obj["list"].every(isWorkOrder);
};

export interface WorkOrder extends MbscCalendarEvent, MongoDocument {
  _id?: string; // ID mongodb
  workOrderNumber?: string; // ID in the other systems (CEGID)
  allDay: boolean; // Work order lasts all day or not

  // Appointment datetimes
  start: Date;
  end?: Date;
  // Actual work datetimes
  actualStart?: Date; 
  actualEnd?: Date;

  title?: string;
  description?: string; // "Travaux à Effectuer"
  descriptionWorkDone?: string, // "Travaux Réalisés"

  //
  // Client info
  //
  contactName?: string;
  contactPhone?: string;
  client?: Client; // Company or person
  clientName?: string;
  clientEmail?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  billingAddress?: string; // Empty if same as address
  billingCity?: string;
  billingPostalcode?: string;
  
  valvertContactName?: string; // Name of the salesman
  vehicles: Vehicle[];
  team: Person[]; // Workers + drivers
  status: WorkOrderStatus;
  site: Site; // Agency in charge of the order
  goBackReasons?: GoBackReason[]; // When the workorder must be changed to a previous status, user has to indicate why (mistake, ...)
  location?: Location[];
  bsdId?: string; // Id in trackdechet.gouv.fr

  //
  // Attachments filenames
  //
  interventionSheet?: string; // "Feuille de procédure d'intervention"
  bsd?: string;               // "Bordereau de suivi de déchets"
  plan?: string;              // Map to access the workplace
  photos?: Photo[];           // Photos of the work done
  signedDocument?: string;    // PDF of the work order, signed by the client
  otherAttachments?: string[];// e.g. other administrative docs, photos taken by the salesman, ...

  licenses?: DrivingLicense[]; // Licenses required to work on this order, e.g. "ADR"

  // Fields required for Calendar, not persisted
  isHeaderWorkOrder?: boolean; // True if this is not a real workOrder, it's used to display common fields in the header of the calendar slot
  hasTeamHeader?: boolean; // True if the calendar slot has a "header" workOrder that contains the team, so we don't need to display the team inside the event
  hasVehicleHeader?: boolean; // True if the calendar slot has a "header" workOrder that contains the 2nd vehicle, so we don't need to display the 2nd vehicle inside the event
  hasWorkOrderLicenseHeader?: boolean; // True if the calendar slot has a "header" workOrder that contains the missing licenses, so we don't need to display the 2nd vehicle inside the event

  payment?: {
    paymentMethod?: "Chèque" | "Compte client" | "Espèce" | undefined; // Method of payment chosen by the client at the end of the intervention. 
    number?: string; // Number is for the number of the check
  };
}

export type GoBackReason = {
  description: string;
  time: Date;
  originStatus: string;
  author: string;
  lat: string;
  lng: string;
};

export type Location = {
  status: string;
  lat: string;
  lng: string;
};

export type Photo = {
  name: string;
  legend: string;
};
