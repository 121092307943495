/** @jsxImportSource @emotion/react */
import { useForm } from "react-hook-form";
import "twin.macro";
import { ErrorMessage } from '@hookform/error-message';
import { useUpsertWorkOrderMutation } from "../shared/hooks/useWorkOrderQueries";
import {
  CloseButton,
  PrimaryMobileButton,
  SecondaryMobileButton,
} from "../shared/components/Buttons";
import { FormGroup, TextAreaStyled, ErrorMessage as CustomErrorMessage } from "../shared/components/Form";
import { WorkOrderStatus } from "../types/WorkOrderStatus";

export const ModalConfirmStatus = ({ showResults, setShowResults, updateStatus, title }) => {
  return (
    <>
      <div
        tw="fixed z-10 inset-0 overflow-y-auto mt-20"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        onClick={() => setShowResults(!showResults)}
      >
        <div tw="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            tw="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span tw="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div tw="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div tw="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div tw="sm:flex sm:items-start justify-between">
                <div tw="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 tw="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    {title}
                  </h3>
                </div>
              </div>
            </div>
            <div tw="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <div tw="flex space-x-3 items-center justify-end">
                <SecondaryMobileButton onClick={() => setShowResults(!showResults)}>
                  Retour
                </SecondaryMobileButton>
                <PrimaryMobileButton onClick={updateStatus}>Valider</PrimaryMobileButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalGoBackReasonStatus = ({ setGoBack, workOrder, title }) => {
  const { mutateAsync: mutate } = useUpsertWorkOrderMutation();
  const onSubmitTexarea = (data) => {
    mutate(
      {
        ...workOrder,
        goBackReasons: [
          ...workOrder.goBackReasons,
          {
            description: data.description,
            time: new Date(),
            originStatus: workOrder.status,
          },
        ],
        status: WorkOrderStatus.EN_COURS,
      },
      {
        onSuccess: () => {
          setGoBack(false);
        },
      }
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <>
      <div
        tw="fixed z-10 inset-0 overflow-y-auto mt-20"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div tw="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            tw="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
            onClick={() => setGoBack(false)}
          ></div>

          <span tw="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div tw="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div tw="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div tw="sm:flex flex-col sm:items-start justify-between">
                <div tw="flex mt-3 mb-4 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 tw="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    {title}
                  </h3>
                  <CloseButton onClose={() => setGoBack(false)} />
                </div>
                <div tw="w-full border-t border-gray-200 px-2 py-5 sm:px-2">
                  <dd tw="mt-2 md:mt-0 md:col-span-7">
                    <form onSubmit={handleSubmit(onSubmitTexarea)}>
                      <FormGroup>
                        <TextAreaStyled
                          tw="flex-1 block w-full text-sm z-0 h-44 focus:z-10 border-gray-300 mt-2 rounded-md focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500)"
                          {...register("description", {
                            required: "Veuillez remplir le champs pour revenir au status précédent",
                          })}
                          id="description"
                        />
                        <ErrorMessage as={CustomErrorMessage} name="description" errors={errors} />
                      </FormGroup>

                      <div tw="flex space-x-3 items-center justify-end mt-4">
                        <PrimaryMobileButton type="submit">Valider</PrimaryMobileButton>
                      </div>
                    </form>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
