/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { HTTPError, TimeoutError } from "ky";
import { CancelButton, CheckButton, CloseButton } from "./Buttons";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { FC, PropsWithChildren } from "react";

type AlertProps = {
  message: string | HTTPError | TimeoutError | undefined;
  setMessage: (b: string | undefined) => void;
  title?: string | undefined;
};

export const CenterModal = ({ children }) => {
  return (
    <div aria-live="assertive" tw="z-20 fixed inset-0 pointer-events-none flex sm:items-center">
      <div tw="w-full flex flex-col items-center space-y-4 sm:items-center">
        <div tw="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-black ring-opacity-5 overflow-hidden">
          {children}
        </div>
      </div>
    </div>
  );
};

export const Alert = ({ title = "Une erreur est survenue", message, setMessage }: AlertProps) => {
  return (
    <>
      {!!message && (
        <CenterModal>
          <div tw="p-4 flex items-start">
            <div tw="ml-3 w-0 flex-1 pt-0.5">
              <p tw="text-sm font-medium text-gray-900">{title}</p>
              <p tw="mt-1 text-sm text-gray-500 whitespace-pre-line">{message?.toString()}</p>
            </div>
            <div tw="ml-4 flex-shrink-0 flex">
              <CloseButton onClose={() => setMessage(undefined)} />
            </div>
          </div>
        </CenterModal>
      )}
    </>
  );
};


export const Confirm = ({ onValidate, onCancel, message }) => {
  return (
    <>
      {!!message && (
        <CenterModal>
          <div tw="p-4">
            <p tw="my-4 text-sm text-gray-500">{message}</p>
            <div tw="w-full flex justify-end mr-4 space-x-2">
              <CancelButton onClick={onCancel}>Annuler</CancelButton>
              <CheckButton css={tw`bg-green-500`} onClick={onValidate}>Confirmer</CheckButton>
            </div>
          </div>
        </CenterModal>
      )}
    </>
  );
};

type SideModalProps = {
  isOpen: boolean
  onDismiss: (event: React.MouseEvent | React.KeyboardEvent) => void;
  centeredModal?: boolean;
  [s: string]: unknown;
};
export const SideModal: FC<PropsWithChildren<SideModalProps>> = ({ isOpen, onDismiss, centeredModal, children, ...props }) => {
  return (
    <DialogOverlay
      tw="fixed z-10 inset-0 overflow-y-auto bg-transparent "
      onDismiss={onDismiss}
      isOpen={isOpen}
    >
      <DialogContent tw="flex items-end justify-center pt-20 text-center sm:block sm:p-0" aria-label="Popup">
        {/* Off-canvas menu, show/hide based on off-canvas menu state. */}
        <section
          tw="fixed -top-8 right-0 align-bottom bg-white px-4 pt-3 pb-4 text-left shadow-xl sm:my-8 sm:align-middle sm:w-auto sm:p-6 m-0 w-full overflow-y-auto h-full"
          css={[centeredModal && tw`!top-[50%] !left-[50%] ![transform: translate(-50%, -50%)] h-auto ![margin: 0]`]}
          {...props}
        >
          {isOpen && children}
        </section>
      </DialogContent>
    </DialogOverlay>
  );
};