/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { useCallback, FC } from "react";
import { Unavailability, UnavailabilityType } from "../../types/Unavailability";
import { dateDisplay, toDate } from "../functions/Datetime";
import { AddButton } from "./Buttons";
import { ErrorMessage, InputStyled } from "./Form";
import { RemoveIcon } from "./Icons";
import { List, ListItem, ListItemContent, ListItemRow } from "./List";
import { useStartEndDates } from "../hooks/useStartEndDates";

type UnavailabilityListProps = {
  types: UnavailabilityType[];
  unavailabilities: Unavailability[];
  setUnavailabilities: (newunavailabilities: Unavailability[]) => void;
};
export const UnavailabilityList: FC<UnavailabilityListProps> = ({
  types,
  unavailabilities,
  setUnavailabilities,
}) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onStartDateChange,
    onEndDateChange,
    errorMessage,
    setErrorMessage,
  } = useStartEndDates(undefined, undefined);

  const add = useCallback(
    (type: UnavailabilityType) => {
      if (startDate && endDate) {
        const updatedunavailabilities = [
          ...unavailabilities,
          {
            type,
            start: toDate(startDate),
            end: toDate(endDate),
          } as Unavailability,
        ];
        setUnavailabilities(updatedunavailabilities);
        setStartDate(undefined);
        setEndDate(undefined);
        setErrorMessage(undefined);
      } else {
        setErrorMessage("Renseigner la date de début et la date de fin");
      }
    },
    [setUnavailabilities, unavailabilities, startDate, endDate]
  );

  const remove = useCallback(
    (index: number) => {
      const updatedunavailabilities = [...unavailabilities];
      updatedunavailabilities.splice(index, 1);
      setUnavailabilities(updatedunavailabilities);
    },
    [setUnavailabilities, unavailabilities]
  );

  return (
    <section>
      <section tw="flex flex-row space-x-2 items-center flex-wrap">
        <span tw="text-sm font-medium text-gray-700">Du</span>
        <InputStyled type="date" value={startDate || ""} onChange={onStartDateChange} tw="!w-40" />
        <span tw="text-sm font-medium text-gray-700">au</span>
        <InputStyled type="date" value={endDate || ""} onChange={onEndDateChange} tw="!w-40" />
        {types
          .filter((item) => item !== "CP-Récup") //Add this filter because of the new version of the app which separate the CP from the Récup. Let the visual of the former CP-Récup in the PersonPickingList but not let the older button CP-Récup
          .map((t) => (
            <AddButton
              tw=""
              css={{ span: tw`ml-0 text-xs`, svg: tw`w-4` }}
              key={t}
              onClick={() => add(t)}
            >
              {t}
            </AddButton>
          ))}
      </section>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <List tw="mb-2">
        <ListItem>
          <ListItemContent>
            {unavailabilities?.map((unavailability, index) => (
              <ListItemRow
                key={`${unavailability.type}-${unavailability.start}`}
                tw="justify-start"
              >
                <div tw="w-80 text-gray-800 text-sm">
                  {unavailability.type} du {dateDisplay(unavailability.start)} au{" "}
                  {dateDisplay(unavailability.end)}
                </div>
                <RemoveIcon
                  tw="h-5 w-5 cursor-pointer text-gray-800 hover:text-gray-600"
                  onClick={() => remove(index)}
                />
              </ListItemRow>
            ))}
          </ListItemContent>
        </ListItem>
      </List>
    </section>
  );
};
