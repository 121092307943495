/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";
import { Popover, Transition } from "@headlessui/react";
import { ColorSwatchIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { BadgeContainer } from "../../../mobile/workOrderList/WorkOrderListItem";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { DrivingLicenseBadge } from "../Badge";
import { DrivingLicense } from "../../../types/DrivingLicense";
import { Tooltip } from "../Tooltip";

export const Legend = () => (
  <Popover.Group
    tw="inline-flex items-center px-1 pt-1 border-b-2"
    css={{
      "&": tw`rounded-md border border-gray-300 `,
      "&.active": tw`border-primary-500 text-gray-900 hover:border-primary-500 hover:text-gray-900`,
    }}
  >
    <Popover tw="relative">
      {({ open }) => (
        <>
          <Popover.Button
            tw="inline-flex items-center text-sm font-medium"
            css={{
              "&": tw`border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700`,
              "&.active": tw`border-primary-500 text-gray-900 hover:border-primary-500 hover:text-gray-900`,
            }}
          >
            <Tooltip message="Légende" position="bottom">
              <ColorSwatchIcon
                css={[open ? tw`text-gray-600` : tw`text-gray-400`, tw` h-5 w-5`]}
                aria-hidden="true"
              />
            </Tooltip>
          </Popover.Button>

          {/* @ts-ignore */}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel tw="absolute z-10 right-0 mt-3 px-2 sm:px-0">
              <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div tw="relative grid gap-6 bg-white px-3 py-4">
                  <div tw="grid gap-2">
                    <span tw="text-sm font-medium text-gray-600 border-b border-gray-400">
                      Statuts OT
                    </span>

                    <div tw="grid gap-2">
                      {Object.entries(WorkOrderStatus).map(([, value]) => (
                        <BadgeContainer status={value} key={value} />
                      ))}
                    </div>
                  </div>
                  <div tw="grid gap-2">
                    <span tw="text-sm font-medium text-gray-600 border-b border-gray-400">
                      Permis
                    </span>
                    <div tw="grid gap-2">
                      {Object.entries(DrivingLicense).map(([, value]) => (
                        <DrivingLicenseBadge
                          drivingLicense={value}
                          key={value}
                          tw="justify-center"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  </Popover.Group>
);
