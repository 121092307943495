/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";

import {  useContext, useEffect, useState } from "react";
import { CrudList } from "../shared/components/CrudList";
import { buildSiteFilter, Filter } from "../shared/functions/QueryHelper";
import { Td } from "../shared/components/Table";
import { useDeleteMutation, useSearchQuery, useUpsertManyMutation, useUpsertPutMutation } from "../shared/hooks/useVehicleQueries";
import { DrivingLicenseBadge } from "../shared/components/Badge";
import { Vehicle } from "../types/Vehicle";
import { VehicleModal } from "./VehicleModal";
import { Page } from "../shared/components/Page";
import { useCrudParams } from "../shared/hooks/useCrudParams";
import { CrudListHeader } from "../shared/components/CrudListHeader";
import { Permission } from "../types/Role";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { NumberStyled } from "../shared/components/Form";

export const VEHICLE_ROOT_ROUTE = "/vehicle";
export const VEHICULE_READ_PERMISSIONS = [Permission.VEHICLE_VIEW];
export const VEHICULE_WRITE_PERMISSIONS = [Permission.VEHICLE_VIEW];

export const VehicleCrud = () => {
  const { currentSite } = useContext(CurrentSiteContext);
  const {
    itemIsOpen,
    onItemOpen,
    onItemClose,
    itemId,
    selectedItems,
    isItemSelected,
    onItemToggle,
    onItemsToggle,
    search,
    debouncedSearch,
    setSearch,
    setPagination,
    pagination,
  } = useCrudParams();
  const { status, data: itemList, error } = useSearchQuery({
    ...Filter.and(
      Filter.or(
        debouncedSearch && { name: Filter.regex(debouncedSearch) },
        debouncedSearch && { model: Filter.regex(debouncedSearch) },
      ),
      buildSiteFilter(currentSite),
    ),
    ...pagination,
  });

  return (
    <Page tw="space-y-2">
      <>
      {status === "error" && <div>{error.toString()}</div>}
      {status === "success" && (
        <>
          <CrudListHeader
            pageTitle="Véhicules"
            fieldNames={[
              "name",
              "model",
              "licensePlate",
              "login",
              "drivingLicense",
              "principal",
              "status",
              "_id",
            ]}
            useUpsertManyMutation={useUpsertManyMutation}
            useDeleteMutation={useDeleteMutation}
            importEnabled={true}
            exportEnabled={true}
            creationCallback={() => onItemOpen(null)}
            selectedItems={selectedItems}
            data={itemList}
            search={search}
            setSearch={setSearch}
            searchPlaceHolder="Nom, modèle"
            readPermissions={VEHICULE_READ_PERMISSIONS}
            writePermissions={VEHICULE_WRITE_PERMISSIONS}
          />
          <CrudList
            fieldLabels={["Nom", "Modèle", "Immatriculation", "Permis", "Login", "Position Planning"]}
            creationCallback={() => onItemOpen(null)}
            crudListCells={(item) => <CrudListCells item={item as Vehicle} goDetailsCallback={onItemOpen} />}
            data={itemList}
            status={status}
            debouncedSearch={debouncedSearch}
            isSelected={isItemSelected}
            onToggleMany={onItemsToggle}
            onToggle={onItemToggle}
            setPagination={setPagination}
            readPermissions={VEHICULE_READ_PERMISSIONS}
            writePermissions={VEHICULE_WRITE_PERMISSIONS}
          />
          <VehicleModal vehicleId={itemId} isOpen={itemIsOpen} onClose={onItemClose} />
          </>
        )}
      </>          
    </Page>
  );
};

export const CrudListCells = ({
  item,
  goDetailsCallback,
}: {
  item: Vehicle;
  goDetailsCallback;
}) => {
  const [positionValue, setPositiontValue] = useState(item.position);
  const [handler, setHandler] = useState(undefined);

  useEffect(() =>
  {
    setPositiontValue(item.position);
  },[item]
  )
  const { mutateAsync: upsertVehicle } = useUpsertPutMutation();
  
  const onChange = (value) => {
    clearTimeout(handler);

    setPositiontValue(value);
    setHandler( 
      setTimeout(() => {
        onVehiclePositionChange(value,item)
     }, 1000)
    )


  }

  const onVehiclePositionChange = (
    value,
    vehicle,
    ) => {
      const pos = value == "" ? undefined : value;
      return upsertVehicle({
        ...vehicle,
        position: pos,
      });
  };

  
  return (
    <>
      <Td onClick={() => goDetailsCallback(item._id)}>{item.name}</Td>
      <Td onClick={() => goDetailsCallback(item._id)}>{item.model}</Td>
      <Td onClick={() => goDetailsCallback(item._id)}>{item.licensePlate}</Td>
      <Td onClick={() => goDetailsCallback(item._id)}>
        <DrivingLicenseBadge drivingLicense={item.drivingLicense} />
      </Td>
      <Td onClick={() => goDetailsCallback(item._id)}>{item.login}</Td>
      <Td>
        {
          item?.principal &&
          <PositionNumber onChange={onChange} value={positionValue}/>
        } 
      </Td>
    </>
  );

};

const PositionNumber = ({
  value = 0,
  onChange = undefined,
}) => {
  return (
    <NumberStyled css={[tw`[max-width: 70px]`]} type="number" onChange={(e) => onChange(e.target.value)} value={value ? value : "-" } min="0" />  
    );
};