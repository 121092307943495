/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";
import { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  CheckBoxStyled,
  ErrorMessage,
  FormGroup,
  ReactSelect,
  InputLabel,
  InputStyled,
  SelectStyled,
} from "../shared/components/Form";
import { DrivingLicense, drivingLicensePriorities } from "../types/DrivingLicense";
import { UnavailabilityList } from "../shared/components/UnavailabilityList";
import { Unavailability, VehicleUnavailabilityType } from "../types/Unavailability";
import { Vehicle } from "../types/Vehicle";
import { StandardStatus } from "../types/StandardStatus";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { VehicleIcon, vehiclesIconList } from "../shared/components/Vehicles/VehiclesIcon";

export const VehicleForm = (props) => {
  const { currentSite } = useContext(CurrentSiteContext);
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [requiredNameOrModel, setRequiredNameOrModel] = useState("");
  const nameWatch = watch("name");
  const modelWatch = watch("model");
  useEffect(() => {
    setRequiredNameOrModel(nameWatch + modelWatch);
  }, [nameWatch, modelWatch]);

  const unavailabilities: Unavailability[] = watch("unavailabilities");
  const setUnavailabilities = useCallback(
    (newUnavailabilities) => setValue("unavailabilities", newUnavailabilities),
    [setValue]
  );

  useEffect(() => {
    setValue("site", currentSite.name ?? "");
  }, [currentSite, setValue]);

  const vehicleIconOptions = vehiclesIconList.map((i) => ({
    value: <VehicleIcon tw="w-14" name={i} />,
    label: <VehicleIcon tw="w-14" name={i} />,
    name: i,
  }));
  
  return (
    <div tw="grid grid-cols-1 px-1" {...props}>
      <ErrorMessage>
        {errors.model && "Vous devez remplir soit le champs «Nom» soit le champs «Modèle»."}
      </ErrorMessage>

      <FormGroup>
        <InputLabel label="Nom" inputId="item-name">
          <InputStyled
            type="text"
            {...register("name", {
              validate: () => requiredNameOrModel !== "",
            })}
            id="item-name"
          />
        </InputLabel>
      </FormGroup>

      <FormGroup>
        <InputLabel label="Modèle" inputId="item-model">
          <div tw="flex">
            <InputStyled
              type="text"
              {...register("model", {
                validate: () => requiredNameOrModel !== "",
                maxLength: 8,
              })}
              id="item-model"
              maxLength={8}
              tw="!w-36"
            />
            <ReactSelect
              tw="ml-5 pt-0 "
              name="icon"
              options={vehicleIconOptions}
              defaultValue="icon1"
              id="icon"
            />
          </div>
        </InputLabel>
      </FormGroup>

      <FormGroup>
        <InputLabel label="Immatriculation" inputId="license-plate">
          <InputStyled
            type="text"
            {...register("licensePlate")}
            id="license-plate"
            maxLength={10}
            tw="!w-36"
          />
        </InputLabel>
      </FormGroup>

      <FormGroup>
        <InputLabel label="Login" inputId="vehicle-login">
          <InputStyled type="text" {...register("login")} id="vehicle-login" />
        </InputLabel>
      </FormGroup>

      <FormGroup>
        <InputLabel label="Permis" inputId="driving-license">
          <SelectStyled {...register("drivingLicense", { required: true })} id="driving-license">
            {Object.entries(DrivingLicense)
              .filter(([, value]) => drivingLicensePriorities.get(value) >= 0 )
              .map(([, value]) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
          </SelectStyled>
        </InputLabel>
      </FormGroup>

      <FormGroup>
        <InputLabel label="Site" inputId="vehicle-site">
          <InputStyled 
            type="text" 
            {...register("site", { required: true, setValueAs: () => currentSite,})} 
            readOnly 
            id="vehicle-site"
            tw="bg-gray-50 text-gray-500"
          />
        </InputLabel>
      </FormGroup>

      <FormGroup>
        <InputLabel label="Véhicule principal" inputId="vehicle-principal" tooltipMessage="Rend visible le véhicule dans le planning">
          <CheckBoxStyled css={[tw` mr-7 mt-1.5`]} {...register("principal")} id="vehicle-principal" type="checkbox"/>
        </InputLabel>
      </FormGroup>
       {}
      <FormGroup>
        <div tw="border-t border-gray-200 pt-5 my-5 text-sm font-medium text-gray-700">
          Absences
        </div>
        <UnavailabilityList
          unavailabilities={unavailabilities}
          setUnavailabilities={setUnavailabilities}
          types={Object.values(VehicleUnavailabilityType)}
        />
      </FormGroup>
    </div>
  );
};

export const vehicleToFormValues = (vehicle: Vehicle) => ({
  ...vehicle,
  principal: vehicle.principal,
  status: vehicle.status ?? StandardStatus.active,
});

export const formValuesToVehicle = (formValues) => ({
  ...formValues,
  status: formValues.status ?? StandardStatus.active,
});
