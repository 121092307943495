import qs from "qs";
import { WorkOrder } from "../../types/WorkOrder";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import { WORK_ORDERS_ENDPOINT } from "../hooks/useWorkOrderQueries";
import { toDate } from "./Datetime";
import { readCsvFile } from "./Import";
import { WORK_ORDER_INIT_VALUES } from "./WorkOrderManagement";
import { API_PATH } from "../../person/usePersonQueries";
import { Role } from "../../types/Role";
import { Site } from "../../types/Site";

export const workOrderImport = async (file, api, site: Site): Promise<WorkOrder[]> => {
  const rawResult = await readCsvFile(file);

  if (rawResult.errors?.length > 0) {
    rawResult.errors.map((e) => console.log(`Ligne ${e.row} : ${e.message}`));
    throw new Error(rawResult.errors.map(() => `Le fichier n'a pas pu être importé`).join("\n"));
  }

  // Limit number of OT because we'll have to search each of them later
  if (rawResult.data?.length > 500) {
    throw new Error("Ne pas importer plus de 100 OT simultanément");
  }
  console.log(rawResult.data);
  const importedWorkOrders = rawResult.data?.map((line) => ({
    ...WORK_ORDER_INIT_VALUES,
    workOrderNumber: line[0],
    start: line[1] && `${toDate(line[1].length === 5 ? `0${line[1]}` : `${line[1]}`)}`,
    // client code : line[2]
    clientName: line[3],
    billingAddress: `${line[4]} ${line[5]}\n${line[6]}`,
    billingPostalcode: line[7],
    billingCity: line[8],
    address: `${line[4]} ${line[5]}\n${line[6]}`,
    postalCode: line[7],
    city: line[8],
    // client contact for invoicing: line[9],
    valvertContactName: line[10],
    // team : line[11],
    // primary vehicle : line[12],
    contactName: line[13], // client contact for the work order
    contactPhone: line[14],
    // invoice line number : line[15],
    title: line[16],
    description: line[17],
    site,
  }));

  const workOrdersWithoutDuplicates = [];
  for (let i = 0; i < importedWorkOrders.length; i++) {
    // File can contain multiple lines by work order, we have to merge them
    const currentWorkOrder = importedWorkOrders[i];
    const duplicateIndex = workOrdersWithoutDuplicates.findIndex(
      (w) => w.workOrderNumber === currentWorkOrder.workOrderNumber
    );
    if (duplicateIndex >= 0) {
      // Ignore following codes because the description is not relevant
      // Ignore transfert because client is more interested by the operation description than transport
      if (["TRANSFERT", "DEVIS", "COMMANDE"].includes(currentWorkOrder.title)) {
        continue; // skip this line
      } else {
        if (
          ["TRANSFERT", "DEVIS", "COMMANDE", "CONTRAT", "ACCORD"].includes(
            workOrdersWithoutDuplicates[duplicateIndex].title
          ) ||
          !workOrdersWithoutDuplicates[duplicateIndex].description
        ) {
          workOrdersWithoutDuplicates[duplicateIndex] = { ...currentWorkOrder };
          continue;
        } else {
          // Merge description
          workOrdersWithoutDuplicates[
            duplicateIndex
          ].description += `\n${currentWorkOrder.description}`;
        }
      }
    } else {
      workOrdersWithoutDuplicates.push(currentWorkOrder);
    }
  }

  // Search work orders by chunks of 20
  const workOrderNumbers = workOrdersWithoutDuplicates
    .filter((r) => !!r.workOrderNumber)
    .map((r) => r.workOrderNumber);
  const workOrderNumbersChunks = [];
  while (workOrderNumbers.length) {
    workOrderNumbersChunks.push(workOrderNumbers.splice(0, 19)); // QS limits array in URL to 20 elements!
  }
  const workOrderSearchQueries = workOrderNumbersChunks.map((chunk) =>
    api
      .get(WORK_ORDERS_ENDPOINT, {
        searchParams: qs.stringify({
          workOrderNumber: { $in: chunk },
          status: { $ne: WorkOrderStatus.ANNULE },
        }),
      })
      .then((r) => r.json())
  );
  const existingWorkOrdersLists = (await Promise.all(workOrderSearchQueries)) as WorkOrder[][];
  const existingWorkOrders = existingWorkOrdersLists.flatMap((l) => l);

  // Merge imported work orders with existing ones
  const mergedWorkOrders = workOrdersWithoutDuplicates
    .filter((importedWorkOrder) => {
      const workOrderNumberExists = existingWorkOrders.some(
        (w) => w.workOrderNumber === importedWorkOrder.workOrderNumber
      );
      return workOrderNumberExists;
    })
    .map((importedWorkOrder) => {
      const existingWorkOrder = existingWorkOrders.find(
        (w) => w.workOrderNumber === importedWorkOrder.workOrderNumber
      );
      const workOrderToUpdate = {
        ...existingWorkOrder,
        // Below the fields that are always replaced
        valvertContactName: importedWorkOrder.valvertContactName
          ? importedWorkOrder.valvertContactName
          : existingWorkOrder.valvertContactName,
        contactName: importedWorkOrder.contactName
          ? importedWorkOrder.contactName
          : existingWorkOrder.contactName,
        contactPhone: importedWorkOrder.contactPhone
          ? importedWorkOrder.contactPhone
          : existingWorkOrder.contactPhone,
        billingAddress: importedWorkOrder.billingAddress
          ? importedWorkOrder.billingAddress
          : existingWorkOrder.billingAddress,
        billingPostalcode: importedWorkOrder.billingPostalcode
          ? importedWorkOrder.billingPostalcode
          : existingWorkOrder.billingPostalcode,
        billingCity: importedWorkOrder.billingCity
          ? importedWorkOrder.billingCity
          : existingWorkOrder.billingCity,
        address: importedWorkOrder.address ? importedWorkOrder.address : existingWorkOrder.address,
        postalCode: importedWorkOrder.postalCode
          ? importedWorkOrder.postalCode
          : existingWorkOrder.postalCode,
        city: importedWorkOrder.city ? importedWorkOrder.city : existingWorkOrder.city,
        clientName: importedWorkOrder.clientName
          ? importedWorkOrder.clientName
          : existingWorkOrder.clientName,
        description: importedWorkOrder.description
          ? importedWorkOrder.description
          : existingWorkOrder.description,
      };
      // Below the fields that are replaced only if missing
      workOrderToUpdate.valvertContactName = workOrderToUpdate.valvertContactName
        ? workOrderToUpdate.valvertContactName
        : importedWorkOrder.valvertContactName;
      return workOrderToUpdate;
    });
  const rolesFilter = [Role.SALESMAN];
  const personInitials = mergedWorkOrders.map((wo) => wo.valvertContactName);

  const personInitialsUnique = [new Set(personInitials)];

  const personInitialsChunks = [];
  while (personInitialsUnique.length) {
    personInitialsChunks.push(personInitialsUnique.splice(0, 19)); // QS limits array in URL to 20 elements!
  }
  const personInitialsSearchQueries = personInitialsChunks.map((chunk) =>
    api
      .get(API_PATH, {
        searchParams: qs.stringify({
          valvertContactName: { $in: chunk },
          roles: { $in: rolesFilter },
        }),
      })
      .then((r) => r.json())
  );
  const personInitialsOrderLists = (await Promise.all(
    personInitialsSearchQueries
  )) as WorkOrder[][];
  const personInitialsLists = personInitialsOrderLists.flatMap((l) => l);

  return mergedWorkOrders.map((w) => {
    w.valvertContactName =
      personInitialsLists.find((e) => w.valvertContactName === e.initials)?.name ??
      w.valvertContactName;
    return w;
  });
};
