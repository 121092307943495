// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { useApi } from "../shared/hooks/useApi";
import { useConnectedUser } from "../shared/hooks/useConnectedUser";
import { CrudDataType } from "../types/CrudDataType";
import { MongoDocument } from "../types/MongoDocument";
import { Person } from "../types/Person";

// Configure the following variables
export const API_PATH = "v1/person";

export const useSearchQuery = (searchParams) => {
  const api = useApi();
  return useQuery<unknown, unknown, CrudDataType<Person>, Array<string>>([API_PATH, searchParams], async () => {
    const response = await api.get(API_PATH, {
      searchParams: qs.stringify({
        limit: searchParams.limit ?? 0,
        skip: searchParams.skip ?? 0,
        sort: searchParams.sort ?? 'name',
        ...searchParams
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

export const useFetchItems = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(API_PATH, { searchParams: qs.stringify(searchParams) }).json();
  };
};

export const useFindByIdQuery = (itemId: string): UseQueryResult<MongoDocument> => {
  const api = useApi();
  return useQuery([API_PATH, itemId], async () => {
    return api.get(`${API_PATH}/${itemId}`).json();
  });
};

export const useUpsertManyMutation = (retry: boolean | number = false, onError?: (error: unknown) => void) => {
  const { mutateAsync: upsertItem } = useUpsertMutation(retry, onError);
  return useMutation(async (items: Person[]) => {
    const results = [];
    for (const item of items) {
      const result = await upsertItem(item);
      results.push(result);
    }
    return results;
  },
  {
    retry,
    onError,
  });
    
};

export const useUpsertMutation = (retry: boolean | number = false, onError?: (error: unknown) => void) => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    async (item: Person) => {
      if (item._id) {
        return api.patch(`${API_PATH}/${item._id}`, { json: item }).json();
      }
      delete item._id;
      return api.post(API_PATH, { json: item }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_PATH]);
      },
      onError,
      retry,
    }
  );
};

export const useDeleteMutation = () => {
  const { connectedPerson } = useConnectedUser();
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    async (item: Person) => {
      if (item._id) {
        if (connectedPerson._id === item._id) {
          throw new Error("Impossible de supprimer votre propre utilisateur.");
        }
        return api.delete(`${API_PATH}/${item._id}`);
      } else if (Array.isArray(item)) {
        if (item.some((i) => connectedPerson._id === i._id)) {
          throw new Error("Impossible de supprimer votre propre utilisateur.");
        }
        return Promise.all(item.map((i) => api.delete(`${API_PATH}/${i._id}`)));
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_PATH]);
      },
    }
  );
};
