/** @jsxImportSource @emotion/react */
import { SkipNavContent } from "@reach/skip-nav";
import "twin.macro";
import styled from "@emotion/styled";
import { Container } from "./Container";
import tw from "twin.macro";

export const Page = ({ ...props }: { [s: string]: unknown }) => {
  return (
    <main
      css={[tw`flex-1 relative focus:(outline-none) overflow-y-auto h-full bg-gray-100`]}
      tabIndex={0}
      {...props}
    />
  );
};

export const PageHeader = ({ title, ...props }: { title; actions?; [s: string]: unknown }) => {
  return (
    <Container as="header" tw="mt-6" {...props}>
      <div tw="md:flex md:items-center md:justify-between">
        <SkipNavContent />
        <div tw="flex-1 min-w-0">{title}</div>
      </div>
    </Container>
  );
};

export const PageTitle = ({ as: Component = "h1", ...props }) => {
  return <Component tw="text-2xl leading-9 font-bold text-gray-900" {...props} />;
};

export const PageContent = (props) => {
  return <Container tw="h-full" {...props} />;
};

export const PageSkeleton = () => {
  return (
    <Page>
      <PageHeader
        title={<PageTitle as="div" tw="h-8 my-1 bg-gray-200 w-1/3 rounded-md animate-pulse" />}
      />
    </Page>
  );
};

export const Skeleton = styled("span")(
  tw`block h-4 my-0.5 bg-gray-100 w-32 rounded-md animate-pulse`
);
