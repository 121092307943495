/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CrudList } from "../shared/components/CrudList";
import { Td } from "../shared/components/Table";
import { useDeleteMutation, useSearchQuery, useUpsertManyMutation } from "./useSiteQueries";
import { arrayProcessor } from "../shared/functions/Import";
import { SiteModal } from "./SiteModal";
import { Page } from "../shared/components/Page";
import { CrudListHeader, CrudStatusIcon, UpdateCrudStatusMenuItem } from "../shared/components/CrudListHeader";
import { useCrudParams } from "../shared/hooks/useCrudParams";
import { Filter } from "../shared/functions/QueryHelper";
import { Permission } from "../types/Role";
import { Site } from "../types/Site";
import { useEffect, useState } from "react";
import { Alert } from "../shared/components/Modal";
import { StandardStatus, StandardStatusColors } from "../types/StandardStatus";

export const SITE_ROOT_ROUTE = "/site";

export const SiteCrud = () => {
  const {
    itemIsOpen,
    onItemOpen,
    onItemClose,
    itemId,
    selectedItems,
    isItemSelected,
    onItemToggle,
    onItemsToggle,
    search,
    debouncedSearch,
    setSearch,
    setPagination,
    pagination,
  } = useCrudParams();


  const [error, setError] = useState(undefined);

  const {
    status,
    data: itemList,
    error: searchError,
  } = useSearchQuery({
    ...Filter.and(debouncedSearch && Filter.or({ name: Filter.regex(debouncedSearch) })),
    ...pagination,
  });

  useEffect(() => {
    setError(searchError);
  }, [searchError]);

  const customMassActions = (
    selectedItems,
    useUpsertManyMutation,
    _useDeleteMutation,
    setMessage
  ) => {
    const archivedCss = { color: StandardStatusColors.get(StandardStatus.archived) };
    const activeCss = { color: StandardStatusColors.get(StandardStatus.active) };
    return (
      <>
        <UpdateCrudStatusMenuItem
          status={StandardStatus.active}
          items={selectedItems}
          useUpsertManyMutation={useUpsertManyMutation}
          setMessage={setMessage}
        >
          <CrudStatusIcon style={activeCss} />
          Activer
        </UpdateCrudStatusMenuItem>
        <UpdateCrudStatusMenuItem
          status={StandardStatus.archived}
          items={selectedItems}
          useUpsertManyMutation={useUpsertManyMutation}
          setMessage={setMessage}
        >
          <CrudStatusIcon style={archivedCss} />
          Désactiver
        </UpdateCrudStatusMenuItem>
      </>
    );
  };

  return (
    <Page tw="space-y-2">
      <>
        <Alert message={error} setMessage={setError} />
        {status !== "error" && (
          <>
            <CrudListHeader
              pageTitle="Sites"
              fieldNames={["name", "address", "postalCode", "city", "siret", "status", "_id"]}
              useUpsertManyMutation={useUpsertManyMutation}
              useDeleteMutation={useDeleteMutation}
              importEnabled={false}
              exportEnabled={false}
              creationCallback={() => onItemOpen(null)}
              selectedItems={selectedItems}
              data={itemList}
              search={search}
              setSearch={setSearch}
              fieldsProcessors={[null, null, null, null, arrayProcessor]}
              searchPlaceHolder="Nom"
              readPermissions={[Permission.SITE_READ]}
              writePermissions={[Permission.SITE_WRITE]}
              customMassActions={customMassActions}
            />
            <CrudList
              fieldLabels={["Nom", "Adresse", "Code postal", "Ville", "Siret"]}
              creationCallback={() => onItemOpen(null)}
              crudListCells={(item) => (
                <CrudListCells item={item as Site} goDetailsCallback={onItemOpen} />
              )}
              data={itemList}
              status={status}
              debouncedSearch={debouncedSearch}
              isSelected={isItemSelected}
              onToggleMany={onItemsToggle}
              onToggle={onItemToggle}
              setPagination={setPagination}
              readPermissions={[Permission.SITE_READ]}
              writePermissions={[Permission.SITE_WRITE]}
            />
            <SiteModal siteId={itemId} isOpen={itemIsOpen} onClose={onItemClose} setError={setError} />
          </>
        )}
      </>
    </Page>
  );
};

export const CrudListCells = ({ item, goDetailsCallback }: { item: Site; goDetailsCallback }) => (
  <>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.name}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.address}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.postalCode}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.city}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.siret}</Td>
  </>
);
