// @ts-check

import qs from "qs";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { CrudDataType } from "../../types/CrudDataType";
import { MongoDocument } from "../../types/MongoDocument";
import { Site } from "../../types/Site";
import { useApi } from "./useApi";

// Configure the following variables
export const API_PATH = "v1/site";

export const useSearchQuery = (searchParams) => {
  const api = useApi();
  return useQuery<unknown, unknown, CrudDataType<Site>, Array<string>>([API_PATH, searchParams], async () => {
    const response = await api.get(API_PATH, {
      searchParams: qs.stringify({
        limit: searchParams.limit ?? 0,
        skip: searchParams.skip ?? 0,
        sort: searchParams.sort ?? 'name',
        ...searchParams
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

export const useFindByIdQuery = (itemId: string): UseQueryResult<MongoDocument> => {
    const api = useApi();
    return useQuery([API_PATH, itemId], async () => {
      return api.get(`${API_PATH}/${itemId}`).json();
    });
  };

  export const useUpsertSiteMutation = (retry: boolean | number = false) => {
    const queryClient = useQueryClient();
    const api = useApi();
    return useMutation(
      async (item: Site) => {
        if (item._id) {
          return api.patch(`${API_PATH}/${item._id}`, { json: item }).json();
        }
        delete item._id;
        return api.post(API_PATH, { json: item }).json();
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([API_PATH]);
        },
        retry,
      }
    );
  };