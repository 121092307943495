/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";
import { Link, useLocation } from "react-router-dom";
import { WorkOrderStatus } from "../types/WorkOrderStatus";
import { CheckIcon } from "@heroicons/react/outline";

export const StatusStepper = ({ workOrdersData, otId }) => {
  const location = useLocation();
  const steps = [
    {
      id: "01",
      name: "Informations",
      href: `/mobile/${otId}/informations${location.search}`,
      status: [WorkOrderStatus.RECU, WorkOrderStatus.TRANSMIS],
    },
    {
      id: "02",
      name: "Travaux réalisés",
      href: `/mobile/${otId}/work${location.search}`,
      status: [WorkOrderStatus.EN_COURS],
    },
    {
      id: "03",
      name: "Signature",
      href: `/mobile/${otId}/signature${location.search}`,
      status: [WorkOrderStatus.NON_TERMINE, WorkOrderStatus.TERMINE],
    },
  ];

  const isCurrentStep = (step) => {
    const currentLocationStep = `${location.pathname}${location.search}`.split("/").pop();
    const stepUrl = step.href.split("/").pop();
    return currentLocationStep === stepUrl;
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        tw="border bg-white  border-gray-300 rounded-md divide-y divide-gray-300 flex divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.id} tw="relative flex-1 flex">
            {(workOrdersData.status === WorkOrderStatus.EN_COURS &&
              step.status.find((s) => s === WorkOrderStatus.RECU)) ||
            (workOrdersData.status === WorkOrderStatus.TERMINE &&
              step.status.find((s) => s === WorkOrderStatus.EN_COURS)) ||
            (workOrdersData.status === WorkOrderStatus.TERMINE &&
              step.status.find((s) => s === WorkOrderStatus.RECU)) ||
            (workOrdersData.status === WorkOrderStatus.NON_TERMINE &&
              step.status.find((s) => s === WorkOrderStatus.EN_COURS)) ||
            (workOrdersData.status === WorkOrderStatus.NON_TERMINE &&
              step.status.find((s) => s === WorkOrderStatus.RECU)) ? (
              <Link to={step.href} tw="flex items-center w-full">
                <span tw="mx-auto px-4 md:px-6 py-2 md:py-4 flex items-center text-sm font-medium">
                  <span tw="flex-shrink-0 w-8 md:w-10 h-8 md:h-10 flex items-center justify-center border-2 bg-indigo-600 border-indigo-600 rounded-full">
                    <span>
                      <CheckIcon tw="h-full w-6 text-white" />
                    </span>
                  </span>
                  <span
                    tw="hidden ml-4 text-sm font-medium text-gray-500 xs:block"
                    css={[isCurrentStep(step) && tw`text-indigo-600`]}
                  >
                    {step.name}
                  </span>
                </span>
              </Link>
            ) : step.status.find((s) => s === workOrdersData.status) ? (
              <Link to={step.href} tw="flex items-center w-full" aria-current="step">
                <span tw="mx-auto px-4 md:px-6 py-2 md:py-4 flex items-center text-sm font-medium">
                  <span tw="flex-shrink-0 w-8 md:w-10 h-8 md:h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                    <span tw="text-indigo-600">{step.id}</span>
                  </span>
                  <span
                    tw="hidden ml-4 text-sm font-medium text-gray-500 xs:block"
                    css={[isCurrentStep(step) && tw`text-indigo-600`]}
                  >
                    {step.name}
                  </span>{" "}
                </span>
              </Link>
            ) : (
              <Link to={step.href} tw="flex items-center w-full" aria-current="step">
                <span tw="mx-auto px-4 md:px-6 py-2 md:py-4 flex items-center text-sm font-medium">
                  <span tw="flex-shrink-0 w-8 md:w-10 h-8 md:h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                    <span tw="text-gray-500">{step.id}</span>
                  </span>
                  <span
                    tw="hidden ml-4 text-sm font-medium text-gray-500 xs:block"
                    css={[isCurrentStep(step) && tw`text-indigo-600`]}
                  >
                    {step.name}
                  </span>
                </span>
              </Link>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div tw="hidden block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    tw="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
