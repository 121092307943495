/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { DrivingLicense, DrivingLicenseColors } from "../../types/DrivingLicense";
import { StandardStatus, StandardStatusColors } from "../../types/StandardStatus";
import { WorkOrderColors } from "../../types/WorkOrderColors";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const colors = new Map<string, any>([
  ["gray", tw`bg-gray-300 text-gray-800 svg:text-gray-800`],
  ["red", tw`bg-red-500 text-gray-100 svg:text-gray-100`],
  ["yellow", tw`bg-yellow-400 text-gray-800 svg:text-gray-800`],
  ["green", tw`bg-green-700 text-gray-100 svg:text-green-100`],
  ["indigo", tw`bg-indigo-200 text-gray-800 svg:text-gray-800`],
  ["pink", tw`bg-pink-400 text-gray-100 svg:text-pink-400`],
  ["#facc15", { backgroundColor: "#facc15", color: "#a16207" }], // EN_PREPARATION jaune
  ["#f59e0b", { backgroundColor: "#f59e0b", color: "#c2410c" }], // TRANSMIS  orange
  ["#14b8a6", { backgroundColor: "#14b8a6", color: "#0e7490" }], // RECU turquoise
  ["#84cc16", { backgroundColor: "#84cc16", color: "#4d7c0f" }], // EN_ROUTE vert clair
  ["#22c55e", { backgroundColor: "#22c55e", color: "#15803d" }], // EN_COURS vert
  ["#64748b", { backgroundColor: "#64748b", color: "#f8fafc" }], // TERMINE noir
  ["#f43f5e", { backgroundColor: "#f43f5e", color: "#f8fafc" }], // NON_TERMINE rouge
  ["#ec4899", { backgroundColor: "#ec4899", color: "#f8fafc" }], // ANNULE rouse
]);

export const Badge = ({ color = "indigo", children, ...props }) => {
  return (
    <span
      tw="inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium flex-shrink-0 whitespace-nowrap"
      css={colors.get(color)}
      {...props}
    >
      {children}
    </span>
  );
};

export const SmallBadge = ({ color = "indigo", children, ...props }) => {
  return (
    <span
      tw="inline-flex items-center px-1 rounded-sm text-xxs font-medium flex-shrink-0 whitespace-nowrap"
      css={colors.get(color)}
      {...props}
    >
      {children}
    </span>
  );
};

export const BadgeDot = (props) => {
  return (
    <svg tw="mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8" {...props}>
      <circle cx="4" cy="4" r="3" />
    </svg>
  );
};

export const VerticalSignal = ({ color = "indigo", ...props }) => {
  return <div tw="absolute  w-2 h-full" css={colors.get(color)} {...props}></div>;
};

export const StatusBadge = ({
  status,
  children,
}: {
  status: StandardStatus | WorkOrderStatus;
  children?: unknown;
}) => {
  const color =
    StandardStatusColors.get(status as StandardStatus) ??
    WorkOrderColors.get(status as WorkOrderStatus);
  return (
    <Badge color={color}>
      {children}
      {status}
    </Badge>
  );
};

export const DrivingLicenseBadge = ({
  drivingLicense,
  ...props
}: {
  drivingLicense: DrivingLicense;
}) => {
  return drivingLicense != DrivingLicense.NOLICENCE ? (
    <Badge color={DrivingLicenseColors.get(drivingLicense)} {...props}>
      {drivingLicense}
    </Badge>
  ) : (
    <></>
  );
};
