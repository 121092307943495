// eslint-disable-next-line @typescript-eslint/no-unused-vars
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from "react";
import { Datepicker, locale } from "@mobiscroll/react";
import { saveAs } from "file-saver";
import qs from "qs";
import { CrudList } from "../shared/components/CrudList";
import { buildDateRangeFilter, buildSiteFilter, Filter } from "../shared/functions/QueryHelper";
import { Td } from "../shared/components/Table";
import {
  useDeleteWorkOrderMutation,
  useSearchQuery,
  useUpsertManyWorkOrdersMutation,
  useUpsertWorkOrderMutation,
  WORK_ORDERS_ENDPOINT,
} from "../shared/hooks/useWorkOrderQueries";
import { useSearchQuery as useVehicleQuery } from "../shared/hooks/useVehicleQueries";
import { WorkOrder } from "../types/WorkOrder";
import { WorkOrderCreatePopup } from "../shared/components/WorkOrderForm/WorkOrderCreatePopup";
import { WorkOrderEditPopup } from "../shared/components/WorkOrderForm/WorkOrderEditPopup";
import {
  getPrimaryVehicle,
  getSecondaryVehicles,
  WORK_ORDER_INIT_VALUES,
} from "../shared/functions/WorkOrderManagement";
import {
  dateDisplay,
  endOfPeriod,
  isAfter,
  isBefore,
  startOfPeriod,
  toDate,
} from "../shared/functions/Datetime";
import { useApi } from "../shared/hooks/useApi";
import { StandardStatus } from "../types/StandardStatus";
import { Vehicle } from "../types/Vehicle";
import { Page } from "../shared/components/Page";
import { useCrudParams } from "../shared/hooks/useCrudParams";
import {
  CrudListHeader,
  CrudStatusIcon,
  DeleteMenuItem,
  UpdateCrudStatusMenuItem,
} from "../shared/components/CrudListHeader";
import { Container } from "../shared/components/Container";
import { CheckBoxStyled, SelectStyled } from "../shared/components/Form";
import { workOrderImport } from "../shared/functions/WorkOrderImport";
import { VehicleOption } from "../shared/components/VehicleOption";
import { Permission } from "../types/Role";
import { TrashIcon } from "@heroicons/react/solid";
import { WorkOrderStatus } from "../types/WorkOrderStatus";
import { WorkOrderColors } from "../types/WorkOrderColors";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { hasAnyPermission } from "../shared/functions/RoleManagement";
import { useConnectedUser } from "../shared/hooks/useConnectedUser";
import { Alert } from "../shared/components/Modal";
import { StatutsFilter } from "../shared/components/StatusFilter";
import { ExportButton } from "../shared/components/Buttons";
import { handleError } from "../shared/functions/ErrorHandling";
import { Tooltip } from "../shared/components/Tooltip";

export const WORKORDER_ROOT_ROUTE = "/workorder";

export const WorkOrderCrud = () => {
  const { currentSite } = useContext(CurrentSiteContext);

  const {
    selectedItems,
    isItemSelected,
    onItemToggle,
    onItemsToggle,
    search,
    setSearch,
    setPagination,
    pagination,
  } = useCrudParams();
  const api = useApi();
  const [workOrderId, setWorkOrderId] = useState(undefined);
  const [workOrderInitValues, setWorkOrderInitValues] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  // Filtres
  const [withoutTruck, setWithoutTruck] = useState<boolean | undefined>();
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    startOfPeriod(new Date(), "week").toDate(),
    endOfPeriod(new Date(), "week").toDate(),
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const filters = Filter.and(
    search &&
      Filter.or(
        { workOrderNumber: Filter.regex(search) },
        { title: Filter.regex(search) },
        { valvertContactName: Filter.regex(search) }
      ),
    withoutTruck && { vehicles: { $size: 0 } },
    buildSiteFilter(currentSite),
    { status: [...selectedOptions] },
    ...buildDateRangeFilter(selectedDates[0], selectedDates[1], "start")
  );

  const {
    status,
    data: itemList,
    error,
  } = useSearchQuery({
    ...filters,
    ...pagination,
  });

  // Update vehicles from Work Order list
  const vehicleFilter = {
    ...Filter.from(buildSiteFilter(currentSite), {
      status: StandardStatus.active,
    }),
  };
  const { data: vehicleData } = useVehicleQuery(vehicleFilter);
  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();
  const onVehicleSelect = (event, workOrder) => {
    const secondaryVehicle = getSecondaryVehicles(workOrder);
    const primaryVehicle = vehicleData?.list?.find((v) => v._id === event.target.value);
    return upsertWorkOrder({
      ...workOrder,
      vehicles: [primaryVehicle, ...secondaryVehicle],
    });
  };

  const creationCallback = () =>
    setWorkOrderInitValues({ ...WORK_ORDER_INIT_VALUES, site: currentSite });

  // Imported items are show in green in the list
  const [createdByImport, setCreatedByImport] = useState([]);
  const [updatedByImport, setUpdatedByImport] = useState([]);
  const [notValidImport, setNotValidImport] = useState([]);
  const importFileProcessor = async (file) => {
    try {
      const workOrderToImport = await workOrderImport(file, api, currentSite);

      setNotValidImport(
        workOrderToImport
          .filter((w) => w.status !== WorkOrderStatus.EN_PREPARATION)
          .map((w) => w.workOrderNumber)
      );

      const workOrderToUpsert = workOrderToImport.filter(
        (w) => w.status === WorkOrderStatus.EN_PREPARATION
      );

      if (workOrderToUpsert.length === 0) return workOrderToUpsert;
      // Date filter is adjusted if needed to show the result of import
      const firstWorkOrder = workOrderToUpsert.reduce((min, current) =>
        isBefore(current.start, min.start) ? current : min
      );
      const lastWorkOrder = workOrderToUpsert.reduce((max, current) =>
        isAfter(current.start, max.start) ? current : max
      );
      const adjustedSelectedDates = selectedDates;
      if (isBefore(firstWorkOrder.start, selectedDates[0])) {
        adjustedSelectedDates[0] =
          typeof firstWorkOrder.start === "string"
            ? toDate(firstWorkOrder.start as string)
            : firstWorkOrder.start;
      }
      if (isAfter(lastWorkOrder.start, selectedDates[1])) {
        adjustedSelectedDates[1] =
          typeof lastWorkOrder.start === "string"
            ? toDate(lastWorkOrder.start as string)
            : lastWorkOrder.start;
      }
      setSelectedDates(adjustedSelectedDates);

      // Imported work orders are selected to show user which ones were inserted or updated
      setCreatedByImport(workOrderToUpsert.filter((w) => !w._id).map((w) => w.workOrderNumber));
      setUpdatedByImport(workOrderToUpsert.filter((w) => !!w._id).map((w) => w._id));

      return workOrderToUpsert;
    } catch (e) {
      setErrorMessage(e.message);
      return [];
    }
  };

  const onExport = async () => {
    try {
      const blob = await api
        .get(`${WORK_ORDERS_ENDPOINT}/export`, {
          searchParams: qs.stringify({
            limit: 0,
            skip: 0,
            ...filters,
          }),
        })
        .blob();
      return saveAs(blob, `export-${new Date().toISOString()}.zip`);
    } catch (error) {
      const message = await handleError(error);
      setErrorMessage(message);
    }
  };

  const renderListCells = (item) => (
    <CrudListCells
      item={item}
      goDetailsCallback={() => setWorkOrderId(item._id)}
      vehicles={vehicleData?.list}
      onVehicleSelect={onVehicleSelect}
      createdByImport={createdByImport}
      updatedByImport={updatedByImport}
      notValidImport={notValidImport}
    />
  );

  const customMassActions = (
    selectedItems,
    useUpsertManyMutation,
    useDeleteMutation,
    setMessage
  ) => {
    const archivedCss = { color: WorkOrderColors.get(WorkOrderStatus.ANNULE) };
    const activeCss = { color: WorkOrderColors.get(WorkOrderStatus.EN_PREPARATION) };
    return (
      <>
        <UpdateCrudStatusMenuItem
          status={WorkOrderStatus.EN_PREPARATION}
          items={selectedItems}
          useUpsertManyMutation={useUpsertManyMutation}
          setMessage={setMessage}
        >
          <CrudStatusIcon style={activeCss} />
          Restaurer les OT
        </UpdateCrudStatusMenuItem>
        <UpdateCrudStatusMenuItem
          status={WorkOrderStatus.ANNULE}
          items={selectedItems}
          useUpsertManyMutation={useUpsertManyMutation}
          setMessage={setMessage}
        >
          <CrudStatusIcon style={archivedCss} />
          Annuler les OT
        </UpdateCrudStatusMenuItem>
        <DeleteMenuItem
          items={selectedItems}
          useDeleteMutation={useDeleteMutation}
          setMessage={setMessage}
          disabled
        >
          <Tooltip message="Désactivé pour éviter les suppressions accidentelles">
            <div tw="flex flex-row">
              <TrashIcon tw="text-gray-300!" />
              <span tw="text-gray-400">Supprimer définitivement</span>
            </div>
          </Tooltip>
        </DeleteMenuItem>
      </>
    );
  };

  return (
    <Page tw="space-y-2">
      <>
        {status === "error" && <div>{error.toString()}</div>}
        {status !== "error" && (
          <>
            <CrudListHeader
              pageTitle="Ordres de travail"
              fieldNames={[
                "workOrderNumber",
                "start",
                "title",
                "clientName",
                "city",
                "valvertContactName",
                "site",
                "status",
                "_id",
              ]}
              useUpsertManyMutation={useUpsertManyWorkOrdersMutation}
              useDeleteMutation={useDeleteWorkOrderMutation}
              importEnabled={true}
              importFileProcessor={importFileProcessor}
              importFileFormat=".csv"
              exportEnabled={false}
              creationCallback={creationCallback}
              selectedItems={selectedItems}
              data={itemList}
              search={search}
              setSearch={setSearch}
              searchPlaceHolder="N°, résumé, client ou contact"
              readPermissions={[Permission.WORKORDER_LIST_VIEW]}
              writePermissions={[Permission.WORKORDER_WRITE]}
              customMassActions={customMassActions}
            >
              <ExportButton onClick={onExport}>Exporter</ExportButton>
            </CrudListHeader>
            <Container tw="md:(px-32) flex flex-row space-x-8 text-sm text-gray-800 pt-6 items-center">
              <div tw="font-medium text-gray-900">Période :</div>
              <Datepicker
                id="date-filter"
                theme="windows"
                controls={["calendar"]}
                select="range"
                touchUi={true}
                value={selectedDates}
                onChange={(e) => setSelectedDates(e.value)}
                inputProps={{
                  style: {
                    padding: "10px",
                    borderRadius: "0.375rem",
                    border: "1px solid rgb(209, 213, 219)",
                    fontSize: "14px",
                    color: "rgb(31, 41, 55)",
                    backgroundColor: "white",
                    margin: "0",
                    width: "190px",
                  },
                }}
                locale={locale.fr}
                setText="Valider"
                rangeStartLabel="Début"
              />

              <div tw="flex items-center px-4">
                <label htmlFor="truck-filter" tw="font-medium text-gray-900">
                  {"OT non planifiés : "}
                </label>
                <CheckBoxStyled
                  id="truck-filter"
                  type="checkbox"
                  checked={withoutTruck || false}
                  onChange={() => setWithoutTruck(!(withoutTruck || false))}
                  tw="border border-gray-800 ml-4"
                />
              </div>

              <StatutsFilter
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                tw="flex items-center px-4"
              />
            </Container>
            <CrudList
              fieldLabels={[
                "N° OT",
                "Date",
                "Résumé",
                "Client",
                "Adresse",
                `Contact ${process.env.REACT_APP_WHITE_LABEL}`,
                "Camion",
                "Site",
              ]}
              creationCallback={creationCallback}
              crudListCells={renderListCells}
              data={itemList}
              status={status}
              debouncedSearch={search}
              isSelected={isItemSelected}
              onToggleMany={onItemsToggle}
              onToggle={onItemToggle}
              setPagination={setPagination}
              readPermissions={[Permission.WORKORDER_LIST_VIEW]}
              writePermissions={[Permission.WORKORDER_WRITE]}
            />
            {workOrderInitValues && (
              <WorkOrderCreatePopup
                onClose={() => setWorkOrderInitValues(undefined)}
                workOrderInitValues={workOrderInitValues}
              />
            )}
            {workOrderId && (
              <WorkOrderEditPopup
                onClose={() => setWorkOrderId(undefined)}
                workOrderId={workOrderId}
              />
            )}
          </>
        )}
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      </>
    </Page>
  );
};

const WorkOrderTd = ({ item, goDetailsCallback, children, ...props }) => (
  <Td onClick={() => goDetailsCallback(item._id)} {...props}>
    {children}
  </Td>
);

type CrudListCellsType = {
  item: WorkOrder;
  goDetailsCallback: () => void;
  vehicles: Vehicle[] | undefined;
  onVehicleSelect: (e: ChangeEvent, i: WorkOrder) => void;
  createdByImport: string[];
  updatedByImport: string[];
  notValidImport: string[];
};
export const CrudListCells = ({
  item,
  goDetailsCallback,
  vehicles,
  onVehicleSelect,
  createdByImport,
  updatedByImport,
  notValidImport,
}: CrudListCellsType) => {
  const { permissions } = useConnectedUser();

  const [loading, setLoading] = useState(false);
  const [labelColor, setLabelColor] = useState(undefined);

  const onVehicleChange = useCallback(
    async (e) => {
      setLoading(true);
      await onVehicleSelect(e, item);
      setLoading(false);
    },
    [setLoading, onVehicleSelect]
  );
  const importedLabel = useMemo(() => {
    if (createdByImport.some((workOrderNumber) => workOrderNumber === item.workOrderNumber)) {
      setLabelColor(tw` text-green-700`);
      return "Créé par l'import";
    } else if (updatedByImport.some((workOrderId) => workOrderId === item._id)) {
      setLabelColor(tw` text-green-700`);
      return "Mis à jour par l'import";
    } else if (notValidImport.some((workOrderId) => workOrderId === item.workOrderNumber)) {
      setLabelColor(tw` text-red-700`);
      return "Ne peut pas être modifié";
    }
  }, [createdByImport, updatedByImport, notValidImport, item]);

  const readOnly =
    item.status !== WorkOrderStatus.EN_PREPARATION ||
    !hasAnyPermission([Permission.WORKORDER_WRITE], permissions);
  return (
    <>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback} tw="flex-col justify-center">
        <div>{item.workOrderNumber}</div>
        {importedLabel && (
          <div
            tw="[font-size: x-small] italic -mt-0.5 self-end whitespace-normal [line-height: 1em]"
            css={[labelColor]}
          >
            {importedLabel}
          </div>
        )}
      </WorkOrderTd>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback}>
        {dateDisplay(item.start ?? item.actualStart)}
      </WorkOrderTd>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback}>
        {item.title}
      </WorkOrderTd>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback}>
        {item.clientName}
      </WorkOrderTd>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback}>
        {item.postalCode ? `${item.city} (${item.postalCode})` : item.city}
      </WorkOrderTd>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback}>
        {item.valvertContactName}
      </WorkOrderTd>
      <Td>
        <VehicleSelect
          vehicles={vehicles}
          readOnly={loading || readOnly}
          vehicleFilter={(v) => v.principal}
          value={getPrimaryVehicle(item)?._id || ""}
          onChange={onVehicleChange}
          loading={loading}
        />
      </Td>
      <WorkOrderTd item={item} goDetailsCallback={goDetailsCallback}>
        {item.site.name}
      </WorkOrderTd>
    </>
  );
};

type VehicleSelectProps = {
  vehicles: Vehicle[];
  readOnly: boolean;
  vehicleFilter: (v: Vehicle) => boolean;
  formInputName?: string;
  value?: string;
  onChange?: (e: ChangeEvent) => void;
  loading?: boolean;
};
const VehicleSelect: FC<VehicleSelectProps> = ({
  vehicles,
  readOnly,
  vehicleFilter,
  value = "",
  onChange = undefined,
  loading = false,
}) => {
  const options = (
    <>
      <option key="none" value={undefined}>
        {loading ? "..." : "-"}
      </option>
      {vehicles &&
        vehicles
          .filter(vehicleFilter)
          .map((v) => <VehicleOption key={v._id} v={v} loading={loading} />)}
    </>
  );
  return (
    <SelectStyled onChange={onChange} disabled={readOnly} value={value}>
      {options}
    </SelectStyled>
  );
};
