/** @jsxImportSource @emotion/react */
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import "twin.macro";
import { PrimaryMobileButton, SecondaryMobileButton } from "../../../shared/components/Buttons";
import { Container } from "../../../shared/components/Container";
import { FormGroup, TextAreaStyled } from "../../../shared/components/Form";
import { ClientIcon, LocalisationIcon } from "../../../shared/components/Icons";
import { Skeleton } from "../../../shared/components/Page";
import {
  CardContainer,
  DateAndBadgeContainer,
  HeaderContainer,
  NavigationTitle,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";
import { LabelOT } from "../received/Received";
import { StatusStepperSkeleton } from "../received/ReceivedSkeleton";

const DoneSkeleton = () => {
  return (
    <>
      <StatusStepperSkeleton />
      <Container tw="mb-20 xl:px-48 2xl:px-96">
        <CardContainer>
          <HeaderContainer>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              <NavigationTitle>
                {" "}
                <Skeleton />
              </NavigationTitle>
            </div>
            <div tw="flex items-center">
              <Skeleton />

              <DateAndBadgeContainer>
                <Skeleton /> <Skeleton />
              </DateAndBadgeContainer>
            </div>
          </HeaderContainer>
          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8">
              <div tw="sm:col-span-1">
                <LabelOT>Nom du client</LabelOT>
                <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                  {" "}
                  <Skeleton />
                </dd>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT>Contact chantier</LabelOT>
                <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                  <ClientIcon tw="w-5 h-5 text-gray-500 mr-2" /> <Skeleton />
                </dd>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT>Téléphone du contact</LabelOT>
                <div tw="mt-1 text-sm text-gray-500 flex items-center">
                  <PhoneIcon tw="w-5 h-5 text-gray-500 mr-2" /> <Skeleton />
                </div>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT tw="inline">Adresse du client</LabelOT>

                <div>
                  <LocalisationIcon tw="w-5 h-5 text-gray-500 mr-2" />
                  <Skeleton />
                </div>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT>Contact</LabelOT>
                <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                  <MailIcon tw="w-5 h-5 text-gray-500 mr-2" /> <Skeleton />
                </dd>
              </div>
            </dl>
          </div>
        </CardContainer>

        <div tw="grid grid-cols-1 sm:grid-cols-2">
          {/* 2ND SECTION */}
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer
                title="TRAVAUX À EFFECTUER"
                subTitle="Informations générales avant intervention concernant les travaux éffectués"
              />
            </HeaderContainer>
            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl tw="divide-y divide-gray-200">
                <div tw="pt-6 pb-8">
                  <dd tw="mt-2 md:mt-0 md:col-span-7">
                    <div tw="text-base text-gray-500">
                      <span tw="block h-4 my-0.5 bg-gray-100 w-32 rounded-md animate-pulse"></span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </CardContainer>

          {/* 3RD SECTION */}
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer
                title="TRAVAUX RÉALISÉS"
                subTitle="Informations générales et observations concernant les travaux éffectués"
              />
            </HeaderContainer>
            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl tw="divide-y divide-gray-200">
                <div tw="pt-6 pb-8">
                  <dd tw="mt-2 md:mt-0 md:col-span-7">
                    <div tw="text-base text-gray-500">
                      <span tw="block h-4 my-0.5 bg-gray-100 w-32 rounded-md animate-pulse"></span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </CardContainer>
        </div>

        <CardContainer>
          <HeaderContainer>
            <TitleHeaderContainer
              title="OBSERVATION CHANTIERS"
              subTitle="Veuillez terminer l'intervention depuis l'écran précédent avant de saisir ces informations"
            />
          </HeaderContainer>
          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="divide-y divide-gray-200">
              <dd tw="mt-2 md:mt-0 md:col-span-7">
                <div>
                  <FormGroup>
                    <TextAreaStyled
                      tw="flex-1 block w-full text-sm z-0 h-44 focus:z-10 border-gray-300 mt-6 rounded-md focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500) pointer-events-none"
                      id="observations"
                    />
                  </FormGroup>

                  <div tw="flex space-x-3 items-center justify-end mt-8">
                    <PrimaryMobileButton type="submit" disabled>
                      Sauvegarder
                    </PrimaryMobileButton>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </CardContainer>

        <div>
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer title="Ordre de travail" subTitle="Veuillez générer l'OT" />

              <Skeleton />
            </HeaderContainer>

            {/* 5TH SECTION */}
            <Skeleton />
          </CardContainer>
        </div>

        {/* 7TH SECTION */}
        <div tw="flex space-x-3 items-center justify-start mt-8">
          <SecondaryMobileButton disabled>Précedent</SecondaryMobileButton>
        </div>
      </Container>
    </>
  );
};

export default DoneSkeleton;
