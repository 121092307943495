/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { DotsVerticalIcon, TrashIcon } from "@heroicons/react/solid";
import { FC, useContext, useEffect, useState } from "react";
import { UseMutationResult } from "react-query";
import { StandardStatus, StandardStatusColors } from "../../types/StandardStatus";
import { exportToExcel } from "../functions/Export";
import { readExcelFile } from "../functions/Import";
import { AddButton, ExportButton } from "./Buttons";
import { Container } from "./Container";
import { CrudImport } from "./CrudImport";
import { SearchSolidIcon } from "./Icons";
import { Menu, MenuButton, MenuItemButton, MenuItems } from "./Menu";
import { PageHeader, PageTitle } from "./Page";
import { useConnectedUser } from "../hooks/useConnectedUser";
import { Permission } from "../../types/Role";
import { CrudDataType } from "../../types/CrudDataType";
import { hasAnyPermission } from "../functions/RoleManagement";
import { MongoDocument } from "../../types/MongoDocument";
import { Alert } from "./Modal";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import { CurrentSiteContext } from "../contexts/CurrentSiteContext";
import { handleError } from "../functions/ErrorHandling";

type CrudListHeaderProps = {
  pageTitle: string;
  fieldLabels?: string[];
  fieldNames: string[];
  importFileProcessor?: (file: File) => Promise<unknown[]>;
  creationCallback: () => void;
  importEnabled: boolean;
  importFileFormat?: string;
  exportEnabled: boolean;
  useDeleteMutation: () => UseMutationResult;
  useUpsertManyMutation: () => UseMutationResult;
  selectedItems: unknown[];
  data: CrudDataType<MongoDocument>;
  fieldsProcessors?: Array<(f: string) => void>;
  search: string;
  setSearch: (s: string) => void;
  searchPlaceHolder?: string;
  readPermissions: Permission[];
  writePermissions: Permission[];
  customMassActions?: (
    selectedItems: MongoDocument[],
    useUpsertManyMutation,
    useDeleteMutation,
    setMessage: (s: string) => void
  ) => unknown;
  children?: unknown;
};
export const CrudListHeader: FC<CrudListHeaderProps> = ({
  pageTitle,
  fieldLabels = [],
  fieldNames = [],
  importFileProcessor = undefined,
  creationCallback,
  importEnabled = false,
  importFileFormat = ".xlsx",
  exportEnabled = false,
  useDeleteMutation,
  useUpsertManyMutation,
  selectedItems,
  data,
  fieldsProcessors = [],
  search,
  setSearch,
  searchPlaceHolder = undefined,
  readPermissions = [],
  writePermissions = [],
  customMassActions = undefined,
  children = <></>,
}) => {
  const { currentSite } = useContext(CurrentSiteContext);
  const defaultProcessor = (file) => readExcelFile(file, fieldNames, fieldsProcessors, currentSite);
  const fileProcessor = importFileProcessor ?? defaultProcessor;
  const { permissions } = useConnectedUser();
  const hasReadPermission = hasAnyPermission(readPermissions, permissions);
  const hasWritePermission = hasAnyPermission(writePermissions, permissions);
  const standardMassActions = (
    selectedItems,
    useUpsertManyMutation,
    useDeleteMutation,
    setMessage
  ) => (
    <StandardMassActions
      selectedItems={selectedItems}
      useDeleteMutation={useDeleteMutation}
      useUpsertManyMutation={useUpsertManyMutation}
      setMessage={setMessage}
    />
  );

  const renderImportExport = (setMessage: (s: string) => void) => {
    const onExport = () => {
      try {
        exportToExcel(data.list, fieldLabels, fieldNames);
      } catch (error) {
        setMessage(error?.toString());
      }
    };
    return (
      <>
        {importEnabled && hasWritePermission && (
          <CrudImport
            fileProcessor={fileProcessor}
            useUpsertManyMutation={useUpsertManyMutation}
            accept={importFileFormat}
            setMessage={setMessage}
          />
        )}
        {exportEnabled && hasReadPermission && (
          <ExportButton onClick={onExport}>Exporter</ExportButton>
        )}
      </>
    );
  };

  return (
    <PageHeader
      tw="md:(px-32) mt-8"
      title={
        <div tw="flex justify-between">
          <PageTitle>{pageTitle}</PageTitle>
          <HeaderBar
            search={search}
            setSearch={setSearch}
            searchPlaceHolder={searchPlaceHolder}
            selectedItems={selectedItems}
            creationCallback={creationCallback}
            useUpsertManyMutation={useUpsertManyMutation}
            useDeleteMutation={useDeleteMutation}
            hasWritePermission={hasWritePermission}
            renderImportExport={renderImportExport}
            renderMassActions={customMassActions ?? standardMassActions}
          >
            {children}
          </HeaderBar>
        </div>
      }
    />
  );
};

/**
 * children = top menu options
 * massActions = "see more" menu options
 */
const HeaderBar = ({
  search,
  setSearch,
  searchPlaceHolder,
  selectedItems,
  renderImportExport,
  renderMassActions,
  creationCallback,
  useDeleteMutation,
  useUpsertManyMutation,
  hasWritePermission,
  children,
}) => {
  const [message, setMessage] = useState<string>();

  return (
    <Container tw="flex flex-row space-x-2 flex-wrap mx-0 sm:px-0 justify-end">
      <SearchBar value={search} onChange={setSearch} searchPlaceHolder={searchPlaceHolder} />
      {renderImportExport(setMessage)}
      {children}
      {hasWritePermission && (
        <>
          <AddButton onClick={() => creationCallback()}>Créer</AddButton>
          <Menu>
            <MenuButton disabled={selectedItems.length === 0} tw="h-full">
              <DotsVerticalIcon tw="h-5 w-5" />
            </MenuButton>
            <MenuItems>
              {renderMassActions(
                selectedItems,
                useUpsertManyMutation,
                useDeleteMutation,
                setMessage
              )}
            </MenuItems>
          </Menu>
        </>
      )}
      <Alert message={message} setMessage={setMessage} />
    </Container>
  );
};

const SearchBar = ({ value, onChange, searchPlaceHolder, ...props }) => {
  return (
    <div tw="relative rounded-md shadow-sm" {...props}>
      <div tw="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchSolidIcon tw="h-5 w-5 text-gray-400" />
      </div>
      <input
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        type="search"
        tw="focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full rounded-md pl-10 border-gray-300 text-sm"
        placeholder={searchPlaceHolder ?? "Rechercher"}
        aria-label={searchPlaceHolder ?? "Rechercher"}
      />
    </div>
  );
};

type StandardMassActionsType = {
  selectedItems: MongoDocument[];
  useDeleteMutation: () => UseMutationResult<unknown, { message: string }>;
  useUpsertManyMutation: () => UseMutationResult<unknown, { message: string }>;
  setMessage: (s: string) => void;
};
export const StandardMassActions = ({
  selectedItems,
  useDeleteMutation,
  useUpsertManyMutation,
  setMessage,
}: StandardMassActionsType) => {
  const archivedCss = { color: StandardStatusColors.get(StandardStatus.archived) };
  const activeCss = { color: StandardStatusColors.get(StandardStatus.active) };
  return (
    <>
      <UpdateCrudStatusMenuItem
        status={StandardStatus.active}
        items={selectedItems}
        useUpsertManyMutation={useUpsertManyMutation}
        setMessage={setMessage}
      >
        <CrudStatusIcon style={activeCss} />
        Activer
      </UpdateCrudStatusMenuItem>
      <UpdateCrudStatusMenuItem
        status={StandardStatus.archived}
        items={selectedItems}
        useUpsertManyMutation={useUpsertManyMutation}
        setMessage={setMessage}
      >
        <CrudStatusIcon style={archivedCss} />
        Désactiver
      </UpdateCrudStatusMenuItem>
      <DeleteMenuItem
        items={selectedItems}
        useDeleteMutation={useDeleteMutation}
        setMessage={setMessage}
      >
        <TrashIcon tw="text-gray-500!" />
        Supprimer définitivement
      </DeleteMenuItem>
    </>
  );
};

export const CrudStatusIcon = (props) => {
  return (
    <svg fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="3" />
      <circle cx="10" cy="10" r="6" fillOpacity={0.3} />
    </svg>
  );
};

type UpdateCrudStatusMenuItemProps = {
  status: StandardStatus | WorkOrderStatus;
  items: MongoDocument[];
  useUpsertManyMutation: (retry: boolean, onError: (error: unknown) => void) => UseMutationResult<unknown, { message: string }>;
  setMessage: (s: string) => void;
  [s: string]: unknown;
};
export const UpdateCrudStatusMenuItem: FC<UpdateCrudStatusMenuItemProps> = ({
  status,
  items,
  useUpsertManyMutation,
  setMessage,
  ...props
}) => {
  // following state fix the bug where react query calls onError twice:
  // the 2nd time, response body stream is "already consumed" which prevent to display the actual error
  const { mutateAsync: upsertManyItem } = useUpsertManyMutation(false, async (error) => {
    const message = await handleError(error);
    if (message) {
      setMessage(message);
    } else {
      console.log("Error on mass update", error);
      
    }
  });

  const onUpsertManyItems = () => {
    upsertManyItem(
      items.map((item) => ({
        _id: item._id,
        status,
      })),
    );
  };
  return <MenuItemButton onClick={onUpsertManyItems} {...props} />;
};

type DeleteMenuItemProps = {
  items: MongoDocument[];
  useDeleteMutation: () => UseMutationResult<unknown, { message: string }>;
  setMessage: (s: string) => void;
  [s: string]: unknown;
};
export const DeleteMenuItem: FC<DeleteMenuItemProps> = ({
  items,
  useDeleteMutation,
  setMessage,
  ...props
}) => {
  const { mutateAsync: deleteManyItem, error } = useDeleteMutation();
  useEffect(() => {
    setMessage(error?.message);
  }, [error]);
  const onDeleteManyItem = () => deleteManyItem(items);
  return <MenuItemButton onClick={onDeleteManyItem} {...props} />;
};
