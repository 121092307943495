/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useMemo, useState } from "react";
import "twin.macro";
import tw from "twin.macro";
import { SchedulerResource } from "../planning/Scheduler/SchedulerResource";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";

import { getEachDayOfPeriod } from "../shared/functions/Datetime";
import { buildSiteFilter, Filter } from "../shared/functions/QueryHelper";
import { isVehicleUnavailable } from "../shared/functions/VehicleManagement";
import {
  addHeaderEvents,
  getPrimaryVehicle,
  mapWorkOrderToEvent,
} from "../shared/functions/WorkOrderManagement";
import { WorkOrder } from "../types/WorkOrder";
import { PageContext } from "./API/PageContext";
import { useSearchQuery as useVehicleQuery } from "../shared/hooks/useVehicleQueries";
import { useWorkOrderQuery } from "./API/workOrderQueries";
import { SummaryWorkOrder } from "./SummaryWorkOrder";

const PERIOD_RANGE = "week";
const NB_VEHICLES_PAGE = 25;
const TIME_PAGE = 10000;

//
// TV screen broadcast for employee breakroom
//
const SummaryPage = () => {
  const { currentSite } = useContext(CurrentSiteContext);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [sortedVehicle, setSortedVehicle] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);

  const { page, setPage, maxPage, setMaxPage } = useContext(PageContext);
  const { data: vehicles } = useVehicleQuery({
    ...Filter.and({ principal: true, status: "Actif" }, buildSiteFilter(currentSite)),
  });

  const { data: workOrdersData } = useWorkOrderQuery(new Date());
  useEffect(() => {
    if (workOrdersData?.list) {
      const events = workOrdersData.list.map(mapWorkOrderToEvent) as WorkOrder[];
      const workOrdersWithHeaders = addHeaderEvents(events).filter(
        (w) =>
          !w.isHeaderWorkOrder ||
          (w.hasTeamHeader && w.team.length > 0) ||
          (w.hasVehicleHeader && w.vehicles.length > 1)
      );
      setWorkOrders(workOrdersWithHeaders);
    }
  }, [workOrdersData, setWorkOrders]);

  const sortVehicle = (v1, v2) => {
    if (!v1.position) {
      return 1;
    }
    if (!v2.position) {
      return -1;
    } else return v1.position <= v2.position ? -1 : 1;
  };

  const pageChange = () => {
    const pageTmp = (page + 1) % maxPage;
    setPage(pageTmp);
    setSelectedVehicles(
      sortedVehicle.slice(
        (pageTmp * sortedVehicle.length) / maxPage,
        ((pageTmp + 1) * sortedVehicle.length) / maxPage
      )
    );
  };

  useEffect(() => {
    setSelectedVehicles(
      sortedVehicle?.slice(
        (page * sortedVehicle.length) / maxPage,
        ((page + 1) * sortedVehicle.length) / maxPage
      )
    );
  }, [sortedVehicle]);

  useEffect(() => {
    if (!sortedVehicle) return;
    if (sortedVehicle?.length <= NB_VEHICLES_PAGE) {
      setPage(undefined);
      return setSelectedVehicles(sortedVehicle);
    }

    const timout = setTimeout(pageChange, TIME_PAGE);
    return () => clearTimeout(timout);
  }, [page, sortedVehicle]);

  useEffect(() => {
    setSortedVehicle(vehicles?.list.sort(sortVehicle));
    if (vehicles?.list && vehicles?.list?.length > NB_VEHICLES_PAGE) {
      setMaxPage(Math.ceil(vehicles?.list.length / NB_VEHICLES_PAGE));
      setPage(0);
    }
  }, [vehicles]);

  const invalids = useMemo(() => {
    if (!sortedVehicle) return;
    const days = getEachDayOfPeriod(new Date(), "week");
    return days
      .flatMap((day) =>
        sortedVehicle.map((vehicle) => {
          const unavailability = isVehicleUnavailable(vehicle, day);
          return (
            unavailability && {
              allDay: true,
              start: day,
              end: day,
              title: unavailability.type.toString(),
              resource: vehicle._id,
            }
          );
        })
      )
      .filter((u) => !!u);
  }, [sortedVehicle]);

  return (
    <div tw="overflow-y-auto h-full">
      <table tw="w-full [table-layout: fixed]  h-full ">
        <SummaryHeader tw="text-sm" />
        <tbody>
          {selectedVehicles?.map((vehicle) => (
            <SummaryBodyLine key={vehicle._id} vehicle={vehicle}>
              <SummaryBodyWeek
                invalids={invalids?.filter((i) => i.resource === vehicle._id)}
                workOrders={workOrders?.filter((w) => getPrimaryVehicle(w)._id === vehicle._id)}
              />
            </SummaryBodyLine>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SummaryHeader = ({ ...props }) => {
  const days = getEachDayOfPeriod(new Date(), PERIOD_RANGE);
  return (
    <thead {...props}>
      <tr tw="border-0 bg-transparent">
        <th tw="h-3 w-28"></th>
        {days?.map((day) => {
          if (day.getUTCDay() < 5)
            return (
              <th key={day.toISOString()} tw=" p-0">
                <div tw=" mx-0.5 rounded-t border bg-indigo-200 border-indigo-300 text-indigo-800">
                  {day.toLocaleDateString('fr-FR', {weekday: "long", month: "numeric", day: "numeric"})}
                </div>
              </th>
            );
        })}
      </tr>
    </thead>
  );
};

const SummaryBodyLine = ({ vehicle, children }) => {
  return (
    <tr tw="odd:bg-indigo-50 even:bg-[#f3f6ff]">
      <td tw="border border-indigo-200 odd:bg-indigo-100 ">
        <SchedulerResource resource={vehicle} onClick={() => undefined} />
      </td>
      {children}
    </tr>
  );
};

type SummaryBodyWeekProps = {
  workOrders?: WorkOrder[];
  invalids;
};
const SummaryBodyWeek = ({ workOrders = [], invalids = [] }: SummaryBodyWeekProps) => {
  const workOrdersTab: WorkOrder[][] = [[], [], [], [], []];
  workOrders.forEach((w) => workOrdersTab[new Date(w.start).getUTCDay() - 1]?.push(w));
  return (
    <>
      {workOrdersTab.map((w, i) => (
        <SummaryBodyWorkOrders
          invalid={invalids.find((invalid) => invalid.start.getUTCDay() === i)}
          key={i}
          workOrders={w.sort((a, b) => (a.isHeaderWorkOrder ? -1 : a?.start < b?.start ? -1 : 1))}
        />
      ))}
    </>
  );
};

type SummaryBodyWorkOrdersProps = {
  workOrders: WorkOrder[];
  invalid;
};
const SummaryBodyWorkOrders = ({ workOrders, invalid }: SummaryBodyWorkOrdersProps) => {
  return (
    <td tw="border border-gray-300  align-top pt-1" css={[invalid && tw`bg-gray-200`]}>
      {invalid ? (
        <span tw=" mx-1.5 text-gray-500 text-xs">{invalid.title}</span>
      ) : (
        workOrders.map((w) => {
          return (
            <div key={w._id ?? w.id}>
              <SummaryWorkOrder event={w} />
              {w.isHeaderWorkOrder && <hr tw="border-dashed my-1 border-gray-500" />}
            </div>
          );
        })
      )}
    </td>
  );
};

export default SummaryPage;
