/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Person } from "../../../types/Person";
import { DrivingLicenseBadge } from "../Badge";
import { CheckBoxStyled } from "../Form";

type WorkOrderPersonCheckboxProps = {
  person: Person;
  register: (name: string) => unknown;
  [s: string]: unknown;
};

export const WorkOrderPersonCheckbox: FC<WorkOrderPersonCheckboxProps> = ({ person, ...props }) => {
  const { register } = useFormContext();
  return (
    <div tw="relative flex items-start space-y-2">
      <div tw="pt-1">
        <CheckBoxStyled
          {...register("personIds")}
          id={`person-${person._id}`}
          value={person._id}
          aria-describedby={`person-${person._id}-desc`}
          type="checkbox"
          {...props}
        />
      </div>
      <div tw="ml-3 text-sm">
        <label htmlFor={`person-${person._id}`} tw="font-medium text-gray-700 cursor-pointer">
          <span tw="font-bold" css={[person.tempWorker && tw`text-yellow-500`]}>{person.name?.toUpperCase()}</span> <span css={[person.tempWorker && tw`text-yellow-500`]}>{person.shortName}</span>
        </label>
        <span id={`person-${person._id}-desc`} tw="text-gray-500 ml-1">
          {person.drivingLicenses.map((d) => (
            <DrivingLicenseBadge key={d} drivingLicense={d} />
          ))}
        </span>
      </div>
    </div>
  );
};
