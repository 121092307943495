/** @jsxImportSource @emotion/react */
import "twin.macro";
import { FunctionComponent } from "react";
import { WorkOrder } from "../../../types/WorkOrder";
import { BadgeContainer } from "../../../mobile/workOrderList/WorkOrderListItem";
import { timeDisplay } from "../../functions/Datetime";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import tw from "twin.macro";
import { getPrimaryVehicle } from "../../functions/WorkOrderManagement";

type WorkOrderAttachmentsProps = {
  workOrder: WorkOrder;
  [other: string]: unknown;
};
export const WorkOrderHistory: FunctionComponent<WorkOrderAttachmentsProps> = ({
  workOrder,
  ...props
}) => {
  return (
    <section tw="mx-4 min-h-full" {...props}>
      <header tw="flex flex-row space-x-5 items-center border-t border-gray-200">
        <h3 tw="text-lg leading-6 font-medium text-gray-900 py-3">
          Historique de l&apos;OT {workOrder.workOrderNumber && `n° ${workOrder.workOrderNumber} `}
          {workOrder.title}
        </h3>
      </header>
      <div tw="mt-4 xl:w-3/6">
        <ul role="list" tw="-mb-8">
          <OTHistoryItem
            OTStatus="Création"
            mot="Créé"
            person={workOrder.valvertContactName}
            date={workOrder.createdAt}
            workOrder={workOrder}
          />
          {workOrder.actualStart && (
            <OTHistoryItem
              OTStatus={WorkOrderStatus.EN_COURS}
              mot="Validé"
              person={getPrimaryVehicle(workOrder)?.name}
              date={workOrder.actualStart}
              workOrder={workOrder}
            />
          )}
          {timeDisplay(workOrder.actualEnd) !== "" && (
            <OTHistoryItem
              OTStatus={WorkOrderStatus.TERMINE}
              mot="Confirmé"
              person={getPrimaryVehicle(workOrder)?.name}
              date={workOrder.actualEnd}
              workOrder={workOrder}
            />
          )}

          {workOrder.goBackReasons && workOrder.goBackReasons.length > 0 && (
            <>
              <h4 tw="text-base leading-4 font-medium text-gray-700 py-3 mb-4">
                Historique des actions annulées de statuts de l&apos;OT{" "}
                {workOrder.workOrderNumber && `n° ${workOrder.workOrderNumber} `}
                {workOrder.title}
              </h4>

              {workOrder.goBackReasons.map((event, index) => (
                <OTHistoryItemGoBackReason
                  key={index}
                  OTStatus={event.originStatus}
                  person={workOrder.vehicles[0].name}
                  date={event.time}
                  description={event.description}
                />
              ))}
            </>
          )}
        </ul>
      </div>
    </section>
  );
};

const OTHistoryItem = ({ OTStatus, person, date, workOrder, mot }) => {
  return (
    <li>
      <div tw="relative pb-8">
        {OTStatus === "Création" ? null : (
          <span
            tw="absolute bottom-10 left-3 -ml-px h-full w-0.5 bg-gray-200 z-0"
            aria-hidden="true"
            css={[
              OTStatus === WorkOrderStatus.EN_COURS && tw`h-16 bottom-14`,
              OTStatus === WorkOrderStatus.TERMINE && tw`h-20 bottom-10`,
            ]}
          />
        )}
        <div tw="relative flex items-center space-x-3 z-10">
          <div>
            <BadgeContainer status={OTStatus} />
          </div>
          <div tw="min-w-0 flex-1 flex justify-between space-x-4">
            <div tw="text-xs sm:text-sm text-gray-500">
              {mot} par <span tw="font-medium text-gray-900">{person}</span>
            </div>
            {date && (
              <div tw="flex items-center text-right text-xs sm:text-sm whitespace-nowrap text-gray-500">
                {date.toString().slice(8, 10)}/{date.toString().slice(5, 7)}/
                {date.toString().slice(0, 4)} à {timeDisplay(date)}
              </div>
            )}
          </div>
        </div>
        {workOrder?.location && workOrder?.location.length > 0 && mot === "Validé" && (
          <div tw="ml-6 text-xs sm:text-sm text-gray-500">
            {(workOrder.location[0].lat === undefined || workOrder.location[0].lng === undefined ) 
              ? (<span tw="hidden sm:inline-block">Pas de Coordonnées GPS</span>)
              : (<div>
                <span tw="hidden sm:inline-block">Coordonnées GPS : </span> Lat:
                {workOrder.location[0].lat} Long:
                {workOrder.location[0].lng}
              </div>)
            }
          </div>
        )}
      </div>
    </li>
  );
};

const OTHistoryItemGoBackReason = ({ OTStatus, person, date, description }) => {
  return (
    <li>
      <div tw="relative pb-8">
        <div tw="relative flex items-center space-x-3">
          <div tw="flex">
            <BadgeContainer status={OTStatus} />
            <ArrowNarrowRightIcon tw="w-4 h-auto text-gray-400 pt-1" />
            <BadgeContainer
              status={
                OTStatus === WorkOrderStatus.TERMINE
                  ? WorkOrderStatus.EN_COURS
                  : OTStatus === WorkOrderStatus.EN_COURS
                  ? WorkOrderStatus.RECU
                  : OTStatus === WorkOrderStatus.NON_TERMINE
                  ? WorkOrderStatus.EN_COURS
                  : null
              }
            />
          </div>
          <div tw="min-w-0 flex-1 flex justify-between space-x-4">
            <p tw="text-xs sm:text-sm text-gray-500">
              Validé par <span tw="font-medium text-gray-900">{person}</span>
            </p>
            <p tw="flex items-center text-right text-xs sm:text-sm whitespace-nowrap text-gray-500">
              {date.toString().slice(8, 10)}/{date.toString().slice(5, 7)}/
              {date.toString().slice(0, 4)} à {timeDisplay(date)}
            </p>
          </div>
        </div>
        <p tw="ml-6 text-xs sm:text-sm text-gray-500">Motif : {description}</p>
      </div>
    </li>
  );
};
