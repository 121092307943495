/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { useMemo } from "react";
import { Period } from "../../../types/Period";
import { timeDisplay } from "../../functions/Datetime";
import { WorkOrder } from "../../../types/WorkOrder";

type TimeLabelProps = {
  start: Date;
  end: Date;
  allDay: boolean;
  period: Period;
  event: WorkOrder;
};
export const TimeLabel = ({ start, end, allDay, period, event }: TimeLabelProps) => {
  const tStart = allDay ? "" : timeDisplay(start);
  const tEnd = allDay ? "" : timeDisplay(end);
  const containerCss = useMemo(() => {
    const result = [];
    result.push(period === "day" ? tw`px-2` : tw`px-1`); // padding
    result.push(period === "day" ? tw`flex flex-row` : tw`flex-none`); // row or col
    result.push(period === "day" ? tw`text-base` : tw`text-xs`); // text size
    return result;
  }, [period]);
  const separatorCss = useMemo(
    () => (period === "day" && !allDay ? { display: "block" } : { display: "none" }),
    [period, allDay]
  );
  return (
    <aside tw="w-auto px-0.5 text-gray-900" css={containerCss}>
      <div
        css={[
          (event?.status === "Terminé" || event?.status === "Non terminé") && tw`text-white`,
          tw`text-center`,
        ]}
      >
        {tStart}
      </div>
      {period === "day" && (
        <>
          {tEnd !== "" && <div css={separatorCss}>&nbsp;-&nbsp;</div>}
          <div tw="text-center">{tEnd}</div>
        </>
      )}
    </aside>
  );
};
