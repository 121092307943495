/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import "twin.macro";
import { CrudCreation, CrudDetails } from "../shared/components/CrudForm";
import { SideModal } from "../shared/components/Modal";
import {
  useUpsertMutation as useSiteUpsertMutation,
  useFindByIdQuery as useSiteFindByQuery,
} from "./useSiteQueries";
import { formValuesToSite, SiteForm, siteToFormValues } from "./SiteForm";
import { Permission } from "../types/Role";

/*
 * Use line bellow to control modal from outside
 * const { isOpen, onOpen, onClose } = useDisclosure(false);
 */

export const SiteModal = ({ siteId, isOpen, onClose, setError }) => {
  const renderSiteFormFields = useCallback(() => <SiteForm setError={setError} />, []);
  return (
    <SideModal isOpen={isOpen} onDismiss={onClose} aria-label="Popup de création de site">
      {siteId && (
        <CrudDetails
          title="Informations du site"
          objectId={siteId}
          renderFormFields={renderSiteFormFields}
          useFindByIdQuery={useSiteFindByQuery}
          useUpsertMutation={useSiteUpsertMutation}
          itemToFormValues={siteToFormValues}
          formValuesToItem={formValuesToSite}
          closeModalAction={onClose}
          readPermissions={[Permission.SITE_READ]}
          writePermissions={[Permission.SITE_WRITE]}
        />
      )}
      {!siteId && (
        <CrudCreation
          title="Informations du site"
          renderFormFields={renderSiteFormFields}
          useUpsertMutation={useSiteUpsertMutation}
          itemToFormValues={siteToFormValues}
          formValuesToItem={formValuesToSite}
          closeModalAction={onClose}
          writePermissions={[Permission.SITE_WRITE]}
        />
      )}
    </SideModal>
  );
};
