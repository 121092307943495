/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import styled from "styled-components";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { useController } from "react-hook-form";
import Select from "react-select";
import { FC } from "react";
import { Tooltip } from "./Tooltip";

export const FormBase = styled("form")(tw`space-y-8 divide-y divide-gray-200 p-4`);

export const FormBody = ({ children, ...props }) => (
  <section tw="py-8 divide-y divide-gray-200 sm:py-5" {...props}>
    {children}
  </section>
);

export const FormHeader = styled("header")(tw`space-y-8 divide-y divide-gray-200 sm:space-y-5`);

export const FormFooter = ({ children, ...props }) => (
  <div tw="pt-5 flex justify-end" {...props}>
    {children}
  </div>
);

export const InputStyled = styled("input")(
  tw`flex-1 block w-full text-sm z-0 focus:z-10 border-gray-300 rounded-md focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500)`
);

export const CheckBoxStyled = styled("input")(
  tw`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer disabled:(text-gray-400 cursor-default)`
);

export const TextAreaStyled = styled("textarea")(
  tw`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:(bg-gray-50 text-gray-500)`
);

export const SelectStyled = styled("select")(
  tw` block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:(bg-gray-50 text-gray-500 cursor-default)`
);

type ReactSelectProps = {
  name: string;
  defaultValue: string | string[];
  // Resolve option even when entries are not loaded from the Dimension collection.
  // Use the value as a label. If no value is defined, no element is selected
  options: Array<{
    value: unknown;
    label: unknown;
    name: string | number;
  }>;
  [s: string]: unknown;
};
export const ReactSelect: FC<ReactSelectProps> = ({ name, defaultValue, options, ...props }) => {
  const {
    field: { value, onChange, ref, ...controllerProps },
  } = useController({
    name,
    defaultValue,
  });

  const selectedOption = value ? options.find((e) => e.name === value) : null;

  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.name : null);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      backgroundColor: state.isSelected ? "#6366F1" : state.isFocused ? "#CCCCCC" : "white",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#6366F1" : "#D1D5DB",
    }),
  };

  return (
    <>
      <Select
        styles={customStyles}
        value={selectedOption}
        onChange={handleChange}
        isSearchable={false}
        options={options}
        ref={ref}
        {...controllerProps}
        tw=" border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:(bg-gray-50 text-gray-500 cursor-default)"
        {...props}
      />
    </>
  );
};

export const NumberStyled = styled("input")(
  tw` block w-full px-1.5 py-1 text-base font-normal border border-gray-300 border-solid rounded transition ease-in-out m-0  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`
);

export const InputLabel = ({ label, inputId, tooltipMessage = undefined, children, ...props }) => (
  <div
    tw="sm:grid sm:grid-flow-col sm:auto-cols-fr sm:gap-y-4 sm:items-start sm:pt-5 mt-5"
    {...props}
  >
    <label htmlFor={inputId} tw=" inline-flex text-sm font-medium text-gray-700 sm:mt-px sm:py-2">
      {label}
      {tooltipMessage && (
        <Tooltip message={tooltipMessage}>
          <QuestionMarkCircleIcon tw=" mx-1.5 h-4 w-auto text-indigo-500" />
        </Tooltip>
      )}
    </label>
    <div tw="mt-1 sm:mt-0 sm:col-span-2">{children}</div>
  </div>
);

export const FormGroup = ({ children, ...props }) => {
  return (
    <div tw="space-y-1" {...props}>
      {children}
    </div>
  );
};

export const Label = ({ children, ...props }) => {
  return (
    <label tw="block text-sm font-medium text-gray-700 truncate" {...props}>
      {children}
    </label>
  );
};

export const ErrorMessage = styled("div")(tw`flex text-sm font-medium pt-1 text-red-500`);

export const RequiredAsterisk = (props) => {
  return (
    <span tw="text-red-500 font-medium" {...props}>
      *
    </span>
  );
};
