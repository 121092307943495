import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { Global } from "@emotion/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import tw, { GlobalStyles } from "twin.macro";
import { ErrorBoundary } from "@sentry/react";
import i18n from "./i18n";
import { AppRoutes } from "./Routes";
import { overrideGlobalToJSON } from "./shared/functions/Datetime";
import "./App.css";
import { ConnectedUserContext } from "./shared/contexts/ConnectedUserContext";
import { useEffect, useState } from "react";
import { ConnectedUser } from "./types/ConnectedUser";
import { CurrentSiteContext } from "./shared/contexts/CurrentSiteContext";
import { Site } from "./types/Site";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    // This is a global override to prevent browser to apply Timezone offset when sending dates to API
    // In the scope of this project, we don't need timezone management which adds useless complexity.
    overrideGlobalToJSON();
  }, []);


  const [connectedUser, setConnectedUser] = useState<ConnectedUser>({
    permissions: [],
    permissionsLoaded: false,
  });
  const [currentSite, setCurrentSite] = useState<Site>();

  useEffect(() => {
    setCurrentSite(connectedUser?.connectedPerson?.site ?? connectedUser?.connectedVehicle?.site);
  }, [connectedUser])

  return (
    <ErrorBoundary
      showDialog={process.env.NODE_ENV === "production"}
      dialogOptions={{
        user: {
          name: window.localStorage.getItem("auth0_username"),
          email: `${window.localStorage.getItem("auth0_nickname")}@valvert-ra.com`,
        },
        labelName: "Nom d'utilisateur",
        labelEmail: "Autre nom",
      }}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
        useRefreshTokens={true}
      >
        <QueryClientProvider client={queryClient}>
          <ConnectedUserContext.Provider value={{connectedUser, setConnectedUser}}>
            <CurrentSiteContext.Provider value={{currentSite, setCurrentSite}}>
              <GlobalStyles />
              <Global styles={{ body: tw`antialiased font-sans` }} />
              <I18nextProvider i18n={i18n}>
                <HelmetProvider>
                  <Helmet titleTemplate={`%s | ${process.env.REACT_APP_WHITE_LABEL}`} />
                  <AppRoutes />
                </HelmetProvider>
              </I18nextProvider>
            </CurrentSiteContext.Provider>
          </ConnectedUserContext.Provider>
        </QueryClientProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

export default App;
