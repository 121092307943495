/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useEffect, useState } from "react";
import { StatusOfflineIcon, StatusOnlineIcon } from "@heroicons/react/outline";

const NetworkStatus = () => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  return status ? (
    <StatusOnlineIcon tw="h-4 w-auto text-gray-400" />
  ) : (
    <StatusOfflineIcon tw="h-4 w-auto text-gray-400" />
  );
};

export default NetworkStatus;
