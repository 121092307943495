/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import "twin.macro";
import { PageMobile } from "../../../shared/components/PageMobile";
import {
  useUpsertWorkOrderMutation,
  useWorkOrderFindByIdQuery,
} from "../../../shared/hooks/useWorkOrderQueries";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { ModalGoBackReasonStatus } from "../../MobileModal";
import InProgress from "./InProgress";
import InProgressSkeleton from "./InProgressSkeleton";

const InProgressPage = () => {
  const [goBack, setGoBack] = useState(false); // open unlock modal if true
  const navigate = useNavigate();

  // Get workorder from list query for a better offline mode
  // (if user didn't visit the workorder detail online, he can still do it offline)
  const { otId } = useParams();
  const { status, data: workOrder } = useWorkOrderFindByIdQuery(otId);

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();
  const handleUpsertWorkOrder = () => {
    upsertWorkOrder(
      {
        ...workOrder,
        status: WorkOrderStatus.TERMINE,
        actualEnd: new Date(),
      },
      {
        onSuccess: () => {
          navigate(`/mobile/${otId}/signature`);
        },
      }
    );
  };

  const handleNotFinishUpsertWorkOrder = () => {
    upsertWorkOrder(
      {
        ...workOrder,
        status: WorkOrderStatus.NON_TERMINE,
        actualEnd: new Date(),
      },
      {
        onSuccess: () => {
          navigate(`/mobile/${otId}/signature`);
        },
      }
    );
  };

  // If an OT do not exist anymore, redirect to /mobile
  useEffect(() => {
    if (status === "success" && workOrder === undefined) {
      navigate(`/mobile`);
    }
  }, [workOrder]);

  return (
    <>
      <Helmet title={status === "success" ? workOrder?.title : process.env.REACT_APP_WHITE_LABEL} />
      <PageMobile>
        {status === "loading" && <InProgressSkeleton />}
        {workOrder && status === "success" && (
          <>
            <InProgress
              cancelWorkOrder={() => {
                setGoBack(true);
              }}
              confirmFinishWorkOrder={handleUpsertWorkOrder}
              confirmNotFinishWorkOrder={handleNotFinishUpsertWorkOrder}
              status={status}
              workOrder={workOrder}
              otId={otId}
            />
            {goBack && (
              <ModalGoBackReasonStatus
                setGoBack={setGoBack}
                workOrder={workOrder}
                title="Pour quelle raison voulez-vous dévérouiller l'intervention ?"
              />
            )}
          </>
        )}
      </PageMobile>
    </>
  );
};

export default InProgressPage;
