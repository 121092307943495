import { useState } from "react";
import { useDebounce } from "./useDebounce";
import { useDisclosure } from "./useDisclosure";
import { useItemSelection } from "./useItemSelection";

export const useCrudParams = () => {
  const { isOpen: itemIsOpen, onOpen: onItemOpen, onClose: onItemClose, data: itemId } = useDisclosure(false);
  const { selectedItems, isSelected: isItemSelected, onToggle: onItemToggle, onToggleMany: onItemsToggle } = useItemSelection();
  const [search, setSearch] = useState(undefined);
  const [ pagination, setPagination ] = useState(undefined)
  const debouncedSearch = useDebounce(search);
  return {
    itemIsOpen,
    onItemOpen,
    onItemClose,
    itemId,
    selectedItems,
    isItemSelected,
    onItemToggle,
    onItemsToggle,
    search,
    debouncedSearch,
    setSearch,
    setPagination,
    pagination,
  };
};
