import { Unavailability } from "../../types/Unavailability";
import { Vehicle } from "../../types/Vehicle";
import { dateIncludedInPeriod } from "./Datetime";

export const isVehicleUnavailable = (vehicle: Vehicle, date: Date): Unavailability => {
  if (!vehicle || !date || !vehicle.unavailabilities || vehicle.unavailabilities.length === 0) {
    return undefined;
  }

  // not available if date included in one period of unavailability
  return vehicle.unavailabilities.find((u) => dateIncludedInPeriod(date, u.start, u.end));
};
