/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMessage as CustomErrorMessage,
  FormGroup,
  InputLabel,
  InputStyled,
  Label,
  RequiredAsterisk,
} from "../shared/components/Form";
import { Client } from "../types/Client";
import { StandardStatus } from "../types/StandardStatus";

export const ClientForm = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div tw="grid grid-cols-2 gap-6 mt-6" {...props}>
      <FormGroup>
        <Label htmlFor="client-name">
          Nom
          <RequiredAsterisk tw="text-red-500" />
        </Label>
        <InputStyled
          type="text"
          {...register("name", { required: "Champs obligatoire" })}
          id="client-name"
        />
        <ErrorMessage as={CustomErrorMessage} name="name" errors={errors}  />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="client-address">
          Adresse
          <RequiredAsterisk tw="text-red-500" />
        </Label>
        <InputStyled
          type="text"
          {...register("address", { required: "Champs obligatoire" })}
          id="client-address"
        />
        <ErrorMessage as={CustomErrorMessage} name="address" errors={errors}  />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="client-postalCode">Code postal</Label>
        <InputStyled type="text" {...register("postalCode")} id="client-postalCode" />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="client-city">Ville</Label>
        <InputStyled type="text" {...register("city")} id="client-city" />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="contact-name">Contact chantier</Label>
        <InputStyled type="text" {...register("contactName")} id="contact-name" />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="client-contact-phone">Tél. du contact</Label>
        <InputStyled type="text" {...register("contactPhone")} id="client-contact-phone" />
      </FormGroup>

      <FormGroup>
        <InputLabel label="Site" inputId="client-site">
          <InputStyled type="text" {...register("site", { required: true })} id="client-site" />
        </InputLabel>
      </FormGroup>
    </div>
  );
};

export const clientToFormValues = (client: Client) => ({
  ...client,
  status: client.status ?? StandardStatus.active,
});

export const formValuesToClient = (formValues) => ({
  ...formValues,
  status: formValues.status ?? StandardStatus.active,
});
