/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import styled from "styled-components";
import {
  DocumentAddIcon,
  DocumentDownloadIcon,
  PlusIcon,
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import {
  AttachmentIcon,
  BackIcon,
  CancelIcon,
  CheckIcon,
  DuplicateIcon,
  SaveIcon,
  XOutlineIcon,
} from "./Icons";
import { LoadingIndicator } from "./LoadingIndicator";
import { CloudIcon, RewindIcon } from "@heroicons/react/outline";

type ButtonBaseProps = {
  $green?: boolean;
  $blue?: boolean;
  $red?: boolean;
  $orange?: boolean;
  $gray?: boolean;
  $pink?: boolean;
  $lightgray?: boolean;
  $white?: boolean;
};
export const ButtonBase = styled.button<ButtonBaseProps>(
  tw`inline-flex flex-row items-center space-x-1.5 px-3 py-2.5 border text-sm font-medium rounded-md`,
  tw`focus:(outline-none ring-2 ring-offset-2 ring-indigo-500)`,
  tw`disabled:(opacity-50 pointer-events-none)`,
  ({ disabled }) => disabled && tw`opacity-50 pointer-events-none`,
  // $attribute is not passed to DOM and avoid console warning
  ({ $green }) => $green && tw`bg-green-600 hover:(bg-green-600) border-0 text-gray-100`,
  ({ $blue }) => $blue && tw`bg-indigo-600 hover:(bg-indigo-700) border-0 text-gray-100`,
  ({ $red }) => $red && tw`bg-red-600 hover:(bg-red-700) border-0 text-gray-100`,
  ({ $orange }) => $orange && tw`bg-yellow-600 hover:(bg-yellow-700) border-0 text-gray-100`,
  ({ $gray }) => $gray && tw`bg-gray-800 hover:(bg-gray-900) border-0 text-gray-100`,
  ({ $pink }) => $pink && tw`bg-pink-800 hover:(bg-pink-900) border-0 text-gray-100`,
  ({ $lightgray }) => $lightgray && tw`bg-gray-200 hover:(bg-gray-300) border-0 text-gray-700`,
  ({ $white }) => $white && tw`bg-white hover:(bg-gray-50) border border-gray-300 text-gray-700`
);

export const PrimaryMobileButton = ({ children = undefined, ...props }) => (
  <ButtonBase $green {...props}>
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const SecondaryMobileButton = ({ children = undefined, ...props }) => (
  <ButtonBase $gray {...props}>
    <RewindIcon tw="h-5 w-5" />
    <span>{children}</span>
  </ButtonBase>
);

export const DangerMobileButton = ({ children = undefined, ...props }) => (
  <ButtonBase $red {...props}>
    <span>{children}</span>
  </ButtonBase>
);

export const RoundButton = styled(ButtonBase)(
  tw`border-transparent shadow-sm text-white bg-gray-100 px-3 py-2 rounded-full [font-size: 40px]`,
  tw`hover:(bg-gray-300)`
);

export const RoundButtonStyled = styled(ButtonBase)(
  tw`inline-flex items-center p-1 rounded-full shadow-sm`
);

export const AddButton = ({ children, ...props }) => (
  <ButtonBase $blue type="button" {...props}>
    <PlusIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const CloseButton = ({ onClose, ...props }) => (
  <RoundButtonStyled tw="h-7 w-7" type="button" onClick={onClose} {...props}>
    <XOutlineIcon tw="h-5 w-5" />
  </RoundButtonStyled>
);

export const SubmitButton = ({ children = undefined, ...props }) => (
  <ButtonBase $green type="submit" {...props}>
    <SaveIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const SubmitButtonWithInput = ({ children = undefined, ...props }) => (
  <>
    <ButtonBase $green type="submit" {...props}>
      <SaveIcon tw="h-5 w-5" />
      {children && <span>{children}</span>}
    </ButtonBase>
    <input tw="hidden" type="submit" />
  </>
);

export const DuplicateButton = ({ onDuplicate, children = undefined, ...props }) => (
  <ButtonBase $blue type="button" onClick={onDuplicate} {...props}>
    <DuplicateIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const AttachmentButton = ({ children = undefined, ...props }) => (
  <ButtonBase $gray type="button" {...props}>
    <AttachmentIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const HistoryButton = ({ children = undefined, ...props }) => (
  <ButtonBase $pink type="button" {...props}>
    <CloudIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const BackButton = ({ children = undefined, ...props }) => (
  <ButtonBase $gray type="button" {...props}>
    <BackIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const CheckButton = ({ children = undefined, ...props }) => (
  <ButtonBase $orange type="button" {...props}>
    <CheckIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const CancelButton = ({ children = undefined, ...props }) => (
  <ButtonBase $lightgray type="button" {...props}>
    <CancelIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const ImportButton = ({ children, status, ...props }) => (
  <ButtonBase $white disabled={status === "loading"} {...props}>
    {status === "loading" ? (
      <LoadingIndicator tw="h-5 w-5" />
    ) : status === "error" ? (
      <XOutlineIcon tw="h-5 w-5 text-red-600" />
    ) : (
      <DocumentAddIcon tw="h-5 w-5 text-gray-500" />
    )}
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const ExportButton = ({ children, ...props }) => (
  <ButtonBase $white {...props}>
    <DocumentDownloadIcon tw="h-5 w-5 text-gray-500" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const PrevButton = ({ children, ...props }) => (
  <ButtonBase $gray {...props}>
    <ArrowNarrowLeftIcon tw="mr-3 h-5 w-5 text-gray-400" />
    {children && <span>{children}</span>}
  </ButtonBase>
);

export const NextButton = ({ children, ...props }) => (
  <ButtonBase $gray {...props}>
    <span>{children}</span>
    <ArrowNarrowRightIcon tw="ml-3 h-5 w-5 text-gray-400" />
  </ButtonBase>
);

const CameraButtonBase = styled("button")(
  tw`inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md`,
  tw`disabled:(opacity-50 pointer-events-none)`
);

export const CameraButton = styled(CameraButtonBase)(
  tw`border-transparent text-gray-500 bg-white px-3 py-2 rounded-full [font-size: 30px] ring-gray-200/50 ring-offset-2 ring-4`,
  tw`hover:(bg-gray-100)`
);

export const DeleteButton = ({ children, ...props }) => (
  <ButtonBase type="button" $red {...props}>
    <TrashIcon tw="h-5 w-5" />
    {children && <span>{children}</span>}
  </ButtonBase>
);
