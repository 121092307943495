/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useState, useRef, useEffect, FC } from "react";
import { ZoomInIcon, ZoomOutIcon } from "./Icons";

type PdfViewerType = {
  url: string;
  scale: number;
  isThumbnail: boolean;
  height?: number;
  width?: number;
  [s: string]: unknown;
};
export const PdfViewer: FC<PdfViewerType> = ({ url, height, width, scale, isThumbnail, ...props }) => {
  const [pdfRef, setPdfRef] = useState<{ numPages: number, destroy: () => void }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentScale, setCurrentScale] = useState(scale);

  const canvasRef = useRef<HTMLCanvasElement>();
  
  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  }, []);

  // Reload file display when url change
  useEffect(() => {
    const asyncEffect = async () => {
      if (url) {
        try {
          const pdf = await pdfjsLib.getDocument(url).promise;
          if (pdfRef) {
            pdfRef.destroy(); // fix: Error Cannot use the same canvas during multiple render() operations
          }
          setPdfRef(pdf);
        } catch (err) {
          console.log(err);
        }
      } else {
        setPdfRef(undefined);
      }
    };
    asyncEffect();
  }, [url]);
  useEffect(() => {
    const asyncEffect = async (pageNum, scale, pdf) => {
      if (canvasRef?.current) {
        const canvas = canvasRef.current;
        if (pdf) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale });
          // Support HiDPI-screens.
          const outputScale = window.devicePixelRatio || 1;

          const context = canvas.getContext("2d");

          canvas.width = Math.floor((width ?? viewport.width) * outputScale);
          canvas.height = Math.floor((height ?? viewport.height) * outputScale);
          canvas.style.width = `${width ?? Math.floor(viewport.width)}px`;
          canvas.style.height = `${height ?? Math.floor(viewport.height)}px`;

          const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

          const renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport,
          };
          page.render(renderContext);
        } else {
          // file was removed: clear the canvas
          canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        }
      }
    };
    asyncEffect(currentPage, currentScale, pdfRef);
  }, [currentPage, pdfRef, currentScale]);

  return (
    <section css={[pdfRef && tw`border border-gray-200 flex flex-col items-center`]} {...props}>
      { pdfRef && (
        <>
        { !isThumbnail && (
          <section tw="flex flex-row space-x-2 my-2 ml-2">
            { pdfRef.numPages > 1 && (
                <Paginator
                  numberOfPages={pdfRef?.numPages}
                  currentPageNumber={currentPage}
                  onPageClick={(i) => { setCurrentPage(i); }}
                />
              )
            }
            <Zoominator scale={currentScale} setScale={setCurrentScale} />
          </section>
        )}
        </>
      )}
      <canvas ref={canvasRef}></canvas>
    </section>
  );
};

export const Paginator = ({ numberOfPages, currentPageNumber, onPageClick }) => {
  const pageElements = [];
  for (let i = 1; i <= numberOfPages; i++) {
    const css =
      currentPageNumber === i
        ? tw`bg-indigo-50 border-indigo-500 text-indigo-600 `
        : tw`bg-white border-gray-300 text-gray-500 hover:bg-gray-50`;
    pageElements.push(
      <a
        key={`page-${i}`}
        href="#"
        css={[css, tw`z-10 relative inline-flex items-center px-4 py-2 border text-sm font-medium`]}
        onClick={(e) => { e.stopPropagation(); onPageClick(i); }}
      >
        {i}
      </a>
    );
  }

  return (
    <div tw="bg-white flex items-center justify-between sm:px-6">
      <nav tw="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        {pageElements}
      </nav>
    </div>
  );
};

const Zoominator = ({ scale, setScale }) => (
  <span tw="relative z-0 inline-flex shadow-sm rounded-md h-10">
    <button
      type="button"
      onClick={(e) => { e.stopPropagation(); setScale(scale + 0.5); }}
      tw="relative inline-flex items-center px-4 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
    >
      <ZoomInIcon tw="h-5 w-5" />
    </button>
    <button
      type="button"
      onClick={(e) => { e.stopPropagation(); setScale(scale - 0.5); }}
      tw="-ml-px relative inline-flex items-center px-4 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
    >
      <ZoomOutIcon tw="h-5 w-5" />
    </button>
  </span>
);
