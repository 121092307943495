import { StandardStatus } from "./StandardStatus";
import { WorkOrderStatus } from "./WorkOrderStatus";

export type MongoDocument = {
  _id?: string;
  _version?: string;
  status: StandardStatus | WorkOrderStatus;
};

export const mongoDocumentTypeGuard = (obj: unknown): obj is MongoDocument => !!obj?.["_id"];
