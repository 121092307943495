import { saveAs } from "file-saver";

export const ARRAY_SEPARATOR = ",";

export const exportToExcel = async (
  items: unknown[],
  fieldLabels: string[],
  fieldNames: string[],
): Promise<unknown> => {
  const { default: ExcelJS } = await import("exceljs");
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Export");
  const columns = fieldLabels.map((fieldLabel) => ({ name: fieldLabel }));
  if (columns.length < fieldNames.length) {
    // Complete missing label with field names
    fieldNames.slice(columns.length).forEach((fieldName) => columns.push({ name: fieldName }));
  }
  worksheet.addTable({
    name: "SerialNumbers",
    ref: "A1",
    columns,
    rows: items.map((item) => fieldNames.map((fieldName) => {
      const value = item[fieldName];
      if (Array.isArray(value)) {
        return value.join(ARRAY_SEPARATOR);
      } else {
        return value;
      }
    })),
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);

  return saveAs(blob, `export-${new Date().toISOString()}.xlsx`);
};
