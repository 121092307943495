import { HTTPError, TimeoutError } from "ky";

/**
 * Extract message seamlessly from HTTP or standard error objects
 * @param error object from catch block
 * @returns error message
 */
export const handleError = async (error: unknown): Promise<string> => {
  if ((error as HTTPError)?.response) {
    try {
      const apiError = await (error as HTTPError).response.json();
      return apiError.message;
    } catch (err) {
      return undefined; // Body has already been consumed
    }
  } else if ((error as TimeoutError)?.message) {
    return (error as TimeoutError).message;
  } else {
    return error?.toString();
  }
};
