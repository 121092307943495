/** @jsxImportSource @emotion/react */
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "twin.macro";
import {
  useUpsertWorkOrderMutation,
  WORK_ORDERS_ENDPOINT,
} from "../../../shared/hooks/useWorkOrderQueries";
import {
  DangerMobileButton,
  DuplicateButton,
  PrimaryMobileButton,
  RoundButton,
  SecondaryMobileButton,
} from "../../../shared/components/Buttons";
import { Container } from "../../../shared/components/Container";
import { FormGroup, InputStyled, Label, TextAreaStyled } from "../../../shared/components/Form";
import { useDownloadFileQueries } from "../../../shared/hooks/useDownloadApi";
import {
  BadgeContainer,
  CardContainer,
  DateAndBadgeContainer,
  HeaderContainer,
  NavigationTitle,
  StartDate,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";
import { StatusStepper } from "../../StatusStepper";
import { useCallback, useState } from "react";
import tw from "twin.macro";
import { UploadIcon, XCircleIcon } from "@heroicons/react/outline";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { SideModal } from "../../../shared/components/Modal";
import { useDisclosure } from "../../../shared/hooks/useDisclosure";
import { LockOpenIcon } from "@heroicons/react/solid";
import { useApi } from "../../../shared/hooks/useApi";
import { handleError } from "../../../shared/functions/ErrorHandling";
import { Spinner } from "../../../shared/components/Spinner";
import PhotoPreview from "./PhotoPreview";

const InProgress = ({
  status,
  cancelWorkOrder,
  confirmFinishWorkOrder,
  confirmNotFinishWorkOrder,
  workOrder,
  otId,
}) => {
  const navigate = useNavigate();
  const { mutateAsync: upsertWorkOrder, isLoading } = useUpsertWorkOrderMutation();
  const { search: locationSearch } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const api = useApi();

  const onSubmitTexarea = (data) => {
    upsertWorkOrder({
      ...workOrder,
      descriptionWorkDone: data.descriptionWorkDone,
      roundTrip: data.roundTrip,
      arrivalTime: data.arrivalTime,
      workDuration: data.workDuration,
    });
  };

  //delete a photo
  const deletePhoto = (selectedPhoto) => {
    const clone = [...workOrder?.photos];
    const i = clone?.findIndex((p) => p.name === selectedPhoto.name);
    if (i >= 0) {
      clone.splice(i, 1);
    }
    upsertWorkOrder({
      _id: workOrder._id,
      photos: clone,
    });
  };

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      descriptionWorkDone: workOrder?.descriptionWorkDone,
      roundTrip: workOrder?.roundTrip,
      arrivalTime: workOrder?.arrivalTime,
      workDuration: workOrder?.workDuration,
    },
  });
  const loadingArray = 4;

  const photoNames = [];
  for (let i = 0; i <= workOrder?.photos?.length; i++) {
    if (workOrder.photos[i]?.name) {
      photoNames.push(workOrder.photos[i]?.name);
    }
  }

  const dataPhoto = useDownloadFileQueries(photoNames);

  const [photos, setPhotos] = useState([...(workOrder?.photos || [])]);
  const onSubmitLegends = () => {
    upsertWorkOrder({
      ...workOrder,
      photos,
    });
  };
  const onLegendChange = useCallback(
    (e, index) => {
      const newPhotos = [...workOrder?.photos];
      newPhotos[index].legend = e.target.value;
      setPhotos(newPhotos);
    },
    [workOrder]
  );
  const onCopyDescription = () => setValue("descriptionWorkDone", workOrder?.description);

  const onFileChange = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("attachment", file);
      try {
        const uploadResponse = (await api
          .post(`${WORK_ORDERS_ENDPOINT}/upload/${otId}`, { body: formData })
          .json()) as { filename };
        const newWorkOrder = {
          _id: workOrder._id,
          photos: [...workOrder.photos, { name: uploadResponse.filename }],
        };

        await upsertWorkOrder(newWorkOrder);
      } catch (err) {
        handleError(err).then((errMsg) => console.log(errMsg));
      }
    },
    [api, upsertWorkOrder]
  );

  return (
    <div>
      <StatusStepper workOrdersData={workOrder} otId={otId} />
      <Container tw="mb-20 xl:px-48 2xl:px-96">
        {/* 1ST SECTION */}
        <CardContainer>
          <HeaderContainer>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              <NavigationTitle>{workOrder.title.toUpperCase()}</NavigationTitle>
            </div>
            <div tw="flex h-full items-center">
              {[WorkOrderStatus.NON_TERMINE, WorkOrderStatus.TERMINE].includes(
                workOrder.status
              ) && (
                <RoundButton
                  tw="!bg-gray-600 !px-2"
                  onClick={() => {
                    onClose();
                    cancelWorkOrder();
                  }}>
                  <LockOpenIcon tw="h-5 w-5 text-white" />
                </RoundButton>
              )}
              <DateAndBadgeContainer>
                <BadgeContainer status={workOrder.status} />
                <StartDate start={workOrder.actualStart ?? workOrder.start} />
              </DateAndBadgeContainer>
            </div>
          </HeaderContainer>

          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div>
              <div tw="text-lg leading-6 font-medium text-gray-900">TRAVAUX RÉALISÉS</div>
              <p tw="mt-1 max-w-2xl text-sm text-gray-500">
                {workOrder?.status === WorkOrderStatus.EN_COURS
                  ? "Informations générales et observations concernant les travaux Éffectués"
                  : "Veuillez démarrer l'intervention depuis l'écran précédent avant de saisir ces informations"}
              </p>
            </div>

            <dd tw="mt-2 md:mt-0 md:col-span-7">
              <form onSubmit={handleSubmit(onSubmitTexarea)}>
                <FormGroup>
                  <TextAreaStyled
                    tw="flex-1 block w-full text-sm z-0 h-44 focus:z-10 border-gray-300 mt-6 rounded-md focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500)"
                    css={[
                      workOrder?.status !== WorkOrderStatus.EN_COURS && tw`pointer-events-none`,
                    ]}
                    defaultValue=""
                    placeholder=""
                    {...register("descriptionWorkDone", {
                      disabled: workOrder?.status !== WorkOrderStatus.EN_COURS,
                    })}
                    id="descriptionWorkDone"
                  />
                  {workOrder?.status === WorkOrderStatus.EN_COURS && (
                    <div tw="flex space-x-3 items-center justify-end mt-8">
                      <DuplicateButton
                        type="button"
                        disabled={isLoading}
                        onDuplicate={onCopyDescription}>
                        Recopier
                      </DuplicateButton>
                    </div>
                  )}
                  <Label htmlFor="roundTrip">Temps de trajet A/R :</Label>
                  <InputStyled tw="mt-2" {...register("roundTrip")} type="text" id="roundTrip" />
                  <Label htmlFor="arrivalTime">Heure d’arrivée :</Label>
                  <InputStyled
                    tw="mt-2"
                    {...register("arrivalTime")}
                    type="text"
                    id="arrivalTime"
                  />
                  <Label htmlFor="workDuration">Temps de chantier :</Label>
                  <InputStyled
                    tw="mt-2"
                    {...register("workDuration")}
                    type="text"
                    id="workDuration"
                  />
                </FormGroup>

                {workOrder?.status === WorkOrderStatus.EN_COURS && (
                  <div tw="flex space-x-3 items-center justify-end mt-8">
                    <PrimaryMobileButton type="submit" disabled={isLoading}>
                      Sauvegarder
                    </PrimaryMobileButton>
                  </div>
                )}
              </form>
            </dd>
          </div>
        </CardContainer>

        {/* 2ND SECTION */}
        {(workOrder?.status === WorkOrderStatus.EN_COURS ||
          workOrder?.status === WorkOrderStatus.TERMINE ||
          workOrder?.status === WorkOrderStatus.NON_TERMINE) && (
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer
                title="PHOTOS"
                subTitle="Photos prises pendant l'intervention"
              />
            </HeaderContainer>

            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl tw="divide-y divide-gray-200">
                <dd tw="mt-2 md:mt-0 md:col-span-7">
                  <section>
                    <ul
                      role="list"
                      css={[
                        dataPhoto.length === 0 ? tw`grid grid-cols-1` : tw`grid grid-cols-4`,
                        tw`gap-2`,
                      ]}>
                      {status === "loading" &&
                        [...Array(loadingArray)].map((e, index) => (
                          <li
                            tw="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 "
                            key={index}></li>
                        ))}

                      {photoNames.map((photo, index) => (
                        <div key={index} tw="relative">
                          <li tw="col-span-1 rounded-lg divide-y divide-gray-200">
                            <div tw="space-y-4">
                              <PhotoPreview photoNames={photo} />
                            </div>
                          </li>
                          <FormGroup>
                            <InputStyled
                              tw="mt-2"
                              css={[
                                workOrder.status !== WorkOrderStatus.EN_COURS &&
                                  tw`pointer-events-none`,
                              ]}
                              value={workOrder.photos?.[index]?.legend ?? ""}
                              onChange={(e) => onLegendChange(e, index)}
                              type="text"
                              id="legend"
                            />
                          </FormGroup>

                          {workOrder.status === WorkOrderStatus.EN_COURS && (
                            <XCircleIcon
                              tw="absolute -top-2 -right-2 h-6 w-6 text-gray-500"
                              onClick={() => {
                                deletePhoto(workOrder.photos[index]);
                              }}
                            />
                          )}
                        </div>
                      ))}
                      {workOrder.photos.length <= 2 && (
                        <div tw="max-w-lg min-h-full flex justify-center items-center px-3 py-3 border-2 border-gray-300 border-dashed rounded-md">
                          <div tw="space-y-1 text-center">
                            <>
                              {isLoading ? (
                                <Spinner tw="mx-auto h-6 w-6 text-gray-400" />
                              ) : (
                                <>
                                  <UploadIcon tw="mx-auto h-6 w-6 text-gray-400" />
                                  <div tw="flex text-sm text-gray-600">
                                    <label
                                      htmlFor="file-upload"
                                      tw=" relative cursor-pointer bg-white rounded-md text-xs text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 disabled:(cursor-default text-gray-600)">
                                      <span>Charger une photo</span>
                                      <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        disabled={workOrder?.status !== WorkOrderStatus.EN_COURS}
                                        tw="disabled:(cursor-default text-gray-600) sr-only "
                                        onChange={(event) => {
                                          onFileChange(event.target.files[0]);
                                        }}
                                      />
                                    </label>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      )}
                    </ul>
                    {workOrder.status === WorkOrderStatus.EN_COURS && (
                      <div tw="flex space-x-3 items-center justify-end mt-8">
                        {dataPhoto.length !== 0 && (
                          <PrimaryMobileButton onClick={onSubmitLegends} disabled={isLoading}>
                            Sauvegarder
                          </PrimaryMobileButton>
                        )}
                      </div>
                    )}
                  </section>
                </dd>
              </dl>
            </div>
          </CardContainer>
        )}

        {/* 3RD SECTION */}

        <div tw="flex flex-wrap space-x-3 items-center justify-between mt-8">
          <SecondaryMobileButton
            onClick={() => {
              navigate(`/mobile/${otId}/informations${locationSearch}`);
            }}>
            Précédent
          </SecondaryMobileButton>
          {workOrder?.status === WorkOrderStatus.EN_COURS ? (
            <div>
              <PrimaryMobileButton
                onClick={() => {
                  if (workOrder.status === WorkOrderStatus.TERMINE) {
                    navigate(`/mobile/${otId}/signature${locationSearch}`);
                  } else if (workOrder.status === WorkOrderStatus.NON_TERMINE) {
                    navigate(`/mobile/${otId}/signature${locationSearch}`);
                  } else {
                    onOpen();
                  }
                }}>
                Fin de l&apos;intervention
              </PrimaryMobileButton>
            </div>
          ) : (
            <PrimaryMobileButton
              onClick={() => {
                navigate(`/mobile/${otId}/signature`);
              }}>
              Suivant
            </PrimaryMobileButton>
          )}
        </div>
      </Container>

      <SideModal isOpen={isOpen} onDismiss={onClose} centeredModal={true}>
        <div tw="mb-4">{"Quel est l'état final de l'ordre de travail ?"}</div>

        <div tw="flex justify-center items-center gap-2">
          <PrimaryMobileButton onClick={() => confirmFinishWorkOrder()}>
            Terminé
          </PrimaryMobileButton>
          <DangerMobileButton onClick={() => confirmNotFinishWorkOrder()}>
            Non terminé
          </DangerMobileButton>
        </div>
      </SideModal>
    </div>
  );
};

export default InProgress;
