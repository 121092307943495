import { useState, useCallback } from "react";
import { formatDate, isAfter, isBefore } from "../functions/Datetime";

export const useStartEndDates = (initStartDate, initEndDate) => {
  const [startDate, setStartDate] = useState<string>(initStartDate);
  const [endDate, setEndDate] = useState<string>(initEndDate);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);

  const onStartDateChange = useCallback(
    (e) => {
      if (e.target.value && endDate && isAfter(e.target.value, endDate)) {
        setErrorMessage("La date de début ne peut pas être postérieure à la date de fin.");
      } else {
        if (e.target.value) {
          setStartDate(formatDate(e.target.value, "YYYY-MM-DD"));
        } else {
          setStartDate(undefined);
        }
        setErrorMessage(undefined);
      }
    },
    [endDate, setStartDate]
  );
  const onEndDateChange = useCallback(
    (e) => {
      if (e.target.value && startDate && isBefore(e.target.value, startDate)) {
        setErrorMessage("La date de fin ne peut pas être antérieure à la date de début.");
      } else {
        if (e.target.value) {
          setEndDate(formatDate(e.target.value, "YYYY-MM-DD"));
        } else {
          setEndDate(undefined);
        }
        setErrorMessage(undefined);
      }
    },
    [startDate, setEndDate]
  );
  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onStartDateChange, 
    onEndDateChange,
    errorMessage,
    setErrorMessage,
  };
};
