import { WorkOrderStatus } from "./WorkOrderStatus";

export const WorkOrderBorderColors = new Map<WorkOrderStatus, string>([
  [WorkOrderStatus.EN_PREPARATION, "#facc15"], // clair
  [WorkOrderStatus.TRANSMIS, "#f59e0b"], // orange
  [WorkOrderStatus.RECU, "#14b8a6"], // turquoise 500
  [WorkOrderStatus.EN_ROUTE, "#84cc16"],  // vert clair
  [WorkOrderStatus.EN_COURS,"#22c55e"],   // vert
  [WorkOrderStatus.TERMINE, "#64748b"],  // noir
  [WorkOrderStatus.NON_TERMINE, "#f43f5e"], // Rouge
  [WorkOrderStatus.ANNULE, "#ec4899"],  // Rose
]);

export const WorkOrderSecondaryColors = new Map<WorkOrderStatus, string>([
  [WorkOrderStatus.EN_PREPARATION, "#facc15"], // clair
  [WorkOrderStatus.TRANSMIS, "#f59e0b"], // orange
  [WorkOrderStatus.RECU, "#14b8a6"], // turquoise 500
  [WorkOrderStatus.EN_ROUTE, "#84cc16"],  // vert clair
  [WorkOrderStatus.EN_COURS,"#22c55e"],   // vert
  [WorkOrderStatus.TERMINE, "#64748b"],  // noir
  [WorkOrderStatus.NON_TERMINE, "#f43f5e"], // Rouge
  [WorkOrderStatus.ANNULE, "#ec4899"],  // Rose
]);

export const WorkOrderColors = new Map<WorkOrderStatus, string>([
  [WorkOrderStatus.EN_PREPARATION, "#facc15"], // clair
  [WorkOrderStatus.TRANSMIS, "#f59e0b"], // orange
  [WorkOrderStatus.RECU, "#14b8a6"], // turquoise 500
  [WorkOrderStatus.EN_ROUTE, "#84cc16"],  // vert clair
  [WorkOrderStatus.EN_COURS,"#22c55e"],   // vert
  [WorkOrderStatus.TERMINE, "#64748b"],  // noir
  [WorkOrderStatus.NON_TERMINE, "#f43f5e"], // Rouge
  [WorkOrderStatus.ANNULE, "#ec4899"],  // Rose
]);
