/** @jsxImportSource @emotion/react */ 
import "twin.macro"; 
import { useEffect, useState } from "react"; 
import Done from "./Done"; 
import ModalFullPdf from "./ModalPdfTemplate"; 
import { useNavigate, useParams } from "react-router-dom"; 
import { useWorkorderQuery } from "../../MobileAPIs"; 
import { PageMobile } from "../../../shared/components/PageMobile"; 
import { Helmet } from "react-helmet-async"; 
import DoneSkeleton from "./DoneSkeleton"; 
import { useDownloadFileQuery } from "../../../shared/hooks/useDownloadApi"; 
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid"; 
 
const DonePage = () => { 
  const [showResults, setShowResults] = useState(false); 
  const [showResultsOTPdfNotSent, setShowResultsOTPdfNotSent] = useState(false); 
  const [showResultsOTPdfSent, setShowResultsOTPdfSent] = useState(false); 
 
  const [urlTechnicianSig, setUrlTechnicianSig] = useState(); 
  const [urlClientSig, setUrlClientSig] = useState(); 
  const [siteFooter, setSiteFooter] = useState<string>(); 
  const [siteLeftHeader, setSiteLeftHeader] = useState<string>(); 
  const navigate = useNavigate(); 
 
  // Get workorder from list query for a better offline mode 
  // (if user didn't visit the workorder detail online, he can still do it offline) 
  const { otId } = useParams(); 
  const { status, data: workOrdersData } = useWorkorderQuery(); 
  const workOrder = workOrdersData?.list?.find((w) => w._id === otId); 
 
  // If an OT do not exist anymore, redirect to /mobile 
  useEffect(() => { 
    if (status === "success" && workOrder === undefined) { 
      navigate(`/mobile`); 
    } 
  }, [workOrder]); 
 
  const siteFooterBlob = useDownloadFileQuery(workOrder?.site?.footerImage); 
  const siteFooterBlobData = siteFooterBlob.data; 
  async function siteFooterBlobDataToBase64(siteFooterBlobData) { 
    return new Promise((resolve) => { 
      const reader = new FileReader(); 
      reader.onloadend = () => resolve(reader.result); 
      reader.readAsDataURL(siteFooterBlobData); 
    }); 
  } 
 
  useEffect(() => { 
    if (siteFooterBlobData) { 
      siteFooterBlobDataToBase64(siteFooterBlobData).then((base64String: string) => 
        setSiteFooter(base64String) 
      ); 
    } 
  }, [siteFooterBlobData, setSiteFooter]); 
 
 
  const siteLeftHeaderBlob = useDownloadFileQuery(workOrder?.site?.leftHeaderImage); 
  const siteLeftHeaderBlobData = siteLeftHeaderBlob.data; 
  async function siteLeftHeaderBlobDataToBase64(siteLeftHeaderBlobData) { 
    return new Promise((resolve) => { 
      const reader = new FileReader(); 
      reader.onloadend = () => resolve(reader.result); 
      reader.readAsDataURL(siteLeftHeaderBlobData); 
    }); 
  } 
 
  useEffect(() => { 
    if (siteLeftHeaderBlobData) { 
      siteLeftHeaderBlobDataToBase64(siteLeftHeaderBlobData).then((base64String: string) => 
        setSiteLeftHeader(base64String) 
      ); 
    } 
  }, [siteLeftHeaderBlobData, setSiteLeftHeader]); 
 
  return ( 
    <> 
      <Helmet title={status === "success" ? workOrder?.title : process.env.REACT_APP_WHITE_LABEL} /> 
      <PageMobile> 
        {status === "loading" && <DoneSkeleton />} 
        {workOrder && status === "success" && ( 
          <> 
            <Done 
              workOrder={workOrder} 
              setShowResults={setShowResults} 
              setUrlTechnicianSig={setUrlTechnicianSig} 
              setUrlClientSig={setUrlClientSig} 
            /> 
            {showResults && ( 
              <ModalFullPdf 
                status={status} 
                workOrder={workOrder} 
                showResults={showResults} 
                setShowResults={setShowResults} 
                urlTechnicianSig={urlTechnicianSig} 
                urlClientSig={urlClientSig} 
                siteFooter={siteFooter} 
                siteLeftHeader={siteLeftHeader} 
                setShowResultsOTPdfNotSent={setShowResultsOTPdfNotSent} 
                setShowResultsOTPdfSent={setShowResultsOTPdfSent} 
                typePdf="mobile" 
              /> 
            )} 
            {showResultsOTPdfNotSent && ( 
              <ModalErrorOtPdf setShowResultsOTPdfNotSent={setShowResultsOTPdfNotSent} setShowResults={setShowResults} /> 
            )} 
            {showResultsOTPdfSent && ( 
              <ModalValidOtPdf setShowResultsOTPdfSent={setShowResultsOTPdfSent} /> 
            )} 
          </> 
        )} 
      </PageMobile> 
    </> 
  ); 
}; 
 
export default DonePage; 
 
export const ModalValidOtPdf = ({ setShowResultsOTPdfSent }) => { 
  useEffect(() => { 
    const timeout = setTimeout(() => { 
      setShowResultsOTPdfSent(false); 
    }, 10000); 
    return () => clearTimeout(timeout); 
  }, []); 
 
  const onClose = () => { 
    setShowResultsOTPdfSent(false); 
  } 
 
  return ( 
    <div tw="fixed bottom-10 right-0 border-l-4 border-green-400 bg-green-50 p-4 w-1/2"> 
      <div tw="flex"> 
        <div tw="flex-shrink-0"> 
          <CheckCircleIcon tw="h-5 w-5 text-green-400" aria-hidden="true" /> 
        </div> 
        <div tw="ml-3"> 
          <div tw="text-sm text-green-700"> 
            <div>Document PDF envoyé avec succès.</div> 
            <a href="#" onClick={onClose} tw="font-medium text-yellow-700 underline hover:text-yellow-600"> 
              Fermer 
            </a> 
          </div> 
        </div> 
      </div> 
    </div> 
  ); 
}; 
 
export const ModalErrorOtPdf = ({ setShowResultsOTPdfNotSent, setShowResults }) => { 
  const onRetry = () => { 
    setShowResultsOTPdfNotSent(false); 
    setShowResults(true); 
  } 
 
  const onClose = () => { 
    setShowResultsOTPdfNotSent(false); 
  } 
 
  return ( 
    <div tw="fixed bottom-10 right-0 border-l-4 border-yellow-400 bg-yellow-50 p-4 w-full"> 
      <div tw="flex"> 
        <div tw="flex-shrink-0"> 
          <ExclamationIcon tw="h-5 w-5 text-yellow-400" aria-hidden="true" /> 
        </div> 
        <div tw="ml-3"> 
          <div tw="text-sm text-yellow-700"> 
            <div>Echec de l&apos;envoi du document PDF.</div> 
            <div>Vérifiez votre connexion internet.</div> 
            <div tw="flex justify-between"> 
              <a href="#" onClick={onClose} tw="font-medium text-yellow-700 underline hover:text-yellow-600"> 
                Fermer 
              </a> 
              <a href="#" onClick={onRetry} tw="font-medium text-yellow-700 underline hover:text-yellow-600"> 
                Ré-essayer 
              </a> 
            </div> 
          </div> 
        </div> 
      </div> 
    </div> 
  ); 
}; 
