/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import "twin.macro";
import { CrudCreation, CrudDetails } from "../shared/components/CrudForm";
import { SideModal } from "../shared/components/Modal";
import {
  useUpsertMutation as useClientUpsertMutation,
  useFindByIdQuery as useClientFindByQuery,
} from "./useClientQueries";
import { ClientForm, clientToFormValues, formValuesToClient } from "./ClientForm";
import { CLIENT_READ_PERMISSIONS, CLIENT_WRITE_PERMISSIONS } from "./ClientCrud";

/*
 * Use line bellow to control modal from outside
 * const { isOpen, onOpen, onClose } = useDisclosure(false);
 */

export const ClientModal = ({ clientId, isOpen, onClose }) => {
  const renderClientFormFields = useCallback(() => <ClientForm />, []);
  return (
    // @ts-ignore
    <SideModal isOpen={isOpen} onDismiss={onClose} aria-label="Vehicle modal">
      {clientId && (
        <CrudDetails
          title="Informations sur le client"
          objectId={clientId}
          renderFormFields={renderClientFormFields}
          useFindByIdQuery={useClientFindByQuery}
          useUpsertMutation={useClientUpsertMutation}
          itemToFormValues={clientToFormValues}
          formValuesToItem={formValuesToClient}
          closeModalAction={onClose}
          readPermissions={CLIENT_READ_PERMISSIONS}
          writePermissions={CLIENT_WRITE_PERMISSIONS}
        />
      )}
      {!clientId && (
        <CrudCreation
          title="Informations sur le client"
          renderFormFields={renderClientFormFields}
          useUpsertMutation={useClientUpsertMutation}
          itemToFormValues={clientToFormValues}
          formValuesToItem={formValuesToClient}
          closeModalAction={onClose}
          writePermissions={CLIENT_WRITE_PERMISSIONS}
        />
      )}
    </SideModal>
  );
};
