/** @jsxImportSource @emotion/react */
import { useContext } from "react";
import "twin.macro";
import { Permission } from "../../../types/Role";
import { CurrentSiteContext, CurrentSiteContextType } from "../../contexts/CurrentSiteContext";
import { Filter } from "../../functions/QueryHelper";
import { hasAnyPermission } from "../../functions/RoleManagement";
import { useConnectedUser } from "../../hooks/useConnectedUser";
import { useSearchQuery as useSiteQuery } from "../../hooks/useSiteQueries";
import { SelectStyled } from "../Form";

export const SiteSelection = () => {
  const { permissions } = useConnectedUser();
  const { currentSite, setCurrentSite } = useContext<CurrentSiteContextType>(CurrentSiteContext);

  const { data: siteData } = useSiteQuery({...Filter.and({ status: "Actif" })});
  const sites = siteData?.list ?? [];
  
  const onChange = (e) => {
    setCurrentSite(sites.find((s) => s.name === e.target.value))
  }
  
  const value = sites.find(e => currentSite && e._id === currentSite._id)

  if (hasAnyPermission([Permission.MANAGE_SITE_ADMINISTRATORS], permissions)) {
    return (
      <SelectStyled value={value?.name} onChange={onChange} tw="h-8 !mt-0 ![line-height: 0.8rem]">
        {sites.map((value) => (
          <option key={value?.name} value={value?.name}>
            {value?.name}
          </option>
        ))}
      </SelectStyled>
    );
  } else {
    return <></>;
  }
};
