/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "twin.macro";
import { PrimaryMobileButton } from "../../../shared/components/Buttons";
import {
  FormGroup,
  InputStyled,
  ErrorMessage as CustomErrorMessage,
} from "../../../shared/components/Form";
import { Spinner } from "../../../shared/components/Spinner";
import { useUpsertWorkOrderMutation } from "../../../shared/hooks/useWorkOrderQueries";
import { useSendMailMutation } from "../../MobileAPIs";
import {
  CardContainer,
  HeaderContainer,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/outline";

export const ModalSendMailAutomatically = ({ setEmailAutoSent }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      setEmailAutoSent(false);
    }, 20000);
    return () => clearTimeout(timeout);
  }, []);

  const onClose = () => {
    setEmailAutoSent(false);
  };

  return (
    <div tw="fixed bottom-10 right-0 border-l-4 border-green-400 bg-green-50 p-4 w-1/2">
      <div tw="flex">
        <div tw="flex-shrink-0">
          <CheckCircleIcon tw="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div tw="ml-3">
          <div tw="text-sm text-green-700">
            <div>Email envoyé avec succès.</div>
            <a
              href="#"
              onClick={onClose}
              tw="font-medium text-yellow-700 underline hover:text-yellow-600">
              Fermer
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const SendMail = ({
  clientName,
  signedDocument,
  emailSentNotification,
  setEmailSentNotification,
  workOrder,
}) => {
  const [emailSendindDelay, setEemailSendindDelay] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate } = useSendMailMutation();
  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  const onSubmit = (data) => {
    setEemailSendindDelay(true);
    mutate(
      {
        ...data,
        clientName: clientName,
        signedDocument: signedDocument,
      },
      {
        onSuccess: () => {
          setEemailSendindDelay(false);
          setEmailSentNotification(true);
        },
      }
    );
    upsertWorkOrder({
      ...workOrder,
      clientEmail: data.email,
    });
  };

  return (
    <>
      <CardContainer>
        <HeaderContainer>
          <div tw="flex flex-wrap justify-between">
            <TitleHeaderContainer
              title="ADRESSE EMAIL"
              subTitle="Veuillez saisir l'adresse mail du client pour envoyer l'OT"
            />
            {workOrder.emailSentAuto === true && (
              <div tw="flex items-center gap-1 py-0 sm:py-5 px-4 sm:px-6 text-xs leading-6 text-zinc-400">
                <InformationCircleIcon tw="h-4 text-zinc-400" />
                <p>Envoi automatique de l&apos;email demandé</p>
              </div>
            )}
          </div>
        </HeaderContainer>
        <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl tw="divide-y divide-gray-200">
            <dd tw="mt-2 md:mt-0 md:col-span-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <InputStyled
                    tw="mt-2"
                    {...register("site", {
                      required: true,
                      setValueAs: () => workOrder.clientEmail,
                    })}
                    type="text"
                    id="email"
                    defaultValue={workOrder.clientEmail}
                  />
                  <ErrorMessage as={CustomErrorMessage} name="email" errors={errors} />
                  {emailSentNotification && (
                    <div tw="text-sm font-medium pt-1 text-gray-800">
                      L&apos;email a bien été envoyé !
                    </div>
                  )}
                </FormGroup>
                <div tw="flex space-x-3 items-center justify-end mt-8">
                  {emailSentNotification ? (
                    <div tw="inline-flex flex-row items-center space-x-1.5 px-3 py-2.5 text-sm font-medium rounded-md bg-green-600 hover:(bg-green-600) border-0 text-gray-100">
                      Email envoyé !
                    </div>
                  ) : emailSendindDelay ? (
                    <div tw="inline-flex flex-row items-center space-x-1.5 px-3 py-2.5 text-sm font-medium rounded-md bg-green-600 hover:(bg-green-600) border-0 text-gray-100">
                      <Spinner />
                    </div>
                  ) : (
                    <PrimaryMobileButton type="submit">Envoyer</PrimaryMobileButton>
                  )}
                </div>
              </form>
            </dd>
          </dl>
        </div>
      </CardContainer>
    </>
  );
};

export default SendMail;
