/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm, FieldValues, Control } from "react-hook-form";
import {
  FormBase,
  FormBody,
  InputStyled,
  CheckBoxStyled,
  InputLabel,
  TextAreaStyled,
  FormGroup,
  Label,
  RequiredAsterisk,
} from "../Form";
import qs from "qs";
import { WorkOrder } from "../../../types/WorkOrder";
import { useApi, useGenericQuery } from "../../hooks/useApi";
import {
  CloseButton,
  DuplicateButton,
  AttachmentButton,
  BackButton,
  CheckButton,
  CancelButton,
  DeleteButton,
  SubmitButtonWithInput,
  HistoryButton,
} from "../Buttons";
import { computeDates, dateDisplay, formatDate, timeDisplay } from "../../functions/Datetime";
import {
  useDeleteWorkOrderMutation,
  useReplaceWorkOrderMutation,
  useUpsertWorkOrderMutation,
  WORK_ORDERS_ENDPOINT,
} from "../../hooks/useWorkOrderQueries";
import { useSearchQuery as usePersonQuery } from "../../../person/usePersonQueries";
import { Vehicle } from "../../../types/Vehicle";
import { Alert, Confirm } from "../Modal";
import { buildSiteFilter, Filter } from "../../functions/QueryHelper";
import { WorkOrderAttachments } from "./WorkOrderAttachments";
import { handleError } from "../../functions/ErrorHandling";
import { WorkOrderColors } from "../../../types/WorkOrderColors";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { isPersonUnavailable } from "../../functions/PersonManagement";
import { StandardStatus } from "../../../types/StandardStatus";
import {
  bsdUrl,
  getPrimaryVehicle,
  getSecondaryVehicles,
} from "../../functions/WorkOrderManagement";
import {
  VehicleOption,
  vehicleToOption,
  WorkOrderVehicleSelect,
  WorkOrderVehicleSelectMulti,
} from "./WorkOrderVehicleSelect";
import { WorkOrderPersonCheckbox } from "./WorkOrderPersonCheckbox";
import { Badge } from "../Badge";
import { hasAnyPermission } from "../../functions/RoleManagement";
import { useConnectedUser } from "../../hooks/useConnectedUser";
import { Permission, Role } from "../../../types/Role";
import { WorkOrderHistory } from "./WorkOrderHistory";
import { CurrentSiteContext } from "../../contexts/CurrentSiteContext";

type WorkOrderFormProps = {
  workOrder?: WorkOrder;
  onCancel: () => void;
  onValidate: (event: WorkOrder) => void;
  onDuplicate?: (event: WorkOrder) => void;
};

const WorkOrderForm = ({ workOrder, onCancel, onValidate, onDuplicate }: WorkOrderFormProps) => {
  const { permissions } = useConnectedUser();
  const { currentSite } = useContext(CurrentSiteContext);
  const [currentTab, setCurrentTab] = useState<"workOrderForm" | "attachments" | "OTHistory">(
    "workOrderForm"
  );
  const [isCheque, setIsCheque] = useState(
    workOrder.payment?.paymentMethod === "Chèque" ? true : false
  );
  const filter = {
    ...Filter.from(
      buildSiteFilter(currentSite),
      { status: StandardStatus.active },
      { sort: "name" }
    ),
  };

  const { data: personData } = usePersonQuery({
    ...Filter.from({ roles: Role.TECHNICIAN }, filter),
  });
  const persons = personData?.list ?? [];

  const { data: vehicleData } = useGenericQuery<{ list: Vehicle[] }>("v1/vehicle", filter);
  const vehicles = vehicleData?.list ?? [];

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  const { mutateAsync: deleteWorkOrder } = useDeleteWorkOrderMutation();

  const [error, setError] = useState(undefined);
  const [cancelMessage, setCancelMessage] = useState(undefined);
  const [deleteMessage, setDeleteMessage] = useState(undefined);

  const formDefaultValues = useMemo(
    () => ({
      defaultValues: {
        workOrderNumber: workOrder?.workOrderNumber,
        primaryVehicle: getPrimaryVehicle(workOrder)?._id,
        secondaryVehicles: getSecondaryVehicles(workOrder)?.map(vehicleToOption),
        clientId: workOrder?.client?._id,
        clientName: workOrder?.clientName,
        clientEmail: workOrder?.clientEmail,
        emailSentAuto: workOrder?.emailSentAuto,
        contactName: workOrder?.contactName,
        contactPhone: workOrder?.contactPhone,
        address: workOrder?.address,
        city: workOrder?.city,
        postalCode: workOrder?.postalCode,
        billingAddress: workOrder?.billingAddress,
        billingCity: workOrder?.billingCity,
        billingPostalcode: workOrder?.billingPostalcode,
        start: formatDate(workOrder.start, "YYYY-MM-DD"),
        startTime: workOrder?.start ? timeDisplay(workOrder.start) : undefined,
        endTime: workOrder?.end ? timeDisplay(workOrder.end) : undefined,
        allDay: workOrder?.allDay,
        valvertContactName: workOrder?.valvertContactName,
        title: workOrder?.title,
        description: workOrder?.description,
        internalNotes: workOrder?.internalNotes,
        descriptionWorkDone: workOrder?.descriptionWorkDone,
        observations: workOrder?.observations,
        salesRepresentativeComment: workOrder?.salesRepresentativeComment,
        personIds: workOrder?.team?.map((t) => t._id),
        status: workOrder.status,
        bsdId: workOrder.bsdId,
        licenses: workOrder.licenses,
        payment: workOrder.payment,
      },
    }),
    [workOrder]
  );

  const formProps = useForm(formDefaultValues);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = formProps;

  const allDayWatch = watch("allDay");
  useEffect(() => {
    if (allDayWatch) {
      setValue("startTime", undefined);
      setValue("endTime", undefined);
    }
  }, [allDayWatch]);

  useEffect(
    () =>
      setError(
        Object.entries(errors)
          .map((k) => k[1].message || `Le champs ${k[0]} n'est pas valide`)
          .join("\n")
      ),
    [errors, setError]
  );

  // Sometimes, vehicle select is blank, maybe vehicleData is undefined when default value is set
  // So we set value when we have both vehicle data and work order data
  useEffect(() => {
    setValue("primaryVehicle", getPrimaryVehicle(workOrder)?._id);
    setValue("secondaryVehicles", getSecondaryVehicles(workOrder)?.map(vehicleToOption));
  }, [vehicleData, workOrder]);

  const timeStyle = useMemo(() => (allDayWatch ? tw`[visibility: hidden]` : tw``), [allDayWatch]);

  const saveWorkOrder = useCallback(
    async (formValues) => {
      const dates = computeDates(
        formValues.start,
        formValues.allDay,
        formValues.startTime,
        formValues.endTime
      );

      const selectedVehicles = vehicles.filter(
        (v) =>
          v._id === formValues.primaryVehicle ||
          formValues.secondaryVehicles?.map((o: VehicleOption) => o.value).includes(v._id)
      );

      const workOrderToSave = {
        _id: workOrder._id,
        workOrderNumber: formValues.workOrderNumber?.toUpperCase(),
        allDay: formValues.allDay,
        clientName: formValues.clientName,
        contactName: formValues.contactName,
        contactPhone: formValues.contactPhone,
        address: formValues.address,
        postalCode: formValues.postalCode,
        city: formValues.city,
        billingAddress: formValues.billingAddress,
        billingCity: formValues.billingCity,
        billingPostalcode: formValues.billingPostalcode,
        valvertContactName: formValues.valvertContactName,
        payment: formValues.payment,
        title: formValues.title,
        description: formValues.description,
        observations: formValues.observations,
        salesRepresentativeComment: formValues.salesRepresentativeComment,
        internalNotes: formValues.internalNotes,
        descriptionWorkDone: formValues.descriptionWorkDone,
        clientEmail: formValues.clientEmail,
        emailSentAuto: formValues.emailSentAuto,
        start: dates[0],
        end: dates[1],
        team: formValues.personIds
          .map((id) => persons.find((p) => p._id === id))
          .filter((p) => !!p),
        vehicles: selectedVehicles,
        status: formValues.status ?? WorkOrderStatus.EN_PREPARATION,
        site: workOrder.site,
        bsdId: formValues.bsdId,
        licenses:
          typeof formValues.licenses === "string"
            ? [formValues.licenses]
            : Array.isArray(formValues.licenses)
            ? formValues.licenses
            : [],
      } as WorkOrder;

      try {
        await upsertWorkOrder(workOrderToSave);
        onValidate(workOrderToSave);
      } catch (err) {
        handleError(err).then((errMsg) => setError(errMsg));
      }
    },
    [workOrder, vehicles, persons]
  );

  if (workOrder && !workOrder.vehicles) {
    workOrder.vehicles = workOrder.vehicles || [];
  }

  const setStatus = useCallback(
    async (status: WorkOrderStatus) => {
      const workOrderToSave = { ...workOrder, status };
      try {
        await upsertWorkOrder(workOrderToSave);
        onValidate(workOrderToSave);
      } catch (err) {
        handleError(err).then((errMsg) => setError(errMsg));
      }
    },
    [workOrder]
  );

  const onWorkOrderCancel = useCallback(() => {
    setCancelMessage(
      "L'OT sera retiré du planning mais sera toujours présent dans la liste. Souhaitez-vous continuer ?"
    );
  }, [setCancelMessage]);

  const onWorkOrderCancelConfirm = useCallback(async () => {
    setValue("status", WorkOrderStatus.ANNULE);
    handleSubmit(saveWorkOrder)();
    setCancelMessage(undefined);
  }, [setCancelMessage, workOrder]);

  const onWorkOrderDelete = useCallback(async () => {
    setDeleteMessage(
      "L'OT sera supprimé définitivement du planning et de la liste. Souhaitez-vous continuer ?"
    );
  }, [setDeleteMessage, workOrder]);

  const onWorkOrderDeleteConfirm = useCallback(async () => {
    deleteWorkOrder(workOrder);
    setDeleteMessage(undefined);
    onCancel();
  }, [setDeleteMessage, workOrder]);

  const onSendClick = () => {
    setValue("status", WorkOrderStatus.TRANSMIS);
    handleSubmit(saveWorkOrder)();
  };

  const { mutateAsync: replaceWorkOrder } = useReplaceWorkOrderMutation();
  const onWorkOrderRestore = useCallback(async () => {
    setValue("status", WorkOrderStatus.EN_PREPARATION);
    handleSubmit(saveWorkOrder)();
    const newWorkOrder = { ...workOrder };
    newWorkOrder.actualStart = undefined;
    newWorkOrder.actualEnd = undefined;
    newWorkOrder.locations = undefined;
    newWorkOrder.goBackReasons = [];
    return replaceWorkOrder(newWorkOrder);
  }, [workOrder]);

  const statusCss = workOrder && { backgroundColor: WorkOrderColors.get(workOrder.status) };

  const bsdId = watch("bsdId");
  const urlOfTheBsd = useMemo(() => bsdUrl(bsdId), [bsdId]);

  const readOnly =
    workOrder.status !== WorkOrderStatus.EN_PREPARATION ||
    !hasAnyPermission([Permission.WORKORDER_WRITE], permissions);
  const canUnlockReadonly = [WorkOrderStatus.TRANSMIS, WorkOrderStatus.RECU].includes(
    workOrder.status
  );

  const api = useApi();
  const validateWorkOrderNumber = async (workOrderNumber) => {
    if (workOrderNumber) {
      const searchParams = qs.stringify({
        workOrderNumber: workOrderNumber.toUpperCase(),
        status: { $ne: WorkOrderStatus.ANNULE },
      });
      const response = await api.get(WORK_ORDERS_ENDPOINT, { searchParams });
      const workOrders = (await response.json()) as WorkOrder[];
      if (workOrders.length >= 0) {
        const existingWorkOrder = workOrder?._id
          ? workOrders.find((w) => w._id !== workOrder?._id)
          : workOrders[0];
        if (existingWorkOrder) {
          return `Le n° "${workOrderNumber}" a déjà été associé à l'OT "${
            existingWorkOrder.title
          }" du ${dateDisplay(existingWorkOrder.start)}`;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const formRef = useRef<HTMLFormElement>();

  const { ref: registerTitleRef, ...titleProps } = register("title", {
    required: readOnly ? false : `Le champs "${WORK_ORDER_TITLE_LABEL}" doit être saisi`,
  });
  const titleRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, [titleRef]);

  const attachmentCount =
    workOrder.otherAttachments?.length +
    (workOrder.bsd ? 1 : 0) +
    (workOrder.plan ? 1 : 0) +
    workOrder.photos?.length +
    (workOrder.signedDocument ? 1 : 0) +
    (workOrder.interventionSheet ? 1 : 0);

  return (
    <FormProvider {...formProps}>
      {workOrder && (
        <>
          <FormBase
            ref={formRef}
            css={[
              tw`mx-4 [min-width: 400px] [width: 66vw] max-w-5xl`,
              currentTab === "workOrderForm" && tw`h-full`,
            ]}
            onSubmit={handleSubmit(saveWorkOrder)}>
            <section tw="flex flex-row justify-between w-full">
              <div tw="flex flex-row space-x-1 ml-6">
                {(currentTab === "attachments" || currentTab === "OTHistory") && (
                  <BackButton onClick={() => setCurrentTab("workOrderForm")}>Retour</BackButton>
                )}
                {currentTab === "workOrderForm" &&
                  !!workOrder._id &&
                  workOrder.status !== WorkOrderStatus.EN_PREPARATION &&
                  workOrder.status !== WorkOrderStatus.TRANSMIS && (
                    <HistoryButton onClick={() => setCurrentTab("OTHistory")}>
                      Historique de l&apos;OT
                    </HistoryButton>
                  )}
                {currentTab === "workOrderForm" && !!workOrder._id && (
                  <AttachmentButton onClick={() => setCurrentTab("attachments")}>
                    Pièces jointes<Badge tw="ml-1">{attachmentCount}</Badge>
                  </AttachmentButton>
                )}
                {currentTab === "workOrderForm" &&
                  !!workOrder._id &&
                  onDuplicate &&
                  workOrder.status ===
                    WorkOrderStatus.EN_PREPARATION /* don't duplicate orders which technicians have already worked on, because progress data might be duplicated */ && (
                    <DuplicateButton onDuplicate={onDuplicate}>Dupliquer</DuplicateButton>
                  )}
                {currentTab === "workOrderForm" &&
                  !!workOrder._id &&
                  ![WorkOrderStatus.EN_PREPARATION, WorkOrderStatus.ANNULE].includes(
                    workOrder.status
                  ) && <DeleteButton onClick={onWorkOrderCancel}>Annuler l&apos;OT</DeleteButton>}
                {currentTab === "workOrderForm" &&
                  !!workOrder._id &&
                  workOrder.status === WorkOrderStatus.EN_PREPARATION && (
                    <DeleteButton onClick={onWorkOrderDelete}>Supprimer l&apos;OT</DeleteButton>
                  )}
                {currentTab === "workOrderForm" && workOrder.status === WorkOrderStatus.ANNULE && (
                  <CancelButton onClick={onWorkOrderRestore}>Restaurer l&apos;OT</CancelButton>
                )}
                {currentTab === "workOrderForm" && !readOnly && (
                  <SubmitButtonWithInput>Sauvegarder</SubmitButtonWithInput>
                )}
                {currentTab === "workOrderForm" && !readOnly && !!workOrder._id && (
                  <CheckButton onClick={onSendClick}>Transmettre</CheckButton>
                )}
                {currentTab === "workOrderForm" && canUnlockReadonly && (
                  <CancelButton onClick={() => setStatus(WorkOrderStatus.EN_PREPARATION)}>
                    Annuler transmission
                  </CancelButton>
                )}
              </div>
              <div tw="flex flex-row space-x-1">
                <CloseButton onClose={onCancel} title="Fermer" />
              </div>
            </section>
            <FormBody
              css={[
                currentTab !== "workOrderForm" ? tw`hidden` : tw`h-full overflow-y-auto pr-2.5`,
                tw`w-full`,
              ]}>
              <fieldset>
                <section tw="flex flex-row w-full gap-4">
                  <section tw="flex flex-col flex-1">
                    <section tw="flex flex-row">
                      <h3 tw="text-lg leading-6 font-medium text-gray-900 flex items-center ">
                        <span>{workOrder._id ? "OT n°" : "Nouvel OT n°"}</span>
                        <InputStyled
                          {...register("workOrderNumber", { validate: validateWorkOrderNumber })}
                          type="text"
                          id="workorder-number"
                          maxLength={10}
                          disabled={readOnly}
                          tw="ml-0.5 !w-40 h-7"
                        />
                      </h3>
                      <span
                        tw="mx-3 px-3 py-1 rounded-full text-sm font-medium text-gray-900"
                        style={statusCss}>
                        {workOrder.status}
                      </span>
                      <input type="hidden" {...register("status")} />
                    </section>
                    <InputLabel
                      label={WORK_ORDER_TITLE_LABEL}
                      inputId="title"
                      tw="!flex !flex-row !space-x-2">
                      <InputStyled
                        {...titleProps}
                        type="text"
                        id="title"
                        maxLength={30}
                        disabled={readOnly}
                        tw="!w-96"
                        ref={(e) => {
                          registerTitleRef(e);
                          titleRef.current = e;
                        }}
                      />
                    </InputLabel>
                  </section>
                  <section tw="flex flex-col flex-1">
                    <InputLabel label={WORK_ORDER_START_LABEL} inputId="start-date" tw="!mt-0">
                      <InputStyled
                        {...register("start", {
                          required: readOnly
                            ? false
                            : `Le champs "${WORK_ORDER_START_LABEL}" doit être saisi`,
                        })}
                        type="date"
                        id="start-date"
                        disabled={readOnly}
                      />
                    </InputLabel>
                    <section tw="flex flex-row h-full gap-x-6 mt-2">
                      <section tw="flex flex-row flex-shrink flex-grow-0 h-full items-center space-x-2">
                        <label
                          htmlFor="all-day"
                          tw="text-sm font-medium text-gray-700 cursor-pointer">
                          Journée entière
                        </label>
                        <CheckBoxStyled
                          css={[tw`mt-1`]}
                          {...register("allDay")}
                          id="all-day"
                          aria-describedby={`all-day-desc`}
                          type="checkbox"
                          disabled={readOnly}
                        />
                      </section>
                      <section
                        css={[tw`flex flex-row flex-1 h-full items-center space-x-2`, timeStyle]}>
                        <InputLabel
                          label="Début"
                          inputId="start-time"
                          tw="!mt-0 !pt-0 !flex-1 !flex !flex-row !space-x-2">
                          <InputStyled
                            {...register("startTime")}
                            type="time"
                            id="start-time"
                            disabled={readOnly}
                            tw="flex-1 !w-24"
                          />
                        </InputLabel>
                        <InputLabel
                          label="Fin"
                          inputId="end-time"
                          tw="!mt-0 !pt-0 !flex-1 !flex !flex-row !space-x-2">
                          <InputStyled
                            {...register("endTime")}
                            type="time"
                            id="end-time"
                            disabled={readOnly}
                            tw="!w-24"
                          />
                        </InputLabel>
                      </section>
                    </section>
                  </section>
                </section>
              </fieldset>

              <fieldset tw="pt-6 mt-6">
                <h3 tw="text-lg leading-6 font-medium text-gray-900">Client</h3>
                <div tw="space-y-6 sm:space-y-5">
                  <div tw="mt-0 pb-6 grid grid-cols-2 gap-x-8">
                    <InputLabel label="Nom du client" inputId="client-name" tw=" cursor-pointer">
                      <InputStyled
                        {...register("clientName")}
                        type="text"
                        id="client-name"
                        disabled={readOnly}
                      />
                    </InputLabel>
                    <div>
                      <InputLabel label="Email du client" inputId="client-email">
                        <InputStyled
                          {...register("clientEmail")}
                          type="email"
                          id="client-email"
                          disabled={readOnly}
                        />
                      </InputLabel>
                      <section tw="flex justify-end flex-row items-center space-x-2 mt-2">
                        <CheckBoxStyled
                          {...register("emailSentAuto")}
                          id="emailSentAuto"
                          aria-describedby={`emailSentAuto`}
                          type="checkbox"
                          disabled={readOnly}
                        />
                        <label
                          htmlFor="emailSentAuto"
                          tw="text-sm font-medium text-gray-700 cursor-pointer">
                          Envoi automatique de l&apos;email
                        </label>
                      </section>
                    </div>
                  </div>

                  <div tw="mt-0 pb-6 grid grid-cols-2 gap-x-8">
                    <InputLabel label="Contact sur place" inputId="client-contact-name">
                      <InputStyled
                        {...register("contactName")}
                        type="text"
                        id="client-contact-name"
                        disabled={readOnly}
                      />
                    </InputLabel>

                    <InputLabel label="Téléphone du contact" inputId="phone">
                      <InputStyled
                        {...register("contactPhone")}
                        id="phone"
                        type="tel"
                        disabled={readOnly}
                      />
                    </InputLabel>
                  </div>

                  <div tw="p-6 bg-gray-50 rounded-lg">
                    <div tw="grid grid-cols-1 gap-x-4 sm:gap-x-12">
                      <div>
                        <div tw="text-sm font-bold uppercase text-gray-700 text-center mb-4">
                          Adresse chantier
                        </div>
                        <InputLabel tw="mt-0 pt-0 sm:pt-0" label="Adresse" inputId="street-address">
                          <TextAreaStyled
                            {...register("address")}
                            id="street-address"
                            disabled={readOnly}
                          />
                        </InputLabel>

                        <InputLabel label="Ville" inputId="city">
                          <InputStyled {...register("city")} id="city" disabled={readOnly} />
                        </InputLabel>

                        <InputLabel label="Code postal" inputId="postal-code">
                          <InputStyled
                            {...register("postalCode")}
                            type="text"
                            id="postal-code"
                            disabled={readOnly}
                          />
                        </InputLabel>
                      </div>

                      {/* Billing address seems not required in the work order processs. Let's hide it for now in case of requirements change */}

                      <div tw="text-sm font-bold uppercase text-gray-700 text-center mb-4 mt-6">
                        Adresse facturation
                      </div>
                      <InputLabel tw="mt-0 pt-0 sm:pt-0" label="Adresse" inputId="billingAddress">
                        <TextAreaStyled
                          {...register("billingAddress")}
                          id="billingAddress"
                          disabled={readOnly}
                        />
                      </InputLabel>

                      <InputLabel label="Ville" inputId="billingCity">
                        <InputStyled
                          {...register("billingCity")}
                          type="text"
                          id="billingCity"
                          disabled={readOnly}
                        />
                      </InputLabel>

                      <InputLabel label="Code postal" inputId="billingPostalcode">
                        <InputStyled
                          {...register("billingPostalcode")}
                          type="text"
                          id="billingPostalcode"
                          disabled={readOnly}
                        />
                      </InputLabel>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <InputLabel label="Travaux à effectuer" inputId="workToDo">
                  <TextAreaStyled
                    {...register("description")}
                    rows={4}
                    id="workToDo"
                    disabled={readOnly}
                  />
                </InputLabel>

                <InputLabel label="Notes internes" inputId="internalNotes">
                  <TextAreaStyled
                    {...register("internalNotes")}
                    rows={4}
                    id="internalNotes"
                    disabled={readOnly}
                  />
                </InputLabel>

                <InputLabel label="Travaux réalisés" inputId="workDone">
                  <TextAreaStyled
                    {...register("descriptionWorkDone")}
                    rows={4}
                    id="workDone"
                    disabled={readOnly}
                  />
                </InputLabel>

                <InputLabel label="Observations du client" inputId="observations">
                  <TextAreaStyled
                    {...register("observations")}
                    rows={4}
                    id="observations"
                    disabled={readOnly}
                  />
                </InputLabel>

                <InputLabel
                  label={`Contact ${process.env.REACT_APP_WHITE_LABEL}`}
                  inputId="valvert-contact-name">
                  <InputStyled
                    {...register("valvertContactName")}
                    type="text"
                    disabled={readOnly}
                    id="valvert-contact-name"
                  />
                </InputLabel>
              </fieldset>

              <fieldset tw="pt-6 mt-6">
                <h3 tw="text-lg leading-6 font-medium text-gray-900">Track déchets</h3>
                <InputLabel label="Identifiant bordereau" inputId="bsd-id">
                  <InputStyled {...register("bsdId")} type="text" disabled={readOnly} id="bsd-id" />
                </InputLabel>
                {bsdId && (
                  <InputLabel
                    label="URL de test"
                    inputId="bsd-url"
                    tw="!h-full !w-full !flex !items-end">
                    <a
                      href={urlOfTheBsd}
                      target="_blank"
                      rel="noreferrer"
                      tw="flex truncate max-w-full text-sm text-blue-800 underline ml-3"
                      title={urlOfTheBsd}>
                      {urlOfTheBsd}
                    </a>
                  </InputLabel>
                )}
              </fieldset>

              <fieldset tw="pt-6 mt-6">
                <h3 tw="text-lg leading-6 font-medium text-gray-900">Véhicules</h3>
                {vehicles?.length > 0 && (
                  <>
                    <InputLabel label="Véhicule principal" inputId="primary-vehicle">
                      <WorkOrderVehicleSelect
                        vehicles={vehicles}
                        readOnly={readOnly}
                        required={!readOnly}
                        vehicleFilter={(v) => v.principal}
                        formInputName="primaryVehicle"
                        workOrder={workOrder}
                      />
                    </InputLabel>
                    <InputLabel label="Véhicule secondaire" inputId="secondary-vehicle">
                      <WorkOrderVehicleSelectMulti
                        vehicles={vehicles}
                        readOnly={readOnly}
                        required={false}
                        vehicleFilter={(v) => !v.principal}
                        name="secondaryVehicles"
                        workOrder={workOrder}
                        control={control as unknown as Control<FieldValues, unknown>}
                      />
                    </InputLabel>
                  </>
                )}
                <InputLabel label="ADR" inputId="adr" tw="mb-2">
                  <CheckBoxStyled
                    css={[tw`mt-2.5`]}
                    disabled={readOnly}
                    {...register("licenses")}
                    id="adr"
                    type="checkbox"
                    value="ADR"
                  />
                  <CheckBoxStyled
                    {...register("licenses")}
                    id="adr"
                    type="checkbox"
                    value="DO NOT DELETE"
                    tw="hidden"
                  />
                </InputLabel>
              </fieldset>

              <fieldset tw="pt-6 mt-6">
                <h3 tw="text-lg leading-6 font-medium text-gray-900">Paiement</h3>
                <div tw="flex justify-start flex-wrap flex-col xs:flex-row xs:justify-around gap-4 mt-5">
                  <label
                    htmlFor="espece"
                    tw="block text-sm font-medium text-gray-700 cursor-pointer">
                    <input
                      {...register("payment.paymentMethod")}
                      tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2 cursor-pointer disabled:(text-gray-400 cursor-default)"
                      type="radio"
                      name="payment.paymentMethod"
                      value="Espèce"
                      disabled={readOnly}
                      onChange={() => setIsCheque(false)}
                      id="espece"
                    />
                    Espèce
                  </label>
                  <label
                    htmlFor="cheque"
                    tw="block text-sm font-medium text-gray-700 cursor-pointer">
                    <input
                      {...register("payment.paymentMethod")}
                      tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2 cursor-pointer disabled:(text-gray-400 cursor-default)"
                      type="radio"
                      name="payment.paymentMethod"
                      value="Chèque"
                      id="cheque"
                      disabled={readOnly}
                      onChange={() => setIsCheque(true)}
                    />
                    Chèque
                  </label>
                  <label
                    htmlFor="clientAccount"
                    tw="block text-sm font-medium text-gray-700 cursor-pointer disabled:(cursor-default)">
                    <input
                      {...register("payment.paymentMethod")}
                      tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2 cursor-pointer disabled:(text-gray-400 cursor-default)"
                      type="radio"
                      name="payment.paymentMethod"
                      value="Compte client"
                      disabled={readOnly}
                      onChange={() => setIsCheque(false)}
                      id="clientAccount"
                    />
                    Compte client
                  </label>
                </div>

                {isCheque && (
                  <FormGroup>
                    <Label htmlFor="number" tw="mt-6">
                      Numéro du chèque <RequiredAsterisk tw="text-red-500" />
                    </Label>
                    <InputStyled
                      tw="mt-6 w-2/3"
                      {...register("payment.number", {
                        required: "Le numéro du chèque est obligatoire",
                      })}
                      disabled={readOnly}
                      type="text"
                      name="payment.number"
                      id="number"
                    />
                  </FormGroup>
                )}
              </fieldset>

              <fieldset tw="pt-6 mt-6">
                <h3 tw="text-lg leading-6 font-medium text-gray-900">Service Commercial</h3>
                <InputLabel label="Commentaire du commercial" inputId="salesRepresentativeComment">
                  <TextAreaStyled
                    {...register("salesRepresentativeComment")}
                    rows={4}
                    id="salesRepresentativeComment"
                  />
                </InputLabel>
              </fieldset>

              <fieldset tw="pt-6 mt-6">
                <h3 tw="text-lg leading-6 font-medium text-gray-900">Équipe</h3>
                <section tw="py-5 px-2">
                  {persons
                    .filter((person) => !isPersonUnavailable(person, workOrder.start))
                    .map((person) => (
                      <WorkOrderPersonCheckbox
                        key={person._id}
                        person={person}
                        register={register}
                        disabled={readOnly}
                      />
                    ))}
                </section>
              </fieldset>
            </FormBody>
          </FormBase>
          <WorkOrderAttachments
            workOrder={workOrder}
            setError={setError}
            css={[currentTab !== "attachments" && tw`hidden`]}
          />
          {workOrder.status !== WorkOrderStatus.EN_PREPARATION &&
            workOrder.status !== WorkOrderStatus.TRANSMIS && (
              <WorkOrderHistory
                workOrder={workOrder}
                css={[currentTab !== "OTHistory" && tw`hidden`]}
              />
            )}
        </>
      )}
      <Alert message={error} setMessage={setError} />
      <Confirm
        message={cancelMessage}
        onValidate={onWorkOrderCancelConfirm}
        onCancel={() => setCancelMessage(undefined)}
      />
      <Confirm
        message={deleteMessage}
        onValidate={onWorkOrderDeleteConfirm}
        onCancel={() => setDeleteMessage(undefined)}
      />
    </FormProvider>
  );
};

const WORK_ORDER_TITLE_LABEL = "Résumé";
const WORK_ORDER_START_LABEL = "Date du chantier";

export default WorkOrderForm;
