export enum DrivingLicense {
  VL = "VL",
  PL = "PL",
  SPL = "SPL",
  ADR = "ADR",
  NOLICENCE = "Pas de permis"
}

export const DrivingLicenseColors = new Map<DrivingLicense, string>([
  [DrivingLicense.VL, "indigo"],
  [DrivingLicense.PL, "green"],
  [DrivingLicense.SPL, "yellow"],
  [DrivingLicense.ADR, "red"],
]);

export const drivingLicensePriorities = new Map<DrivingLicense, number>([
  [DrivingLicense.NOLICENCE, -2],
  [DrivingLicense.ADR, -1],
  [DrivingLicense.SPL, 1],
  [DrivingLicense.PL, 2],
  [DrivingLicense.VL, 3],
]);
