/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useContext } from "react";
import { CurrentSiteContextType, CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { useUpsertManyMutation as useUpsertManyPersonMutation } from "../person/usePersonQueries";
import { getRandomInt, nameArray } from "./ExampleDatasVehicles";

export const ExampleDatasWorkers = () => {
  // Get the current site
  const { currentSite } = useContext<CurrentSiteContextType>(CurrentSiteContext);
  // Post multiple OT
  const { mutateAsync: upsertManyPerson } = useUpsertManyPersonMutation();

  const random1 = Math.floor(Math.random() * nameArray.length);
  const random2 = Math.floor(Math.random() * nameArray.length);
  const random3 = Math.floor(Math.random() * nameArray.length);

  const firstnameArray = [
    "Héléna",
    "David",
    "Pierre",
    "April",
    "Jeanne",
    "Prune",
    "Diego",
    "Henri",
    "Johnny",
    "Marie",
    "Amir",
  ];

  const importExampleWorker = () => {
    upsertManyPerson([
      {
        // @ts-ignore
        drivingLicenses: ["VL", "PL"],
        name: `${nameArray[getRandomInt(nameArray.length)]} ${
          firstnameArray[getRandomInt(firstnameArray.length)]
        }`,
        shortName: nameArray[random1],
        initials: "DP",
        site: currentSite,
        login: nameArray[random1].toLowerCase(),
        phone: "",
        unavailabilities: [],
        // @ts-ignore
        status: "Actif",
        // @ts-ignore
        roles: ["Technicien"],
        tempWorker: false,
        __v: 0,
      },
      {
        // @ts-ignore
        drivingLicenses: ["VL", "PL"],
        name: `${nameArray[getRandomInt(nameArray.length)]} ${
          firstnameArray[getRandomInt(firstnameArray.length)]
        }`,
        shortName: nameArray[random2],
        initials: "HH",
        site: currentSite,
        login: nameArray[random2].toLowerCase(),
        phone: "",
        unavailabilities: [],
        // @ts-ignore
        status: "Actif",
        // @ts-ignore
        roles: ["Technicien", "Exploitation"],
        tempWorker: false,
        __v: 0,
      },
      {
        // @ts-ignore
        drivingLicenses: ["VL", "PL", "SPL"],
        name: `${nameArray[getRandomInt(nameArray.length)]} ${
          firstnameArray[getRandomInt(firstnameArray.length)]
        }`,
        shortName: nameArray[random3],
        initials: "CF",
        site: currentSite,
        login: nameArray[random3].toLowerCase(),
        phone: "",
        unavailabilities: [],
        // @ts-ignore
        status: "Actif",
        // @ts-ignore
        roles: ["Technicien", "Exploitation"],
        tempWorker: false,
        __v: 0,
      },
    ]);
  };

  return (
    <span onClick={importExampleWorker} tw="text-primary-500 font-semibold cursor-pointer">
      employés
    </span>
  );
};
