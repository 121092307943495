/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import tw from "twin.macro";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import { BadgeContainer } from "../../mobile/workOrderList/WorkOrderListItem";

export const StatutsFilter = ({ selectedOptions, setSelectedOptions, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <div {...props}>
      <div tw="relative">
        <div onClick={() => setOpen(!open)} tw="flex cursor-pointer">
          <span tw="font-medium text-gray-900">Statuts</span>
          <span tw="ml-2 flex items-center">
            <ChevronDownIcon
              css={[open ? tw`-rotate-180` : tw`rotate-0`, tw`h-5 w-5 transform`]}
              aria-hidden="true"
            />
          </span>
        </div>

        <form
          css={[
            open ? tw`absolute top-3 right-0 z-20` : tw`hidden`,
            tw`mt-4 bg-white p-4 border rounded-md`,
          ]}
        >
          <div tw="flex flex-col gap-4">
            {Object.entries(WorkOrderStatus).map(([, value]) => (
              <div key={value} tw="flex items-center gap-2">
                <input
                  id={value}
                  name={value}
                  type="checkbox"
                  checked={!!selectedOptions.find((o) => o === value)}
                  tw="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                  onChange={() => {
                    if (selectedOptions.find((o) => o === value)) {
                      const filteredOpts = selectedOptions.filter((o) => o !== value);
                      setSelectedOptions(filteredOpts);
                    } else {
                      setSelectedOptions([...selectedOptions, value]);
                    }
                  }}
                />
                <label htmlFor={value}>
                  <BadgeContainer status={value} key={value} />
                </label>
              </div>
            ))}
          </div>
        </form>

        <div
          css={[open ? tw`fixed z-10 inset-0 overflow-y-auto` : tw`hidden`]}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div tw="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              tw="fixed inset-0 bg-red-500 bg-opacity-0 transition-opacity"
              aria-hidden="true"
              onClick={() => setOpen(false)}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
