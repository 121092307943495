import ky from "ky";
import { useMemo } from "react";
import { useQuery, UseQueryResult } from "react-query";
import qs from "qs";
import { KyInstance } from "ky/distribution/types/ky";
import { useAccessToken } from "./useConnectedUser";

export type GenericQueryOptions = {
  limit?: number,
  skip?: number,
  sort?: string;
  populate?: string;
  [query: string]: unknown;
};
export const useGenericQuery = <T, > (
  endpoint: string,
  searchParams = { limit: 0, skip: 0, sort: "-createdAt", populate: null } as GenericQueryOptions
): UseQueryResult<T> => {
  const api = useApi();
  return useQuery<unknown, unknown, T, Array<string | GenericQueryOptions>>([endpoint, searchParams], async () => {
    const response = await api.get(endpoint, {
      searchParams: qs.stringify(searchParams),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json() as T[];
    return {
      totalCount,
      list,
    };
  }, { refetchInterval: 60000 });
};

export const useGenericFindbyIdQuery = <T, > (
  endpoint: string,
  id: string,
): UseQueryResult<T> => {
  const api = useApi();
  return useQuery<unknown, unknown, T, Array<string | GenericQueryOptions>>([endpoint, id], async () => {
    const response = await api.get(`${endpoint}/${id}`);
    return await response.json() as T;
  }, { refetchInterval: 60000 });
};
export const genericFindbyIdQuery = <T, > (endpoint: string, id: string,) => {
  const api = useApi();
  return useQuery<unknown, unknown, T, Array<string | GenericQueryOptions>>({
    queryKey: [endpoint, id],
    queryFn: async () => api.get(`${endpoint}/${id}`).json(),
  });
};

export const useApi = (): KyInstance => {
  const { getAccessTokenSilently } = useAccessToken();
  return useMemo(() => {
    return ky.extend({
      timeout: false,
      prefixUrl: process.env.REACT_APP_API_URL,
      hooks: {
        beforeRequest: [
          async (request) => {
            const accessToken = await getAccessTokenSilently();
            request.headers.set("Authorization", `Bearer ${accessToken}`);
          },
        ],
      },
    });
  }, [getAccessTokenSilently]);
};

