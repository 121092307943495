/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useRef, useState } from "react";
import "twin.macro";
import { PrimaryMobileButton, SecondaryMobileButton } from "../../../shared/components/Buttons";
import { Container } from "../../../shared/components/Container";
import { getPdfFile, useDownloadFileQueries } from "../../../shared/hooks/useDownloadApi";
import { CanvasClientSignature, CanvasSignature } from "./canvasSignature/CanvasSignature";
import {
  BadgeContainer,
  CardContainer,
  DateAndBadgeContainer,
  HeaderContainer,
  NavigationTitle,
  OtHours,
  OtLocation,
  StartDate,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";
import LogoTrackDechets from "../../../resources/trackdechets-img.png";
import { bsdUrl } from "../../../shared/functions/WorkOrderManagement";
import { StatusStepper } from "../../StatusStepper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { ClockIcon, DownloadIcon, PencilIcon } from "@heroicons/react/outline";
import { saveAs } from "file-saver";
import { Spinner } from "../../../shared/components/Spinner";
import { FormGroup, TextAreaStyled } from "../../../shared/components/Form";
import { useForm } from "react-hook-form";
import { useUpsertWorkOrderMutation } from "../../../shared/hooks/useWorkOrderQueries";
import SendMail, { ModalSendMailAutomatically } from "./SendMail";
import tw from "twin.macro";
import { PaymentMethod } from "./PaymentMethod";
import { LabelOT } from "../received/Received";
import { LocalisationIcon } from "../../../shared/components/Icons";
import { useSendMailMutation } from "../../MobileAPIs";

const Done = ({ workOrder, setShowResults, setUrlTechnicianSig, setUrlClientSig }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mutateAsync: upsertWorkOrder, isLoading } = useUpsertWorkOrderMutation();
  const photoNames = [];
  for (let i = 0; i <= workOrder?.photos.length; i++) {
    if (workOrder.photos[i]?.name) {
      photoNames.push(workOrder.photos[i]?.name);
    }
  }
  const { mutateAsync: getPdf, status: getPdfStatus } = getPdfFile();

  const dataPhoto = useDownloadFileQueries(photoNames);

  const { otId } = useParams();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      observations: workOrder?.observations,
    },
  });

  const onSubmitTexarea = (data) => {
    upsertWorkOrder({
      ...workOrder,
      observations: data.observations,
    });
  };

  //Signature
  const sigTechnicianCanvas = useRef<unknown>();
  const sigClientCanvas = useRef<unknown>();

  const saveClientSignature = useCallback(async () => {
    /* @ts-ignore */
    const imageSrc = sigClientCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setUrlClientSig(imageSrc);
    setShowResults(true);
  }, [sigClientCanvas]);

  const saveTechnicianSignature = useCallback(async () => {
    /* @ts-ignore */
    const imageSrc = sigTechnicianCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setUrlTechnicianSig(imageSrc);
    setShowResults(true);
  }, [sigTechnicianCanvas]);
  //EO Signature

  const trackDechetUrl = workOrder?.bsdId
    ? bsdUrl(workOrder.bsdId)
    : `${process.env.REACT_APP_TRACK_DECHET}/transport/to-collect`;

  const [updateBill, setUpdateBill] = useState(false);
  const [emailSentNotification, setEmailSentNotification] = useState(false);
  const [emailAutoSent, setEmailAutoSent] = useState(false);

  const downloadBill = async () => {
    if (workOrder.signedDocument) {
      const pdf = await getPdf(workOrder.signedDocument);
      const blob = new Blob([pdf], { type: "application/pdf" });
      saveAs(blob, workOrder.signedDocument);
    }
  };

  useEffect(() => {
    if (workOrder && !workOrder?.signedDocument) {
      setUpdateBill(true);
    }
  }, [workOrder]);

  const workOrderDone = [WorkOrderStatus.TERMINE, WorkOrderStatus.NON_TERMINE].includes(
    workOrder?.status
  );

  const { mutate } = useSendMailMutation();

  useEffect(() => {
    if (
      workOrder?.emailSentAuto === true &&
      workOrder?.signedDocument &&
      workOrder?.clientEmail &&
      workOrder?.contactName
    ) {
      mutate(
        {
          email: workOrder?.clientEmail,
          clientName: workOrder?.contactName,
          signedDocument: workOrder?.signedDocument,
        },
        {
          onSuccess: () => {
            setEmailAutoSent(true);
          },
        }
      );
    }
  }, [workOrder.signedDocument]);

  return (
    <>
      <StatusStepper workOrdersData={workOrder} otId={otId} />
      <Container tw="mb-20 xl:px-48 2xl:px-96">
        {/* 1ST SECTION */}
        <CardContainer>
          <HeaderContainer>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              <NavigationTitle>{workOrder?.title?.toUpperCase()}</NavigationTitle>
            </div>
            <div tw="flex items-center">
              <a href={trackDechetUrl}>
                <img tw="h-16 w-16 rounded-full" src={LogoTrackDechets} alt="lien track dechets" />
              </a>

              <DateAndBadgeContainer>
                <BadgeContainer status={workOrder.status} />
                <StartDate start={workOrder.actualStart ?? workOrder.start} />
              </DateAndBadgeContainer>
            </div>
          </HeaderContainer>

          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <OtLocation
              address={workOrder.address}
              city={workOrder.city}
              clientName={workOrder.clientName}
              contactPhone={workOrder.contactPhone}
              contactName={workOrder.contactName}
              postalCode={workOrder.postalCode}
              valvertContactName={workOrder.valvertContactName}
            />
            <OtHours start={workOrder.actualStart} end={workOrder.actualEnd} tw="mt-6" />
            {(workOrder.billingAddress || workOrder.billingCity) && (
              <div tw="sm:col-span-1 mt-6">
                <LabelOT tw="inline">Adresse de facturation</LabelOT>

                <div tw="mt-1 text-sm text-gray-900 flex items-center">
                  <LocalisationIcon tw="w-5 h-5 text-gray-500 mr-2" />
                  {workOrder.billingAddress} {workOrder.billingPostalcode} {workOrder.billingCity}
                </div>
              </div>
            )}

            <dl tw="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 mt-6">
              {workOrder.roundTrip && (
                <div tw="sm:col-span-1">
                  <LabelOT>Temps de trajet A/R</LabelOT>
                  <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                    <ClockIcon tw="w-5 h-5 text-gray-500 mr-2" />
                    {workOrder.roundTrip}
                  </dd>
                </div>
              )}
              {workOrder.arrivalTime && (
                <div tw="sm:col-span-1">
                  <LabelOT>Heure d’arrivée</LabelOT>
                  <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                    <ClockIcon tw="w-5 h-5 text-gray-500 mr-2" />
                    {workOrder.arrivalTime}
                  </dd>
                </div>
              )}
              {workOrder.workDuration && (
                <div tw="sm:col-span-1">
                  <LabelOT>Temps de chantier</LabelOT>
                  <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                    <ClockIcon tw="w-5 h-5 text-gray-500 mr-2" />
                    {workOrder.workDuration}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </CardContainer>
        <div tw="grid grid-cols-1 sm:grid-cols-2">
          {/* 2ND SECTION */}
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer
                title="TRAVAUX À EFFECTUER"
                subTitle="Informations générales avant intervention concernant les travaux éffectués"
              />
            </HeaderContainer>
            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl tw="divide-y divide-gray-200">
                <div tw="pt-6 pb-8">
                  <dd tw="mt-2 md:mt-0 md:col-span-7">
                    <div tw="text-base text-gray-500">
                      {workOrder?.description
                        ? workOrder?.description
                            ?.split("\n")
                            .map((str, index) => <p key={index}>{str}</p>)
                        : "Rien à signaler"}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </CardContainer>

          {/* 3RD SECTION */}
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer
                title="TRAVAUX RÉALISÉS"
                subTitle="Informations générales et observations concernant les travaux éffectués"
              />
            </HeaderContainer>
            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl tw="divide-y divide-gray-200">
                <div tw="pt-6 pb-8">
                  <dd tw="mt-2 md:mt-0 md:col-span-7">
                    <div tw="text-base text-gray-500">
                      {workOrder?.descriptionWorkDone
                        ? workOrder?.descriptionWorkDone
                            ?.split("\n")
                            .map((str, index) => <p key={index}>{str}</p>)
                        : "Rien à signaler"}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </CardContainer>
        </div>

        {/* 4TH SECTION */}
        {workOrder?.photos?.length !== 0 && (
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer
                title="PHOTOS"
                subTitle="Photos prises pendant l’intervention"
              />
            </HeaderContainer>
            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl tw="divide-y divide-gray-200">
                <dd tw="mt-2 md:mt-0 md:col-span-7">
                  <ul role="list" tw="grid grid-cols-4 gap-6">
                    {dataPhoto.map(
                      (photo, index) =>
                        photo.status === "success" && (
                          <li
                            tw="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 bg-gray-200"
                            key={index}>
                            <div tw="space-y-4">
                              <img
                                tw="object-cover shadow-lg rounded-lg"
                                src={URL.createObjectURL(photo.data as Blob)}
                                alt=""
                              />
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </dd>
              </dl>
            </div>
          </CardContainer>
        )}

        <CardContainer>
          <HeaderContainer>
            <TitleHeaderContainer
              title="OBSERVATION CHANTIERS"
              subTitle={
                workOrderDone
                  ? "Observations clients et problèmes rencontrés"
                  : "Veuillez terminer l'intervention depuis l'écran précédent avant de saisir ces informations"
              }
            />
          </HeaderContainer>
          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="divide-y divide-gray-200">
              <dd tw="mt-2 md:mt-0 md:col-span-7">
                <form onSubmit={handleSubmit(onSubmitTexarea)}>
                  <FormGroup>
                    <TextAreaStyled
                      tw="flex-1 block w-full text-sm z-0 h-44 focus:z-10 border-gray-300 mt-6 rounded-md focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500)"
                      css={[!workOrderDone && tw`pointer-events-none`]}
                      {...register("observations", { disabled: !workOrderDone })}
                      id="observations"
                    />
                  </FormGroup>

                  {workOrderDone && (
                    <div tw="flex space-x-3 items-center justify-end mt-8">
                      <PrimaryMobileButton type="submit" disabled={isLoading}>
                        Sauvegarder
                      </PrimaryMobileButton>
                    </div>
                  )}
                </form>
              </dd>
            </dl>
          </div>
        </CardContainer>

        {/* 6TH SECTION */}
        {workOrderDone && <PaymentMethod workOrder={workOrder} />}

        <div>
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer title="Ordre de travail" subTitle="Veuillez générer l'OT" />

              {!updateBill && (
                <div tw="flex justify-center items-center">
                  <div tw="py-2 px-4 mr-4 bg-gray-200 rounded-lg cursor-pointer">
                    <div
                      tw="flex flex-col justify-center items-center"
                      onClick={() => {
                        downloadBill();
                      }}>
                      {" "}
                      {getPdfStatus === "loading" ? (
                        <Spinner tw="text-gray-700 w-6 h-6" />
                      ) : (
                        <DownloadIcon tw="text-gray-700 w-6 h-6" />
                      )}
                      <div tw="text-gray-700 text-sm font-medium">PDF</div>
                    </div>
                  </div>
                  <div
                    tw="flex justify-center items-center p-4 bg-gray-700 h-full cursor-pointer"
                    onClick={() => {
                      setUpdateBill(true);
                    }}>
                    <div tw="flex flex-col justify-center items-center">
                      <PencilIcon tw="text-gray-100 w-4 sm:w-6 h-4 sm:h-6" />
                      <span tw="text-xs text-gray-100">Modifier</span>
                    </div>
                  </div>
                </div>
              )}
            </HeaderContainer>

            {/* 5TH SECTION */}
            {updateBill && workOrderDone && (
              <>
                <div tw="pb-6 px-6 grid grid-cols-1 xs:grid-cols-2 gap-6">
                  <CardContainer tw="bg-gray-50">
                    <HeaderContainer>
                      <TitleHeaderContainer
                        title="VISA CHAUFFEUR"
                        subTitle={
                          workOrder?.team.length != 0 &&
                          workOrder?.team.map((member, index) => (
                            <p key={index}>
                              {member?.shortName} {member?.name}
                            </p>
                          ))
                        }
                        tw="h-24"
                      />
                    </HeaderContainer>
                    <div tw="border-t border-gray-200 px-4 pt-5 sm:px-6">
                      <dl tw="divide-y divide-gray-200">
                        <div tw="pt-6">
                          <dd tw="mt-2 md:mt-0 md:col-span-7">
                            <CanvasSignature sigCanvas={sigTechnicianCanvas} />
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </CardContainer>

                  <CardContainer tw="bg-gray-50">
                    <HeaderContainer>
                      <TitleHeaderContainer
                        title="VISA CLIENT"
                        subTitle="Bon pour acceptation,"
                        tw="h-24"
                      />
                    </HeaderContainer>
                    <div tw="border-t border-gray-200 px-4 pt-5 sm:px-6">
                      <dl tw="divide-y divide-gray-200">
                        <div tw="pt-6">
                          <dd tw="mt-2 md:mt-0 md:col-span-7">
                            <CanvasClientSignature sigCanvas={sigClientCanvas} />
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </CardContainer>
                </div>

                <div tw="flex justify-end px-6 pb-6 gap-4">
                  {workOrder && workOrder?.signedDocument && (
                    <PrimaryMobileButton
                      tw="!bg-gray-800"
                      onClick={() => {
                        setUpdateBill(false);
                      }}>
                      Annuler
                    </PrimaryMobileButton>
                  )}
                  <PrimaryMobileButton
                    onClick={() => {
                      setUpdateBill(false);
                      setEmailSentNotification(false);
                      saveTechnicianSignature();
                      saveClientSignature();
                    }}>
                    {workOrder && workOrder?.signedDocument ? "Mettre à jour l'OT" : "Générer l'OT"}
                  </PrimaryMobileButton>
                </div>
              </>
            )}
          </CardContainer>

          {workOrder?.signedDocument && (
            <SendMail
              clientName={workOrder.contactName}
              signedDocument={workOrder.signedDocument}
              emailSentNotification={emailSentNotification}
              setEmailSentNotification={setEmailSentNotification}
              workOrder={workOrder}
            />
          )}

          {emailAutoSent && <ModalSendMailAutomatically setEmailAutoSent={setEmailAutoSent} />}
        </div>

        {/* 7TH SECTION */}
        <div tw="flex space-x-3 items-center justify-start mt-8">
          <SecondaryMobileButton
            onClick={() => {
              navigate(`/mobile/${otId}/work${search}`);
            }}>
            Précedent
          </SecondaryMobileButton>
        </div>
      </Container>
    </>
  );
};

export default Done;
