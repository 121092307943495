import { Person } from "../../types/Person"
import { PersonUnavailabilityType, Unavailability } from "../../types/Unavailability";
import { Vehicle } from "../../types/Vehicle";
import { WorkOrder } from "../../types/WorkOrder"
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import { dateIncludedInPeriod, setTimeInDate } from "./Datetime";

export const MAX_TEAM_SIZE = 3;

export const canAddPersonToWorkOrder = (workOrder: WorkOrder, person?: Person): boolean => {
  return WorkOrderStatus.EN_PREPARATION === workOrder.status // WorkOrder is readonly excepting in draft
    && workOrder.team.length < MAX_TEAM_SIZE // Valvert said 3 person max per workorder
    && (!person || !workOrder.team.some((p) => p._id === person._id)) // If a person is passed, we control it won't be added twice
    && (isPersonUnavailable(person, workOrder.start) === undefined);
};

/** Return unavailability details if person is Unavailable at the given date */
export const isPersonUnavailable = (person: Person, date: Date): Unavailability | undefined => {
  if (!person || !date || !person.unavailabilities || person.unavailabilities.length === 0) {
    return undefined;
  }

  const dateWithoutTime = setTimeInDate(date, "00:00:00.000");

  // not available if date excluded from contract dates
  const contractDates = person.unavailabilities.find((u) => u.type === PersonUnavailabilityType.CONTRAT);
  if (contractDates && !dateIncludedInPeriod(dateWithoutTime, contractDates.start, contractDates.end)) {
    return contractDates;
  }

  // not available if date included in one period of absence
  const absenceDates = person.unavailabilities.filter((u) => u.type !== PersonUnavailabilityType.CONTRAT);
  return absenceDates.find((u) => dateIncludedInPeriod(dateWithoutTime, u.start, u.end));
};

export const getInitials = (user: Vehicle | Person | undefined) =>
  user?.initials && user.initials !== "" ?
    user.initials :
    `${(user?.shortName?.substring(0, 1) ?? "")}${(user?.name?.substring(0, 1) ?? "")}`;

export const getInitials2 = (user: Vehicle | Person | undefined) =>
  user?.initials && user.initials !== "" ?
    user.initials :
    `${(user?.name?.substring(0, 1) ?? "")}${(user?.shortName?.substring(0, 1) ?? "")}`;


