/** @jsxImportSource @emotion/react */
import "twin.macro";
import "./sigCanvas.css";
import SignatureCanvas from "react-signature-canvas";
import { RoundButton } from "../../../../shared/components/Buttons";
import { TrashIcon } from "@heroicons/react/outline";

export const CanvasSignature = ({ sigCanvas }) => {
  const clear = () => {
    /* @ts-ignore */
    sigCanvas.current.clear();
  };

  return (
    <div>
      <>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="green"
          canvasProps={{
            className: "signatureCanvas",
          }}
        />
        <div tw="flex items-center justify-end">
          <div tw="flex items-center justify-end py-2">
            <RoundButton onClick={clear}>
              <TrashIcon tw="h-7 w-5 text-gray-400" />
            </RoundButton>
          </div>
        </div>
      </>
    </div>
  );
};

export const CanvasClientSignature = ({ sigCanvas }) => {
  const clear = () => {
    /* @ts-ignore */
    sigCanvas.current.clear();
  };

  return (
    <div>
      <>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="green"
          canvasProps={{
            className: "signatureCanvas",
          }}
        />
        <div tw="flex items-center justify-end py-2">
          <RoundButton onClick={clear}>
            <TrashIcon tw="h-7 w-5 text-gray-400" />
          </RoundButton>
        </div>
      </>
    </div>
  );
};

export const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
};
