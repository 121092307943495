/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { CrudList } from "../shared/components/CrudList";
import { Td } from "../shared/components/Table";
import { useDeleteMutation, useSearchQuery, useUpsertManyMutation } from "./usePersonQueries";
import { Person } from "../types/Person";
import { DrivingLicenseBadge } from "../shared/components/Badge";
import { arrayProcessor } from "../shared/functions/Import";
import { PersonModal } from "./PersonModal";
import { Page } from "../shared/components/Page";
import {
  CrudListHeader,
  CrudStatusIcon,
  UpdateCrudStatusMenuItem,
} from "../shared/components/CrudListHeader";
import { useCrudParams } from "../shared/hooks/useCrudParams";
import { buildSiteFilter, Filter } from "../shared/functions/QueryHelper";
import { Permission, Role } from "../types/Role";
import { hasAnyPermission } from "../shared/functions/RoleManagement";
import { useConnectedUser } from "../shared/hooks/useConnectedUser";
import { useContext } from "react";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { StandardStatus, StandardStatusColors } from "../types/StandardStatus";

export const PERSON_ROOT_ROUTE = "/person";
export const PERSON_READ_PERMISSIONS = [
  Permission.MANAGE_TECHNICIANS,
  Permission.MANAGE_PLANNERS,
  Permission.MANAGE_SITE_ADMINISTRATORS,
];
export const PERSON_WRITE_PERMISSIONS = [
  Permission.MANAGE_TECHNICIANS,
  Permission.MANAGE_PLANNERS,
  Permission.MANAGE_SITE_ADMINISTRATORS,
];

export const PersonCrud = () => {
  const {
    itemIsOpen,
    onItemOpen,
    onItemClose,
    itemId,
    selectedItems,
    isItemSelected,
    onItemToggle,
    onItemsToggle,
    search,
    debouncedSearch,
    setSearch,
    setPagination,
    pagination,
  } = useCrudParams();
  const { connectedPerson, permissions } = useConnectedUser();
  const { currentSite } = useContext(CurrentSiteContext);
  const rolesFilter = [];
  if (hasAnyPermission([Permission.MANAGE_TECHNICIANS], permissions)) {
    rolesFilter.push({ roles: Role.TECHNICIAN });
  }
  if (hasAnyPermission([Permission.MANAGE_PLANNERS], permissions)) {
    rolesFilter.push({ roles: Role.PLANNER });
  }
  if (hasAnyPermission([Permission.MANAGE_SITE_ADMINISTRATORS], permissions)) {
    rolesFilter.push({ roles: Role.SITE_MANAGER });
  }
  const isAdmin = connectedPerson?.roles?.includes(Role.ADMIN);

  const {
    status,
    data: itemList,
    error,
  } = useSearchQuery({
    ...Filter.and(
      debouncedSearch &&
        Filter.or(
          { name: Filter.regex(debouncedSearch) },
          { shortName: Filter.regex(debouncedSearch) }
        ),
      // Display peoples with the restricted roles fields or no role
      !isAdmin && Filter.or(...rolesFilter, { roles: { $size: 0 } }, { roles: { $exists: false } }),
      buildSiteFilter(currentSite)
    ),
    ...pagination,
  });

  const personMassActions = (
    selectedItems,
    useUpsertManyMutation,
    _useDeleteMutation,
    setMessage
  ) => {
    const archivedCss = { color: StandardStatusColors.get(StandardStatus.archived) };
    const activeCss = { color: StandardStatusColors.get(StandardStatus.active) };

    return (
      <>
        <UpdateCrudStatusMenuItem
          status={StandardStatus.active}
          items={selectedItems}
          useUpsertManyMutation={useUpsertManyMutation}
          setMessage={setMessage}>
          <CrudStatusIcon style={activeCss} />
          Activer
        </UpdateCrudStatusMenuItem>
        <UpdateCrudStatusMenuItem
          status={StandardStatus.archived}
          items={selectedItems}
          useUpsertManyMutation={useUpsertManyMutation}
          setMessage={setMessage}>
          <CrudStatusIcon style={archivedCss} />
          Désactiver
        </UpdateCrudStatusMenuItem>
      </>
    );
  };

  return (
    <Page tw="space-y-2">
      <>
        {status === "error" && <div>{error.toString()}</div>}
        {status !== "error" && (
          <>
            <CrudListHeader
              pageTitle="Employés"
              fieldNames={[
                "name",
                "shortName",
                "login",
                "initials",
                "drivingLicenses",
                "roles",
                "status",
                "_id",
              ]}
              useUpsertManyMutation={useUpsertManyMutation}
              useDeleteMutation={useDeleteMutation}
              importEnabled={true}
              exportEnabled={true}
              creationCallback={onItemOpen}
              selectedItems={selectedItems}
              data={itemList}
              search={search}
              setSearch={setSearch}
              fieldsProcessors={[null, null, null, null, arrayProcessor]}
              searchPlaceHolder="Nom, Prénom"
              readPermissions={PERSON_READ_PERMISSIONS}
              writePermissions={PERSON_WRITE_PERMISSIONS}
              customMassActions={personMassActions}
            />
            <CrudList
              fieldLabels={["Nom", "Prénom", "Login", "Rôles", "Permis"]}
              creationCallback={() => onItemOpen(null)}
              crudListCells={(item) => (
                <CrudListCells item={item as Person} goDetailsCallback={onItemOpen} />
              )}
              data={itemList}
              status={status}
              debouncedSearch={debouncedSearch}
              isSelected={isItemSelected}
              onToggleMany={onItemsToggle}
              onToggle={onItemToggle}
              setPagination={setPagination}
              readPermissions={PERSON_READ_PERMISSIONS}
              writePermissions={PERSON_WRITE_PERMISSIONS}
            />
            <PersonModal personId={itemId} isOpen={itemIsOpen} onClose={onItemClose} />
          </>
        )}
      </>
    </Page>
  );
};

export const CrudListCells = ({ item, goDetailsCallback }: { item: Person; goDetailsCallback }) => (
  <>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.name}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.shortName}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.login}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>
      {item.tempWorker && <span tw="text-yellow-500 font-bold">Int - </span>}
      <span css={[item.tempWorker && tw`text-yellow-500`]}>{item.roles?.join("\n")}</span>
    </Td>
    <Td onClick={() => goDetailsCallback(item._id)}>
      {item.drivingLicenses.map((d) => (
        <DrivingLicenseBadge key={d} drivingLicense={d} />
      ))}
    </Td>
  </>
);
