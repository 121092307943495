/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import "@reach/skip-nav/styles.css";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { HomeOutlineIcon } from "./Icons";
import { GoBackHomePage, Sidebar, SidebarHeader, SidebarNavLink } from "./Sidebar";
import { useNavigate } from "react-router-dom";
import NetworkStatus from "../../mobile/NetworkStatus";
import { MobileAppProfileDropdown } from "./Header/ProfileDropdown";

const SearchBar = () => {
  return <></>;
};

const NotificationButton = () => {
  const { t } = useTranslation();
  return (
    <button tw="p-1 rounded-full text-white hover:text-gray-500 focus:(outline-none ring-2 ring-offset-2 ring-indigo-500)">
      <span tw="sr-only">{t("Layout.notifications")}</span>
    </button>
  );
};

const Navbar = ({ start, center, end }) => {
  return (
    <header tw="relative z-10 flex-shrink-0 flex h-16 bg-gray-100">
      {start}
      <nav tw="flex-1 px-4 flex justify-between">
        <div tw="flex-1 flex">{center}</div>
        <div tw="ml-4 flex items-center md:ml-6 space-x-3">{end}</div>
      </nav>
    </header>
  );
};

export const LayoutMobile = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const closeSidebar = useCallback(() => setIsSidebarOpen(false), []);
  const navigate = useNavigate();
  const naviagateToHomePage = () => {
    navigate(`/mobile`);
  };
  return (
    <div tw="h-screen flex overflow-hidden bg-gray-100">
      <Sidebar isOpen={isSidebarOpen} onDismiss={closeSidebar} header={<SidebarHeader />}>
        <SidebarNavLink to="/mobile" exact="true">
          <HomeOutlineIcon />
          Liste des OT
        </SidebarNavLink>
      </Sidebar>

      {/* Navbar & content */}
      <div tw="flex flex-col w-0 flex-1 overflow-hidden">
        <Navbar
          start={<GoBackHomePage onClick={naviagateToHomePage} />}
          center={<SearchBar />}
          end={
            <>
              <NotificationButton />
              <NetworkStatus />
              <MobileAppProfileDropdown />
            </>
          }
        />
        {children}
      </div>
    </div>
  );
};
