/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useContext } from "react";
import { CurrentSiteContextType, CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { useUpsertManyWorkOrdersMutation } from "../shared/hooks/useWorkOrderQueries";
import { useSearchQuery } from "../shared/hooks/useVehicleQueries";
import { Filter } from "../shared/functions/QueryHelper";
import { getRandomInt } from "./ExampleDatasVehicles";
import { WorkOrderStatus } from "../types/WorkOrderStatus";

export const ExampleDatasOT = () => {
  // Get the current site
  const { currentSite } = useContext<CurrentSiteContextType>(CurrentSiteContext);
  console.log("currentSite", currentSite);
  // Post multiple OT
  const { mutateAsync: upsertManyWorkOrder } = useUpsertManyWorkOrdersMutation();
  // Today date
  const todayDate = new Date().toISOString().slice(0, 10);
  // Get all vehicles by currentSite
  const { data: vehicleList } = useSearchQuery({
    ...Filter.and({ site: currentSite }),
  });

  const nameArray = [
    "ENC refoul",
    "CHIMIREC",
    "Etanch aspi",
    "APRR debouchage",
    "Boucherie Du lac",
    "Pompage",
    "Mairie Clermont",
    "Mr Abeille",
    "ASPI cuve",
    "Aspiration piscine",
    "APBK",
    "Camion chez V1",
  ];

  const importExampleOT = () => {
    upsertManyWorkOrder([
      {
        payment: { paymentMethod: null },
        workOrderNumber: "",
        allDay: false,
        clientName: "Durand",
        contactName: "Durand",
        contactPhone: "0649883746",
        address: "42 rue Joseph Deseymard",
        postalCode: "63000",
        city: "Clermont-Ferrand",
        billingAddress: "",
        billingPostalcode: "",
        billingCity: "",
        valvertContactName: "",
        title: nameArray[getRandomInt(nameArray.length)],
        description: "",
        internalNotes: "",
        descriptionWorkDone: "",
        observations: "",
        salesRepresentativeComment: "",
        start: `${todayDate}T00:00:00.000Z`,
        end: `${todayDate}T23:59:59.999Z`,
        team: [],
        vehicles: [vehicleList.list[0]],
        status: "Transmis",
        site: currentSite,
        bsdId: "",
        clientEmail: "",
        otherAttachments: [],
        licenses: [],
        goBackReasons: [],
        location: [],
        photos: [],
        __v: 0,
      },
      {
        payment: { paymentMethod: null },
        workOrderNumber: "",
        allDay: false,
        clientName: "Smith",
        contactName: "John",
        contactPhone: "0495885777",
        address: "10 place de la victoire",
        postalCode: "63000",
        city: "Clermont-Ferrand",
        billingAddress: "",
        billingPostalcode: "",
        billingCity: "",
        valvertContactName: "",
        title: nameArray[getRandomInt(nameArray.length)],
        description: "",
        internalNotes: "",
        descriptionWorkDone: "",
        observations: "",
        salesRepresentativeComment: "",
        start: `${todayDate}T00:00:00.000Z`,
        end: `${todayDate}T23:59:59.999Z`,
        team: [],
        vehicles: [vehicleList.list[0]],
        status: WorkOrderStatus[Object.keys(WorkOrderStatus)[getRandomInt(8)]],
        site: currentSite,
        bsdId: "",
        clientEmail: "",
        otherAttachments: [],
        licenses: [],
        goBackReasons: [],
        location: [],
        photos: [],
        __v: 0,
      },
      {
        payment: { paymentMethod: null },
        workOrderNumber: "",
        allDay: false,
        clientName: "De lille",
        contactName: "Pierre",
        contactPhone: "0649883746",
        address: "42 rue Joseph Deseymard",
        postalCode: "63000",
        city: "Clermont-Ferrand",
        billingAddress: "",
        billingPostalcode: "",
        billingCity: "",
        valvertContactName: "",
        title: nameArray[getRandomInt(nameArray.length)],
        description: "",
        internalNotes: "",
        descriptionWorkDone: "",
        observations: "",
        salesRepresentativeComment: "",
        start: `${todayDate}T00:00:00.000Z`,
        end: `${todayDate}T23:59:59.999Z`,
        team: [],
        vehicles: [vehicleList.list[1]],
        status: WorkOrderStatus[Object.keys(WorkOrderStatus)[getRandomInt(8)]],
        site: currentSite,
        bsdId: "",
        clientEmail: "",
        otherAttachments: [],
        licenses: [],
        goBackReasons: [],
        location: [],
        photos: [],
        __v: 0,
      },
      {
        payment: { paymentMethod: null },
        workOrderNumber: "",
        allDay: false,
        clientName: "Smith",
        contactName: "John",
        contactPhone: "0495885777",
        address: "10 place de la victoire",
        postalCode: "63000",
        city: "Clermont-Ferrand",
        billingAddress: "",
        billingPostalcode: "",
        billingCity: "",
        valvertContactName: "",
        title: nameArray[getRandomInt(nameArray.length)],
        description: "",
        internalNotes: "",
        descriptionWorkDone: "",
        observations: "",
        salesRepresentativeComment: "",
        start: `${todayDate}T00:00:00.000Z`,
        end: `${todayDate}T23:59:59.999Z`,
        team: [],
        vehicles: [vehicleList.list[2]],
        status: WorkOrderStatus[Object.keys(WorkOrderStatus)[getRandomInt(8)]],
        site: currentSite,
        bsdId: "",
        clientEmail: "",
        otherAttachments: [],
        licenses: [],
        goBackReasons: [],
        location: [],
        photos: [],
        __v: 0,
      },
      {
        payment: { paymentMethod: null },
        workOrderNumber: "",
        allDay: false,
        clientName: "Jean",
        contactName: "Pierre",
        contactPhone: "0642883746",
        address: "42 rue Joseph Deseymard",
        postalCode: "63000",
        city: "Clermont-Ferrand",
        billingAddress: "",
        billingPostalcode: "",
        billingCity: "",
        valvertContactName: "",
        title: nameArray[getRandomInt(nameArray.length)],
        description: "",
        internalNotes: "",
        descriptionWorkDone: "",
        observations: "",
        salesRepresentativeComment: "",
        start: `${todayDate}T09:00:00.000Z`,
        end: `${todayDate}T11:00:00.000Z`,
        team: [],
        vehicles: [vehicleList.list[3]],
        status: WorkOrderStatus[Object.keys(WorkOrderStatus)[getRandomInt(8)]],
        site: currentSite,
        bsdId: "",
        clientEmail: "",
        otherAttachments: [],
        licenses: [],
        goBackReasons: [],
        location: [],
        photos: [],
        __v: 0,
      },
      {
        payment: { paymentMethod: null },
        workOrderNumber: "",
        allDay: false,
        clientName: "Smith",
        contactName: "John",
        contactPhone: "0495885777",
        address: "10 place de la victoire",
        postalCode: "63000",
        city: "Clermont-Ferrand",
        billingAddress: "",
        billingPostalcode: "",
        billingCity: "",
        valvertContactName: "",
        title: nameArray[getRandomInt(nameArray.length)],
        description: "",
        internalNotes: "",
        descriptionWorkDone: "",
        observations: "",
        salesRepresentativeComment: "",
        start: `${todayDate}T15:00:00.000Z`,
        end: `${todayDate}T18:00:00.000Z`,
        team: [],
        vehicles: [vehicleList.list[3]],
        status: WorkOrderStatus[Object.keys(WorkOrderStatus)[getRandomInt(8)]],
        site: currentSite,
        bsdId: "",
        clientEmail: "",
        otherAttachments: [],
        licenses: [],
        goBackReasons: [],
        location: [],
        photos: [],
        __v: 0,
      },
    ]);
  };

  return (
    <div>
      {vehicleList?.list.length >= 3 ? (
        <span onClick={importExampleOT} tw="text-primary-500 font-semibold cursor-pointer">
          ordres de travail
        </span>
      ) : (
        <span>ordres de travail</span>
      )}
    </div>
  );
};
