/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import "twin.macro";
import { CrudCreation, CrudDetails } from "../shared/components/CrudForm";
import { SideModal } from "../shared/components/Modal";
import {
    useUpsertMutation as usePersonUpsertMutation,
    useFindByIdQuery as usePersonFindByQuery,
  } from "./usePersonQueries";
import { formValuesToPerson, PersonForm, personToFormValues } from "./PersonForm";
import { PERSON_READ_PERMISSIONS, PERSON_WRITE_PERMISSIONS } from "./PersonCrud";

/*  
* Use line bellow to control modal from outside
* const { isOpen, onOpen, onClose } = useDisclosure(false); 
*/

export const PersonModal = ({ personId, isOpen, onClose }) => {
    const renderPersonFormFields = useCallback(() => <PersonForm />, []);
    return (
        <SideModal isOpen={isOpen} onDismiss={onClose} aria-label="Popup de création de collaborateur">
            { personId && (
                <CrudDetails
                  title="Informations du collaborateur"
                  objectId={personId}
                  renderFormFields={renderPersonFormFields}
                  useFindByIdQuery={usePersonFindByQuery}
                  useUpsertMutation={usePersonUpsertMutation}
                  itemToFormValues={personToFormValues}
                  formValuesToItem={formValuesToPerson}
                  closeModalAction={onClose}
                  readPermissions={PERSON_READ_PERMISSIONS}
                  writePermissions={PERSON_WRITE_PERMISSIONS}
                />
            )}
            { !personId && (
                <CrudCreation
                  title="Informations du collaborateur"
                  renderFormFields={renderPersonFormFields}
                  useUpsertMutation={usePersonUpsertMutation}
                  itemToFormValues={personToFormValues}
                  formValuesToItem={formValuesToPerson}
                  closeModalAction={onClose}
                  writePermissions={PERSON_WRITE_PERMISSIONS}
                />
            )}
        </SideModal>
    );
};
