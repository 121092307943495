import { useMutation, useQuery } from "react-query";
import qs from "qs";
import { useContext } from "react";
import moment from "moment";
import { useApi } from "../shared/hooks/useApi";
import { DOWNLOAD_ENDPOINT } from "../shared/hooks/useWorkOrderQueries";
import { buildSiteFilter, Filter } from "../shared/functions/QueryHelper";
import { useConnectedUser } from "../shared/hooks/useConnectedUser";
import { WorkOrderStatus } from "../types/WorkOrderStatus";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { WorkOrder } from "../types/WorkOrder";
import { CrudDataType } from "../types/CrudDataType";

export const useWorkorderQuery = () => {
  const api = useApi();
  const { connectedVehicle, connectedPerson } = useConnectedUser();
  const { currentSite } = useContext(CurrentSiteContext);
  const today = moment().startOf("day");

  const filterList = [
    buildSiteFilter(currentSite),
    { status: { $nin: [WorkOrderStatus.ANNULE, WorkOrderStatus.EN_PREPARATION] } },
    {
      start: {
        $gte: today.toDate(),
      },
    },
  ] as unknown[];

  if (process.env.REACT_APP_AUTH0_MOCK) {
    // Fetch everything if authentication is disabled
  } else if (connectedVehicle) {
    filterList.push({ vehicles: connectedVehicle._id });
  } else if (connectedPerson) {
    filterList.push({ team: connectedPerson._id });
  } else {
    filterList[0] = { title: "xxxxxxxxx" }; // Fetch nothing if not connected
  }

  const searchParams = {
    ...Filter.from({ $and: filterList }),
  };
  return useQuery<unknown, unknown, CrudDataType<WorkOrder>, Array<string>>(
    [`v1/workorder`, searchParams],
    async () => {
      const { limit = 10, skip = 0, sort = "-createdAt", ...query } = searchParams;

      const response = await api.get(`v1/workorder`, {
        searchParams: qs.stringify({
          limit,
          skip,
          sort,
          ...query,
        }),
      });
      const totalCount = Number(response.headers.get("X-Total-Count"));
      const list = await response.json();
      return {
        totalCount,
        list,
      };
    },
    { refetchInterval: 60000 }
  );
};

export type SendMailType = {
  signedDocument: string;
  email: string;
  clientName: string;
};
export const useSendMailMutation = () => {
  const api = useApi();
  return useMutation({
    mutationFn: async (data: SendMailType) => {

      const fileBlob = await api.get(`${DOWNLOAD_ENDPOINT}/${data.signedDocument}`).blob() //get the blob document to send

      const formData = new FormData(); //format the datas to formData
      formData.append('file', fileBlob); //the blob
      formData.append('email', data.email); //the email of theclient

      formData.append('clientName', data.clientName); //the name of the client

      return api.post("v1/send-mail", { body: formData} ).json();
    },
  });
};
