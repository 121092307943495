/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FC, PropsWithChildren, useMemo } from "react";
import { DocumentIcon, UploadIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { useDropzone } from "react-dropzone";
import { createDownloadUrl, useDownloadFileQuery } from "../hooks/useDownloadApi";
import { PdfViewer } from "./PdfViewer";

export const FileLoadingIndicator = () => {
  return (
    <div tw="absolute -top-2 -right-2 w-6 h-6 inline-flex items-center justify-center rounded-full text-white bg-gray-500 ring-0 ring-offset-2">
      <svg
        tw="animate-spin h-4 w-4 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle tw="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={2} />
        <path
          tw="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
};

type PreviewProps = {
  url: string;
  imgProps?: { [prop: string]: unknown };
  [prop: string]: unknown;
};

const BasePreview: FC<PropsWithChildren<PreviewProps>> = ({ url, children, ...props }) => (
  <a
    className="group"
    tw="focus:(outline-none ring-2 ring-offset-2 ring-indigo-500) block h-full w-full [max-height: 800px] rounded-lg overflow-hidden"
    target="_blank"
    rel="noreferrer"
    href={url}
    {...props}
  >
    {children}
  </a>
);

const ImagePreview: FC<PreviewProps> = ({ url, imgProps, ...props }) => (
  <BasePreview url={url} tw="aspect-w-10 aspect-h-7" {...props}>
    <img
      tw="group-hover:opacity-75 object-cover pointer-events-none"
      src={url}
      alt=""
      {...imgProps}
    />
  </BasePreview>
);

const PdfPreview: FC<PreviewProps> = ({ url, ...props }) => (
  <BasePreview url={url} {...props}>
    <PdfViewer
      tw="group-hover:opacity-75 object-cover pointer-events-none"
      url={url}
      alt=""
      height={400}
      width={400}
      scale={1}
      isThumbnail={true}
    />
  </BasePreview>
);

const DefaultFilePreview: FC<PreviewProps> = ({ url, ...props }) => (
  <BasePreview url={url} tw="aspect-w-10 aspect-h-7" {...props}>
    <div tw="group-hover:opacity-75 pointer-events-none bg-gray-200 flex items-center justify-center">
      <DocumentIcon tw="text-gray-400 h-16 w-16" />
    </div>
  </BasePreview>
);

const extensions = new Map([
  ["jpg", "image/jpeg"],
  ["jpeg", "image/jpeg"],
  ["png", "image/png"],
  ["webp", "image/webp"],
  ["pdf", "application/pdf"],
]);

type FilePreviewProps = {
  filenameToDisplay: string;
  realFilename: string;
  onDelete: () => void;
  [prop: string]: unknown;
};
export const FilePreview: FC<FilePreviewProps> = ({
  filenameToDisplay,
  realFilename,
  onDelete,
  ...props
}) => {
  const fileExtension = realFilename.substring(realFilename.lastIndexOf(".") + 1);
  const contentType = extensions.get(fileExtension.toLowerCase());
  const isImage = contentType?.toLowerCase()?.startsWith("image");
  const isPdf = contentType?.toLowerCase()?.includes("pdf");
  const { data: blob } = useDownloadFileQuery(realFilename);
  const url = useMemo<string>(() => createDownloadUrl(blob, contentType), [blob]);

  return (
    <>
      {isImage && <ImagePreview url={url} {...props} />}
      {isPdf && <PdfPreview url={url} download={filenameToDisplay} {...props} />}
      {!isPdf && !isImage && realFilename && <DefaultFilePreview url={url} {...props} />}

      {/* Delete button */}
      {onDelete && realFilename && (
        <button
          type="button"
          tw="opacity-100 absolute -top-2 -right-2 w-6 h-6 inline-flex items-center justify-center rounded-full text-white bg-gray-500 hover:(bg-gray-600) ring-0 ring-offset-2 focus:(opacity-100 outline-none ring-2 ring-indigo-500)"
        >
          <XIcon tw="h-5 w-5" onClick={() => onDelete()} />
          <span tw="sr-only">Supprimer</span>
        </button>
      )}
      {/* File name */}
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        tw="mt-2 block text-sm font-medium text-primary-800 hover:text-primary-600 truncate"
      >
        {filenameToDisplay}
      </a>
    </>
  );
};

type FileInputType = {
  onFileChange: (e) => void;
  accept: string;
  [prop: string]: unknown;
};
export const FileInput = ({
  onFileChange,
  accept, // e.g. "image/*,*.pdf"
  ...props
}: FileInputType) => {
  const { getRootProps, getInputProps: dropzoneProps } = useDropzone({ onDrop: onFileChange });
  return (
    <div tw="relative flex" {...props}>
      <label
        htmlFor="fileinput"
        tw="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden border-2 border-gray-300 border-dashed cursor-pointer hover:(opacity-75) focus:(outline-none ring-2 ring-offset-2 ring-indigo-500)"
        {...getRootProps()}
        onClick={(e) => e.stopPropagation()}
      >
        <div tw="flex items-center justify-center">
          <UploadIcon tw="h-12 w-12 text-gray-300" />
        </div>
      </label>
      <input
        id="fileinput"
        type="file"
        tw="opacity-0 w-0"
        accept={accept}
        onChange={onFileChange}
        {...dropzoneProps()}
      />
    </div>
  );
};
