/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FC } from "react";
import { Vehicle } from "../../types/Vehicle";
import { DrivingLicenseBadge } from "../../shared/components/Badge";
import { Permission } from "../../types/Role";
import { hasAnyPermission } from "../../shared/functions/RoleManagement";
import { useConnectedUser } from "../../shared/hooks/useConnectedUser";
import { VehicleIcon } from "../../shared/components/Vehicles/VehiclesIcon";

export const SchedulerResource: FC<{
  resource: Vehicle;
  onClick: () => void;
}> = ({ resource, onClick }) => {
  const { permissions } = useConnectedUser();
  const readOnly = !hasAnyPermission([Permission.WORKORDER_WRITE], permissions);

  return (
    <a
      href="#"
      onClick={
        readOnly
          ? () => {
              /* noop */
            }
          : onClick
      }
      css={[tw`!text-primary-800 hover:!text-primary-600`]}
    >
      <p tw="flex flex-row text-sm">
        {resource.name && (
          <span css={[tw`flex items-center max-w-full truncate`]}>{resource.name ?? resource.model}</span>
        )}
      </p>
      <p tw="text-sm font-light flex flex-row space-x-1">
        <VehicleIcon name={resource.icon} tw="w-10" />
        &nbsp;
        <DrivingLicenseBadge drivingLicense={resource.drivingLicense} />
      </p>
    </a>
  );
};
