/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FileInput, FilePreview } from "../shared/components/Attachments";
import { handleError } from "../shared/functions/ErrorHandling";
import { useApi } from "../shared/hooks/useApi";
import { useFormContext } from "react-hook-form";
import { SITE_ENDPOINT } from "./useSiteQueries";

export const SiteFooterAttachment = ({ setError }) => {
  const { watch, setValue } = useFormContext();

  const siteId = watch("_id");
  const footerImage = watch("footerImage");
  const api = useApi();

  const onFileAdd = async (event: FileList) => {
    const formData = new FormData();
    formData.append("attachment", event[0]);
    try {
      const response = (await api
        .post(`${SITE_ENDPOINT}/upload/${siteId || ""}`, {
          body: formData,
        })
        .json()) as unknown as { filename: string };
      setValue("footerImage", response.filename);
    } catch (err) {
      const errMsg = await handleError(err);
      setError(errMsg);
    }
  };

  const onFileRemove = async () => {
    try {
      setValue("footerImage", undefined);
    } catch (err) {
      handleError(err).then((errMsg) => setError(errMsg));
    }
  };

  const title = footerImage?.substring(footerImage?.lastIndexOf("_") + 1);

  return (
    <div tw="relative">
      {footerImage ? (
        <FilePreview
          filenameToDisplay={title}
          realFilename={footerImage}
          onDelete={onFileRemove}
          imgProps={{ css: tw`object-contain h-auto` }}
        />
      ) : (
        <FileInput accept="image/*" onFileChange={onFileAdd} />
      )}
    </div>
  );
};

export const SiteLeftHeaderAttachment = ({ setError }) => {
  const { watch, setValue } = useFormContext();

  const siteId = watch("_id");
  const leftHeaderImage = watch("leftHeaderImage");
  const api = useApi();

  const onFileAdd = async (event: FileList) => {
    const formData = new FormData();
    formData.append("attachment", event[0]);
    try {
      const response = (await api
        .post(`${SITE_ENDPOINT}/upload/${siteId || ""}`, {
          body: formData,
        })
        .json()) as unknown as { filename: string };
      setValue("leftHeaderImage", response.filename);
    } catch (err) {
      const errMsg = await handleError(err);
      setError(errMsg);
    }
  };

  const onFileRemove = async () => {
    try {
      setValue("leftHeaderImage", undefined);
    } catch (err) {
      handleError(err).then((errMsg) => setError(errMsg));
    }
  };

  const title = leftHeaderImage?.substring(leftHeaderImage?.lastIndexOf("_") + 1);

  return (
    <div tw="relative">
      {leftHeaderImage ? (
        <FilePreview
          filenameToDisplay={title}
          realFilename={leftHeaderImage}
          onDelete={onFileRemove}
          imgProps={{ css: tw`object-contain h-auto` }}
        />
      ) : (
        <FileInput accept="image/*" onFileChange={onFileAdd} />
      )}
    </div>
  );
};
