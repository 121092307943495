/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import "twin.macro";
import { FC, useCallback, useMemo } from "react";
import { mapWorkOrderToEvent } from "../../functions/WorkOrderManagement";
import { useUpsertWorkOrderMutation, useWorkOrderFindByIdQuery } from "../../hooks/useWorkOrderQueries";
import { WorkOrder } from "../../../types/WorkOrder";
import WorkOrderForm from "./WorkOrderForm";
import { SideModal } from "../Modal";

type WorkOrderEditPopupProps = {
  onClose: () => void;
  workOrderId: string | undefined;
  onWorkOrderDuplicate?: (event: WorkOrder) => void;
};
export const WorkOrderEditPopup: FC<WorkOrderEditPopupProps> = ({
  onClose,
  workOrderId,
  onWorkOrderDuplicate,
}) => {
  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();
  const { data: workOrderData } = useWorkOrderFindByIdQuery(workOrderId);
  const workOrder = useMemo<WorkOrder>(() => mapWorkOrderToEvent(workOrderData) as WorkOrder, [workOrderData]);

  const onWorkOrderUpdate = useCallback(() => {
    onClose();
  }, [onClose, upsertWorkOrder]);

  return (
    <>
      { workOrder && (
        <SideModal
          isOpen={true}
          onDismiss={onClose}
          aria-label="Modification d'ordre de travail"
          tw="!overflow-y-hidden"
        >
          <WorkOrderForm
            workOrder={workOrder}
            onCancel={onClose}
            onValidate={onWorkOrderUpdate}
            onDuplicate={onWorkOrderDuplicate}
          />
        </SideModal>
      )}
    </>
  );
};
