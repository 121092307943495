/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useForm } from "react-hook-form";
import "twin.macro";
import { PrimaryMobileButton } from "../../../shared/components/Buttons";
import { FormGroup, InputStyled, Label, RequiredAsterisk } from "../../../shared/components/Form";
import { useUpsertWorkOrderMutation } from "../../../shared/hooks/useWorkOrderQueries";
import {
  CardContainer,
  HeaderContainer,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";

export const PaymentMethod = ({ workOrder }) => {
  const [isCheque, setIsCheque] = useState(
    workOrder.payment?.paymentMethod === "Chèque" ? true : false
  );

  const { register, handleSubmit } = useForm({
    defaultValues: {
      payment: workOrder?.payment,
    },
  });

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  const onSubmit = (data) => {
    upsertWorkOrder({
      ...workOrder,
      payment: data.payment,
    });
  };
  return (
    <CardContainer>
      <HeaderContainer>
        <TitleHeaderContainer
          title="RÉGLEMENT"
          subTitle="Informations générales sur le mode de paiement du client"
        />
      </HeaderContainer>
      <div tw="border-t border-gray-200 px-4 pb-5 pt-4 sm:px-6">
        <dl tw="divide-y divide-gray-200">
          <dd tw="md:mt-0 md:col-span-7">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset tw="mt-4">
                <legend tw="sr-only">Paiement</legend>
                <div tw="flex justify-start flex-wrap flex-col xs:flex-row xs:justify-around gap-4">
                  <label htmlFor="espece" tw="block text-sm font-medium text-gray-700">
                    <input
                      {...register("payment.paymentMethod")}
                      tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2"
                      type="radio"
                      name="payment.paymentMethod"
                      value="Espèce"
                      onChange={() => setIsCheque(false)}
                      id="espece"
                    />
                    Espèce
                  </label>
                  <label htmlFor="cheque" tw="block text-sm font-medium text-gray-700">
                    <input
                      {...register("payment.paymentMethod")}
                      tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2"
                      type="radio"
                      name="payment.paymentMethod"
                      value="Chèque"
                      id="cheque"
                      onChange={() => setIsCheque(true)}
                    />
                    Chèque
                  </label>
                  <label htmlFor="clientAccount" tw="block text-sm font-medium text-gray-700">
                    <input
                      {...register("payment.paymentMethod")}
                      tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2"
                      type="radio"
                      name="payment.paymentMethod"
                      value="Compte client"
                      onChange={() => setIsCheque(false)}
                      id="clientAccount"
                    />
                    Compte client
                  </label>
                </div>
                {isCheque === true && (
                  <FormGroup>
                    <Label htmlFor="number" tw="mt-6">
                      Numéro du chèque <RequiredAsterisk tw="text-red-500" />
                    </Label>
                    <InputStyled
                      tw="mt-6 w-2/3"
                      {...register("payment.number", {
                        required: "Le numéro du chèque est obligatoire",
                      })}
                      type="text"
                      name="payment.number"
                      id="number"
                    />
                  </FormGroup>
                )}
              </fieldset>
              <div tw="flex space-x-3 items-center justify-end mt-8">
                <PrimaryMobileButton type="submit" onClick={handleSubmit(onSubmit)}>
                  Sauvegarder
                </PrimaryMobileButton>
              </div>
            </form>
          </dd>
        </dl>
      </div>
    </CardContainer>
  );
};
