import moment, { Moment, unitOfTime } from "moment-timezone";
//
// THE FOLLOWING FUNCTIONS WRAP MOMENT FEATURES
// THE PURPOSE IS TO GET RID OF TIMEZONE 
// + IF NEEDED MOVE EASILY FROM MOMENT TO ANOTHER LIB
//

/**
 * This is a global override to prevent browser to apply Timezone offset when sending dates to API
 * In the scope of this project, we don't need timezone management which adds useless complexity.
 */
export const overrideGlobalToJSON = (): void => {
  Date.prototype.toJSON = function() {
    const clone = moment(this).utc(true);
    // const hoursDiff = this.getHours() + this.getTimezoneOffset() / 60;
    // clone.hours(hoursDiff);
    return clone.toISOString();
  };
};

/** This is a global override to prevent browser to apply Timezone offset when creating a new date from string with timezone */
export const overrideGlobalDateConstructor = () => {
  // @ts-ignore
  // eslint-disable-next-line no-global-assign
  Date = class extends Date {
    constructor(arg, ...rest) {
      if (typeof arg === "string") {
        // @ts-ignore
        super(removeTimezoneFromString(arg), ...rest);
      } else {
        // @ts-ignore
        super(arg, ...rest);
      }
    }
  };
};

const removeTimezoneFromString = (dateString: string): string => {
  const indexOfZ = dateString.indexOf("Z");
  if (indexOfZ >= 0) {
    return dateString.substring(0, indexOfZ);
  }
  return dateString;
}

/** Remove timezone offset from date or string, returns the new date */
const FORMATS = [moment.HTML5_FMT.DATETIME_LOCAL_MS, moment.HTML5_FMT.DATE, "DD/MM/YYYY", "DDMMYY" /* Quadra import */];
export const toDate = (date: string): Date => {
  const noTimezoneString = removeTimezoneFromString(date);
  let m;
  for (const format of FORMATS) {
    m = moment(noTimezoneString, format, true);
    if (m.format() !== "Invalid date") {
      break;
    }
  }
  if (m.format() === "Invalid date") {
    throw new Error(`Date invalide ${date}`);
  }
  const d = m.utc().toDate();
  return d;
};

export const formatDate = (date: Date | string, format: string): string => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  const m = moment(theDate).utc(true);
  return m.format(format);
};

/** Used to fix date format to display accross all the app */
export const dateDisplay = (date: Date | string): string => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  return formatDate(theDate, "DD/MM/YYYY");
};

/** Used to fix time format to display accross all the app */
export const timeDisplay = (date: Date | string): string => {
  if (date) {
    const theDate = typeof date === "string" ? toDate(date) : date;
    const dateMoment = moment(theDate);
    const theTime = dateMoment.format("HH:mm");
    return ["23:59", "00:00"].includes(theTime) ? "" : theTime; 
  } else {
    return "";
  }
};

/** Return complete time format from date */
export const extractTimeFromDate = (date: Date | string): string => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  const dateMoment = moment(theDate);
  const hh = `${dateMoment.hours()}`.padStart(2, "0");
  const mm = `${dateMoment.minutes()}`.padStart(2, "0");
  const ss = `${dateMoment.seconds()}`.padStart(2, "0");
  const ms = `${dateMoment.milliseconds()}`.padStart(3, "0");
  return `${hh}:${mm}:${ss}.${ms}`;
};

/** Set time to given date */
export const setTimeInDate = (date: Date | string, time: string): Date => {
  const [hours, minutes, seconds, millis] = time.split(/[:.]/);
  const theDate = typeof date === "string" ? moment(toDate(date)) : moment(date);
  return theDate.hours(parseInt(hours))
      .minutes(parseInt(minutes))
      .seconds(parseInt(seconds))
      .milliseconds(parseInt(millis))
      .toDate();
};

/** 
 * Set start and end as given day + time, excepting if the allDay flag is set
 * 
 */
export const computeDates = (
  startDate: Date | string,
  allDay: boolean,
  startTime: string,
  endTime: string,
): Date[] => {
  const computedStartTime = allDay || !startTime ? "00:00:00.000" : startTime; // If allDay is true or if time is undefined, start is set to 00:00:00.000 
  const computedEndTime  = allDay || !endTime ? "23:59:59.999" : endTime;      // If allDay is true or if time is undefined, end is set to 23:59:59.999 
  
  // User can't modify end date in the workorder form because workorder are 1 day max
  // so we hardcode same day for start and end
  const endDate = startDate;

  return [
    setTimeInDate(startDate, computedStartTime),
    setTimeInDate(endDate, computedEndTime),
  ];
};

/** Test if 2 dates are the same regardless of their time */
export const sameDay = (oneDate: Date | string, otherDate: Date | string): boolean => {
  const oneDateWithoutTime = setTimeInDate(oneDate, "00:00:00.000");
  const otherDateWithoutTime = setTimeInDate(otherDate, "00:00:00.000");
  return oneDateWithoutTime.getTime() === otherDateWithoutTime.getTime();
};

export const isAfter = (oneDate: Date | string, otherDate: Date | string): boolean => {
  const theOneDate = typeof oneDate === "string" ? toDate(oneDate) : oneDate;
  const theOtherEnd = typeof otherDate === "string" ? toDate(otherDate) : otherDate;
  return moment(theOneDate).isAfter(moment(theOtherEnd));
};

export const isBefore = (oneDate: Date | string, otherDate: Date | string): boolean => {
  const theOneDate = typeof oneDate === "string" ? toDate(oneDate) : oneDate;
  const theOtherEnd = typeof otherDate === "string" ? toDate(otherDate) : otherDate;
  return moment(theOneDate).isBefore(moment(theOtherEnd));
};

export const compare = (oneDate: Date | string, otherDate: Date | string): number => {
  return isAfter(oneDate, otherDate) ? 1 : isBefore(oneDate, otherDate) ? -1 : 0;
};

/** Add days from the start of the week */
export const setWeekDay = (date: Date | string, day: number): Date => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  return moment(theDate).utc().startOf("week").add(day, "days").toDate();
};

export const dateIncludedInPeriod = (date: Date | string, periodStart: Date | string, periodEnd: Date | string): boolean => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  const dateMoment = moment(theDate);
  const thePeriodStart = typeof periodStart === "string" ? toDate(periodStart) : date;
  const periodStartMoment = moment(thePeriodStart);
  const thePeriodEnd = typeof periodEnd === "string" ? toDate(periodEnd) : date;
  const periodEndMoment = moment(thePeriodEnd);
  const result = dateMoment.isBetween(periodStartMoment, periodEndMoment, undefined, "[]");
  return result;
};

/** Return list of days included between dates */
export const getEachDayBetween = (startDate: Date | Moment, endDate: Date | Moment,): Date[] => {
  const firstDay = moment(startDate);
  const lastDay = moment(endDate);
  let currentDay = firstDay;
  const result = [];
  while (!currentDay.isAfter(lastDay)) {
    result.push(currentDay.toDate());
    currentDay = currentDay.add(1, "day");
  }
  return result;
};

/** Return list of days included in the given period */
export const getEachDayOfPeriod = (date: Date | string, periodRange: "week" | "month"): Date[] => {
  const firstDay = startOfPeriod(date, periodRange);
  const lastDay = endOfPeriod(date, periodRange);
  return getEachDayBetween(firstDay, lastDay);
};

export const startOfPeriod = (date: string | Date, period: unitOfTime.StartOf) => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  let startOfPeriod = moment(theDate).startOf(period);
  if (period === "week") {
    startOfPeriod = startOfPeriod.add(1, "days"); // english weeks begin on sunday
  }
  return startOfPeriod;
};

export const endOfPeriod = (date: string | Date, period: unitOfTime.StartOf) => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  let endOfPeriod = moment(theDate).endOf(period);
  if (period === "week") {
    endOfPeriod = endOfPeriod.add(1, "days"); // english weeks end on saturday
  }
  return endOfPeriod;
};

export const getWeekNumber = (date: string | Date) => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  return moment(theDate).isoWeek();
};

export const addDays = (date: string | Date, n: number): Moment => {
  const theDate = typeof date === "string" ? toDate(date) : date;
  return moment(theDate).add(n, "days");
};
