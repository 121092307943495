export enum PersonUnavailabilityType {
  CONGESS = "CP-Récup",
  CONGES = "CP",
  RECUP = "Récup",
  MALADIE = "Maladie",
  AT = "AT",
  FORMATION = "Formation",
  CONTRAT = "Dates de contrat", // CDD, intérim
}

export const PersonUnavailabilityTypeCodes = new Map<PersonUnavailabilityType, string>([
  [PersonUnavailabilityType.CONGES, "CP"],
  [PersonUnavailabilityType.CONGESS, "CP"],
  [PersonUnavailabilityType.CONTRAT, "CO"],
  [PersonUnavailabilityType.FORMATION, "F"],
  [PersonUnavailabilityType.RECUP, "R"],
  [PersonUnavailabilityType.AT, "AT"],
  [PersonUnavailabilityType.MALADIE, "M"],
]);

export enum VehicleUnavailabilityType {
  REVISION = "Révision",
}

export type UnavailabilityType = PersonUnavailabilityType | VehicleUnavailabilityType;

export type Unavailability = {
  start?: Date;
  end?: Date;
  type: UnavailabilityType;
  comment?: string;
};
