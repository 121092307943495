/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import "twin.macro";
import { createDownloadUrl, useDownloadFileQuery } from "../../../shared/hooks/useDownloadApi";

const extensions = new Map([
  ["jpg", "image/jpeg"],
  ["jpeg", "image/jpeg"],
  ["png", "image/png"],
  ["webp", "image/webp"],
]);

const PhotoPreview = ({ photoNames, ...props }) => {
  const fileExtension = photoNames.substring(photoNames.lastIndexOf(".") + 1);
  const contentType = extensions.get(fileExtension.toLowerCase());
  const { data: blob } = useDownloadFileQuery(photoNames);
  const url = useMemo<string>(() => createDownloadUrl(blob, contentType), [blob]);
  return (
    <>
      <img tw="rounded-lg h-32 w-full object-cover" src={url} alt="Photos chantier" {...props} />
    </>
  );
};

export default PhotoPreview;
