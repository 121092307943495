/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FC, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Photo, WorkOrder } from "../../../types/WorkOrder";
import {
  useUpsertWorkOrderMutation,
  useReplaceWorkOrderMutation,
  WORK_ORDERS_ENDPOINT,
} from "../../hooks/useWorkOrderQueries";
import { useApi } from "../../hooks/useApi";
import { handleError } from "../../functions/ErrorHandling";
import { Tab, Tabs } from "../Tabs";
import { FilePreview, FileInput } from "../Attachments";
import qs from "qs";
import styled from "@emotion/styled";
import { PrimaryMobileButton } from "../Buttons";
import { useDownloadFileQuery } from "../../hooks/useDownloadApi";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import ModalFullPdf from "../../../mobile/workOrderDetails/done/ModalPdfTemplate";

type WorkOrderAttachmentsProps = {
  workOrder: WorkOrder;
  setError: (s) => void;
  [other: string]: unknown;
};
export const WorkOrderAttachments: FunctionComponent<WorkOrderAttachmentsProps> = ({
  workOrder,
  setError,
  ...props
}) => {
  const [tabs, setTabs] = useState<AttachmentTab[]>(buildAttachmentsMetadata(workOrder));

  //Generate PDF
  const [showResults, setShowResults] = useState(false);
  const [siteFooter, setSiteFooter] = useState();
  const [siteLeftHeader, setSiteLeftHeader] = useState();

  const siteFooterBlob = useDownloadFileQuery(workOrder?.site?.footerImage);
  const siteFooterBlobData = siteFooterBlob.data;
  async function siteFooterBlobDataToBase64(siteFooterBlobData) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(siteFooterBlobData);
    });
  }

  useEffect(() => {
    if (siteFooterBlobData) {
      siteFooterBlobDataToBase64(siteFooterBlobData).then((base64String: string) =>
        setSiteFooter(base64String as any)
      );
    }
  }, [siteFooterBlobData, setSiteFooter]);

  const siteLeftHeaderBlob = useDownloadFileQuery(workOrder?.site?.leftHeaderImage);
  const siteLeftHeaderBlobData = siteLeftHeaderBlob.data;
  async function siteLeftHeaderBlobDataToBase64(siteLeftHeaderBlobData) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(siteLeftHeaderBlobData);
    });
  }

  useEffect(() => {
    if (siteLeftHeaderBlobData) {
      siteLeftHeaderBlobDataToBase64(siteLeftHeaderBlobData).then((base64String: string) =>
        setSiteLeftHeader(base64String as any)
      );
    }
  }, [siteLeftHeaderBlobData, setSiteLeftHeader]);
  //EO Generate PDF

  // Update tabs with workOrder changes
  useEffect(() => {
    const newTabs = buildAttachmentsMetadata(workOrder).map((tab) => ({
      ...tab,
      current: tab.id === tabs.find((t) => t.current).id,
    }));
    setTabs(newTabs);
  }, [workOrder]);

  const onTabChange = (e: AttachmentTab) => {
    setTabs(tabs.map((tab) => ({ ...tab, current: tab.id === e.id })));
  };
  const currentTab = useMemo<AttachmentTab>(() => tabs.find((tab) => tab.current), [tabs]);

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();
  const { mutateAsync: replaceWorkOrder } = useReplaceWorkOrderMutation();
  const updateParent = useCallback(
    (operation: "addOrReplace" | "remove", filename: string): Promise<unknown> => {
      const newWorkOrder = { ...workOrder };
      switch (operation) {
        case "addOrReplace":
          newWorkOrder[currentTab.id] = filename;
          return upsertWorkOrder(newWorkOrder);
        case "remove":
          newWorkOrder[currentTab.id] = undefined;
          return replaceWorkOrder(newWorkOrder);
        default:
          throw new Error("Unknown operation");
      }
    },
    [workOrder, currentTab]
  );
  const api = useApi();
  const onFileAdd = async (event: FileList) => {
    const { filename } = await uploadFile(
      event,
      setError,
      workOrder._id,
      workOrder.workOrderNumber,
      api
    );
    await updateParent("addOrReplace", filename);
  };
  return (
    <section tw="mx-4 min-h-full bg-white" {...props}>
      <Tabs tabs={tabs} onChange={onTabChange} />
      {currentTab.name === "OT Signé" &&
        !workOrder.signedDocument &&
        (workOrder.status === WorkOrderStatus.EN_COURS ||
          workOrder.status === WorkOrderStatus.TERMINE ||
          workOrder.status === WorkOrderStatus.NON_TERMINE) && (
          <header tw="flex flex-row space-x-5 items-center justify-center border-t border-gray-200 pt-12">
            <h3 tw="text-lg leading-6 font-medium text-gray-900 py-3">{currentTab.name}</h3>
            <PrimaryMobileButton
              onClick={() => {
                setShowResults(true);
              }}>
              {"Générer l'OT"}
            </PrimaryMobileButton>
          </header>
        )}
      {currentTab.type === "single" && (
        <FilePreviewList tw="min-h-full space-x-0 space-y-0 gap-x-0 gap-y-0">
          {workOrder[currentTab.id] && (
            <FilePreviewItem
              filename={workOrder[currentTab.id]}
              title={extractOriginalFilename(workOrder[currentTab.id])}
              updateParent={updateParent}
              setError={setError}
              tw="w-full h-full"
            />
          )}
          {!workOrder[currentTab.id] && !currentTab.readonly && (
            <li tw="relative w-full h-full">
              <FileInput accept={currentTab.contentType} onFileChange={onFileAdd} tw="" />
            </li>
          )}
        </FilePreviewList>
      )}
      {currentTab.type === "list" && (
        <FileListAttachment workOrder={workOrder} currentTab={currentTab} setError={setError} />
      )}

      {showResults && (
        <ModalFullPdf
          status="success"
          workOrder={workOrder}
          showResults={showResults}
          setShowResults={setShowResults}
          urlTechnicianSig=""
          urlClientSig=""
          siteFooter={siteFooter}
          siteLeftHeader={siteLeftHeader}
          setShowResultsOTPdfNotSent={() => { /* noop */}}
          setShowResultsOTPdfSent={() => { /* noop */}}
          typePdf="planning"
        />
      )}
    </section>
  );
};



const uploadFile = async (event: FileList, setError, workOrderId, workOrderNumber, api) => {
  const formData = new FormData();
  formData.append("attachment", event[0]);
  try {
    return api
      .post(`${WORK_ORDERS_ENDPOINT}/upload/${workOrderId}`, {
        body: formData,
        searchParams: workOrderNumber && qs.stringify({ workOrderNumber }),
      })
      .json() as {
      filename;
    };
  } catch (err) {
    handleError(err).then((errMsg) => setError(errMsg));
  }
};

type FileListAttachmentProps = {
  workOrder: WorkOrder;
  currentTab: AttachmentTab;
  setError: (s) => void;
};
const FileListAttachment: FunctionComponent<FileListAttachmentProps> = ({
  workOrder,
  currentTab,
  setError,
}) => {
  const api = useApi();
  const fileList = useMemo<string[] | Photo[]>(
    () => workOrder[currentTab.id] ?? [],
    [workOrder, currentTab]
  );
  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();
  const { mutateAsync: replaceWorkOrder } = useReplaceWorkOrderMutation();
  const updateParent = useCallback(
    (operation: "addOrReplace" | "remove", filename: string, index?: number): Promise<unknown> => {
      const newWorkOrder = { ...workOrder };
      const newFileList = newWorkOrder[currentTab.id] ?? [];
      switch (operation) {
        case "addOrReplace":
          if (index === undefined) {
            // add
            newFileList.push(filename);
          } else {
            // replace
            newFileList[index] = filename;
          }
          return upsertWorkOrder(newWorkOrder);
        case "remove":
          newFileList.splice(index, 1);
          return replaceWorkOrder(newWorkOrder);
        default:
          throw new Error("Unknow operation");
      }
    },
    [workOrder, currentTab, upsertWorkOrder, replaceWorkOrder]
  );
  const onFileAdd = async (event: FileList) => {
    const { filename } = await uploadFile(
      event,
      setError,
      workOrder._id,
      workOrder.workOrderNumber,
      api
    );
    await updateParent("addOrReplace", filename);
  };
  return (
    <FilePreviewList>
      {fileList.map((file: string | Photo, index) => {
        const filename = typeof file === "string" ? file : file.name;
        const title = typeof file === "string" ? extractOriginalFilename(file) : file.legend;
        return (
          <FilePreviewItem
            key={filename}
            filename={filename}
            updateParent={(operation, filename) => updateParent(operation, filename, index)}
            setError={setError}
            title={title}
            tw="w-1/4"
          />
        );
      })}
      {!currentTab.readonly && (
        <li tw="w-1/4">
          <FileInput accept={currentTab.contentType} onFileChange={onFileAdd} />
        </li>
      )}
    </FilePreviewList>
  );
};

const FilePreviewList = styled("ul")(
  tw`flex flex-row flex-wrap gap-x-4 space-x-4 gap-y-8 space-y-8`
);

const extractOriginalFilename = (generatedFilename) =>
  generatedFilename.substring(generatedFilename.lastIndexOf("_") + 1);

type FilePreviewItemProps = {
  filename: string;
  title: string;
  updateParent: UpdateParentType;
  setError: (s) => void;
  [prop: string]: unknown;
};
const FilePreviewItem: FC<FilePreviewItemProps> = ({
  filename,
  title,
  updateParent,
  setError,
  ...props
}) => {
  const api = useApi();

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  const onFileRemove = useCallback(async () => {
    try {
      await updateParent("remove");
    } catch (err) {
      handleError(err).then((errMsg) => setError(errMsg));
    }
  }, [api, upsertWorkOrder, setError]);
  return (
    <li tw="relative" {...props}>
      {filename && (
        <FilePreview filenameToDisplay={title} realFilename={filename} onDelete={onFileRemove} />
      )}
    </li>
  );
};
export interface AttachmentTab extends Tab {
  id: string;
  type: "single" | "list";
  contentType: "*.pdf" | "image/*" | "*";
  readonly: boolean;
}

type UpdateParentType = (
  operation: "addOrReplace" | "remove",
  filename?: string
) => Promise<unknown>;

const buildAttachmentsMetadata = (workOrder): AttachmentTab[] => [
  {
    id: "interventionSheet",
    name: "Feuille de procédure",
    type: "single",
    contentType: "*.pdf",
    count: workOrder.interventionSheet ? 1 : 0,
    current: true,
    readonly: false,
  },
  {
    id: "bsd",
    name: "BSD",
    type: "single",
    contentType: "*.pdf",
    count: workOrder.bsd ? 1 : 0,
    current: false,
    readonly: false,
  },
  {
    id: "plan",
    name: "Plan",
    type: "single",
    contentType: "image/*",
    count: workOrder.plan ? 1 : 0,
    current: false,
    readonly: false,
  },
  {
    id: "photos",
    name: "Photos",
    type: "list",
    contentType: "image/*",
    count: (workOrder.photos ?? []).length,
    current: false,
    readonly: true,
  },
  {
    id: "signedDocument",
    name: "OT Signé",
    type: "single",
    contentType: "*.pdf",
    count: workOrder.signedDocument ? 1 : 0,
    current: false,
    readonly: true,
  },
  {
    id: "otherAttachments",
    name: "Autres",
    type: "list",
    contentType: "*",
    count: (workOrder.otherAttachments ?? []).length,
    current: false,
    readonly: false,
  },
];
