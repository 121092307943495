export enum Role {
  ADMIN = "Administrateur",
  SITE_MANAGER = "Chef d'agence",
  PLANNER = "Exploitation",
  TECHNICIAN = "Technicien",
  SALESMAN = "Commercial"
}

export enum Permission {
  SCHEDULE_VIEW = "Affichage planning",
  WORKORDER_LIST_VIEW = "Accès à la liste des OT",
  WORKORDER_WRITE = "Création/Modification des OT",
  
  MANAGE_TECHNICIANS = "Gestion des techniciens",
  MANAGE_PLANNERS = "Gestion des exploitants, commerciaux et facturation",
  MANAGE_SITE_ADMINISTRATORS = "Gestion des chefs d'agence",
  
  VEHICLE_VIEW = "Gestion des véhicules",
  
  CLIENT_VIEW = "Gestion des clients",

  SITE_READ = "Visualisation des sites",
  SITE_WRITE = "Modification des sites",
}

export const PERMISSIONS = new Map<Role, Permission[]>([
  [Role.ADMIN, [
    Permission.SCHEDULE_VIEW,
    Permission.WORKORDER_LIST_VIEW,
    Permission.WORKORDER_WRITE,
    Permission.MANAGE_SITE_ADMINISTRATORS,
    Permission.MANAGE_TECHNICIANS,
    Permission.MANAGE_PLANNERS,
    Permission.VEHICLE_VIEW,
    Permission.SITE_READ,
    Permission.SITE_WRITE,
  ]],
  [Role.SITE_MANAGER, [
    Permission.SCHEDULE_VIEW,
    Permission.WORKORDER_LIST_VIEW,
    Permission.WORKORDER_WRITE,
    Permission.MANAGE_TECHNICIANS,
    Permission.MANAGE_PLANNERS,
    Permission.VEHICLE_VIEW,
  ]],
  [Role.PLANNER, [
    Permission.SCHEDULE_VIEW,
    Permission.WORKORDER_LIST_VIEW,
    Permission.WORKORDER_WRITE,
    Permission.MANAGE_TECHNICIANS,
    Permission.VEHICLE_VIEW,
  ]],
  [Role.TECHNICIAN, [
    Permission.SCHEDULE_VIEW,
  ]],
]);
