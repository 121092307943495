import { Permission } from "../../types/Role";

export const hasSchedulerPermission = (permissions) => hasAnyPermission([Permission.SCHEDULE_VIEW], permissions);
export const hasWorkOrderListPermission = (permissions) => hasAnyPermission(
  [Permission.WORKORDER_LIST_VIEW],
  permissions,
);
export const hasPersonPermission = (permissions) => hasAnyPermission(
  [
    Permission.MANAGE_TECHNICIANS,
    Permission.MANAGE_PLANNERS,
    Permission.MANAGE_SITE_ADMINISTRATORS,
  ],
  permissions,
);
export const hasVehiclePermission = (permissions) => hasAnyPermission([Permission.VEHICLE_VIEW], permissions);

export const hasAnyPermission = (requiredPermissions: Permission[], userPermissions: Permission[]) => {
  return (requiredPermissions.length === 0 || requiredPermissions.some((p) => userPermissions?.includes(p)));
};
