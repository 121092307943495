/** @jsxImportSource @emotion/react */
import "twin.macro";
import { getInitials } from "../functions/PersonManagement";

export const ChevronForAvatar = () => {
  return (
    <span tw="relative top-3 right-2 flex-shrink-0 h-3 w-3 text-sm rounded-full border-2 overflow-hidden">
      <span tw="absolute flex items-center justify-center font-medium inset-0 leading-none uppercase bg-white text-gray-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </span>
  );
};

export const Avatar = ({ user, ...props }) => {
  return (
    <>
      <>
        <span tw="relative flex-shrink-0 h-12 w-12 text-sm rounded-full overflow-hidden" {...props}>
          <span
            tw="absolute flex items-center justify-center font-medium inset-0 leading-none uppercase bg-indigo-500 text-white"
            {...props}
          >
            {getInitials(user)}
          </span>
        </span>
      </>
    </>
  );
};
