export const XOutlineIcon = (props) => (
  <svg
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
);

export const SearchSolidIcon = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
      clipRule="evenodd"
    />
  </svg>
);

export const HomeOutlineIcon = (props) => (
  <svg
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
    />
  </svg>
);

export const DuplicateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
    />
  </svg>
);

export const SaveIcon = (props) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path>
  </svg>
);

export const PhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
    />
  </svg>
);

export const LocalisationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
    />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
);

export const MailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    />
  </svg>
);

export const ArrowLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
  </svg>
);

export const ArrowRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
  </svg>
);

export const AttachmentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
    />
  </svg>
);

export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
    />
  </svg>
);

export const BackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
  </svg>
);

export const ZoomInIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
    />
  </svg>
);

export const ZoomOutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7"
    />
  </svg>
);

export const RemoveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
    />
  </svg>
);

export const ClientIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    />
  </svg>
);

export const LittleMapsIcon = (props) => (
  <svg viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      y="56.5685"
      width="80"
      height="80"
      rx="5"
      transform="rotate(-45 0 56.5685)"
      fill="white"
    />
    <rect
      x="34"
      y="71"
      width="20.8205"
      height="11.0164"
      rx="1"
      transform="rotate(-90 34 71)"
      fill="#058EDA"
    />
    <rect x="44.0164" y="50.1795" width="22.0328" height="10.4103" fill="#058EDA" />
    <path
      d="M79.8456 54.1964C80.409 54.5949 80.409 55.4308 79.8456 55.8293L66.643 65.1666C65.9807 65.6351 65.0656 65.1614 65.0656 64.3502V45.6754C65.0656 44.8642 65.9807 44.3906 66.643 44.859L79.8456 54.1964Z"
      fill="#058EDA"
    />
  </svg>
);

export const CheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
  </svg>
);

export const CancelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
    />
  </svg>
);


export const FullScreenIcon = (props) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs></defs>
    <path d="M312.1 591.5c3.1 3.1 8.2 3.1 11.3 0l101.8-101.8 86.1 86.2c3.1 3.1 8.2 3.1 11.3 0l226.3-226.5c3.1-3.1 3.1-8.2 0-11.3l-36.8-36.8c-3.1-3.1-8.2-3.1-11.3 0L517 485.3l-86.1-86.2c-3.1-3.1-8.2-3.1-11.3 0L275.3 543.4c-3.1 3.1-3.1 8.2 0 11.3l36.8 36.8z"></path>
    <path d="M904 160H548V96c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H120c-17.7 0-32 14.3-32 32v520c0 17.7 14.3 32 32 32h356.4v32L311.6 884.1c-3.7 2.4-4.7 7.3-2.3 11l30.3 47.2v0.1c2.4 3.7 7.4 4.7 11.1 2.3L512 838.9l161.3 105.8c3.7 2.4 8.7 1.4 11.1-2.3v-0.1l30.3-47.2c2.4-3.7 1.3-8.6-2.3-11L548 776.3V744h356c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32z m-40 512H160V232h704v440z"></path>
  </svg>
);

export const Vehicle1Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M1638 1853 c-6 -16 -14 -61 -17 -101 l-6 -73 -55 -23 c-60 -24 -134
  -71 -164 -105 -17 -18 -30 -21 -107 -21 -48 0 -95 -3 -104 -6 -26 -10 -41 -51
  -74 -199 -37 -165 -43 -182 -73 -195 -13 -5 -173 -21 -356 -35 -184 -14 -342
  -30 -352 -35 -46 -24 -50 -37 -50 -148 0 -59 -3 -158 -7 -219 l-6 -113 -26 0
  c-49 0 -52 -8 -49 -136 l3 -119 90 -3 90 -3 0 83 c0 74 4 91 33 150 48 98 123
  155 236 178 203 42 402 -141 383 -352 l-5 -58 678 0 c594 0 679 2 690 15 7 9
  15 46 17 83 16 237 259 386 468 286 47 -22 112 -80 141 -125 27 -43 37 -47 59
  -21 103 121 139 147 239 171 79 19 174 -5 251 -63 91 -70 126 -131 132 -231 7
  -112 9 -117 46 -113 l32 3 0 155 0 155 -39 3 c-29 3 -52 14 -85 44 -88 77
  -189 118 -291 118 -78 0 -188 -35 -254 -80 -38 -27 -65 -39 -72 -34 -75 56
  -110 75 -168 93 -133 41 -270 19 -381 -62 -33 -24 -66 -51 -74 -60 -15 -19
  -115 -24 -141 -7 -13 8 -16 81 -20 526 l-5 518 -30 8 c-33 9 -411 3 -487 -8
  l-48 -7 0 54 c0 30 4 70 10 89 7 25 7 36 -3 42 -22 14 -37 8 -49 -19z m490
  -475 l3 -68 -141 0 -140 0 0 63 c0 35 3 67 7 71 4 3 66 5 137 4 l131 -3 3 -67z
  m-864 40 c8 -30 8 -146 0 -241 l-7 -77 -59 0 c-44 0 -60 4 -65 16 -5 13 43
  297 54 317 2 4 19 7 37 7 26 0 35 -5 40 -22z m324 10 c8 -8 12 -59 12 -164 0
  -129 -2 -153 -16 -158 -20 -8 -238 -8 -258 0 -14 5 -16 29 -16 163 0 87 3 161
  7 164 12 13 258 8 271 -5z"
      />
      <path
        d="M650 670 c-60 -13 -94 -30 -133 -68 -63 -61 -82 -105 -82 -197 0 -69
  4 -86 28 -127 55 -95 170 -157 264 -144 104 14 201 96 229 192 48 167 -56 326
  -226 346 -25 3 -61 3 -80 -2z"
      />
      <path
        d="M2683 670 c-126 -26 -202 -114 -211 -245 -6 -93 21 -157 90 -220 118
  -106 258 -97 370 23 101 107 100 250 -2 365 -52 60 -162 94 -247 77z"
      />
      <path
        d="M3321 669 c-70 -14 -128 -54 -169 -117 -31 -49 -37 -67 -40 -128 -3
  -57 0 -81 18 -123 46 -106 173 -182 278 -166 106 16 197 96 228 203 55 189
  -118 371 -315 331z"
      />
    </g>
  </svg>
);

export const Vehicle2Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M1105 1889 c-131 -18 -292 -65 -364 -107 -46 -27 -151 -114 -163
    -136 -4 -8 -26 -91 -48 -186 -39 -169 -41 -174 -97 -254 l-58 -81 -3 -255 c-3
    -250 -3 -255 -25 -272 -20 -15 -22 -28 -25 -115 -2 -54 0 -114 5 -133 11 -43
    50 -65 98 -56 l33 7 -2 92 c-1 83 2 98 28 150 18 39 44 71 77 97 153 120 357
    74 451 -103 20 -35 23 -57 22 -137 0 -57 4 -98 10 -102 13 -9 1059 -8 1100 1
    29 6 29 6 27 77 -3 85 26 172 73 223 48 52 139 91 212 91 90 0 150 -24 211
    -86 65 -68 87 -127 81 -230 -4 -80 2 -89 48 -78 20 5 23 10 16 33 -25 92 11
    213 87 284 65 61 122 81 213 75 84 -5 132 -25 188 -78 62 -59 85 -118 83 -221
    l-1 -87 30 -6 c47 -9 174 -7 202 4 l26 10 0 133 c0 90 -4 137 -12 145 -8 8
    -49 12 -118 12 l-106 0 -30 43 c-20 28 -52 54 -95 77 -63 34 -67 35 -179 35
    -110 0 -119 -2 -180 -32 -36 -18 -83 -48 -105 -66 l-40 -35 -45 38 c-25 20
    -63 49 -85 63 -123 81 -353 40 -453 -80 l-36 -43 -158 0 c-114 0 -159 3 -165
    13 -4 6 -10 289 -13 627 l-5 615 -39 19 c-36 17 -67 20 -320 22 -155 2 -312
    -1 -351 -7z m90 -289 c33 -13 30 -32 -21 -138 -39 -81 -144 -202 -175 -202
    -18 0 -19 10 -19 155 0 193 1 195 114 195 41 0 87 -5 101 -10z m-283 -17 c16
    -14 18 -33 18 -165 0 -84 -5 -158 -10 -169 -10 -18 -23 -19 -186 -19 -147 0
    -176 2 -180 15 -6 16 41 292 57 333 8 21 13 22 145 22 114 0 141 -3 156 -17z"
      />
      <path
        d="M644 610 c-27 -12 -54 -35 -75 -67 -32 -45 -34 -53 -34 -129 0 -77 2
    -83 35 -127 59 -78 145 -109 235 -83 83 25 145 95 160 182 14 87 -41 190 -121
    225 -58 24 -145 24 -200 -1z"
      />
      <path
        d="M2385 608 c-83 -31 -145 -117 -145 -203 1 -139 139 -258 262 -224 61
    16 116 57 146 108 60 103 20 255 -81 307 -37 19 -144 27 -182 12z"
      />
      <path
        d="M3035 611 c-63 -15 -112 -60 -140 -128 -57 -135 52 -303 197 -304
    146 -2 255 142 214 283 -34 117 -146 179 -271 149z"
      />
    </g>
  </svg>
);

export const Vehicle3Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M1490 1563 c-66 -17 -112 -43 -207 -120 -40 -32 -119 -94 -175 -139
    l-103 -80 -170 -23 c-185 -24 -442 -73 -518 -98 -67 -21 -77 -47 -77 -206 l0
    -124 -26 -12 -27 -12 7 -87 c4 -48 13 -96 21 -109 17 -26 69 -38 119 -29 32 6
    36 11 50 64 36 131 103 219 206 270 48 24 67 27 150 27 84 0 102 -4 150 -28
    30 -15 72 -43 93 -63 48 -45 104 -146 113 -204 4 -25 11 -51 17 -58 7 -9 172
    -12 763 -12 460 0 755 4 759 9 3 6 10 36 16 68 21 112 90 202 201 260 47 25
    63 28 148 28 84 0 102 -3 152 -28 115 -57 202 -181 211 -305 l2 -27 155 0
    c196 0 216 8 222 87 7 92 -2 157 -22 163 -16 5 -18 23 -22 163 -5 172 -15 213
    -56 231 -20 10 -184 12 -692 10 -511 -2 -670 0 -687 9 -26 14 -27 19 -48 161
    -28 193 -40 214 -126 225 -103 12 -540 4 -599 -11z m381 -62 c6 -6 -15 -199
    -29 -271 -11 -56 -29 -60 -278 -60 -122 0 -236 3 -253 6 -26 6 -30 10 -25 29
    7 28 -18 45 -65 45 -17 0 -31 4 -31 9 0 5 46 51 101 101 126 115 182 143 299
    151 93 6 271 0 281 -10z m212 -91 c4 -52 6 -126 5 -165 l-3 -70 -85 0 c-70 0
    -85 3 -83 15 8 59 25 194 29 240 8 83 7 81 71 78 l58 -3 8 -95z"
      />
      <path
        d="M662 798 c-69 -21 -142 -80 -175 -142 -18 -34 -22 -57 -22 -131 1
    -80 4 -95 27 -130 62 -97 202 -175 258 -145 12 6 21 6 25 1 7 -12 58 5 105 35
    76 48 139 138 140 197 1 137 -14 181 -84 245 -83 77 -176 101 -274 70z"
      />
      <path
        d="M2921 799 c-120 -37 -201 -146 -201 -272 0 -108 53 -189 164 -253 67
    -38 160 -40 226 -5 118 61 158 120 167 241 9 125 -42 216 -151 272 -47 24
    -153 33 -205 17z"
      />
    </g>
  </svg>
);

export const Vehicle4Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M1425 1569 c-71 -4 -144 -12 -162 -17 -113 -36 -194 -114 -285 -275
    -75 -133 -78 -137 -105 -137 -35 0 -402 -126 -424 -146 -17 -15 -19 -31 -19
    -145 0 -140 -4 -159 -30 -159 -31 0 -41 -117 -16 -200 17 -58 46 -78 121 -82
    l60 -3 5 90 c5 81 9 97 43 157 59 105 127 143 257 143 100 -1 159 -25 217 -88
    69 -77 88 -161 71 -307 l-3 -25 763 -3 c655 -2 762 0 762 12 0 8 -5 37 -12 65
    -20 88 16 204 84 267 62 59 113 78 208 78 98 1 151 -19 211 -77 62 -60 84
    -118 85 -222 l0 -90 60 2 c86 3 181 23 206 43 19 15 22 30 26 109 4 74 1 94
    -11 104 -12 10 -16 79 -24 440 -10 427 -10 428 -33 452 l-23 25 -951 -2 c-523
    -1 -1009 -5 -1081 -9z m127 -144 c-5 -90 -19 -201 -33 -242 -9 -28 -16 -33
    -62 -42 -46 -9 -188 -31 -299 -45 l-38 -5 0 40 0 39 -50 0 c-62 0 -63 9 -15
    93 76 136 152 211 226 226 24 5 96 8 159 8 l115 -2 -3 -70z"
      />
      <path
        d="M780 709 c-79 -36 -125 -96 -136 -182 -17 -125 99 -257 227 -257 77
    0 138 39 190 119 24 37 29 56 29 108 0 169 -161 280 -310 212z"
      />
      <path
        d="M2860 703 c-134 -70 -167 -231 -70 -345 91 -106 212 -117 313 -29 61
    54 79 92 79 171 1 60 -3 77 -28 117 -40 64 -108 106 -182 111 -48 3 -66 -1
    -112 -25z"
      />
    </g>
  </svg>
);

export const Vehicle5Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M844 1639 c-57 -16 -128 -119 -224 -325 -88 -187 -120 -323 -120
    -504 0 -79 -2 -91 -20 -100 -17 -9 -20 -21 -20 -85 0 -106 12 -117 126 -114
    l86 2 18 43 c24 55 81 118 138 151 61 36 182 39 254 6 79 -36 122 -90 169
    -210 l9 -23 634 0 635 0 34 72 c19 40 49 88 67 106 155 149 425 76 481 -130
    12 -46 33 -53 124 -43 68 7 94 27 81 62 -4 9 -4 31 0 49 6 34 6 34 63 34 36 0
    65 6 80 16 22 16 23 20 19 155 -2 79 -8 143 -14 149 -7 7 -300 9 -945 8 l-934
    -3 -3 -67 c-2 -54 -7 -70 -22 -78 -10 -5 -26 -7 -34 -4 -14 5 -16 51 -16 403
    0 343 -2 400 -16 419 -15 22 -16 22 -317 21 -166 0 -316 -5 -333 -10z m454
    -250 c2 -158 0 -198 -12 -208 -9 -8 -84 -11 -257 -9 -233 3 -244 4 -241 22 8
    43 -7 64 -48 71 -30 5 -40 11 -40 26 0 19 99 219 123 247 6 9 26 24 42 34 27
    16 50 18 230 16 l200 -3 3 -196z"
      />
      <path
        d="M900 658 c-178 -51 -208 -300 -48 -399 81 -50 157 -46 237 12 50 36
    91 117 91 180 0 49 -38 129 -77 164 -32 28 -104 55 -142 54 -14 0 -42 -5 -61
    -11z"
      />
      <path
        d="M2761 656 c-48 -16 -91 -53 -123 -105 -27 -42 -29 -151 -5 -198 34
    -64 135 -133 197 -133 35 0 90 22 126 51 82 64 115 169 80 253 -17 41 -71 103
    -106 121 -42 22 -119 26 -169 11z"
      />
    </g>
  </svg>
);

export const Vehicle6Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M2020 1434 c-159 -16 -234 -34 -355 -82 -59 -24 -283 -128 -305 -142
    -8 -5 -64 -35 -125 -65 -100 -50 -121 -57 -225 -71 -356 -48 -645 -106 -719
    -144 -50 -25 -65 -45 -101 -128 -17 -40 -40 -82 -52 -94 -19 -19 -20 -29 -15
    -112 9 -145 42 -186 151 -186 72 0 89 16 80 75 -13 89 37 233 105 300 19 20
    65 50 101 68 58 28 74 32 155 31 80 0 97 -3 150 -29 150 -74 218 -199 213
    -387 l-3 -93 780 -3 c429 -2 799 0 822 3 l41 6 4 122 c3 107 6 129 29 178 30
    63 63 101 129 145 72 49 108 59 201 59 77 0 93 -3 151 -33 83 -41 125 -82 163
    -158 26 -51 30 -72 33 -159 2 -55 9 -106 14 -112 14 -17 62 -16 189 2 71 11
    110 22 122 34 23 22 36 79 37 153 0 46 -4 60 -20 71 -18 13 -22 31 -30 155 -5
    77 -14 151 -19 165 -18 47 -95 83 -236 112 -127 25 -151 34 -320 120 -239 121
    -327 158 -436 184 -43 10 -635 23 -709 15z m193 -86 c2 -7 -2 -26 -9 -43 -7
    -16 -17 -68 -24 -115 -18 -137 -20 -149 -30 -155 -11 -7 -228 -20 -472 -29
    l-148 -6 0 39 c0 46 -12 58 -68 67 -23 4 -45 10 -47 14 -8 12 138 103 210 132
    22 9 67 28 100 44 33 15 98 35 145 45 105 21 336 25 343 7z m514 -12 c28 -9
    54 -23 57 -31 5 -13 -9 -188 -19 -231 -3 -15 -15 -24 -37 -28 -62 -11 -465
    -19 -471 -9 -8 12 26 274 41 311 8 21 358 11 429 -12z m252 -120 c22 -14 42
    -34 46 -45 8 -25 -20 -87 -49 -106 -24 -17 -162 -21 -168 -5 -5 16 8 206 16
    218 7 12 88 -20 155 -62z"
      />
      <path
        d="M631 793 c-64 -13 -153 -105 -180 -182 -42 -127 24 -263 164 -332 57
    -28 70 -30 117 -24 69 9 107 27 162 78 87 80 113 198 67 302 -24 55 -80 118
    -125 142 -30 15 -161 26 -205 16z"
      />
      <path
        d="M2976 786 c-50 -19 -108 -76 -140 -138 -34 -65 -36 -168 -6 -229 42
    -82 117 -141 201 -158 76 -16 129 -2 205 55 71 53 104 110 111 193 10 103 -33
    190 -123 252 -40 27 -56 32 -128 35 -55 3 -93 0 -120 -10z"
      />
    </g>
  </svg>
);

export const Vehicle7Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M336 1224 l-166 -237 0 -152 c0 -139 -2 -153 -20 -170 -26 -23 -26
    -59 0 -85 17 -17 33 -20 106 -20 83 0 86 1 111 30 77 92 227 92 295 0 l22 -30
    1022 0 1023 0 20 29 c43 61 152 88 224 57 18 -7 48 -30 66 -50 35 -36 35 -36
    121 -36 85 0 87 1 109 31 46 61 155 87 227 53 19 -9 47 -31 64 -50 l29 -34
    104 0 c114 0 137 10 137 63 0 61 97 57 -1512 57 l-1468 0 0 390 0 390 -174 0
    -174 0 -166 -236z m444 -14 l0 -150 -236 0 c-188 0 -234 3 -227 13 6 6 43 59
    83 117 40 58 84 120 97 138 l24 32 130 0 129 0 0 -150z"
      />
      <path
        d="M1610 1431 l0 -30 -242 -3 c-239 -3 -244 -3 -299 -29 -72 -34 -134
    -96 -168 -168 -22 -47 -26 -70 -26 -141 0 -71 4 -94 26 -140 34 -72 97 -136
    167 -168 l57 -27 1230 -3 c1375 -3 1304 -7 1400 66 91 69 135 160 135 278 0
    126 -72 241 -187 299 -57 30 -61 30 -240 33 l-183 4 0 29 0 29 -135 0 -135 0
    0 -30 0 -30 -565 0 -565 0 0 30 0 30 -135 0 -135 0 0 -29z"
      />
      <path
        d="M440 618 c-84 -43 -114 -135 -72 -218 38 -77 152 -106 229 -59 71 44
    95 161 46 227 -23 32 -97 72 -133 72 -14 0 -46 -10 -70 -22z"
      />
      <path
        d="M2840 629 c-41 -17 -90 -71 -101 -111 -28 -105 47 -198 159 -198 155
    0 216 215 84 293 -38 22 -109 30 -142 16z"
      />
      <path
        d="M3366 630 c-63 -19 -106 -83 -106 -157 0 -57 26 -104 72 -134 43 -26
    140 -23 181 6 33 24 67 91 67 131 0 53 -40 117 -90 142 -46 23 -76 26 -124 12z"
      />
    </g>
  </svg>
);

export const Vehicle8Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M2542 1813 c-31 -28 -35 -56 -11 -86 11 -15 27 -27 35 -27 8 0 14 -9
    14 -20 0 -19 -11 -19 -382 -22 l-383 -3 -57 -28 c-72 -35 -130 -93 -165 -165
    -27 -56 -28 -61 -28 -227 0 -165 1 -172 28 -231 35 -76 94 -135 172 -171 l60
    -28 685 0 685 0 56 26 c79 37 131 88 170 166 l34 68 0 170 c0 165 -1 172 -27
    225 -35 71 -97 133 -167 168 -50 24 -69 27 -178 31 -113 3 -123 5 -123 22 0
    10 5 19 10 19 19 0 50 40 50 65 0 13 -10 33 -22 45 -20 19 -34 20 -229 20
    -183 0 -209 -2 -227 -17z"
      />
      <path
        d="M720 1722 c0 -4 -56 -104 -125 -222 l-125 -215 0 -272 0 -273 -26
    -16 c-49 -33 -69 -109 -41 -164 7 -13 28 -35 46 -47 31 -21 45 -23 176 -23
    l142 0 19 43 c42 95 146 167 239 167 94 0 216 -87 244 -173 l13 -37 693 0 694
    0 22 50 c43 100 157 169 260 157 92 -11 183 -81 215 -164 l17 -43 64 0 c91 0
    129 19 155 76 25 58 15 104 -32 149 l-31 30 -925 3 -924 2 0 490 0 490 -385 0
    c-212 0 -385 -3 -385 -8z m608 -314 l-3 -173 -347 -3 c-192 -1 -348 1 -348 4
    0 3 42 82 93 175 l92 169 258 0 257 0 -2 -172z"
      />
      <path
        d="M974 639 c-95 -22 -161 -119 -152 -224 12 -144 168 -228 305 -165 47
    21 98 90 109 147 28 151 -109 277 -262 242z"
      />
      <path
        d="M2874 639 c-49 -12 -115 -65 -138 -113 -9 -19 -16 -56 -16 -83 1
    -194 239 -285 367 -140 40 46 57 98 49 155 -17 128 -136 210 -262 181z"
      />
    </g>
  </svg>
);

export const Vehicle9Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M405 1537 c-3 -6 -4 -165 -3 -352 l3 -340 1638 -3 1637 -2 0 355 0
    355 -1635 0 c-1304 0 -1637 -3 -1640 -13z"
      />
      <path d="M550 705 l0 -125 55 0 55 0 0 125 0 125 -55 0 -55 0 0 -125z" />
      <path
        d="M1236 758 c4 -7 18 -30 31 -50 l23 -38 451 0 450 0 15 -35 c13 -31
    18 -35 53 -35 32 0 40 4 45 23 7 31 59 78 101 93 64 22 146 -17 178 -86 8 -16
    22 -26 39 -28 22 -3 30 3 43 29 25 48 46 67 93 83 37 13 47 13 87 0 44 -16
    105 -74 105 -101 0 -8 13 -13 35 -13 26 0 35 4 35 17 0 9 11 31 25 49 65 86
    209 67 252 -33 11 -28 18 -33 47 -33 28 0 38 6 55 35 l20 35 131 0 130 0 0 50
    0 50 -1226 0 c-977 0 -1224 -3 -1218 -12z"
      />
      <path
        d="M2379 641 c-38 -39 -39 -87 -3 -130 36 -43 93 -44 135 -2 41 42 40
    92 -2 132 -42 40 -90 40 -130 0z"
      />
      <path
        d="M2742 645 c-70 -59 -31 -165 61 -165 31 0 45 7 68 31 41 43 40 91 -2
    130 -40 37 -86 39 -127 4z"
      />
      <path
        d="M3096 645 c-21 -19 -26 -33 -26 -70 0 -58 34 -95 87 -95 71 0 111 55
    92 125 -18 65 -102 87 -153 40z"
      />
      <path d="M470 545 l0 -25 140 0 140 0 0 25 0 25 -140 0 -140 0 0 -25z" />
    </g>
  </svg>
);

export const Vehicle10Icon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 400.000000 200.000000"
    {...props}
  >
    <rect x="103" y="43" width="31" height="6" />
    <rect x="260" y="43" width="31" height="6" />
    <path d="M70.2823 49.672C49.4519 49.9637 35.9289 63.4475 36.0312 84.2797C36.0332 84.6851 36.0353 85.0919 36.0375 85.5C36.2037 104.336 51.5021 119.528 70.3388 119.562L200.137 119.8L328.338 119.957C347.937 119.981 363.837 104.099 363.837 84.5C363.837 64.8939 347.944 49 328.337 49H200.338C133.667 49 93.9996 49.3398 70.2823 49.672Z" />
    <path d="M50.8374 133.5V146H56.3374H61.8374V133.5V121H56.3374H50.8374V133.5Z" />
    <path d="M119.438 128.2C119.838 128.9 121.238 131.2 122.538 133.2L124.838 137H169.938H214.938L216.438 140.5C217.738 143.6 218.238 144 221.738 144C224.938 144 225.738 143.6 226.238 141.7C226.938 138.6 232.138 133.9 236.338 132.4C242.738 130.2 250.938 134.1 254.138 141C254.938 142.6 256.338 143.6 258.038 143.8C260.238 144.1 261.038 143.5 262.338 140.9C264.838 136.1 266.938 134.2 271.638 132.6C275.338 131.3 276.338 131.3 280.338 132.6C284.738 134.2 290.838 140 290.838 142.7C290.838 143.5 292.138 144 294.338 144C296.938 144 297.838 143.6 297.838 142.3C297.838 141.4 298.938 139.2 300.338 137.4C306.838 128.8 321.238 130.7 325.538 140.7C326.638 143.5 327.338 144 330.238 144C333.038 144 334.038 143.4 335.738 140.5L337.738 137H350.838H363.838V132V127H241.238C143.538 127 118.838 127.3 119.438 128.2Z" />
    <path d="M233.738 139.9C229.938 143.8 229.838 148.6 233.438 152.9C237.038 157.2 242.738 157.3 246.938 153.1C251.038 148.9 250.938 143.9 246.738 139.9C242.538 135.9 237.738 135.9 233.738 139.9Z" />
    <path d="M270.037 139.5C263.037 145.4 266.937 156 276.137 156C279.237 156 280.637 155.3 282.937 152.9C287.037 148.6 286.937 143.8 282.737 139.9C278.737 136.2 274.137 136 270.037 139.5Z" />
    <path d="M305.437 139.5C303.337 141.4 302.837 142.8 302.837 146.5C302.837 152.3 306.237 156 311.537 156C318.637 156 322.637 150.5 320.737 143.5C318.937 137 310.537 134.8 305.437 139.5Z" />
    <path d="M42.8374 149.5V152H56.8374H70.8374V149.5V147H56.8374H42.8374V149.5Z" />
  </svg>
);

