/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FC, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Page, PageContent, PageSkeleton, PageTitle } from "./Page";
import { CancelButton, SubmitButton } from "./Buttons";
import { PanelFooter } from "./Panel";
import { Alert } from "./Modal";
import { handleError } from "../functions/ErrorHandling";
import { UseMutationResult, UseQueryResult } from "react-query";
import { MongoDocument } from "../../types/MongoDocument";
import { Permission } from "../../types/Role";

type CrudCreationProps = {
  title: string;
  renderFormFields: () => void;
  itemToFormValues?: (item: MongoDocument) => unknown;
  formValuesToItem?: (formValues) => MongoDocument;
  useUpsertMutation: () => UseMutationResult;
  closeModalAction?: () => unknown;
  writePermissions: Permission[];
};
export const CrudCreation: FC<CrudCreationProps> = ({
  title,
  renderFormFields,
  itemToFormValues,
  formValuesToItem,
  useUpsertMutation,
  closeModalAction,
  writePermissions = [],
}) => (
  <Page tw="bg-white h-auto">
    <PageContent tw="my-4 max-w-4xl mx-auto">
      <CrudForm
        title={title}
        renderFormFields={renderFormFields}
        useUpsertMutation={useUpsertMutation}
        itemToFormValues={itemToFormValues}
        formValuesToItem={formValuesToItem}
        closeModalAction={closeModalAction}
        writePermissions={writePermissions}
      />
    </PageContent>
  </Page>
);

type CrudDetailsProps = {
  title: string;
  objectId?: string;
  useFindByIdQuery: (id: unknown) => UseQueryResult<MongoDocument>;
  renderFormFields: () => void;
  useUpsertMutation: () => UseMutationResult;
  itemToFormValues?: (item: MongoDocument) => unknown;
  formValuesToItem?: (formValues) => MongoDocument;
  closeModalAction?: () => unknown;
  readPermissions: Permission[];
  writePermissions: Permission[];
};
export const CrudDetails: FC<CrudDetailsProps> = ({
  title,
  useFindByIdQuery,
  renderFormFields,
  useUpsertMutation,
  itemToFormValues,
  formValuesToItem,
  objectId,
  closeModalAction,
  readPermissions = [],
  writePermissions = [],
}) => {
  const { status: apiStatus, data: item, error } = useFindByIdQuery(objectId);
  const status = readPermissions ? apiStatus : "missing_permissions";

  return (
    <>
      {status === "loading" && <PageSkeleton />}
      {status === "missing_permissions" && <div>Vous n&apos;avez pas accès à cette page</div>}
      {status === "error" && !item && <div>{error.toString()}</div>}
      {status === "success" && item && (
        <Page tw="bg-white h-auto">
          <PageContent tw="flex-1 relative overflow-y-auto h-auto focus:(outline-none)">
            <CrudForm
              title={title}
              item={item}
              renderFormFields={renderFormFields}
              useUpsertMutation={useUpsertMutation}
              itemToFormValues={itemToFormValues}
              formValuesToItem={formValuesToItem}
              closeModalAction={closeModalAction}
              writePermissions={writePermissions}
            />
          </PageContent>
        </Page>
      )}
    </>
  );
};

/**
 * Item creation or update form wrapped within a Panel.
 */
type CrudFormProps = {
  title: string;
  item?: MongoDocument;
  renderFormFields: (setError: unknown) => unknown;
  useUpsertMutation: () => UseMutationResult;
  itemToFormValues?: (item: MongoDocument) => unknown;
  formValuesToItem?: (formValues) => MongoDocument;
  closeModalAction?: () => unknown;
  writePermissions: Permission[];
};
export const CrudForm: FC<CrudFormProps> = ({
  title,
  item = {} as MongoDocument,
  renderFormFields,
  useUpsertMutation,
  itemToFormValues = (item) => item,
  formValuesToItem = (formValues) => formValues,
  closeModalAction,
  writePermissions = [],
}) => {
  const [error, setError] = useState(undefined);
  const formFields = useMemo(() => renderFormFields(setError), [renderFormFields]);
  const formProps = useForm({
    defaultValues: itemToFormValues(item),
  });
  const { mutateAsync: upsertItem, status } = useUpsertMutation();
  const onSubmit = async (formValues) => {
    try {
      const newItem = formValuesToItem(formValues);
      if (newItem._id === "") {
        newItem._id = undefined; // Side-effect of the input hidden for _id
      }
      await upsertItem(newItem);
      closeModal();
    } catch (err) {
      handleError(err).then((errMsg) => setError(errMsg));
    }
  };
  const { handleSubmit, register } = formProps;

  const closeModal = () => {
    if (closeModalAction) {
      closeModalAction();
    }
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <input type="hidden" {...register("_id" as never)} />
          <input type="hidden" {...register("status" as never)} />
          <PageTitle>{title}</PageTitle>
          {formFields}
          { writePermissions && (
            <PanelFooter tw="bg-white px-0 sm:px-0">
              <CancelButton
                type="button"
                disabled={status === "loading"}
                onClick={() => {
                  closeModal();
                }}
              >
                Retour
              </CancelButton>
              <SubmitButton type="submit" disabled={status === "loading"}>
                Enregistrer
              </SubmitButton>
            </PanelFooter>
          )}
        </>
      </form>
      <Alert message={error} setMessage={setError} />
    </FormProvider>
  );
};
