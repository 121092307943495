import { DrivingLicense, drivingLicensePriorities } from "../../types/DrivingLicense";
import { WorkOrder } from "../../types/WorkOrder";
import { computeMissingLicenses } from "./WorkOrderManagement";

export const sortLicences = (l1: DrivingLicense, l2: DrivingLicense): number =>
  drivingLicensePriorities.get(l1) - drivingLicensePriorities.get(l2); 

/** Return the most significant licenses, e.g. when driver has SPL, no need to display VL and PL licences */
export const significantLicenses = (drivingLicences: DrivingLicense[]): DrivingLicense[] => {
  if (!drivingLicences || drivingLicences.length === 0) {
    return [];
  }
  const licenseByPriority = new Map<number, DrivingLicense[]>();
  drivingLicensePriorities.forEach((priority) => {
    const licenses = drivingLicences.filter((l) => drivingLicensePriorities.get(l) === priority);
    if (licenses.length > 0) {
      licenseByPriority.set(priority, licenses);
    }
  });
  return licenseByPriority.values().next().value; // return the list of licenses with the highest priority
}

/* Return the list of license to display in the calendar slot header or in the event body */
export const filterLicensesToDisplay = (workOrder: WorkOrder) => {
  return workOrder ? computeMissingLicenses(workOrder)
    .filter((l) => {
      if (l === DrivingLicense.ADR) {
        // The license is related to the work order
        if (workOrder.isHeaderWorkOrder) {
          return workOrder.hasWorkOrderLicenseHeader;
        } else {
          return !workOrder.hasWorkOrderLicenseHeader;
        }
      } else {
        // The license is related to the vehicle
        if (workOrder.isHeaderWorkOrder) {
          // Same team and vehicles for all the work orders of the slot
          return workOrder.hasTeamHeader && workOrder.hasVehicleHeader;
        } else {
          // Work orders have different team or different vehicles
          return !workOrder.hasTeamHeader || !workOrder.hasVehicleHeader;
        }
      }
    }) : [];
};
