/** @jsxImportSource @emotion/react */

import {
  Vehicle10Icon,
  Vehicle1Icon,
  Vehicle2Icon,
  Vehicle3Icon,
  Vehicle4Icon,
  Vehicle5Icon,
  Vehicle6Icon,
  Vehicle7Icon,
  Vehicle8Icon,
  Vehicle9Icon,
} from "../Icons";

export const vehiclesIconList = [
  "icon1",
  "icon2",
  "icon7",
  "icon8",
  "icon9",
  "icon10",
  "icon4",
  "icon5",
  "icon3",
  "icon6",
];

export const VehicleIcon = ({ name, ...props }) => {
  switch (name) {
    case "icon1":
      return <Vehicle1Icon {...props} />;
      break;
    case "icon2":
      return <Vehicle2Icon {...props} />;
      break;
    case "icon3":
      return <Vehicle3Icon {...props} />;
      break;
    case "icon4":
      return <Vehicle4Icon {...props} />;
      break;
    case "icon5":
      return <Vehicle5Icon {...props} />;
      break;
    case "icon6":
      return <Vehicle6Icon {...props} />;
      break;
    case "icon7":
      return <Vehicle7Icon {...props} />;
      break;
    case "icon8":
      return <Vehicle8Icon {...props} />;
      break;
    case "icon9":
      return <Vehicle9Icon {...props} />;
      break;
    case "icon10":
      return <Vehicle10Icon {...props} />;
      break;
    default:
      return <Vehicle1Icon {...props} />;
      break;
  }
};
