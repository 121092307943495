/** @jsxImportSource @emotion/react */
import "twin.macro";
import React, { useContext } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import { steps as newSteps } from "./steps";
import "shepherd.js/dist/css/shepherd.css";
import "./Tuto.css";
import { PrimaryMobileButton } from "../shared/components/Buttons";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

export const StartTutorialButton = () => {
  const tour = useContext(ShepherdTourContext);

  return <PrimaryMobileButton onClick={tour.start}>Didacticiel</PrimaryMobileButton>;
};

export const StartTutorial = () => {
  return (
    <div>
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <StartTutorialButton />
      </ShepherdTour>
    </div>
  );
};
