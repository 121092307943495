export enum WorkOrderStatus {
  EN_PREPARATION = "En préparation",
  TRANSMIS = "Transmis",
  RECU = "Reçu",
  EN_ROUTE = "En route",
  EN_COURS = "En cours",
  TERMINE = "Terminé",
  NON_TERMINE = "Non terminé",
  ANNULE = "Annulé",
}
