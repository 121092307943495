import { useMutation, useQueries, useQuery, UseQueryResult } from "react-query";
import { useApi } from "./useApi";
import { DOWNLOAD_ENDPOINT } from "./useWorkOrderQueries";

export const createDownloadUrl = (blob: Blob, contentType: string) => {
  const file = new Blob([blob], { type: contentType });
  return URL.createObjectURL(file);
};

export const useDownloadFileQueries = (filenames: string[]): UseQueryResult<Blob>[] => {
  const api = useApi(); 
  return useQueries( 
    filenames.map((filename) => { 
      return { 
        queryKey: `${DOWNLOAD_ENDPOINT}/${filename}`, 
        queryFn: async () => api.get(`${DOWNLOAD_ENDPOINT}/${filename}`).blob(), 
      }; 
    }) 
  ); 
};

export const useDownloadFileQuery = (filename: string): UseQueryResult<Blob> => {
  const api = useApi();
  return useQuery({
    queryKey: [`${DOWNLOAD_ENDPOINT}/${filename}`],
    queryFn: async () => api.get(`${DOWNLOAD_ENDPOINT}/${filename}`).blob(),
    enabled: !!filename,
  });
};

export const getPdfFile = () => {
  const api = useApi();
  return useMutation({
    mutationFn : async (filename) => {
      return api.get(`${DOWNLOAD_ENDPOINT}/${filename}`).blob()
    }
  } 
  );
};

