/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { useState, useMemo, useEffect, FC } from "react";
import { AddButton } from "./Buttons";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "./EmptyState";
import { ListPagination } from "./List";
import { PageContent } from "./Page";
import { CheckBoxStyled } from "./Form";
import { Table, Td, Th } from "./Table";
import { StatusBadge } from "./Badge";
import { NotFoundIllustration } from "../../not-found/NotFoundIllustration";
import { CrudDataType } from "../../types/CrudDataType";
import { hasAnyPermission } from "../functions/RoleManagement";
import { useConnectedUser } from "../hooks/useConnectedUser";
import { Permission } from "../../types/Role";
import { MongoDocument } from "../../types/MongoDocument";

const CRUD_LIST_PAGE_SIZE = 50;

export const CrudList: FC<CrudListProps> = ({
  fieldLabels = [],
  crudListCells,
  creationCallback,
  status,
  data,
  debouncedSearch,
  isSelected,
  onToggleMany,
  onToggle,
  setPagination,
  readPermissions = [],
  writePermissions = [],
}) => {
  const [page, setPage] = useState(1);
  useEffect(
    () =>
      setPagination({
        skip: (Number(page) - 1) * CRUD_LIST_PAGE_SIZE,
        limit: CRUD_LIST_PAGE_SIZE,
      }),
    [page]
  );

  const headerCheckboxRef = (el) => {
    if (el) {
      el.indeterminate = el.checked && data.list.some((item) => !isSelected(item));
    }
  };

  const thead = useMemo(
    () => (
      <tr>
        <Th tw="relative w-12 px-6 sm:w-16 sm:px-8">
          <CheckBoxStyled
            type="checkbox"
            tw="absolute left-4 top-1/2 -mt-2 sm:left-6"
            ref={headerCheckboxRef}
            checked={data?.list?.some((item) => isSelected(item))}
            onChange={onToggleMany(data?.list)}
          />
        </Th>
        {fieldLabels &&
          fieldLabels.map((columnHeader) => (
            <Th key={columnHeader} scope="col">
              {columnHeader}
            </Th>
          ))}
        <Th>Statut</Th>
      </tr>
    ),
    [fieldLabels, data, isSelected, headerCheckboxRef, onToggleMany]
  );

  const tfoot = useMemo(
    () => (
      <tr>
        <td colSpan={fieldLabels.length + 2}>
          <ListPagination
            page={Number(page)}
            setPage={setPage}
            pageSize={CRUD_LIST_PAGE_SIZE}
            totalCount={data?.totalCount}
          />
        </td>
      </tr>
    ),
    [page, setPage, CRUD_LIST_PAGE_SIZE, data]
  );

  const { permissions } = useConnectedUser();
  const hasReadPermission = hasAnyPermission(readPermissions, permissions);
  const hasWritePermission = hasAnyPermission(writePermissions, permissions);

  return (
    <>
      <PageContent tw="md:(px-32) h-auto">
        {!hasReadPermission && (
          <section>Vous n&apos;avez pas les droits de consulter ces données</section>
        )}
        {hasReadPermission && status === "success" && data.totalCount !== 0 && (
          <Table thead={thead} tfoot={tfoot}>
            {data.list.map((item) => (
              <tr key={item._id} tw="hover:bg-gray-50 cursor-pointer">
                <Td tw="relative w-12 px-6 sm:w-16 sm:px-8">
                  {hasWritePermission && (
                    <CheckBoxStyled
                      type="checkbox"
                      checked={isSelected(item)}
                      onChange={onToggle(item)}
                      tw="absolute left-4 top-1/2 -mt-2 sm:left-6"
                    />
                  )}
                </Td>
                {crudListCells && crudListCells(item)}
                <td tw="overflow-hidden">
                  <StatusBadge status={item.status} />
                </td>
              </tr>
            ))}
          </Table>
        )}
        {hasReadPermission && status === "success" && data.totalCount === 0 && (
          <EmptyStatePage
            debouncedSearch={debouncedSearch}
            creationCallback={creationCallback}
            hasWritePermission={hasWritePermission}
          />
        )}
      </PageContent>
    </>
  );
};

const EmptyStatePage = ({ creationCallback, debouncedSearch, hasWritePermission }) => (
  <EmptyState>
    <EmptyStateIllustration as={NotFoundIllustration} />
    <EmptyStateTitle as="h3">Aucun élément à afficher</EmptyStateTitle>
    <EmptyStateDescription>
      {debouncedSearch
        ? "Essayer de modifier le texte recherché"
        : "Vous pouvez créer des éléments avec le bouton suivant :"}
    </EmptyStateDescription>
    {!debouncedSearch && hasWritePermission && (
      <AddButton tw="mt-6" onClick={() => creationCallback()}>
        Créer
      </AddButton>
    )}
  </EmptyState>
);

type CrudListProps = {
  fieldLabels: string[];
  crudListCells(item: unknown);
  creationCallback: () => void;
  status: string;
  data: CrudDataType<MongoDocument>;
  debouncedSearch: string;
  isSelected: (item: unknown) => boolean;
  onToggleMany: (items: unknown[]) => any;
  onToggle: (item: unknown) => any;
  setPagination: ({ skip, limit }) => void;
  readPermissions: Permission[];
  writePermissions: Permission[];
};
