import { createContext, useState } from "react";

type PageContextType = {
  page: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setPage: Function;
  maxPage: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setMaxPage: Function;
};

export const PageContext = createContext<PageContextType>(undefined);

export const PageContextProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  // const pageRef = useRef(page);

  return (
    <PageContext.Provider value={{ page: page, setPage, maxPage, setMaxPage }}>
      {children}
    </PageContext.Provider>
  );
};
