import moment, { Moment } from "moment-timezone";
import { isWorkOrder, isWorkOrderList, WorkOrder } from "../../types/WorkOrder";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { sameDay, setTimeInDate } from "./Datetime";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import { Vehicle } from "../../types/Vehicle";
import { mongoDocumentTypeGuard } from "../../types/MongoDocument";
import { sortLicences } from "./DrivingLicenceManagement";
import { DrivingLicense } from "../../types/DrivingLicense";
import { QueryKey, QueryClient } from "react-query";

// Type guards
const isDateOrString = (obj: unknown): obj is (Date | string) => (typeof obj === "string" || obj instanceof Date);
const isMoment = (obj: unknown): obj is Moment => obj?.["isValid"] === true;

/** Return events from the list which are in the same slot than oneEvent (same date, same resource)  */
export const eventsOnSameSlot = <T extends MbscCalendarEvent> (oneEvent: T, eventList: T[]): T[] => {
  if (!eventList || !oneEvent) {
    return [oneEvent];
  } else {
    const sameSlotEvent = eventList
      .filter((event) => {
        const start = isDateOrString(event.start) ? event.start : isMoment(event.start) ? event.start.toDate() : undefined;
        const oneEventStart = isDateOrString(oneEvent.start) ? oneEvent.start : isMoment(oneEvent.start) ? oneEvent.start.toDate() : undefined;
        
        if (!start || !oneEventStart) {
          console.log(`Calendar event has no start date: `);
        } else if (!event.resource || !oneEvent.resource) {
          console.log(`Calendar event has no resource: ${JSON.stringify(event)}`);
        } else {
          return event.resource === oneEvent.resource && sameDay(start, oneEventStart);
        }
      });
    return sameSlotEvent.length > 0 ? sameSlotEvent : [oneEvent]; // at least return oneEvent alone on the same slot
  }
};

export const compareFields = (field1, field2) => {
  if (typeof field1 === "string" && typeof field2 === "string") {
    return field1 === field2;
  } else if (Array.isArray(field1) && Array.isArray(field2)) {
    return compareArrays(field1, field2);
  } else if (mongoDocumentTypeGuard(field2) && mongoDocumentTypeGuard(field1)) {
    return field2._id === field1._id;
  } else {
    return false;
  }
};

export const compareArrays = (array1: string[], array2: string[]): boolean => {
  if (array1?.length === array2?.length) {
    return array1.every((field1) => array2.some((field2) => compareFields(field1, field2)));
  } else {
    return false;
  }
};

/** Return true if given field is the same accross all orders */
export const isFieldCommonAcrossObjects = (objects: unknown[], field: string): boolean => {
  if (objects.length <= 1) {
    return true;
  } else {
    const field1 = objects[0][field];
    const orderWithSameTeam = objects
      .map((w) => w[field])
      .filter((field2) => compareFields(field1, field2));
    return orderWithSameTeam.length === objects.length;
  }
};


export const sortWorkOrders = (a: WorkOrder, b: WorkOrder): number => {
  if (a.allDay && b.allDay) {
    return a.title <= b.title ? -1 : 1;
  } else {
    if (a.allDay && !b.allDay) {
      return -1;
    } else if (b.allDay && ! a.allDay) {
      return 1;
    } else {
      return moment(a.start).isBefore(b.start) ? -1 : 1;
    }
  }
};

/** Transform workorder from API to a Scheduler event as expected by Mobiscroll */
export const mapWorkOrderToEvent = (w: WorkOrder): MbscCalendarEvent => (w && {
  id: w._id,
  resource: getPrimaryVehicle(w)?._id,
  editable: w.status === WorkOrderStatus.EN_PREPARATION,
  ...w,
});

export const bsdUrl = (bsdId: string) => `${process.env.REACT_APP_TRACK_DECHET}/bsdds/view/${bsdId}`;

export const WORK_ORDER_INIT_VALUES = {
  start: setTimeInDate(new Date(), "00:00:00.000"),
  vehicles: [],
  team: [],
  status: WorkOrderStatus.EN_PREPARATION,
  allDay: true,
};

export const getPrimaryVehicle = (workOrder: WorkOrder): Vehicle | undefined => workOrder?.vehicles?.find((v) => v.principal);

export const getSecondaryVehicles = (workOrder: WorkOrder): Vehicle[] | undefined => (workOrder?.vehicles?.filter((v) => !v.principal) ?? []);

/** 
 * Generates fictive events which will be used to display common data (team, secondary vehicles, ...)
 * across eventsfor the same slot: 
 */  
export const addHeaderEvents = (events: WorkOrder[]): WorkOrder[] => {
  // Remove existing headers
  let eventsClone = events.filter((e) => !e.isHeaderWorkOrder);
  // Group events by slot (= cell of the calendar)
  const slots = [];
  while (eventsClone.length > 0) {
    const slot = eventsOnSameSlot(eventsClone[0], eventsClone);
    slots.push(slot);
    eventsClone = eventsClone.filter((e) => !slot.some((s) => s._id === e._id));
  }
  for (let i = 0 ; i < slots.length ; i++) {
    let result: {slot: WorkOrder[], header: WorkOrder} = {
      slot: slots[i],
      header: {
        ...WORK_ORDER_INIT_VALUES,
        id: Math.random().toString(36).slice(2, 7),
        isHeaderWorkOrder: true,
        start: slots[i][0].start,
        end: slots[i][0].end,
        site: slots[i][0].site,
        status: slots[i][0].status,
        resource: slots[i][0].resource,
        editable: false,
        title: "1", // Mobiscroll sort events by title alphabetical order: we want header to be the 1st
      },
    };
    
    result = populateCommonFieldFlag(result.slot, result.header, "licenses", "hasWorkOrderLicenseHeader");
    result = populateCommonFieldFlag(result.slot, result.header, "team", "hasTeamHeader");
    result = populateCommonFieldFlag(result.slot, result.header, "vehicles", "hasVehicleHeader");

    if (result.header.hasTeamHeader || result.header.hasVehicleHeader || result.header.hasWorkOrderLicenseHeader) {
      slots[i] = [result.header, ...result.slot];
    }
  }
  return slots.flatMap((slot) => slot);
}

const populateCommonFieldFlag = (slot: WorkOrder[], header: WorkOrder, field: string, hasField: string): {slot: WorkOrder[], header: WorkOrder} => {
  const hasFieldInHeader = isFieldCommonAcrossObjects(slot, field);
  return {
    slot: slot.map((e) => ({...e, [hasField]: hasFieldInHeader})),
    header: {...header, [hasField]: hasFieldInHeader, [field]: structuredClone(slot[0][field])},
  };
};

export const computeMissingLicenses = (workOrder: WorkOrder): DrivingLicense[] => {
  const result = new Set<DrivingLicense>();
  const requiredLicenses = workOrder.vehicles.map((v) => v.drivingLicense);
  
  // Sort to allocate the required driver to the most specific license first
  const sortedLicenses = requiredLicenses.sort(sortLicences);
  const availableDrivers = [...workOrder.team];
  for (const requiredLicense of sortedLicenses) {

    const matchingLicenseIndexes = availableDrivers.reduce((acc, p, i) => (
      p.drivingLicenses.includes(requiredLicense) ? [...acc, i] : acc), []
    );
   
    if (matchingLicenseIndexes.length > 0) {
      if(requiredLicense != DrivingLicense.VL && workOrder.licenses?.length > 0){
        const matchingLicenseIndex = matchingLicenseIndexes.findIndex((i) =>
          availableDrivers[i].drivingLicenses.includes(DrivingLicense.ADR))
        matchingLicenseIndex >= 0 ? availableDrivers.splice(matchingLicenseIndex, 1):
         (result.add(DrivingLicense.ADR) && availableDrivers.splice(0, 1)) ;
      }
      else{
        availableDrivers.splice(matchingLicenseIndexes[0], 1)
      }
    } else {
      result.add(requiredLicense);
      if(workOrder.licenses?.length > 0 && requiredLicense != DrivingLicense.VL){
        result.add(DrivingLicense.ADR);
      }
    }
  }
  return Array.from(result);
};

/**
 * Invalidating query cache takes too long to refresh 
 * So we have to update the state manually
 */
export const updateWorkOrderInCache = (
  responseData: WorkOrder | WorkOrder[],
  queryKey: QueryKey,
  queryData: unknown,
  queryClient: QueryClient,
  type: "upsert" | "update" | "delete",
) => {
  //
  // Cache is a list of work orders
  //
  if (isWorkOrderList(queryData) && queryData.list) {
    if (isWorkOrder(responseData)) {
      const updatedQueryData = updateWorkOrderList(queryData.list, responseData, type);
      queryClient.setQueryData(queryKey, {...queryData, list: updatedQueryData});
    } else if (Array.isArray(responseData)) {
      let updatedQueryData = [...queryData.list];
      for (const w of responseData) {
        if (isWorkOrder(w)) {
          updatedQueryData = updateWorkOrderList(updatedQueryData, w, type);
        } else {
          console.log("Error when updating react query cache: data object is not a work order");
        }
      }
      queryClient.setQueryData(queryKey, {...queryData, list: updatedQueryData});
    } else {
      console.log("Error when updating react query cache: data object is neither a work order nor a list");
    }
  } else if (type === "upsert" && isWorkOrder(queryData)) {
    //
    // Cache is a single work order
    //
    if (isWorkOrder(responseData)) {
      if (queryData._id === responseData._id) {
        queryClient.setQueryData(queryKey, responseData);
      }
    } else if (Array.isArray(responseData)) {
      let updatedQueryData = [...queryData.list];
      for (const w of responseData) {
        if (isWorkOrder(w)) {
          if (queryData._id === w._id) {
            updatedQueryData = updateWorkOrderList(updatedQueryData, w, type);
          }
        } else {
          console.log("Error when updating react query cache: data object is not a work order");
        }
      }
      queryClient.setQueryData(queryKey, {...queryData, list: updatedQueryData});
    } else {
      console.log("Error when updating react query cache: data object is neither a work order nor a list");
    }
  }
};

export const updateWorkOrderList = (list: WorkOrder[], workOrder: WorkOrder, type: "upsert" | "update" | "delete") => {
  const workOrderIndex = list.findIndex((w) => isWorkOrder(w) && workOrder._id === w._id);
  if (workOrderIndex >= 0) {
    //
    // Update
    //
    const w = list[workOrderIndex];
    if (isWorkOrder(w)) {
      if (type === "upsert" || type === "update") {
        return list.map((wo, idx) => idx === workOrderIndex ? workOrder : wo);
      } else if (type === "delete") {
        return list.filter((wo) => wo._id !== w._id);
      } else {
        throw new Error(`Unsupported query type "${type}"`);
      }
    }
  } else {
    //
    // Insert
    //
    if (type === "update") {
      // Can't find updated work order ${workOrder._id} in cache => do nothing
      return list;
    } else  if (type === "upsert") {
      return [...list, workOrder];
    } else if (type === "delete") {
      // already removed from the list, do nothing
      return list;
    } else {
      throw new Error(`Unsupported query type "${type}"`);
    }
  }
};
