/** @jsxImportSource @emotion/react */
import "twin.macro";
import { PrimaryMobileButton } from "../../../shared/components/Buttons";
import { Container } from "../../../shared/components/Container";
import { FormGroup, TextAreaStyled } from "../../../shared/components/Form";
import { Skeleton } from "../../../shared/components/Page";
import {
  CardContainer,
  DateAndBadgeContainer,
  HeaderContainer,
  NavigationTitle,
} from "../../workOrderList/WorkOrderListItem";
import { StatusStepperSkeleton } from "../received/ReceivedSkeleton";

const InProgressSkeleton = () => {
  return (
    <>
      <StatusStepperSkeleton />
      <Container tw="mb-20 xl:px-48 2xl:px-96">
        <CardContainer>
          <HeaderContainer>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              <NavigationTitle>
                {" "}
                <Skeleton />
              </NavigationTitle>
            </div>
            <div tw="flex h-full items-center">
              <DateAndBadgeContainer>
                <Skeleton />
                <Skeleton />
              </DateAndBadgeContainer>
            </div>
          </HeaderContainer>

          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div>
              <div tw="text-lg leading-6 font-medium text-gray-900">TRAVAUX RÉALISÉS</div>
              <div tw="mt-1 max-w-2xl text-sm text-gray-500">
                <Skeleton />
              </div>
            </div>

            <dd tw="mt-2 md:mt-0 md:col-span-7">
              <div>
                <FormGroup>
                  <TextAreaStyled
                    tw="flex-1 block w-full text-sm z-0 h-44 focus:z-10 border-gray-300 mt-6 rounded-md focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500) pointer-events-none"
                    id="descriptionWorkDone"
                  />
                </FormGroup>

                <div tw="flex space-x-3 items-center justify-end mt-8">
                  <PrimaryMobileButton type="submit" disabled>
                    Sauvegarder
                  </PrimaryMobileButton>
                </div>
              </div>
            </dd>
          </div>
        </CardContainer>
      </Container>
    </>
  );
};

export default InProgressSkeleton;
