/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import "@reach/skip-nav/styles.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CurrentSiteContext } from "../contexts/CurrentSiteContext";
import { hasAnyPermission } from "../functions/RoleManagement";
import { Permission } from "../../types/Role";
import { useConnectedUser } from "../hooks/useConnectedUser";
import { PageContext } from "../../summary/API/PageContext";
import { useGenericQuery } from "../hooks/useApi";
import { DailyInformation } from "../../types/DailyInformation";
import { buildPeriodFilter, buildSiteFilter, Filter } from "../functions/QueryHelper";
import { SpeakerphoneIcon } from "@heroicons/react/solid";
import { HomeOutlineIcon } from "./Icons";

export const LayoutSummary = ({ children }) => {
  const { currentSite } = useContext(CurrentSiteContext);
  const { permissions } = useConnectedUser();
  const { page, maxPage } = useContext(PageContext);

  const { data: weeklyInfoData } = useGenericQuery<{ list: DailyInformation[] }>(
    "v1/daily-information",
    {
      ...Filter.from(buildSiteFilter(currentSite), {
        $and: buildPeriodFilter(new Date(), "date", "week"),
      }),
    }
  );

  const Navbar = ({ start, center, end }) => {
    return (
      <header tw="relative z-10 flex-shrink-0 flex h-16 bg-gray-100">
        {start}
        <nav tw="flex-1 px-4 flex justify-center w-full">
          <div tw="ml-4 flex items-center md:ml-6 space-x-3 justify-center w-full">{center}</div>
        </nav>
        <nav tw=" px-4 flex justify-end [flex-basis: 1] ">
          <div tw="ml-4 flex  items-center md:ml-6 space-x-3">{end}</div>
        </nav>
      </header>
    );
  };

  return (
    <div tw="h-screen flex overflow-hidden bg-gray-100">
      <div tw="flex flex-col w-0 flex-1 overflow-hidden">
        <Navbar
          start={
            <Link to="/" tw="flex-shrink-0 flex items-center mx-6">
              <img
                src={`${process.env.PUBLIC_URL}/logo/Logo-${process.env.REACT_APP_WHITE_LABEL}.svg`}
                tw="h-8 w-auto"
              />
            </Link>
          }
          center={
            <>
              {weeklyInfoData && weeklyInfoData?.list[0]?.text !== "" && (
                <div tw="w-full bg-indigo-600 text-center text-white font-bold p-2 rounded-lg flex mr-4 justify-center">
                  <div tw="flex p-2 rounded-lg bg-indigo-800 mr-2">
                    <SpeakerphoneIcon tw="h-5 w-5 text-white" aria-hidden="true" />
                  </div>
                  <div tw=" flex text-center w-full items-center justify-center ">
                    {weeklyInfoData.list[0]?.text}
                  </div>
                </div>
              )}
              {page !== undefined && (
                <div tw="inline-flex items-center justify-center pt-1 border-b-2 rounded-md border border-gray-300 text-indigo-800 font-bold text-lg py-1 px-4 whitespace-nowrap">
                  {`${page + 1} / ${maxPage}`}
                </div>
              )}
            </>
          }
          end={(
            <>
              <Link
                to="/"
                tw="inline-flex items-center px-1 p-1 border-b-2 rounded-md border border-gray-300"
              >
                <HomeOutlineIcon tw="text-gray-400 h-6 w-5" />
              </Link>
              {
                hasAnyPermission([Permission.MANAGE_SITE_ADMINISTRATORS], permissions) ? (
                  <div tw="inline-flex items-center pt-1 border-b-2 rounded-md border border-gray-300 text-black text-sm py-1 px-4">
                    {currentSite?.name}
                  </div>
                ) : (
                  <></>
                )
              }
            </>
          )}
        />
        {children}
      </div>
    </div>
  );
};
