import moment from "moment-timezone";
import { Period } from "../../types/Period";
import { Site } from "../../types/Site";

export const Filter = {
  /**
   * Combine given expressions in a single mingo filter.
   * Falsy expressions will be removed.
   * @example
   * Filter.from(
   *   { foo: "bar" },
   *   search && { baz: search }
   * )
   * // If search === "some search terms", will result in:
   * { foo: "bar", baz: "some search terms" }
   * // If search === "", will result in:
   * { foo: "bar" }
   * @type {(...any) => object}
   */
  from: (...expressions) => {
    const filter = Object.assign({}, ...expressions.filter(Boolean));
    if (Object.keys(filter).length === 0) {
      // If there are no keys in the filter, return undefined.
      // This will skip filtering in the queries and avoid creating a mingo query
      return undefined;
    }
    return filter;
  },
  /**
   * Create a mongi $regex expression and escape the given value.
   * This is a shorcut that can be used instead of `{ $regex: escapeRegExp(value), $options: "i" }`
   */
  regex: (value, options = "i") => {
    return { $regex: escapeRegExp(value), $options: options };
  },
  and: (...args) => {
    return { $and: args.filter(Boolean) };
  },
  or: (...args) => {
    return { $or: args.filter(Boolean) };
  },
};

/**
 * Useful to get the documents of the selected period.
 * Examples:
 * - display next week events: call buildPeriodFilter(anyDayOfNextWeek, "createdAt", "week")
 * - display today events: call buildPeriodFilter(new Date(), "createdAt", "day")
 */
export const buildPeriodFilter = (filterValue: Date, filterField: string, period: Period) => {
  let startOfPeriod = moment(filterValue).startOf(period);
  let endOfPeriod = moment(filterValue).endOf(period);
  if (period === "week") {
    startOfPeriod = startOfPeriod.add(1, "days"); // english weeks begin on sunday
    endOfPeriod = endOfPeriod.add(1, "days"); // english weeks end on saturday
  }
  return buildDateRangeFilter(startOfPeriod.toDate(), endOfPeriod.toDate(), filterField);
};

export const buildDateRangeFilter = (startDate: Date, endDate: Date, filterField: string) => {
  return [
    {
      [filterField]: {
        $gte: startDate.toJSON(),
      },
    },
    {
      [filterField]: {
        $lte: endDate.toJSON(),
      },
    },
  ];
};

export const buildSiteFilter = (site: Site) => {
  return { site: site?._id };
};

export const buildOtByIdFilter = (_id: string) => {
  return { _id };
};

/** Escape a string and allow it to be used as a regexp */
const escapeRegExp = (value) => {
  return value.replace(/[.*+?^${}()|[\]\\]/, "\\$&");
};
