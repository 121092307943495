/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from "react";
import { useId } from "@reach/auto-id";
import "twin.macro";
import { ImportButton } from "./Buttons";
import { handleError } from "../functions/ErrorHandling";
import { UseMutationResult } from "react-query";

/**
 * Fields is the list of columns to import.
 * FieldsProcessors is the list of function (matching fields index) to apply to each cell value
 */
type CrudImportProps = {
  fileProcessor: (file: File) => Promise<unknown[]>;
  useUpsertManyMutation: (b: boolean) => UseMutationResult;
  accept?: string;
  setMessage: (s: string) => void;
};
export const CrudImport: FC<CrudImportProps> = ({ fileProcessor, useUpsertManyMutation, accept, setMessage }) => {
  const { mutateAsync: upsertItems, status: upsertStatus, error: upsertError } = useUpsertManyMutation(false);
  const [readStatus, setReadStatus] = useState(undefined);

  const id = useId();

  const handleImportFile = async (e: { target: EventTarget & { files: FileList } }) => {
    const file = e.target.files[0];
    try {
      const items = await fileProcessor(file);
      upsertItems(items);
    } catch (err) {
      setReadStatus("error");
      let errMsg = err;
      if (err.toString().indexOf("Can't find end of central directory") >= 0) { // exceljs error for wrong file type
        errMsg = "Le fichier à importer n'est pas un fichier Excel";
      }
      setMessage(errMsg);
    }
  };

  const globalStatus = useMemo(() => readStatus === "success" ? upsertStatus : readStatus, [upsertStatus, readStatus]);
  useEffect(() => {
    handleError(upsertError).then((errMsg) => setMessage(errMsg));
  }, [upsertError]);

  return (
    <>
      <input
        type="file"
        tw="sr-only"
        id={id}
        disabled={globalStatus === "loading"}
        onChange={handleImportFile}
        accept={accept}
      />
      <ImportButton as="label" tw="cursor-pointer" status={globalStatus} htmlFor={id}>
        Importer
      </ImportButton>
    </>
  );
};
