/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import "twin.macro";
import { CrudCreation, CrudDetails } from "../shared/components/CrudForm";
import { SideModal } from "../shared/components/Modal";
import { formValuesToVehicle, VehicleForm, vehicleToFormValues } from "./VehicleForm";
import {
  useUpsertMutation as useVehicleUpsertMutation,
  useFindByIdQuery as useVehicleFindByIdQuery,
} from "../shared/hooks/useVehicleQueries";
import { VEHICULE_READ_PERMISSIONS, VEHICULE_WRITE_PERMISSIONS } from "./VehicleCrud";

/*
 * Use line bellow to control modal from outside
 * const { isOpen, onOpen, onClose } = useDisclosure(false);
 */

export const VehicleModal = ({ vehicleId, isOpen, onClose }) => {
  const renderVehicleFormFields = useCallback(() => <VehicleForm />, []);
  return (
    <SideModal isOpen={isOpen} onDismiss={onClose} aria-label="Vehicle modal">
      {vehicleId && (
        <CrudDetails
          title="Informations du véhicule"
          objectId={vehicleId}
          renderFormFields={renderVehicleFormFields}
          useFindByIdQuery={useVehicleFindByIdQuery}
          useUpsertMutation={useVehicleUpsertMutation}
          itemToFormValues={vehicleToFormValues}
          formValuesToItem={formValuesToVehicle}
          closeModalAction={onClose}
          readPermissions={VEHICULE_READ_PERMISSIONS}
          writePermissions={VEHICULE_WRITE_PERMISSIONS}
        />
      )}
      {!vehicleId && (
        <CrudCreation
          title="Informations du véhicule"
          renderFormFields={renderVehicleFormFields}
          useUpsertMutation={useVehicleUpsertMutation}
          itemToFormValues={vehicleToFormValues}
          formValuesToItem={formValuesToVehicle}
          closeModalAction={onClose}
          writePermissions={VEHICULE_WRITE_PERMISSIONS}
        />
      )}
    </SideModal>
  );
};
