import { useAuth0 } from "@auth0/auth0-react";
import qs from "qs";
import { useEffect, useContext } from "react";
import { ConnectedUser } from "../../types/ConnectedUser";
import { DrivingLicense } from "../../types/DrivingLicense";
import { Person } from "../../types/Person";
import { PERMISSIONS, Role } from "../../types/Role";
import { StandardStatus } from "../../types/StandardStatus";
import { Vehicle } from "../../types/Vehicle";
import { ConnectedUserContext, ConnectedUserContextType } from "../contexts/ConnectedUserContext";
import { CurrentSiteContext } from "../contexts/CurrentSiteContext";
import { Filter } from "../functions/QueryHelper";
import { useApi } from "./useApi";

/// AUTH0 WRAPPER
/// => Provide additional user data from mongodb
/// => Easier to disable Auth0 for testing
/// => Easier to switch to another provider
export const useConnectedUser = (): ConnectedUser => {
  const { user, logout } = useAuth0();
  const api = useApi();
  const { connectedUser, setConnectedUser } = useContext<ConnectedUserContextType>(ConnectedUserContext);

  const siteContext =  useContext(CurrentSiteContext);

  useEffect(() => {
    window.localStorage.setItem("auth0_username", usernameClaim(user));
    window.localStorage.setItem("auth0_nickname", user?.nickname);
  }, [user]);

  useEffect(() => {
    const asyncFetchUserData = async (auth0User, auth0Logout) => {
      if (process.env.REACT_APP_AUTH0_MOCK === "person") {
        setConnectedUser(MOCK_PERSON);
      } else if (process.env.REACT_APP_AUTH0_MOCK === "vehicle") {
        setConnectedUser(MOCK_VEHICLE);
      } else {
        // we must support both attributes because user who already logged in don't have the username claim
        const username = usernameClaim(auth0User);
        if (username
            && username !== connectedUser?.connectedPerson?.login 
            && username !== connectedUser?.connectedVehicle?.login) {
          let connectedPerson = undefined;
          let connectedVehicle = undefined;

          const filter = { ...Filter.from({ login: username }) };
          const responsePerson = await api.get("v1/person", {
            searchParams: qs.stringify(filter),
          });
          const personList = await responsePerson.json() as Person[];
          if (personList?.length > 0) {
            connectedPerson = personList[0];
            connectedPerson.picture = auth0User.picture;
          } else {
            const responseVehicle = await api.get("v1/vehicle", {
              searchParams: qs.stringify(filter),
            });
            const vehicleList = await responseVehicle.json() as Vehicle[];
            if (vehicleList?.length > 0) {
              connectedVehicle = vehicleList[0];
              connectedVehicle.picture = auth0User.picture;
            }
          }
          const permissions = connectedPerson?.roles?.flatMap((role) => PERMISSIONS.get(role));

          setConnectedUser({
            connectedPerson,
            connectedVehicle,
            permissions,
            permissionsLoaded: true,
            logout: auth0Logout,
          });
        }
      }
    };
    asyncFetchUserData(user, logout);
  }, [user, logout, api, siteContext]);
  return connectedUser;
};

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  return { getAccessTokenSilently };
};

export const usernameClaim = (auth0User) => auth0User?.[`${[process.env.REACT_APP_AUTH0_AUDIENCE]}/username`] ?? auth0User?.nickname;

export const MOCKED_USER_ID = "MOCKED_USER_ID";

const MOCK_SITE = {
  _id:"1223",
  name:"test",
  address:"test",
  city:"test" ,
  postalCode: "test",
  phone: "test",
  status: StandardStatus.active
};

export const MOCK_VEHICLE = {
  connectedPerson: undefined,
  connectedVehicle: {
    id: MOCKED_USER_ID,
    _id: MOCKED_USER_ID,
    name: "Camion démo",
    licensePlate: "BS956FR",
    drivingLicense: DrivingLicense.PL,
    model: "Semi-remorque",
    unavailabilities: [],
    site: MOCK_SITE,
    login: "test login",
    status: StandardStatus.active,
    roles: [],
  },
  permissions: PERMISSIONS.get(Role.ADMIN),
  permissionsLoaded: true,
  logout: (() => { /* noop */}),
};

export const MOCK_PERSON = {
  connectedPerson: {
    _id: MOCKED_USER_ID,
    name: "Démo",
    shortName: "",
    phone:"0606060606",
    drivingLicenses: [DrivingLicense.VL],
    unavailabilities: [],
    site: MOCK_SITE,
    login: "test login",
    status: StandardStatus.active,
    roles: [Role.ADMIN],
  },
  connectedVehicle: undefined,
  permissions: PERMISSIONS.get(Role.ADMIN),
  permissionsLoaded: true,
  logout: (() => { /* noop */}),
};
