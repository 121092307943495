/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

export interface Tab {
  current: boolean;
  name: string;
  count: number;
}

type TabsProps<T extends Tab> = {
  tabs: T[];
  onChange: (e: T) => void;
};

export const Tabs = ({ tabs, onChange }: TabsProps<Tab>) => (
  <div tw="border-b border-gray-200">
    <nav tw="-mb-px flex space-x-8" aria-label="Tabs">
      {tabs.map((tab) => (
        <a
          key={tab.name}
          href="#"
          css={[
            tw`whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm`,
            tab.current
              ? tw`border-indigo-500 text-indigo-600`
              : tw`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200`,
          ]}
          aria-current={tab.current ? "page" : undefined}
          onClick={() => onChange(tab)}
        >
          {tab.name}
          {tab.count ? (
            <span
              css={[
                tab.current ? tw`bg-indigo-100 text-indigo-600` : tw`bg-gray-100 text-gray-900`,
                tw`hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block`,
              ]}
            >
              {tab.count}
            </span>
          ) : null}
        </a>
      ))}
    </nav>
  </div>
);
