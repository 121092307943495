/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";
import { Disclosure } from "@headlessui/react";
import { AcademicCapIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, NavLink } from "react-router-dom";
import { useConnectedUser } from "../hooks/useConnectedUser";
import { PERSON_ROOT_ROUTE } from "../../person/PersonCrud";
import { VEHICLE_ROOT_ROUTE } from "../../vehicle/VehicleCrud";
import { WORKORDER_ROOT_ROUTE } from "../../workOrder/WorkOrderCrud";
import {
  hasAnyPermission,
  hasPersonPermission,
  hasSchedulerPermission,
  hasVehiclePermission,
  hasWorkOrderListPermission,
} from "../functions/RoleManagement";
import { Legend } from "./Header/Legend";
import { PlanningBurgerMenu, PlanningProfileDropdownDesktop } from "./Header/ProfileDropdown";
import { SiteSelection } from "./Header/SiteSelect";
import { SITE_ROOT_ROUTE } from "../../site/SiteCrud";
import { Permission } from "../../types/Role";
import { FullScreenIcon } from "./Icons";
import { Tooltip } from "./Tooltip";
import { useState } from "react";
import { ModalImportExampleDatas } from "../../tutorial/ModalImportExampleDatas";

export const Layout = ({ children }) => {
  const { permissions } = useConnectedUser();
  const [showTutoModal, setShowTutoModal] = useState(false);

  const navigation = [
    hasSchedulerPermission(permissions) && { children: "Planning", to: "/" },
    hasWorkOrderListPermission(permissions) && {
      children: "Liste des OT",
      to: WORKORDER_ROOT_ROUTE,
    },
    hasPersonPermission(permissions) && { children: "Employés", to: PERSON_ROOT_ROUTE },
    hasVehiclePermission(permissions) && { children: "Véhicules", to: VEHICLE_ROOT_ROUTE },
    hasAnyPermission([Permission.MANAGE_SITE_ADMINISTRATORS], permissions) && {
      children: "Sites",
      to: SITE_ROOT_ROUTE,
    },
  ].filter(Boolean);

  return (
    <div tw="h-screen flex flex-col min-w-0 flex-1 overflow-hidden bg-gray-50">
      <Disclosure as="nav" tw="bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            <div tw="px-4 sm:px-6">
              <div tw="flex justify-between h-16">
                <div tw="flex">
                  <Link to="/" tw="flex-shrink-0 flex items-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/logo/Logo-${process.env.REACT_APP_WHITE_LABEL}.svg`}
                      tw="h-8 w-auto"
                    />
                  </Link>

                  <div tw="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((navigationItem, index) => (
                      <NavLink
                        key={index}
                        tw="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        css={{
                          "&": tw`border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700`,
                          "&.active": tw`border-primary-500 text-gray-900 hover:border-primary-500 hover:text-gray-900`,
                        }}
                        to={navigationItem.to}>
                        {navigationItem.children}
                      </NavLink>
                    ))}
                  </div>
                </div>

                <div tw="hidden sm:flex sm:items-center space-x-4">
                  <Link
                    to="/summary"
                    tw="inline-flex items-center px-1 p-1 border-b-2 rounded-md border border-gray-300">
                    <Tooltip message="Mode présentation" position="bottom">
                      <FullScreenIcon tw="text-gray-400 h-5 w-5" />
                    </Tooltip>
                  </Link>
                  <Legend />
                  {process.env.REACT_APP_WHITE_LABEL === "Becoms" && (
                    <div tw="flex gap-2">
                      <div tw="inline-flex items-center px-1 p-1 border-b-2 rounded-md border border-gray-300 cursor-pointer">
                        <AcademicCapIcon
                          tw="text-gray-400 h-5 w-5"
                          onClick={() => setShowTutoModal(true)}
                        />
                      </div>
                    </div>
                  )}
                  <SiteSelection />
                  <PlanningProfileDropdownDesktop />
                </div>

                {showTutoModal && (
                  <ModalImportExampleDatas
                    showResults={showTutoModal}
                    setShowResults={setShowTutoModal}
                    title="Didacticiel"
                  />
                )}

                <div tw="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button tw="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span tw="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon tw="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon tw="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel tw="sm:hidden">
              <PlanningBurgerMenu />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div tw="flex-1 flex overflow-hidden">{children}</div>
    </div>
  );
};
