/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useContext } from "react";
import { CurrentSiteContextType, CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { useUpsertManyMutation } from "../shared/hooks/useVehicleQueries";

export const ExampleDatasVehicles = () => {
  // Get the current site
  const { currentSite } = useContext<CurrentSiteContextType>(CurrentSiteContext);
  // Post multiple vehicles
  const { mutateAsync: upsertManyVehicles } = useUpsertManyMutation(false);
  // Get all vehicles

  const vehiculeName1 = nameArray[getRandomInt(nameArray.length)];
  const vehiculeName2 = nameArray[getRandomInt(nameArray.length)];
  const vehiculeName3 = nameArray[getRandomInt(nameArray.length)];
  const vehiculeName4 = nameArray[getRandomInt(nameArray.length)];

  const importExampleVehicles = () => {
    upsertManyVehicles([
      {
        // @ts-ignore
        drivingLicense: "VL",
        licensePlate: "EC-678-TH",
        model: "32T ADR ",
        icon: "icon1",
        name: vehiculeName1,
        principal: true,
        site: currentSite,
        login: vehiculeName1.toLowerCase(),
        // @ts-ignore
        status: "Actif",
        unavailabilities: [],
        __v: 0,
      },
      {
        // @ts-ignore
        drivingLicense: "VL",
        licensePlate: "QK-546-RF",
        model: "26T PUL",
        icon: "icon2",
        name: vehiculeName2,
        principal: true,
        site: currentSite,
        login: vehiculeName2.toLowerCase(),
        // @ts-ignore
        status: "Actif",
        unavailabilities: [],
        __v: 0,
      },
      {
        // @ts-ignore
        drivingLicense: "VL",
        licensePlate: "AZ-987-TT",
        model: "32T FR",
        icon: "icon1",
        name: vehiculeName3,
        principal: true,
        site: currentSite,
        login: vehiculeName3.toLowerCase(),
        // @ts-ignore
        status: "Actif",
        unavailabilities: [],
        __v: 0,
      },
      {
        // @ts-ignore
        drivingLicense: "VL",
        licensePlate: "SJ-777-RF",
        model: "27T PUL",
        icon: "icon4",
        name: vehiculeName4,
        principal: true,
        site: currentSite,
        login: vehiculeName4.toLowerCase(),
        // @ts-ignore
        status: "Actif",
        unavailabilities: [],
        __v: 0,
      },
    ]);
  };

  return (
    <span onClick={importExampleVehicles} tw="text-primary-500 font-semibold cursor-pointer">
      véhicules
    </span>
  );
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export const nameArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Goro",
  "Johnny Cage",
  "Kano",
  "Liu Kang",
  "Raiden",
  "Reptile",
  "Scorpion",
  "Shang",
  "Sonya",
  "Sub",
  "Baraka",
  "Jade",
  "Jax",
  "Kintaro",
  "Kitana",
  "Kung",
  "Mileena",
  "Noob",
  "Shao",
  "Smoke",
  "Chameleon",
  "Kia",
  "Jataaka",
  "Sareena",
  "Shinnok",
  "Jarek",
  "Kai",
  "Meat",
  "Reiko",
  "Tanya",
  "Blaze",
];
