/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import ky from "ky";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import { CloseButton } from "../Buttons";
import { SideModal } from "../Modal";
import "./Help.css";

export const Help = ({isOpen, onClose}) => {
  const [markdown, setMarkdown] = useState("");
  ky("/Help.md").then((response) => response.text()).then((text) => {
    setMarkdown(text);
  })
  return (
    <SideModal isOpen={isOpen} onDismiss={onClose}>
      <header tw="w-full flex justify-between m-2"><div tw="text-4xl">Guide Utilisateur</div><CloseButton onClose={onClose} /></header>
      <hr />
      <div className="unreset"><ReactMarkdown>{markdown}</ReactMarkdown></div>
    </SideModal>
  );
};
