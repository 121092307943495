import { useState } from "react";

export const useDisclosure = (initialState) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const [data, setData] = useState(initialState);
  const onOpen = (d?) => {
    if (d !== undefined) {
      setData(d);
    }
    setIsOpen(true);
  };
  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen((isOpen) => !isOpen);
  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
    data,
  };
};
