/** @jsxImportSource @emotion/react */
import "twin.macro";
import "./Tuto.css";

export const steps = [
  {
    id: "welcome",
    text: [
      `
        <p>
            Bonjour 👋,
        </p></br>
        <p>
            Vous débuter le didactitiel de votre Planning créé par Becoms.
        </p></br>
        `,
    ],
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Arrêter",
      },
      {
        type: "next",
        text: "Suivant",
      },
    ],
  },
  {
    id: "createOt",
    title: "Création d'un OT",
    text: [
      `
      <p></br></p><p>🖱️ Cliquez dans le blanc de la case de votre planning afin d'ouvrir la modal de création d'OT.</p></br>
      `,
    ],
    attachTo: {
      element: ".mbsc-timeline-column.mbsc-flex-1-1.mbsc-windows.mbsc-ltr",
      on: "bottom",
    },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Retour",
      },
      {
        type: "next",
        text: "Suivant",
      },
    ],
  },
  {
    id: "writeOtTitle",
    title: "Création d'un titre",
    text: [
      `
    <p></br></p>
    <p>✏️ Définissez un titre pour votre Ordre de Travail.</p>
    </br>
    `,
    ],

    attachTo: {
      element: "#title",
      on: "bottom",
    },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Retour",
      },
      {
        type: "next",
        text: "Suivant",
      },
    ],
  },
  {
    id: "saveOt",
    title: "Sauvegardez votre Ordre de Travail",
    text: [
      `
    <p></br></p>
    <p>🖱️ Cliquez sur le bouton 'Sauvegarder'.</p>
    </br>
    `,
    ],
    attachTo: {
      element: ".sc-bcXHqe.jqaSCO",
      on: "bottom",
    },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Retour",
      },
      {
        type: "next",
        text: "Suivant",
      },
    ],
  },
  {
    id: "doneCreationOt",
    title: "L'OT est créé",
    text: [
      `
      <p></br></p>
      <p>
      ✨ Félicitation, vous avez créé votre OT! 🥳
      </p></br>

      <p>
      Maintenant votre OT est 'En préparation'.
      </p>
      <p>
      </br>
      Afin de le transmettre à votre camion et de le confirmer, il faut changer le statut de l'OT.
      </p>
      </br>
        `,
    ],
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        text: "Next",
      },
    ],
  },
  {
    id: "openOtToUpdate",
    title: "Transmettre",
    text: [
      `
      <p></br></p>
      <p>
      🖱️ Cliquez sur l'OT que vous venez de créer pour ouvrir de nouveau la modal.
      </p></br>
      <p>
      Vous pourrez transmettre votre OT à votre chauffeur !
      </p>
      </br>
      `,
    ],
    attachTo: {
      element: ".mbsc-timeline-column.mbsc-flex-1-1.mbsc-windows.mbsc-ltr",
      on: "bottom",
    },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Retour",
      },
      {
        type: "next",
        text: "Suivant",
      },
    ],
  },
  {
    id: "transmitOt",
    title: "Tramttre votre Ordre de Travail",
    text: [
      `
    <p></br></p>
    <p>
    🖱️ Cliquez sur le bouton 'Tansmettre' pour que votre chauffeur puisse voir l'OT.
    </p>
    </br>
    `,
    ],
    attachTo: {
      element: ".sc-bcXHqe.bAEwEU",
      on: "bottom",
    },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Retour",
      },
      {
        type: "next",
        text: "Suivant",
      },
    ],
  },
  {
    id: "doneTransmitOt",
    title: "L'OT est transmis !",
    text: [
      `
      <p></br></p>
      <p>
      ✨  Félicitation, vous avez transmis votre OT!! 🥳
      </p></br>

      <p>
      Maintenant votre OT est 'Transmis'.
      </p></br>
      <p>
      Votre chauffeur peux maintenant voir l'OT concernant son véhicule à transvers l'application mobile.
      </p></br>
      <p>
      Votre chauffeur peux maintenant voir l'OT concernant son véhicule à transvers l'application mobile.
      </p></br>

      <p>
      Pour ce connecter à l'application mobile, utiliser "/mobile" à la fin de l'url du planning.
      </p></br>
      <p>
      Le chauffeur pourra s'identifier avec les données du camion.
      </p>
      `,
    ],
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        text: "Next",
      },
    ],
  },
];
