/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { FC } from "react";
import { Control, FieldValues, useController, useFormContext } from "react-hook-form";
import Select from "react-select";
import { Vehicle } from "../../../types/Vehicle";
import { SelectStyled } from "../Form";
import { VehicleOption } from "../VehicleOption";
import { isVehicleUnavailable } from "../../functions/VehicleManagement";
import { WorkOrder } from "../../../types/WorkOrder";

export type VehicleOption = {
  value: string;
  label: string;
  name: string;
};
export const vehicleToOption = (v: Vehicle): VehicleOption => ({
  value: v._id,
  label: (v.name ?? v.model) + (v.licensePlate ? ` (${v.licensePlate})` : ""),
  name: v._id,
});

type VehicleSelectProps = {
  vehicles: Vehicle[];
  readOnly: boolean;
  required?: boolean;
  workOrder: WorkOrder;
  vehicleFilter: (v: Vehicle) => boolean;
  formInputName?: string;
};
export const WorkOrderVehicleSelect: FC<VehicleSelectProps> = ({
  vehicles,
  readOnly,
  required = false,
  vehicleFilter,
  workOrder,
  formInputName = undefined,
}) => {
  const { register } = useFormContext();

  const options = (
    <>
      <option key="none" value={undefined}>
        -
      </option>
      {vehicles &&
        vehicles
          .filter(vehicleFilter)
          .filter((v) => !isVehicleUnavailable(v, workOrder.start))
          .map((v) => <VehicleOption key={v._id} v={v} />)}
    </>
  );

  return (
    <SelectStyled {...register(formInputName, { required })} disabled={readOnly}>
      {options}
    </SelectStyled>
  );
};

type VehicleSelectMultiProps = {
  vehicles: Vehicle[];
  readOnly: boolean;
  required?: boolean;
  workOrder: WorkOrder;
  vehicleFilter: (v: Vehicle) => boolean;
  name: string,
  control: Control<FieldValues, unknown>;
};
export const WorkOrderVehicleSelectMulti: FC<VehicleSelectMultiProps> = ({
  vehicles,
  readOnly,
  required,
  vehicleFilter,
  workOrder,
  name = undefined,
  control,
}) => {
  const {
    field: { ref, ...controllerProps },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const secondaryVehicleOptions = [
    {
      value: "",
      label: "-",
      name: "",
    },
    ...vehicles
        .filter(vehicleFilter)
        .filter((v) => !isVehicleUnavailable(v, workOrder.start))
        .map(vehicleToOption),
  ] as VehicleOption[];

  return (
    <Select
      isSearchable={false}
      options={secondaryVehicleOptions}
      ref={ref}
      {...controllerProps}
      tw=" border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:(bg-gray-50 text-gray-500 cursor-default)"
      name="secondaryVehicles"
      isMulti={true}
      isDisabled={readOnly}
    />
  );
};
