// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { useApi } from "../shared/hooks/useApi";
import { Client } from "../types/Client";
import { CrudDataType } from "../types/CrudDataType";
import { MongoDocument } from "../types/MongoDocument";

// Configure the following variables
export const API_PATH = "v1/client";

export const useSearchQuery = (searchParams) => {
  const api = useApi();
  return useQuery<unknown, unknown, CrudDataType<MongoDocument>, Array<string>>([API_PATH, searchParams], async () => {
    const response = await api.get(API_PATH, {
      searchParams: qs.stringify({
        limit: searchParams.limit ?? 0,
        skip: searchParams.skip ?? 0,
        sort: searchParams.sort ?? 'name',
        ...searchParams
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

export const useFetchItems = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(API_PATH, { searchParams: qs.stringify(searchParams) }).json();
  };
};

export const useFindByIdQuery = (itemId: string): UseQueryResult<MongoDocument> => {
  const api = useApi();
  return useQuery([API_PATH, itemId], async () => {
    return api.get(`${API_PATH}/${itemId}`).json();
  });
};

export const useUpsertManyMutation = (retry: boolean | number = false) => {
  const { mutateAsync: upsertItem } = useUpsertMutation(retry);
  return useMutation(async (items: Client[]) => {
    return Promise.all(items.map((item) => upsertItem(item)));
  },
  {
    retry
  });
};

export const useUpsertMutation = (retry: boolean | number = false) => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    async (item: Client) => {
      if (item._id) {
        return api.patch(`${API_PATH}/${item._id}`, { json: item }).json();
      }
      return api.post(API_PATH, { json: item }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_PATH]);
      },
      retry,
    }
  );
};

export const useDeleteMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    async (item: Client) => {
      if (item._id) {
        return api.delete(`${API_PATH}/${item._id}`);
      } else if (Array.isArray(item)) {
        return Promise.all(item.map((i) => api.delete(`${API_PATH}/${i._id}`)));
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_PATH]);
      },
    }
  );
};
