/** @jsxImportSource @emotion/react */
import "twin.macro";
import { FC, useCallback } from "react";
import { SideModal } from "../Modal";
import { useUpsertWorkOrderMutation } from "../../hooks/useWorkOrderQueries";
import { WorkOrder } from "../../../types/WorkOrder";
import WorkOrderForm from "./WorkOrderForm";

type WorkOrderCreatePopupProps = {
  onClose: () => void;
  workOrderInitValues: WorkOrder | undefined; // Init values for create and duplicate
};
export const WorkOrderCreatePopup: FC<WorkOrderCreatePopupProps> = ({
  onClose,
  workOrderInitValues,
}) => {
  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  const onWorkOrderCreated = useCallback(() => {
    onClose();
  }, [onClose, upsertWorkOrder]);

  return (
    // @ts-ignore
    <SideModal
      isOpen={!!workOrderInitValues}
      onDismiss={onClose}
      aria-label="Création d'ordre de travail"
      tw="!overflow-y-hidden"
    >
      <WorkOrderForm
        workOrder={workOrderInitValues}
        onCancel={onClose}
        onValidate={onWorkOrderCreated}
      />
    </SideModal>
  );
};
