/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

export const Tooltip = ({ message, position = "top", children, containerProps = {}, ...props }) => {
  return (
    <div className="group" css={[tw`relative flex flex-col items-center`]} {...containerProps}>
      {children}
      <div css={[tw`absolute left-2 flex-col hidden mb-6 group-hover:flex`, position === "top" ? tw`bottom-0` : tw`top-6`]} {...props}>
        { position !== "top" && <div css={[tw`w-3 h-3 -mb-2 rotate-45 bg-indigo-500`]}></div> }
        <p
          css={[
            tw`right-10 [min-width: 60px] w-max relative z-50 p-2 text-sm max-w-sm leading-4 text-white bg-indigo-500 rounded-md [word-wrap: break-word]`,
          ]}
        >
          {message}
        </p>
        { position === "top" && <div css={[tw`w-3 h-3 -mt-2 rotate-45 bg-indigo-500`]}></div> }
      </div>
    </div>
  );
};
