/** @jsxImportSource @emotion/react */
import { CalendarIcon, PhotographIcon, ViewBoardsIcon, ViewListIcon } from "@heroicons/react/solid";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "twin.macro";
import { DrivingLicenseBadge } from "../../../shared/components/Badge";
import { PrimaryMobileButton } from "../../../shared/components/Buttons";
import { Container } from "../../../shared/components/Container";
import { LittleMapsIcon, LocalisationIcon, PhoneIcon } from "../../../shared/components/Icons";
import { useDownloadFileQueries, useDownloadFileQuery } from "../../../shared/hooks/useDownloadApi";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { StatusStepper } from "../../StatusStepper";
import {
  BadgeContainer,
  CardContainer,
  DateAndBadgeContainer,
  HeaderContainer,
  NavigationTitle,
  OtLocation,
  StartDate,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";

const Received = ({ setShowResults, status, workOrder, handleRoadUpsertWorkOrder }) => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { otId } = useParams();

  const dataOtherAttachments = useDownloadFileQueries(workOrder?.otherAttachments || []);
  const dataInterventionSheet = useDownloadFileQuery(workOrder?.interventionSheet);
  const dataBsd = useDownloadFileQuery(workOrder?.bsd);
  const dataPlan = useDownloadFileQuery(workOrder?.plan);
  const hasAttachment =
    workOrder?.interventionSheet ||
    workOrder?.bsd ||
    workOrder?.plan ||
    workOrder?.otherAttachments?.length > 0;

  return (
    <div>
      <StatusStepper workOrdersData={workOrder} otId={otId} />
      <Container tw="mb-20 xl:px-48 2xl:px-96">
        {/* 1ST SECTION */}
        <CardContainer>
          <HeaderContainer>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              <NavigationTitle>{workOrder.title.toUpperCase()}</NavigationTitle>
            </div>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              {workOrder.address && workOrder.city && (
                <a
                  href={`http://maps.google.com/?q= ${workOrder.address}, ${workOrder.city}`}
                  onClick={handleRoadUpsertWorkOrder}
                >
                  <button
                    tw="bg-blue-500 text-white hover:(bg-blue-800) px-3 py-4 rounded-full flex items-center"
                    type="button"
                  >
                    <LittleMapsIcon tw="w-5 h-5 text-white" />
                    <span tw="hidden sm:block text-xs ml-2">Itinéraire</span>
                  </button>
                </a>
              )}
              {workOrder.contactPhone && (
                <a href={`tel:${workOrder.contactPhone}`}>
                  <button
                    tw="bg-green-500 text-white hover:(bg-green-800) px-3 py-4 rounded-full flex items-center"
                    type="button"
                  >
                    <PhoneIcon tw="w-5 h-5 text-white" />
                    <span tw="hidden sm:block text-xs ml-2">Appeler</span>
                  </button>
                </a>
              )}
            </div>
          </HeaderContainer>
          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <OtLocation
              address=""
              city=""
              contactPhone={workOrder.contactPhone}
              clientName={workOrder.clientName}
              contactName={workOrder.contactName}
              postalCode=""
              valvertContactName={workOrder.valvertContactName}
            />
          </div>
        </CardContainer>

        {/* 2ND SECTION */}
        <CardContainer>
          <HeaderContainer>
            <TitleHeaderContainer title="ADRESSE DU CHANTIER" subTitle="" />
            <DateAndBadgeContainer>
              {copied && <BadgeContainer status="Copié !" />}
            </DateAndBadgeContainer>
          </HeaderContainer>

          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="divide-y divide-gray-200">
              <dd tw="md:col-span-7">
                <div tw="text-lg text-gray-500">
                  {(workOrder.address || workOrder.city) && (
                    <div tw="sm:col-span-1">
                      <CopyToClipboard
                        text={workOrder.address + " " + workOrder.city}
                        onCopy={() => setCopied(true)}
                        tw="mt-1 text-base text-gray-900 flex items-center"
                      >
                        <div>
                          <LocalisationIcon tw="w-5 h-5 text-gray-500 mr-2" />
                          {workOrder.address} {workOrder.postalCode} {workOrder.city}
                        </div>
                      </CopyToClipboard>
                    </div>
                  )}
                </div>
              </dd>
            </dl>
          </div>
        </CardContainer>

        {/* 3RD SECTION*/}
        <CardContainer>
          <HeaderContainer>
            <TitleHeaderContainer
              title="TRAVAUX À EFFECTUER"
              subTitle="Informations générales avant intervention"
            />
            <div tw="flex items-center">
              {workOrder.licenses?.map((l) => (
                <DrivingLicenseBadge key={l} drivingLicense={l} />
              ))}
            </div>
            <DateAndBadgeContainer>
              <BadgeContainer status={workOrder.status} />
              <StartDate start={workOrder.actualStart ?? workOrder.start} />
            </DateAndBadgeContainer>
          </HeaderContainer>

          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="divide-y divide-gray-200">
              <div tw="pt-6 pb-8">
                <dd tw="mt-2 md:mt-0 md:col-span-7">
                  <div tw="text-base text-gray-500">
                    {workOrder.description
                      ? workOrder.description
                          .split("\n")
                          .map((str, index) => <p key={index}>{str}</p>)
                      : "Rien à signaler"}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </CardContainer>

        {/*4TH  SECTION ATTACHMENTS*/}
        {hasAttachment && (
          <CardContainer>
            <HeaderContainer>
              <TitleHeaderContainer title="PIECES JOINTES" subTitle="Images" />
            </HeaderContainer>

            <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
              {dataInterventionSheet?.status === "success" && (
                <a
                  href={URL.createObjectURL(dataInterventionSheet.data as Blob)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div tw="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div tw="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-purple-500">
                      <CalendarIcon tw="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div>
                      <h3 tw="text-sm font-medium text-gray-900">
                        <span tw="absolute inset-0" aria-hidden="true" />
                        Fiche intervention
                        <span aria-hidden="true"> &rarr;</span>
                      </h3>
                      <p tw="mt-1 text-sm text-gray-500 truncate w-60">
                        {workOrder.interventionSheet}
                      </p>
                    </div>
                  </div>
                </a>
              )}

              {workOrder?.bsd && dataBsd?.status === "success" && (
                <a
                  href={URL.createObjectURL(dataBsd.data as Blob)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div tw="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div tw="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-yellow-500">
                      <ViewListIcon tw="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div>
                      <h3 tw="text-sm font-medium text-gray-900">
                        <span tw="absolute inset-0" aria-hidden="true" />
                        BSD
                        <span aria-hidden="true"> &rarr;</span>
                      </h3>
                      <p tw="mt-1 text-sm text-gray-500 truncate w-60">{workOrder.bsd}</p>
                    </div>
                  </div>
                </a>
              )}
              {workOrder?.plan && dataPlan?.status === "success" && (
                <a
                  href={URL.createObjectURL(dataPlan.data as Blob)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div tw="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div tw="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-green-500">
                      <PhotographIcon tw="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div>
                      <h3 tw="text-sm font-medium text-gray-900">
                        <span tw="absolute inset-0" aria-hidden="true" />
                        PLAN
                        <span aria-hidden="true"> &rarr;</span>
                      </h3>
                      <p tw="mt-1 text-sm text-gray-500 truncate w-60">{workOrder.plan}</p>
                    </div>
                  </div>
                </a>
              )}

              {workOrder?.otherAttachments.length > 0 && (
                <dl tw="divide-y divide-gray-200">
                  <div tw="pt-6 pb-8">
                    <dd tw="mt-2 md:mt-0 md:col-span-7">
                      <ul role="list" tw="grid grid-cols-2 gap-2">
                        {status === "loading" &&
                          [...Array(4)].map((e, index) => (
                            <li
                              tw="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 "
                              key={index}
                            >
                              <div tw="w-full flex items-center justify-between p-6 space-x-6"></div>
                            </li>
                          ))}
                        {dataOtherAttachments?.map(
                          (dataOtherAttachment, index) =>
                            dataOtherAttachment.status === "success" && (
                              <li tw="col-span-1 rounded-lg" key={index}>
                                <div tw="space-y-4">
                                  <a
                                    href={URL.createObjectURL(dataOtherAttachment.data as Blob)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div tw="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                                      <div tw="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-blue-500">
                                        <ViewBoardsIcon
                                          tw="h-6 w-6 text-white"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div>
                                        <h3 tw="text-sm font-medium text-gray-900">
                                          <span tw="absolute inset-0" aria-hidden="true" />
                                          AUTRES
                                          <span aria-hidden="true"> &rarr;</span>
                                        </h3>
                                        <p tw="mt-1 text-sm text-gray-500 truncate w-20">
                                          {workOrder.otherAttachments}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            )
                        )}
                      </ul>
                    </dd>
                  </div>
                </dl>
              )}
            </div>
          </CardContainer>
        )}

        {/* SECTION */}
        <div tw="flex space-x-3 items-center justify-end mt-8">
          {workOrder?.status === WorkOrderStatus.EN_ROUTE ||
          workOrder?.status === WorkOrderStatus.RECU ||
          workOrder?.status === WorkOrderStatus.TRANSMIS ? (
            <PrimaryMobileButton onClick={() => setShowResults(true)}>
              Démarrer l’intervention
            </PrimaryMobileButton>
          ) : (
            <PrimaryMobileButton
              onClick={() => {
                navigate(`/mobile/${otId}/work${search}`);
              }}
            >
              Suivant
            </PrimaryMobileButton>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Received;

export const LabelOT = (props) => {
  return <dt tw="text-sm font-medium text-gray-900" {...props} />;
};
