/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import "twin.macro";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMessage as CustomErrorMessage,
  FormGroup,
  InputStyled,
  Label,
  RequiredAsterisk,
} from "../shared/components/Form";
import { Site } from "../types/Site";
import { StandardStatus } from "../types/StandardStatus";
import { SiteFooterAttachment, SiteLeftHeaderAttachment } from "./SiteAttachment";

export const SiteForm = ({ setError, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div tw="mt-6" {...props}>
      <FormGroup>
        <Label htmlFor="site-name">
          Nom
          <RequiredAsterisk tw="text-red-500" />
        </Label>
        <InputStyled
          type="text"
          {...register("name", { required: "Champs obligatoire" })}
          id="site-name"
        />
        <ErrorMessage as={CustomErrorMessage} name="name" errors={errors}  />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="site-address">Adresse</Label>
        <InputStyled type="text" {...register("address")} id="site-address" />
      </FormGroup>

      <div tw="grid [grid-template-columns: repeat(5,minmax(0, 0.5fr))] gap-6 mt-2">
        <FormGroup tw="col-span-2">
          <Label htmlFor="site-postalCode">Code postal</Label>
          <InputStyled type="number" {...register("postalCode")} id="site-postalCode" />
        </FormGroup>

        <FormGroup tw="col-span-2">
          <Label htmlFor="site-city">Ville</Label>
          <InputStyled type="text" {...register("city")} id="site-city" />
        </FormGroup>
      </div>

      <FormGroup tw="col-span-2 mt-2">
        <Label htmlFor="site-phone">Téléphone</Label>
        <InputStyled type="tel" {...register("phone")} id="site-phone" />
      </FormGroup>

      <FormGroup tw="col-span-2 mt-2">
        <Label htmlFor="site-siret">Siret</Label>
        <InputStyled type="text" {...register("siret")} id="site-siret" />
      </FormGroup>

      <FormGroup tw="col-span-2 mt-2 w-auto">
        <Label htmlFor="site-attachment">Pied-de-page de l&apos;OT</Label>
        <SiteFooterAttachment setError={setError} />
      </FormGroup>

      <FormGroup tw="col-span-2 mt-2 w-auto">
        <Label htmlFor="site-leftHeaderAttachment">Header de l&apos;OT</Label>
        <SiteLeftHeaderAttachment setError={setError} />
      </FormGroup>
    </div>
  );
};

export const siteToFormValues = (site: Site) => {
  return {
    ...site,
    status: site.status || StandardStatus.active,
  };
};

export const formValuesToSite = (formValues): Site => {
  return {
    ...formValues,
    status: formValues.status as StandardStatus,
  };
};
