/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "twin.macro";
import { PageMobile } from "../../../shared/components/PageMobile";
import { useUpsertWorkOrderMutation } from "../../../shared/hooks/useWorkOrderQueries";
import { WorkOrderStatus } from "../../../types/WorkOrderStatus";
import { useWorkorderQuery } from "../../MobileAPIs";
import { ModalConfirmStatus } from "../../MobileModal";
import { useGeoLocation } from "../../useGeoLocation";
import Received from "./Received";
import ReceivedSkeleton from "./ReceivedSkeleton";

const ReceivedPage = () => {
  const [showResults, setShowResults] = useState(false);
  const location = useGeoLocation();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  // Get workorder from list query for a better offline mode
  // (if user didn't visit the workorder detail online, he can still do it offline)
  const { otId } = useParams();
  const { status, data: workOrdersData } = useWorkorderQuery();
  const workOrder = workOrdersData?.list?.find((w) => w._id === otId);

  const handleUpsertWorkOrder = () => {
    upsertWorkOrder(
      {
        ...workOrder,
        status: WorkOrderStatus.EN_COURS,
        actualStart: new Date(),
        location: [
          {
            lat: location.loaded && location.coodinates?.lat,
            lng: location.loaded && location.coodinates?.lng,
          },
        ],
      },
      {
        onSuccess: () => {
          navigate(`/mobile/${otId}/work`);
        },
      }
    );
  };

  const previousStatusParam = search.split("=")[1];
  const canEdit =
    (previousStatusParam === "Termin%C3%A9" ||
      previousStatusParam === "Non%20termin%C3%A9" ||
      previousStatusParam === undefined) &&
    parseInt(workOrder?.start.toString().slice(8, 10)) === new Date().getDate();

  const handleRoadUpsertWorkOrder = () => {
    if (
      (canEdit && workOrder.status === WorkOrderStatus.TRANSMIS) ||
      workOrder.status === WorkOrderStatus.RECU
    ) {
      upsertWorkOrder({
        ...workOrder,
        status: WorkOrderStatus.EN_ROUTE,
      });
    }
  };

  // If an OT do not exist anymore, redirect to /mobile
  useEffect(() => {
    if (status === "success" && workOrder === undefined) {
      navigate(`/mobile`);
    }
  }, [workOrder]);

  return (
    <>
      <Helmet title={status === "success" ? workOrder?.title : process.env.REACT_APP_WHITE_LABEL} />
      <PageMobile>
        {status === "loading" && <ReceivedSkeleton />}
        {workOrder && status === "success" && (
          <>
            <Received
              setShowResults={setShowResults}
              status={status}
              workOrder={workOrder}
              handleRoadUpsertWorkOrder={handleRoadUpsertWorkOrder}
            />
            {showResults && (
              <ModalConfirmStatus
                showResults={showResults}
                setShowResults={setShowResults}
                updateStatus={handleUpsertWorkOrder}
                title="Veuillez confirmez le début de l’intervention"
              />
            )}
          </>
        )}
      </PageMobile>
    </>
  );
};

export default ReceivedPage;
