import { withAuthenticationRequired } from "@auth0/auth0-react";
import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersonCrud, PERSON_ROOT_ROUTE } from "./person/PersonCrud";
import { VEHICLE_ROOT_ROUTE, VehicleCrud } from "./vehicle/VehicleCrud";
import { CLIENT_ROOT_ROUTE, ClientCrud } from "./client/ClientCrud";

import { LayoutMobile } from "./shared/components/LayoutMobile";
import { PageSkeleton } from "./shared/components/Page";
import ReceivedPage from "./mobile/workOrderDetails/received/ReceivedPage";
import InProgressPage from "./mobile/workOrderDetails/inProgress/InProgressPage";
import DonePage from "./mobile/workOrderDetails/done/DonePage";
import { Layout } from "./shared/components/Layout";
import { WorkOrderCrud, WORKORDER_ROOT_ROUTE } from "./workOrder/WorkOrderCrud";
import { SiteCrud, SITE_ROOT_ROUTE } from "./site/SiteCrud";
import SummaryPage from "./summary/SummaryPage";
import { LayoutSummary } from "./shared/components/LayoutSummary";
import { PageContextProvider } from "./summary/API/PageContext";

const OtList = lazy(() => import("./mobile/workOrderList/WorkOrderList"));
const PlanningScreen = lazy(() => import("./planning/PlanningScreen"));
const NotFoundScreen = lazy(() => import("./not-found/NotFoundScreen"));

const TheRoutes = () => (
  <BrowserRouter>
    <Suspense fallback={<PageSkeleton />}>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <PlanningScreen />
            </Layout>
          }
        />
        <Route
          path={PERSON_ROOT_ROUTE}
          element={
            <Layout>
              <PersonCrud />
            </Layout>
          }
        />
        <Route
          path={VEHICLE_ROOT_ROUTE}
          element={
            <Layout>
              <VehicleCrud />
            </Layout>
          }
        />
        <Route
          path={WORKORDER_ROOT_ROUTE}
          element={
            <Layout>
              <WorkOrderCrud />
            </Layout>
          }
        />
        <Route
          path={SITE_ROOT_ROUTE}
          element={
            <Layout>
              <SiteCrud />
            </Layout>
          }
        />
        <Route
          path={CLIENT_ROOT_ROUTE}
          element={
            <Layout>
              <ClientCrud />
            </Layout>
          }
        />
        <Route
          path="/mobile/:otId/informations"
          element={
            <LayoutMobile>
              <ReceivedPage />
            </LayoutMobile>
          }
        />
        <Route
          path="/mobile/:otId/work"
          element={
            <LayoutMobile>
              <InProgressPage />
            </LayoutMobile>
          }
        />
        <Route
          path="/mobile/:otId/signature"
          element={
            <LayoutMobile>
              <DonePage />
            </LayoutMobile>
          }
        />
        <Route
          path="/mobile"
          element={
            <LayoutMobile>
              <OtList />
            </LayoutMobile>
          }
        />
        <Route
          path="/summary"
          element={
            <PageContextProvider>
              <LayoutSummary>
                <SummaryPage />
              </LayoutSummary>
            </PageContextProvider>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <NotFoundScreen />
            </Layout>
          }
        />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export const AppRoutes = withAuthenticationRequired(() => <TheRoutes />);
