/** @jsxImportSource @emotion/react */
import { Link, useNavigate } from "react-router-dom";
import "twin.macro";
import { BadgeDot, StatusBadge, VerticalSignal } from "../../shared/components/Badge";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ClientIcon,
  LittleMapsIcon,
  LocalisationIcon,
  MailIcon,
  PhoneIcon,
} from "../../shared/components/Icons";
import { LabelOT } from "../workOrderDetails/received/Received";
import { WorkOrderColors } from "../../types/WorkOrderColors";
import { timeDisplay } from "../../shared/functions/Datetime";
import { ClockIcon } from "@heroicons/react/outline";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import tw from "twin.macro";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";
import qs from "qs";
import { useUpsertWorkOrderMutation } from "../../shared/hooks/useWorkOrderQueries";

export const WorkOrderListItem = ({ index, workOrder, arr }) => {
  const previousWorkOrder = arr[index - 1];
  const navigate = useNavigate();
  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();
  const color = WorkOrderColors.get(workOrder.status);

  const getStatusParam = () => {
    switch (workOrder.status) {
      case WorkOrderStatus.RECU:
        return "/informations";
      case WorkOrderStatus.TRANSMIS:
        return "/informations";
      case WorkOrderStatus.EN_ROUTE:
        return "/informations";
      case WorkOrderStatus.EN_COURS:
        return "/work";
      case WorkOrderStatus.NON_TERMINE:
        return "/signature";
      case WorkOrderStatus.TERMINE:
        return "/signature";
    }
  };

  const handleUpsertWorkOrder = () => {
    if (workOrder.status === WorkOrderStatus.TRANSMIS) {
      upsertWorkOrder({
        ...workOrder,
        status: WorkOrderStatus.RECU,
      });
    }

    const statusParam = getStatusParam();

    if (statusParam) {
      navigate(
        `/mobile/${workOrder._id}${statusParam}${qs.stringify(
          {
            previousStatus: previousWorkOrder?.status,
          },
          { addQueryPrefix: true }
        )}`
      );
    } else {
      navigate(
        `/mobile/${workOrder._id}${qs.stringify(
          {
            previousStatus: previousWorkOrder?.status,
          },
          { addQueryPrefix: true }
        )}`
      );
    }
  };

  return (
    <div
      onClick={handleUpsertWorkOrder}
      css={[
        workOrder?.start.toString().slice(0, 10) !==
          previousWorkOrder?.start.toString().slice(0, 10) && tw`mt-8`,
      ]}
    >
      {workOrder?.start.toString().slice(0, 10) !==
        previousWorkOrder?.start.toString().slice(0, 10) && (
        <h1 tw="mb-2 text-base font-medium text-gray-700 truncate">
          {workOrder.start.toString().slice(8, 10)}-{workOrder.start.toString().slice(5, 7)}-
          {workOrder.start.toString().slice(0, 4)}
        </h1>
      )}

      {workOrder.status === WorkOrderStatus.TERMINE ||
      workOrder.status === WorkOrderStatus.NON_TERMINE ||
      workOrder.status === WorkOrderStatus.ANNULE ? (
        <>
          <div tw="relative flex items-center">
            <span tw="min-w-0 flex flex-col w-full">
              <CardOtListItemInfo color={color} workOrder={workOrder} />
            </span>
          </div>
        </>
      ) : workOrder.status === WorkOrderStatus.EN_ROUTE ||
        workOrder.status === WorkOrderStatus.EN_COURS ? (
        <>
          <div tw="relative flex items-center" aria-current="step">
            <span tw="min-w-0 flex flex-col w-full">
              <CardOtListItemInfo color={color} workOrder={workOrder} />
            </span>
          </div>
        </>
      ) : (
        <>
          <div tw="relative flex items-center">
            <span tw="flex items-center" aria-hidden="true"></span>
            <span tw="min-w-0 flex flex-col w-full">
              <CardOtListItemInfo color={color} workOrder={workOrder} />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export const NavigationHeaderContainer = ({
  linkFrom,
  linkTo,
  title,
  address,
  city,
  handleRoadUpsertWorkOrder,
  contactPhone,
}) => {
  return (
    <HeaderContainer>
      <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
        <Link
          to={{
            pathname: `/${linkFrom}`,
          }}
          tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-100"
        >
          <ArrowLeftIcon tw="-ml-2 h-8 w-8 text-gray-500" aria-hidden="true" />
        </Link>
        <NavigationTitle>{title}</NavigationTitle>
        <Link
          to={{
            pathname: `${linkTo}`,
          }}
          tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-100"
        >
          <ArrowRightIcon tw="-ml-2 h-8 w-8 text-gray-500" aria-hidden="true" />
        </Link>
      </div>
      <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
        <a
          href={`http://maps.google.com/?q= ${address}, ${city}`}
          onClick={handleRoadUpsertWorkOrder}
        >
          <button
            tw="bg-blue-500 text-white hover:(bg-blue-800) px-3 py-4 rounded-full flex items-center"
            type="button"
          >
            <LittleMapsIcon tw="w-5 h-5 text-white" />
            <span tw="hidden sm:block text-xs ml-2">Itinéraire</span>
          </button>
        </a>
        <a href={`tel:${contactPhone}`}>
          <button
            tw="bg-green-500 text-white hover:(bg-green-800) px-3 py-4 rounded-full flex items-center"
            type="button"
          >
            <PhoneIcon tw="w-5 h-5 text-white" />
            <span tw="hidden sm:block text-xs ml-2">Appeler</span>
          </button>
        </a>
      </div>
    </HeaderContainer>
  );
};

export const BadgeContainer = ({ status, ...props }) => {
  return (
    <h3 tw="text-lg leading-6 font-medium text-gray-900" {...props}>
      <StatusBadge status={status}>
        <BadgeDot />
      </StatusBadge>
    </h3>
  );
};

export const StartDate = ({ start, ...props }) => {
  return (
    <p tw="mt-1 max-w-2xl text-sm text-gray-800" {...props}>
      Le {start.toString().slice(8, 10)}/{start.toString().slice(5, 7)}/
      {start.toString().slice(0, 4)}
    </p>
  );
};

export const DateAndBadgeContainer = ({ ...props }) => {
  return <div tw="px-4 py-5 sm:px-6" {...props} />;
};

export const NavigationTitle = ({ ...props }) => {
  return (
    <h3
      tw="inline-flex items-center space-x-3 text-lg leading-6 font-medium text-gray-900"
      {...props}
    />
  );
};

export const TitleHeaderContainer = ({ title, subTitle, ...props }) => {
  return (
    <div tw="px-4 py-5 sm:px-6 flex gap-4" {...props}>
      <div>
        <div tw="text-lg leading-6 font-medium text-gray-900">{title}</div>
        <p tw="mt-1 max-w-2xl text-sm text-gray-500">{subTitle}</p>
      </div>
    </div>
  );
};

export const HeaderContainer = ({ ...props }) => {
  return <div tw="flex justify-between" {...props} />;
};

export const CardContainer = ({ ...props }) => {
  return <div tw="bg-white shadow overflow-hidden sm:rounded-lg mt-5" {...props} />;
};

export const OtLocation = ({
  address,
  city,
  contactPhone,
  clientName,
  contactName,
  postalCode,
  valvertContactName,
  ...props
}) => {
  const [copied, setCopied] = useState(false);

  return (
    <dl tw="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8" {...props}>
      {clientName && (
        <div tw="sm:col-span-1">
          <LabelOT>Nom du client</LabelOT>
          <dd tw="mt-1 text-sm text-gray-900 flex items-center">{clientName}</dd>
        </div>
      )}
      {contactName && (
        <div tw="sm:col-span-1">
          <LabelOT>Contact chantier</LabelOT>
          <dd tw="mt-1 text-sm text-gray-900 flex items-center">
            <ClientIcon tw="w-5 h-5 text-gray-500 mr-2" /> {contactName}
          </dd>
        </div>
      )}
      {contactPhone && (
        <div tw="sm:col-span-1">
          <LabelOT>Téléphone du contact</LabelOT>
          <a href={`tel:${contactPhone}`} tw="mt-1 text-sm text-gray-500 flex items-center">
            <PhoneIcon tw="w-5 h-5 text-gray-500 mr-2" /> {contactPhone}
          </a>
        </div>
      )}
      {(address || city) && (
        <div tw="sm:col-span-1">
          <LabelOT tw="inline">
            Adresse du client {copied ? <span tw="text-green-500">Copié !</span> : null}
          </LabelOT>

          <CopyToClipboard
            text={address + " " + city}
            onCopy={() => setCopied(true)}
            tw="mt-1 text-sm text-gray-900 flex items-center"
          >
            <div>
              <LocalisationIcon tw="w-5 h-5 text-gray-500 mr-2" />
              {address} {postalCode} {city}
            </div>
          </CopyToClipboard>
        </div>
      )}
      {valvertContactName && (
        <div tw="sm:col-span-1">
          <LabelOT>Contact {process.env.REACT_APP_WHITE_LABEL}</LabelOT>
          <dd tw="mt-1 text-sm text-gray-900 flex items-center">
            <MailIcon tw="w-5 h-5 text-gray-500 mr-2" /> {valvertContactName}
          </dd>
        </div>
      )}
    </dl>
  );
};

export const OtHours = ({ start, end, ...props }) => {
  return (
    <dl tw="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2" {...props}>
      <div tw="sm:col-span-1">
        <LabelOT>Heure d’arrivée</LabelOT>
        <dd tw="mt-1 text-sm text-gray-900 flex items-center">
          <ClockIcon tw="w-5 h-5 text-gray-500 mr-2" />
          {timeDisplay(start)}
        </dd>
      </div>

      <div tw="sm:col-span-1">
        <LabelOT>Heure de départ</LabelOT>
        <dd tw="mt-1 text-sm text-gray-900 flex items-center">
          <ClockIcon tw="w-5 h-5 text-gray-500 mr-2" />
          {timeDisplay(end)}
        </dd>
      </div>
    </dl>
  );
};

export const CardOtListItemInfo = ({ color, workOrder }) => {
  return (
    <div tw="block bg-white hover:bg-gray-100 cursor-pointer border">
      <VerticalSignal color={color} />
      <div tw="px-4 py-4">
        <div tw="flex items-center justify-between">
          <div tw="flex items-center gap-2 w-full">
            <p tw="flex items-center text-xs sm:text-sm text-gray-500">
              {workOrder.allDay === true ? "Journée entière" : timeDisplay(workOrder.start)}
            </p>
          </div>
          <div tw="ml-2 flex-shrink-0 flex">
            <StatusBadge status={workOrder.status}>
              <BadgeDot />
            </StatusBadge>
          </div>
        </div>
        <p tw="flex justify-end mt-2 text-sm font-medium text-gray-700 truncate">
          {workOrder?.client?.name}
        </p>

        <div tw="mt-1 flex items-center justify-between">
          <div tw="flex gap-2">
            <p tw="text-sm font-medium text-gray-700 truncate">{workOrder.title.toUpperCase()}</p>
          </div>
          <div tw="flex items-center flex-wrap gap-2 justify-end ">
            <p tw="text-xs font-medium text-gray-500 truncate">{workOrder?.address}</p>
            <p tw="text-sm font-medium text-gray-600 truncate">{workOrder?.city}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
