/** @jsxImportSource @emotion/react */
import { AcademicCapIcon } from "@heroicons/react/outline";
import "twin.macro";
import { ExampleDatasOT } from "../importExampleDatas/ExampleDatasOT";
import { ExampleDatasVehicles } from "../importExampleDatas/ExampleDatasVehicles";
import { ExampleDatasWorkers } from "../importExampleDatas/ExampleDatasWorkers";
import { SecondaryMobileButton } from "../shared/components/Buttons";
import { StartTutorial } from "./StartTutorial";

export const ModalImportExampleDatas = ({ showResults, setShowResults, title }) => {
  return (
    <>
      <div
        tw="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true">
        <div tw="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            tw="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
            aria-hidden="true"></div>

          <span tw="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div tw="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div tw="my-4">
              <div tw="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-50">
                <AcademicCapIcon tw="h-6 w-6 text-primary-500" aria-hidden="true" />
              </div>
              <div tw="mt-3 text-center sm:mt-5">
                <h3 tw="text-base font-semibold leading-6 text-gray-900">{title}</h3>
                <div tw="mt-2">
                  <p tw="text-sm text-gray-500 mt-4">
                    Avant de commencer le didacticiel, voulez vous importer des données de test ?
                  </p>
                  <p tw="text-sm text-gray-500 mt-2">
                    Vous pouvez-importer des <ExampleDatasVehicles />, des <ExampleDatasWorkers />{" "}
                    ou bien des <ExampleDatasOT />
                  </p>
                  <p tw="text-sm text-gray-700 mt-4">
                    Il est maintenant tant de démarrer le didacticiel !
                  </p>
                </div>
              </div>
            </div>

            <div tw="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <div tw="flex space-x-3 items-center justify-end">
                <SecondaryMobileButton onClick={() => setShowResults(!showResults)}>
                  Retour
                </SecondaryMobileButton>
                <div onClick={() => setShowResults(!showResults)}>
                  <StartTutorial />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
