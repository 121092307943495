import { default as ExcelJS } from "exceljs";
import Papa from "papaparse";
import { ARRAY_SEPARATOR } from "./Export";

const defaultProcessor = (val) => val;
export const arrayProcessor = (arrayString: string) => {
  return arrayString ? arrayString.split(ARRAY_SEPARATOR).map((val) => val.trim()) : undefined;
};

export const readExcelFile = async (file, fieldNames, fieldsProcessors, currentSite = undefined): Promise<unknown[]> => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file as Buffer);
  const worksheet = workbook.getWorksheet(1);
  const items = [];
  worksheet.eachRow({ includeEmpty: true }, async (row, rowNumber) => {
    if (rowNumber !== 1) {
      // ignore header
      const item = {};
      for (let i = 0; i < fieldNames.length; i++) {
        const field = fieldNames[i];
        const fieldProcessors = fieldsProcessors[i] ?? defaultProcessor;
        const value = row.getCell(i + 1).value;
        const processedValue = fieldProcessors(value);
        item[field] = processedValue;
      }
      items.push(item);
    }
  });
  return items.map(e => ({...e, site: currentSite }));
};

type PapaParseResult = {
  data: Array<Array<string>>;
  errors: Array<{ message: string; row: number; }>;
};
export const readCsvFile = async (file: File, fieldsProcessors = []): Promise<PapaParseResult> => {
  return new Promise((resolve, reject) => Papa.parse<string[], File>(file, {
    delimitersToGuess: [",", ";"],
    header: false,
    preview: 0,
    encoding: "windows-1252",
    worker: false,
    complete: resolve,
    error: reject,
    skipEmptyLines: true,
    transform: (value, i) => fieldsProcessors[i] ? fieldsProcessors[i](value) : value,
  }));
};

