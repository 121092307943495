/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import tw from "twin.macro";

export const Table = ({ thead, tfoot, children }) => (
  <table tw="table-fixed w-full divide-y divide-gray-300 mt-8 rounded-md">
    <thead tw="bg-gray-50 border-t border-l border-r border-gray-300">{thead}</thead>
    <tbody tw="divide-y divide-gray-200 bg-white border-t border-l border-r border-gray-300">
      {children}
    </tbody>
    <tfoot>{tfoot}</tfoot>
  </table>
);

export const Td = styled("td")(tw`px-3 py-4 text-sm text-gray-500 truncate`);

export const Th = styled("th")(
  tw`px-3 py-3.5 text-left text-sm font-semibold text-gray-900 truncate`
);
