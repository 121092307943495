/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CrudList } from "../shared/components/CrudList";
import { buildSiteFilter, Filter } from "../shared/functions/QueryHelper";
import { Td } from "../shared/components/Table";
import {
  useSearchQuery,
  useUpsertManyMutation,
  useDeleteMutation,
} from "./useClientQueries";
import { Client } from "../types/Client";
import { ClientModal } from "./ClientModal";
import { Page } from "../shared/components/Page";
import { CrudListHeader } from "../shared/components/CrudListHeader";
import { useCrudParams } from "../shared/hooks/useCrudParams";
import { Permission } from "../types/Role";
import { useContext } from "react";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";

export const CLIENT_ROOT_ROUTE = "/client";
export const CLIENT_READ_PERMISSIONS = [Permission.CLIENT_VIEW];
export const CLIENT_WRITE_PERMISSIONS = [Permission.CLIENT_VIEW];

export const ClientCrud = () => {
  const { currentSite } = useContext(CurrentSiteContext);

  const {
    itemIsOpen,
    onItemOpen,
    onItemClose,
    itemId,
    selectedItems,
    isItemSelected,
    onItemToggle,
    onItemsToggle,
    search,
    debouncedSearch,
    setSearch,
    setPagination,
    pagination,
  } = useCrudParams();

  const { status, data: itemList, error } = useSearchQuery({
      ...Filter.and(
        debouncedSearch && { name: Filter.regex(debouncedSearch) },
        buildSiteFilter(currentSite),
      ),
      ...pagination,
  });

  return (
    <Page tw="space-y-2">
      <>
      {status === "error" && <div>{error.toString()}</div>}
      {status !== "error" && (
        <>
          <CrudListHeader
            pageTitle="Clients"
            fieldNames={["name", "address", "postalCode", "city", "contactName", "contactPhone", "status", "_id"]}
            useUpsertManyMutation={useUpsertManyMutation}
            useDeleteMutation={useDeleteMutation}
            importEnabled={true}
            exportEnabled={true}
            creationCallback={() => onItemOpen(null)}
            selectedItems={selectedItems}
            data={itemList}
            search={search}
            setSearch={setSearch}
            searchPlaceHolder="Recherche par nom"
            readPermissions={[]}
            writePermissions={[]}
          />
          <CrudList
            fieldLabels={["Nom", "Adresse", "Code Postal", "Ville", "Contact"]}
            creationCallback={() => onItemOpen(null)}
            crudListCells={(item) => <CrudListCells item={item as Client} goDetailsCallback={onItemOpen} />}
            data={itemList}
            status={status}
            debouncedSearch={debouncedSearch}
            isSelected={isItemSelected}
            onToggleMany={onItemsToggle}
            onToggle={onItemToggle}
            setPagination={setPagination}
            readPermissions={[]}
            writePermissions={[]}
            />
          <ClientModal clientId={itemId} isOpen={itemIsOpen} onClose={onItemClose} />
        </>
      )}
      </>
    </Page>
  );
};

export const CrudListCells = ({ item, goDetailsCallback }: { item: Client, goDetailsCallback }) => (
  <>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.name}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.address}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.postalCode}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>{item.city}</Td>
    <Td onClick={() => goDetailsCallback(item._id)}>
      <div>{item.contactName}</div>
      <div>{item.contactPhone}</div>
    </Td>
  </>
);
