import { useEffect, useState } from "react";

type LocationType = {
  loaded: boolean;
  coodinates?: {
    lat: string;
    lng: string;
  };
  error?: {
    code: string;
    message: string;
  };
};
export const useGeoLocation = () => {
  const [location, setLocation] = useState<LocationType>({
    loaded: false,
    coodinates: { lat: "", lng: "" },
  });

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coodinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);
  return location;
};
