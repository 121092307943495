/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";
import { Menu } from "@headlessui/react";
import { Transition } from "../Transition";
import { KeyIcon, LogoutIcon, TruckIcon, UserIcon } from "@heroicons/react/solid";
import { Avatar, ChevronForAvatar } from "../Avatar";
import { useConnectedUser } from "../../hooks/useConnectedUser";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { PERSON_ROOT_ROUTE } from "../../../person/PersonCrud";
import { VEHICLE_ROOT_ROUTE } from "../../../vehicle/VehicleCrud";
import { WORKORDER_ROOT_ROUTE } from "../../../workOrder/WorkOrderCrud";
import {
  hasSchedulerPermission,
  hasWorkOrderListPermission,
  hasPersonPermission,
  hasVehiclePermission,
} from "../../functions/RoleManagement";
import { useContext } from "react";
import { CurrentSiteContext } from "../../contexts/CurrentSiteContext";
import { Help } from "./Help";
import { useDisclosure } from "../../hooks/useDisclosure";

export const PlanningProfileDropdownDesktop = () => {
  const { connectedPerson, connectedVehicle, logout } = useConnectedUser();
  const { currentSite } = useContext(CurrentSiteContext);
  const {isOpen: isHelpOpen, onClose: onHelpClose, onToggle: onHelpToggle} = useDisclosure(false);

  return (
    <Menu as="div" tw="relative">
      <div>
        <>
          {/* @ts-ignore */}
          <Menu.Button tw="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <span tw="sr-only">Open menu</span>
            {connectedVehicle ? (
              <div tw="rounded-full border p-2 flex flex-row items-center leading-none uppercase bg-primary-600 text-white text-sm">
                <span tw="text-lg">🚛</span>
                <div tw="ml-1">
                  {connectedVehicle ? connectedVehicle.name : connectedPerson?.name}
                </div>
              </div>
            ) : (
              <>
                <Avatar tw="h-8 w-8 rounded-full" user={connectedPerson ?? connectedVehicle} />
              </>
            )}
            <ChevronForAvatar />
          </Menu.Button>
        </>
      </div>

      <>
        {/* @ts-ignore */}
        <Menu.Items tw="z-50 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <section tw="py-1">
            {connectedPerson && (
              <ProfileDropdownItem disabled={true}>
                <span tw="opacity-100 text-gray-900">
                  {connectedPerson?.shortName ?? ""} {connectedPerson.name ?? ""}
                </span>
              </ProfileDropdownItem>
            )}

            {connectedPerson && (
              <ProfileDropdownItem disabled={true}>
                <span tw="opacity-100 text-gray-900">
                  Rôle{connectedPerson?.roles?.length > 1 && "s"} :{" "}
                  {connectedPerson?.roles?.join("\n")}
                </span>
              </ProfileDropdownItem>
            )}

            {connectedVehicle && (
              <ProfileDropdownItem disabled={true}>
                <TruckIcon tw="h-4 w-5 mr-1.5 mt-0.5" />
                <span tw="opacity-100 text-gray-900">{connectedVehicle?.name}</span>
              </ProfileDropdownItem>
            )}

            {currentSite && (
              <ProfileDropdownItem disabled={true}>
                <span tw="opacity-100 text-gray-900">
                  Site par défault : {connectedPerson?.site.name ?? ""}
                </span>
              </ProfileDropdownItem>
            )}

            {process.env.REACT_APP_VERSION && (
              <ProfileDropdownItem disabled={true}>
                <span tw="opacity-100 text-gray-900">
                  Version {process.env.REACT_APP_VERSION}
                </span>
              </ProfileDropdownItem>
            )}

            <ProfileDropdownItem onClick={() => onHelpToggle()}>
              Aide
            </ProfileDropdownItem>

            {/* @ts-ignore */}
            <ProfileDropdownItem onClick={() => logout({LogoutOptions: { returnTo: window.location.origin }})}>
              Se déconnecter
            </ProfileDropdownItem>
          </section>
        </Menu.Items>
      </>
      <Help isOpen={isHelpOpen} onClose={onHelpClose} />
    </Menu>
  );
};

export const PlanningBurgerMenu = () => {
  const { connectedPerson, connectedVehicle, permissions, logout } = useConnectedUser();

  const MobileNavigation = [
    hasSchedulerPermission(permissions) && { children: "Planning", to: "/" },
    hasWorkOrderListPermission(permissions) && {
      children: "Liste des OT",
      to: WORKORDER_ROOT_ROUTE,
    },
    hasPersonPermission(permissions) && { children: "Employés", to: PERSON_ROOT_ROUTE },
    hasVehiclePermission(permissions) && { children: "Véhicules", to: VEHICLE_ROOT_ROUTE },
  ].filter(Boolean);

  const userNavigation = [
    connectedPerson && {
      as: "div",
      children: (
        <>
          <UserIcon tw="h-4 w-5 mr-1.5 mt-0.5" />
          {connectedPerson.shortName ?? ""} {connectedPerson.name ?? ""}
        </>
      ),
      style: { display: "flex" },
    },
    connectedPerson && {
      as: "div",
      children: (
        <>
          <KeyIcon tw="h-4 w-5 mr-1.5 mt-0.5" />
          {connectedPerson.roles.join("\n")}
        </>
      ),
      style: { display: "flex" },
    },
    connectedVehicle && {
      as: "div",
      children: (
        <>
          <TruckIcon tw="h-4 w-5 mr-1.5 mt-0.5" />
          {connectedVehicle.name}
        </>
      ),
      style: { display: "flex" },
    },
    {
      as: "button",
      children: (
        <>
          <LogoutIcon tw="h-4 w-5 mr-1.5 mt-0.5" />
          Déconnecter
        </>
      ),
      onClick: () => logout({ logoutParams: { returnTo: window.location.origin }}),
      style: { display: "flex" },
    },
  ].filter(Boolean);

  return (
    <>
      <div tw="pt-2 pb-3 space-y-1">
        {MobileNavigation.map(({ ...props }, index) => (
          <>
            {/* @ts-ignore */}
            <NavLink
              key={index}
              tw="block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              css={{
                "&": tw`border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800`,
                "&.active": tw`bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:border-primary-500 hover:text-primary-700`,
              }}
              {...props}
            />
          </>
        ))}
      </div>
      <div tw="pt-4 pb-3 border-t border-gray-200">
        <div tw="flex items-center px-4">
          <div tw="ml-3">
            {connectedVehicle ? (
              <div tw="rounded-full border p-2 flex flex-row items-center leading-none uppercase bg-primary-600 text-white text-sm">
                <span tw="text-lg">🚛</span>
                <div tw="ml-1">
                  {connectedVehicle ? connectedVehicle.name : connectedPerson?.name}
                </div>
              </div>
            ) : (
              <>
                <Avatar
                  tw="hidden sm:block h-8 w-8 rounded-full"
                  user={connectedPerson ?? connectedVehicle}
                />
              </>
            )}{" "}
          </div>
        </div>
        <div tw="mt-3 space-y-1">
          {userNavigation.map(({ as: Component, ...props }, index) => (
            <Component
              key={index}
              tw="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none"
              {...props}
            />
          ))}
        </div>
        <div tw="mt-3">
          <span tw="px-4 py-2 text-base text-gray-500">Version {process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </>
  );
};

export const ProfileDropdownItem = ({ disabled = false, ...props }) => {
  return (
    <>
      {/* @ts-ignore */}
      <Menu.Item disabled={disabled}>
        {({ active }) => (
          <button
            tw="flex justify-between w-full px-4 py-2 text-sm text-left"
            css={[
              active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
              disabled && tw`cursor-not-allowed opacity-50`,
            ]}
            {...props}
          />
        )}
      </Menu.Item>
    </>
  );
};

export const MobileAppProfileDropdown = () => {
  const { connectedVehicle, connectedPerson, logout } = useConnectedUser();
  const { t } = useTranslation();
  return (
    <div tw="relative">
      <Menu>
        {({ open }) => (
          <>
            {/* Profile button */}
            {/* @ts-ignore */}
            <Menu.Button tw="max-w-xs bg-white flex items-center text-sm rounded-full focus:(outline-none ring-2 ring-offset-2 ring-indigo-500)">
              <span tw="sr-only">
                {open ? t("Layout.closeProfileMenu") : t("Layout.openProfileMenu")}
              </span>
              <div tw="rounded-full border p-2 flex flex-row space-x-1 items-center">
                {connectedVehicle ? (
                  <span tw="text-lg">🚛</span>
                ) : (
                  <img
                    tw="h-8 w-8 rounded-full"
                    src={connectedPerson?.picture}
                    alt={connectedPerson?.name}
                    width={256}
                    height={256}
                  />
                )}
                <div>{connectedVehicle ? connectedVehicle.name : connectedPerson?.name}</div>
              </div>
            </Menu.Button>

            {/* Profile dropdown panel, show/hide based on dropdown state. */}
            {/* @ts-ignore */}
            <Transition
              show={open}
              enter={tw`transition ease-out duration-100`}
              enterFrom={tw`transform opacity-0 scale-95`}
              enterTo={tw`transform opacity-100 scale-100`}
              leave={tw`transition ease-in duration-75`}
              leaveFrom={tw`transform opacity-100 scale-100`}
              leaveTo={tw`transform opacity-0 scale-95`}
            >
              {/* @ts-ignore */}
              <Menu.Items
                static
                tw="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:(outline-none)"
              >
                <ProfileDropdownItem disabled={true}>
                  Version {process.env.REACT_APP_VERSION}
                </ProfileDropdownItem>
                <ProfileDropdownItem onClick={() => logout({ logoutParams: {returnTo: window.location.origin }})}>
                  Se déconnecter
                </ProfileDropdownItem>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
