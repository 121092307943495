/** @jsxImportSource @emotion/react */
import { CheckIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid";
import "twin.macro";
import { Container } from "../../../shared/components/Container";
import { ClientIcon, LocalisationIcon } from "../../../shared/components/Icons";
import { Skeleton } from "../../../shared/components/Page";
import {
  CardContainer,
  DateAndBadgeContainer,
  HeaderContainer,
  NavigationTitle,
  TitleHeaderContainer,
} from "../../workOrderList/WorkOrderListItem";
import { LabelOT } from "./Received";

const ReceivedSkeleton = () => {
  return (
    <>
      <StatusStepperSkeleton />
      <Container tw="mb-20 xl:px-48 2xl:px-96">
        {/* 1ST SECTION */}
        <CardContainer>
          <HeaderContainer>
            <div tw="px-4 py-5 sm:px-6 flex items-center gap-4">
              <NavigationTitle>
                <Skeleton />
              </NavigationTitle>
            </div>
          </HeaderContainer>
          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8">
              <div tw="sm:col-span-1">
                <LabelOT>Nom du client</LabelOT>
                <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                  {" "}
                  <Skeleton />
                </dd>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT>Contact chantier</LabelOT>
                <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                  <ClientIcon tw="w-5 h-5 text-gray-500 mr-2" /> <Skeleton />
                </dd>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT>Téléphone du contact</LabelOT>
                <div tw="mt-1 text-sm text-gray-500 flex items-center">
                  <PhoneIcon tw="w-5 h-5 text-gray-500 mr-2" /> <Skeleton />
                </div>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT tw="inline">Adresse du client</LabelOT>

                <div>
                  <LocalisationIcon tw="w-5 h-5 text-gray-500 mr-2" />
                  <Skeleton />
                </div>
              </div>

              <div tw="sm:col-span-1">
                <LabelOT>Contact</LabelOT>
                <dd tw="mt-1 text-sm text-gray-900 flex items-center">
                  <MailIcon tw="w-5 h-5 text-gray-500 mr-2" /> <Skeleton />
                </dd>
              </div>
            </dl>
          </div>
        </CardContainer>

        {/* 2ND SECTION */}
        <CardContainer>
          <HeaderContainer>
            <TitleHeaderContainer title="..." subTitle="" />
            <DateAndBadgeContainer>
              <Skeleton />
            </DateAndBadgeContainer>
          </HeaderContainer>

          <div tw="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl tw="divide-y divide-gray-200">
              <dd tw="md:col-span-7">
                <div tw="text-lg text-gray-500">
                  <div tw="sm:col-span-1">
                    <div>
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </CardContainer>
      </Container>
    </>
  );
};

export default ReceivedSkeleton;

const steps = [
  {
    id: "01",
    name: "Informations",
    href: `/mobile`,
    status: "",
  },
  {
    id: "02",
    name: "Travaux réalisés",
    href: `/mobile`,
    status: "",
  },
  {
    id: "03",
    name: "Signature",
    href: `/mobile`,
    status: "",
  },
];

export const StatusStepperSkeleton = () => {
  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        tw="border bg-white  border-gray-300 rounded-md divide-y divide-gray-300 flex divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.id} tw="relative flex-1 flex">
            <div tw="flex items-center w-full">
              <span tw="mx-auto px-4 md:px-6 py-2 md:py-4 flex items-center text-sm font-medium">
                <span tw="flex-shrink-0 w-8 md:w-10 h-8 md:h-10 flex items-center justify-center border-2 bg-gray-200 border-gray-200 rounded-full">
                  <span>
                    <CheckIcon tw="h-full w-6 text-white" />
                  </span>
                </span>
                <span tw="hidden ml-4 text-sm font-medium text-gray-500 xs:block">{step.name}</span>
              </span>
            </div>

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div tw="hidden block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    tw="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
