import { HTTPError } from "ky";
import qs from "qs";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { CurrentSiteContext } from "../../shared/contexts/CurrentSiteContext";
import { buildPeriodFilter, buildSiteFilter, Filter } from "../../shared/functions/QueryHelper";
import { getPrimaryVehicle } from "../../shared/functions/WorkOrderManagement";
import { useApi } from "../../shared/hooks/useApi";
import { WORK_ORDERS_ENDPOINT } from "../../shared/hooks/useWorkOrderQueries";
import { WorkOrder } from "../../types/WorkOrder";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";

type WorkOrderListQueryResult = UseQueryResult<{ list: WorkOrder[]; totalCount: number } , HTTPError>;
export const useWorkOrderQuery = (selectedDate: Date) : WorkOrderListQueryResult => {
  const { currentSite } = useContext(CurrentSiteContext);

  const searchParams = {
    ...Filter.and(
      buildSiteFilter(currentSite),
      ...buildPeriodFilter(selectedDate, "start", "week"),
      { status: { $ne: WorkOrderStatus.ANNULE } },
      { vehicles: { $exists: true, $not: {$size: 0} } },
    ),
  };
  
  const api = useApi();
  return useQuery([WORK_ORDERS_ENDPOINT, searchParams], async () => {
    const searchQueryResult: WorkOrder[] = await api.get(WORK_ORDERS_ENDPOINT, {
      searchParams: qs.stringify({
        ...searchParams
      }),
    }).json();

    return {
      // Work orders without assigned resources must be filtered, unless they will be displayed duplicated on the calendar
      // (Mobiscroll behaviour)
      list: searchQueryResult?.filter((w) => !!getPrimaryVehicle(w))
    };
  }, { refetchInterval: 60000 });
};