/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";
import { useCallback, useMemo } from "react";
import { WorkOrder } from "../types/WorkOrder";
import { Vehicle } from "../types/Vehicle";
import {
  WorkOrderSecondaryColors,
  WorkOrderColors,
  WorkOrderBorderColors,
} from "../types/WorkOrderColors";
import { DrivingLicenseColors } from "../types/DrivingLicense";
import { getSecondaryVehicles } from "../shared/functions/WorkOrderManagement";
import { Person } from "../types/Person";
import { significantLicenses } from "../shared/functions/DrivingLicenceManagement";
import { SmallBadge } from "../shared/components/Badge";
import { VehicleIcon } from "../shared/components/Vehicles/VehiclesIcon";
import { SchedulerEventWeek } from "../shared/components/Scheduler/SchedulerEventWeek";

type SummaryWorkOrderProps = {
  event: WorkOrder;
};

export const SummaryWorkOrder = ({ event }: SummaryWorkOrderProps) => {
  const {
    allDay,
    team,
    isHeaderWorkOrder,
    hasTeamHeader,
    hasVehicleHeader,
    hasWorkOrderLicenseHeader,
  } = event ?? {};

  const secondaryVehicles = useMemo<Vehicle[] | undefined>(
    () => getSecondaryVehicles(event),
    [event]
  );

  const colorStyle = useMemo(
    () => ({
      backgroundColor: `${WorkOrderColors.get(event?.status)}`,
      borderColor: `${WorkOrderBorderColors.get(event?.status)}`,
      color: `${WorkOrderSecondaryColors.get(event?.status)}`,
    }),
    [event]
  );

  const workOrderContainerCss = useMemo(() => {
    const result = [tw`flex flex-row text-base border rounded my-0.5 items-center divide-dotted`];
    if (!allDay) {
      result.push(tw`divide-x divide-dotted divide-gray-900`);
    }

    return result;
  }, [allDay]);

  const teamElements = useCallback(
    (isHeader: boolean, showTeam: boolean, showSecondaryVehicle: boolean) => {
      const vehicleBadges = secondaryVehicles && showSecondaryVehicle && (
        <div css={isHeader && [tw`flex w-full flex-wrap justify-center text-center`]}>
          {secondaryVehicles.map((v) => (
            <SecondaryVehicleBadge tw="my-0.5" key={v._id} vehicle={v} />
          ))}
        </div>
      );
      return (
        <>
          {showTeam && (
            <div tw="block w-full">
              <TeamBadges team={team} isHeaderWorkOrder={isHeader} event={event} />
              <div>{vehicleBadges}</div>
            </div>
          )}
          {!showTeam && vehicleBadges}
        </>
      );
    },
    [event, team]
  );

  const hasBodyElements = useMemo(
    () =>
      (team?.length > 0 && !hasTeamHeader) ||
      (!!secondaryVehicles && !hasVehicleHeader) ||
      !hasWorkOrderLicenseHeader,
    [secondaryVehicles, hasVehicleHeader, hasTeamHeader, hasWorkOrderLicenseHeader]
  );

  const headerElements = teamElements(
    true,
    hasTeamHeader && team?.length > 0,
    hasVehicleHeader && !!secondaryVehicles
  );
  const bodyElements = teamElements(
    false,
    !hasTeamHeader && team?.length > 0,
    !hasVehicleHeader && !!secondaryVehicles
  );

  return (
    <article tw="flex flex-col">
      {isHeaderWorkOrder && (
        <header tw="flex flex-row cursor-default min-h-[20px] px-0 space-x-0 ">
          {headerElements}
        </header>
      )}
      {!isHeaderWorkOrder && (
        <SchedulerEventWeek
          event={event}
          hasBodyElements={hasBodyElements}
          teamElements={bodyElements}
          workOrderContainerCss={workOrderContainerCss}
          colorStyle={colorStyle}
        />
      )}
    </article>
  );
};

type TeamLabelProps = {
  team: Person[];
  isHeaderWorkOrder: boolean;
  event: WorkOrder;
  [s: string]: unknown;
};
const TeamBadges = ({ team, isHeaderWorkOrder, ...props }: TeamLabelProps) => {
  const personElements = team?.map((person) => {
    const licenses = significantLicenses(person.drivingLicenses);
    const badgeColor = licenses && licenses[0] ? DrivingLicenseColors.get(licenses[0]) : "gray";
    return (
      <SmallBadge
        key={person._id}
        tw="my-0.5 h-4 px-2 py-0 rounded-md text-xs font-normal flex flex-row items-center space-x-0.5"
        css={tw` m-0.5`}
        color={badgeColor}
        {...props}
      >
        {isHeaderWorkOrder ? (
          <>
            <span css={[tw`text-xs `]}>{person.shortName}</span>
          </>
        ) : (
          <span css={[tw`text-xs font-medium `]}>{person.shortName}</span>
        )}
      </SmallBadge>
    );
  });
  return (
    <section
      css={[
        tw`flex flex-wrap text-center w-full [align-self: center]`,
        isHeaderWorkOrder ? tw`justify-center` : tw`justify-start`,
      ]}
    >
      {personElements}
    </section>
  );
};

const SecondaryVehicleBadge = ({ vehicle, ...props }: { vehicle: Vehicle }) => (
  <section
    css={[
      tw`flex flex-row flex-nowrap space-x-1 items-center border rounded-md border-gray-200 bg-white max-w-min`,
      tw`text-indigo-800 px-1 `,
    ]}
    {...props}
  >
    <VehicleIcon name={vehicle.icon} tw="h-4" />
    <span tw="text-xs whitespace-nowrap">{vehicle.name}</span>
  </section>
);
