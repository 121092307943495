import { useContext } from "react";
import qs from "qs";
import { useQueryClient, useMutation, UseMutationResult, useQuery, UseQueryResult, QueryClient } from "react-query";
import { useApi } from "./useApi";
import { WorkOrder } from "../../types/WorkOrder";
import { buildPeriodFilter, buildSiteFilter, Filter } from "../functions/QueryHelper";
import { WorkOrderStatus } from "../../types/WorkOrderStatus";
import { TypeFilter } from "../../planning/context/SchedulerFiltersContext";
import { getPrimaryVehicle, updateWorkOrderInCache } from "../functions/WorkOrderManagement";
import { Period } from "../../types/Period";
import { CurrentSiteContext } from "../contexts/CurrentSiteContext";
import { HTTPError } from "ky";

export const WORK_ORDERS_ENDPOINT = "v1/workorder";
export const DOWNLOAD_ENDPOINT = "v1/download";
const USE_SEARCH_QUERY_KEY = "useSearchQueryKey";
const USE_FIND_BY_ID_QUERY_KEY = "useFindByIdQueryKey";
const USE_SCHEDULER_QUERY_KEY = "useSchedulerQueryKey";

//
// MUTATION QUERIES
//

export const useUpsertManyWorkOrdersMutation = (): UseMutationResult<unknown, HTTPError> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(async (items: WorkOrder[]) => {
      return api.post(`${WORK_ORDERS_ENDPOINT}/bulk`, {
        json: items,
      }).json();
    },
    {
      onSuccess: (data) => {
        updateQueryDataAndClearCache(queryClient, data, "upsert");
      },
    }
  );
};

export const useMassPatchWorkOrdersMutation = (): UseMutationResult<unknown, HTTPError> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({searchParams, workOrder}: {searchParams: unknown, workOrder: unknown}) => {
      return api.patch(`${WORK_ORDERS_ENDPOINT}/bulk`, {
        searchParams: qs.stringify(searchParams),
        json: workOrder,
      }).json();
    },
    {
      onSuccess: (data) => {
        updateQueryDataAndClearCache(queryClient, data, "update");
      },
    }
  );
};

export const useUpsertWorkOrderMutation = (): UseMutationResult<WorkOrder, HTTPError> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<WorkOrder, HTTPError, WorkOrder, unknown>(
    async (workOrder) => {
      if (workOrder.isHeaderWorkOrder) {
        return Promise.resolve(workOrder);
      }
      if (workOrder._id) {
        return api
          .patch(`${WORK_ORDERS_ENDPOINT}/${workOrder._id}`, {
            json: workOrder,
          })
          .json();
      }
      // The entry does not exist, create it
      return api
        .post(WORK_ORDERS_ENDPOINT, {
          json: workOrder,
        })
        .json();
    },
    {
      onSuccess: async (data) => {
        await updateQueryDataAndClearCache(queryClient, data, "upsert");
      },
    }
  );
};

export const useReplaceWorkOrderMutation = (): UseMutationResult<WorkOrder, HTTPError> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<WorkOrder, HTTPError, WorkOrder, unknown>(
    async (workOrder) => {
      if (workOrder.isHeaderWorkOrder) {
        return Promise.resolve(workOrder);
      }
      delete workOrder.__v; // Prevent error: VersionError: No matching document found for id "..." version 12 
      return api
        .put(`${WORK_ORDERS_ENDPOINT}/${workOrder._id}`, {
          json: workOrder,
        })
        .json();
    },
    {
      onSuccess: async (data) => {
        await updateQueryDataAndClearCache(queryClient, data, "update");
      },
    }
  );
};

export const useDeleteWorkOrderMutation = (): UseMutationResult => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    async (workOrder: WorkOrder) => {
      if (workOrder.isHeaderWorkOrder) {
        return Promise.resolve(workOrder);
      }
      if (workOrder._id) {
        return api.delete(`${WORK_ORDERS_ENDPOINT}/${workOrder._id}`).json();
      } else if (Array.isArray(workOrder)) {
        return Promise.all(workOrder.map((i) => api.delete(`${WORK_ORDERS_ENDPOINT}/${i._id}`).json()));
      }
    },
    {
      onSuccess: async (data) => {
        await updateQueryDataAndClearCache(queryClient, data, "delete");
      },
    }
  );
};

const updateQueryDataAndClearCache = async (queryClient: QueryClient, responseData, type: "upsert" | "update" | "delete") => {
  // Get all subqueries caches of workorder endpoint
  const queriesToUpdate = queryClient.getQueriesData(WORK_ORDERS_ENDPOINT);
  for (const [queryKey, queryData] of queriesToUpdate) {
    if (Array.isArray(queryKey) && queryKey.includes(USE_SCHEDULER_QUERY_KEY)) {
    // Don't invalid scheduler queries: it takes too long to refresh data and the planning view is blipping
    // Update the state manually is faster
      updateWorkOrderInCache(responseData, queryKey, queryData, queryClient, type);
    } else {
      // For other queries, we can invalidate
      await queryClient.invalidateQueries(queryKey);
      await queryClient.removeQueries(queryKey);
    }
  }
};

//
// GET QUERIES
//

export const useWorkOrderFindByIdQuery = (itemId: string): UseQueryResult<WorkOrder> => {
  const api = useApi();
  return useQuery([WORK_ORDERS_ENDPOINT, USE_FIND_BY_ID_QUERY_KEY, itemId], async () => {
    return api.get(`${WORK_ORDERS_ENDPOINT}/${itemId}`).json();
  });
};

// Used by Scheduler
type WorkOrderListQueryResult = UseQueryResult<{ list: WorkOrder[]; totalCount: number } , HTTPError>;
export const useSchedulerSearchQuery = (selectedDate: Date, period: Period, type: TypeFilter) : WorkOrderListQueryResult => {
  const { currentSite } = useContext(CurrentSiteContext);

  const searchParams = {
    ...Filter.and(
      buildSiteFilter(currentSite),
      ...buildPeriodFilter(selectedDate, "start", period),
      { status: { $ne: WorkOrderStatus.ANNULE } },
      type === "all" && { status: { $ne: WorkOrderStatus.ANNULE } },
      type === "draft" && { status: WorkOrderStatus.EN_PREPARATION },
      type === "final" && { status: { $ne: WorkOrderStatus.EN_PREPARATION }},
      { vehicles: { $exists: true, $not: {$size: 0} } },
    ),
  };
  
  const api = useApi();
  return useQuery([WORK_ORDERS_ENDPOINT, USE_SCHEDULER_QUERY_KEY, searchParams], async () => {
    const searchQueryResult: WorkOrder[] = await api.get(WORK_ORDERS_ENDPOINT, {
      searchParams: qs.stringify({
        ...searchParams
      }),
    }).json();

    return {
      // Work orders without assigned resources must be filtered, unless they will be displayed duplicated on the calendar
      // (Mobiscroll behaviour)
      list: searchQueryResult?.filter((w) => !!getPrimaryVehicle(w))
    };
  }, { refetchInterval: 60000 });
};

// Used by work order CRUD list
export const useSearchQuery = (searchParams): WorkOrderListQueryResult => {
  const api = useApi();
  return useQuery([WORK_ORDERS_ENDPOINT, USE_SEARCH_QUERY_KEY, searchParams], async () => {
    const response = await api.get(WORK_ORDERS_ENDPOINT, {
      searchParams: qs.stringify({
        limit: searchParams.limit ?? 0,
        skip: searchParams.skip ?? 0,
        sort: searchParams.sort ?? 'drivingLicense',
        ...searchParams
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};
