/** @jsxImportSource @emotion/react */
import "twin.macro";
import { timeDisplay } from "../../../shared/functions/Datetime";
import { HeaderContainer } from "../../workOrderList/WorkOrderListItem";
import { Spinner } from "../../../shared/components/Spinner";
import { FC, useCallback, useEffect } from "react";
import {
  useUpsertWorkOrderMutation,
  WORK_ORDERS_ENDPOINT,
} from "../../../shared/hooks/useWorkOrderQueries";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../shared/hooks/useApi";
import html2canvas from "html2canvas";
import { WorkOrder } from "../../../types/WorkOrder";
import jsPDF from "jspdf";
import { handleError } from "../../../shared/functions/ErrorHandling";
import Compressor from 'compressorjs';

type ModalPdfTemplateProps = {
  showResults: boolean;
  setShowResults: (b: boolean) => void;
  status: string;
  workOrder: WorkOrder;
  urlTechnicianSig: string;
  urlClientSig: string;
  siteFooter: string;
  siteLeftHeader: string;
  setShowResultsOTPdfNotSent: (b: boolean) => void;
  setShowResultsOTPdfSent: (b: boolean) => void;
  typePdf: string;
};
export const ModalPdfTemplate: FC<ModalPdfTemplateProps> = ({
  showResults,
  setShowResults,
  status,
  workOrder,
  urlTechnicianSig,
  urlClientSig,
  siteFooter,
  siteLeftHeader,
  setShowResultsOTPdfNotSent,
  setShowResultsOTPdfSent,
  typePdf
}) => {
  const photoNames = [];
  for (let i = 0; i <= workOrder.photos.length; i++) {
    if (workOrder.photos[i]?.name) {
      photoNames.push(workOrder.photos[i]?.name);
    }
  }

  const { otId } = useParams();
  const navigate = useNavigate();
  const api = useApi();

  const { mutateAsync: upsertWorkOrder } = useUpsertWorkOrderMutation();

  const onFileChange = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("attachment", file);
      try {
        const uploadResponse = (await api
          .post(`${WORK_ORDERS_ENDPOINT}/upload/${otId}`, { body: formData })
          .json()) as { filename };
        const newWorkOrder = {
          _id: otId,
          signedDocument: uploadResponse.filename,
        };
        await upsertWorkOrder(newWorkOrder);
        navigate(`/mobile/${otId}/signature`);
        setShowResultsOTPdfSent(true);
      } catch (err) {
        handleError(err).then((errMsg) => {
          console.log(errMsg);
          setShowResultsOTPdfNotSent(true);
        });
      }
    },
    [api, upsertWorkOrder]
  );

  const onFileChangePlannning = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("attachment", file);
      try {
        const uploadResponse = (await api
          .post(`${WORK_ORDERS_ENDPOINT}/upload/${workOrder._id}`, { body: formData })
          .json()) as { filename };
        const newWorkOrder = {
          _id: workOrder._id,
          signedDocument: uploadResponse.filename,
        };
        await upsertWorkOrder(newWorkOrder);
        navigate(`/`);
      } catch (err) {
        handleError(err).then((errMsg) => {
          console.log(errMsg);
        });
      }
    },
    [api, upsertWorkOrder]
  );

  const printPDF = async () => {
    document.documentElement.style.overflowX = "hidden";
    document.documentElement.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "hidden";

    const domElement = document.getElementById("export-pdf");
    const canvas = await html2canvas(domElement, {
      onclone: (document) => {
        if (document.getElementById("print")) {
          document.getElementById("print").style.visibility = "hidden";
        }
        if (document.getElementById("return")) {
          document.getElementById("return").style.visibility = "hidden";
        }

        /* REMOVE CODE BELOW AFTER FIX PHONE ICONS */
        if (document.getElementById("phoneIcon")) {
          document.getElementById("phoneIcon").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon1")) {
          document.getElementById("phoneIcon1").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon2")) {
          document.getElementById("phoneIcon2").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon3")) {
          document.getElementById("phoneIcon3").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon4")) {
          document.getElementById("phoneIcon4").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon5")) {
          document.getElementById("phoneIcon5").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon6")) {
          document.getElementById("phoneIcon6").style.visibility = "hidden";
        }
        if (document.getElementById("phoneIcon7")) {
          document.getElementById("phoneIcon7").style.visibility = "hidden";
        }
      },
    });

    const lowQualityImageUrl = canvas.toDataURL("image/jpeg", 0.1);
    const res = await fetch(lowQualityImageUrl);
    const imageBlob = await res.blob();

    new Compressor(imageBlob , {      
      quality: 0.5,
      success: async (compressedResult) => {
        const image = new Image();
        image.src = URL.createObjectURL(compressedResult);
        
        const filename = `Doc-Signe-${new Date().toISOString()}.pdf`;
        const pdf = new jsPDF();
        pdf.addImage(image, "PNG", 5, 5, 200, 280, "image", "MEDIUM", 0);
        const blobPDF = new Blob([pdf.output("blob")], { type: "application/pdf" });
        const filefyBinaryData = new File([blobPDF], filename, {
          type: "application/pdf",
          lastModified: new Date().getTime(),
        });
        if (typePdf==="mobile") {
          await onFileChange(filefyBinaryData);
        } else {
          await onFileChangePlannning(filefyBinaryData);
        }
        
        pdf.save(filename);

        setShowResults(!showResults);


        document.body.style.removeProperty("overflowX");
        document.body.style.removeProperty("overflowY");
        document.documentElement.style.removeProperty("overflowX");
        document.documentElement.style.removeProperty("overflowY");
      },
      error : (error) => {
          console.error(error);
      },
    })
  };

  useEffect(() => {
    printPDF();
  }, []);

  return (
    <>
      {/* Loading PDF Export */}
      <div tw="fixed z-10 !bottom-[20px] !right-[30px] bg-gray-200 rounded-lg">
        <div>
          <div tw="flex items-center justify-center p-4">
            <div tw="text-base text-gray-600 font-bold text-center pr-3">
              {"Génération de l'OT en cours"}
            </div>
            <Spinner />
          </div>
        </div>
      </div>

      {/* Hidden PDF Component 300 dpi */}
      <div
        id="export-pdf"
        tw="absolute top-0 left-0 [z-index: -99] [height: 1754px] [width: 1240px]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true">
        <div tw="flex items-center justify-center text-center p-0">
          <span tw="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div tw="inline-block bg-white text-left overflow-hidden shadow-xl transform transition-all align-middle h-auto w-full">
            {status === "loading" && (
              <div>
                <div tw="flex justify-between">
                  <div tw="py-5 px-6 flex items-center gap-4">
                    <h3 tw="inline-flex items-center space-x-3 text-lg leading-6 font-medium text-black">
                      <span tw="block h-4 my-0.5 w-32 rounded-md animate-pulse"></span>
                    </h3>
                  </div>
                  <div tw="px-4 py-5 sm:px-6">
                    <h3 tw="text-lg leading-6 font-medium text-black">
                      <span tw="block h-4 my-0.5  w-32 rounded-md animate-pulse"></span>
                    </h3>
                    <p tw="mt-1 max-w-2xl text-sm text-gray-800">
                      <span tw="block h-4 my-0.5  w-32 rounded-md animate-pulse"></span>
                    </p>
                  </div>
                </div>
              </div>
            )}

            <>
              <div tw="px-2 sm:px-4 [height: 3000px]">
                {status === "success" && (
                  <>
                    {/* 1ST PART */}
                    <div tw="flex justify-between text-gray-800">
                      <div tw="flex flex-col gap-2 gap-1">
                        <img
                          tw="object-fill h-56 w-auto"
                          src={siteLeftHeader}
                          alt="site-leftHeader"
                        />

                        <div>
                          {workOrder.valvertContactName && (
                            <div tw="text-gray-800 font-medium">
                              Contact {process.env.REACT_APP_WHITE_LABEL} :{" "}
                              {workOrder.valvertContactName}
                            </div>
                          )}
                          {(workOrder.contactName || workOrder.contactPhone) && (
                            <div tw="text-gray-800 font-medium">
                              Contact client : {workOrder.contactName ?? ""}
                              {workOrder.contactName && workOrder.contactPhone && " - "}
                              {workOrder.contactPhone ?? ""}
                            </div>
                          )}
                        </div>

                        {workOrder?.team.length !== 0 &&
                          workOrder?.team.map((member, index) => (
                            <div tw="text-gray-800 font-medium" key={index}>
                              Opérateur {index + 1}: {member?.shortName} {member?.name}
                            </div>
                          ))}

                        {(workOrder?.vehicles) && (
                          <div tw="text-gray-800 font-medium">
                            Camion : {workOrder?.vehicles[0].name ?? ""}
                          </div>
                        )}
                      </div>

                      <div tw="flex gap-1">
                        <div tw="flex flex-col gap-1">
                          <div tw="grid grid-cols-2">
                            <p tw="text-lg">N° d&apos;OT :</p>
                            {workOrder.workOrderNumber ? (
                              <p tw="text-lg">{workOrder.workOrderNumber}</p>
                            ) : (
                              <p tw="text-lg">___</p>
                            )}
                          </div>

                          <div tw="grid grid-cols-2">
                            <p tw="text-lg">Date :</p>
                            <div tw="text-gray-800 font-medium text-lg">
                              Le {workOrder.start.toString().slice(8, 10)}/
                              {workOrder.start.toString().slice(5, 7)}/
                              {workOrder.start.toString().slice(0, 4)}
                            </div>
                          </div>

                          {workOrder.clientName && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Client name :</p>
                              <p tw="text-lg">{workOrder.clientName}</p>
                            </div>
                          )}

                          {(workOrder.address || workOrder.postalCode || workOrder.city) && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Adresse chantier :</p>
                              <div tw="flex flex-col gap-1">
                                <p tw="text-lg">{workOrder.address && workOrder?.address}</p>
                                <p tw="text-lg">
                                  {workOrder.postalCode && workOrder?.postalCode},{" "}
                                  {workOrder.city && workOrder?.city}
                                </p>
                              </div>
                            </div>
                          )}

                          {(workOrder.billingAddress ||
                            workOrder.billingPostalcode ||
                            workOrder.billingCity) && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Adresse facturation :</p>
                              <div tw="flex flex-col gap-1">
                                <p tw="text-lg">{workOrder.billingAddress}</p>
                                <p tw="text-lg">
                                  {workOrder.billingPostalcode && workOrder?.billingPostalcode},{" "}
                                  {workOrder.billingCity && workOrder?.billingCity}
                                </p>
                              </div>
                            </div>
                          )}

                          {workOrder.contactPhone && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Tél :</p>
                              <p tw="text-lg">{workOrder.contactPhone}</p>
                            </div>
                          )}

                          {workOrder.actualStart && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Début d’intervention :</p>
                              <p tw="text-lg">{timeDisplay(workOrder.actualStart)}</p>
                            </div>
                          )}

                          {workOrder.actualEnd && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Fin d’intervention :</p>
                              <p tw="text-lg">{timeDisplay(workOrder.actualEnd)}</p>
                            </div>
                          )}

                          {workOrder.vehicle && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Véhicle :</p>
                              <p tw="text-lg">{workOrder.vehicle[0].name}</p>
                            </div>
                          )}

                          {workOrder.vehicle && (
                            <div tw="grid grid-cols-2">
                              <p tw="text-lg">Véhicle :</p>
                              <p tw="text-lg">{workOrder.vehicle[0].name}</p>
                            </div>
                          )}

                          {workOrder.payment?.paymentMethod && (
                            <p tw="text-lg">Paiement : {workOrder.payment?.paymentMethod}</p>
                          )}

                          {workOrder.payment?.paymentMethod === "Chèque" && (
                            <p tw="text-lg">
                              N° du chèque :{" "}
                              {workOrder.payment?.number ? workOrder.payment.number : ""}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* 2ND PART */}
                    <div tw="grid grid-cols-2 gap-10 mt-10">
                      <div tw="w-full ">
                        <HeaderContainer>
                          <div tw="px-4 pt-6 pb-8">
                            <div tw="leading-6 font-medium text-2xl text-black">
                              TRAVAUX À EFFECTUER
                            </div>
                          </div>
                        </HeaderContainer>
                        <div tw="border-t border-gray-600 px-2 py-0 sm:py-3 sm:px-6 text-xl">
                          <dl tw="divide-y divide-gray-200">
                            <div>
                              <dd tw="mt-2 md:mt-0 md:col-span-7 ">
                                <div tw="flex flex-col text-black pb-2 gap-2">
                                  {workOrder.description
                                    ? workOrder.description
                                        .split("\n")
                                        .map((str, index) => <p key={index}>{str}</p>)
                                    : "Rien à signaler"}
                                </div>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div tw="w-full ">
                        <HeaderContainer>
                          <div tw="px-4 pt-6 pb-8">
                            <div tw="leading-6 font-medium text-2xl text-black">
                              TRAVAUX RÉALISÉS
                            </div>
                          </div>
                        </HeaderContainer>
                        <div tw="border-t border-gray-600 px-2 py-0 sm:py-3 sm:px-6  text-xl">
                          <dl tw="divide-y divide-gray-200">
                            <div>
                              <dd tw="mt-2 md:mt-0 md:col-span-7 ">
                                <div tw="flex flex-col text-black pb-2 gap-2">
                                  {workOrder.descriptionWorkDone &&
                                    workOrder.descriptionWorkDone
                                      .split("\n")
                                      .map((str, index) => <p key={index}>{str}</p>)}
                                  {workOrder.roundTrip && (
                                    <p tw="text-sm">Temps de trajet A/R : {workOrder.roundTrip}</p>
                                  )}
                                  {workOrder.arrivalTime && (
                                    <p tw="text-sm">Heure d’arrivée : {workOrder.arrivalTime}</p>
                                  )}
                                  {workOrder.workDuration && (
                                    <p tw="text-sm">Temps de chantier : {workOrder.workDuration}</p>
                                  )}
                                  {!workOrder.descriptionWorkDone &&
                                    !workOrder.roundTrip &&
                                    !workOrder.arrivalTime &&
                                    !workOrder.workDuration &&
                                    "Rien à signaler"}
                                </div>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>

                      <div tw="w-full ">
                        <HeaderContainer>
                          <div tw="px-4 pt-6 pb-8">
                            <div tw="leading-6 font-medium text-2xl text-black">
                              OBSERVATIONS CHANTIER
                            </div>
                          </div>
                        </HeaderContainer>
                        <div tw="border-t border-gray-600 px-2 py-0 sm:py-3 sm:px-6 text-xl">
                          <dl tw="divide-y divide-gray-200">
                            <div>
                              <dd tw="mt-2 md:mt-0 md:col-span-7 ">
                                <div tw="flex flex-col text-black pb-2 gap-2">
                                  {workOrder.observations
                                    ? workOrder.observations
                                        .split("\n")
                                        .map((str, index) => <p key={index}>{str}</p>)
                                    : "Rien à signaler"}
                                </div>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>

                    <div tw="grid grid-cols-2 gap-10 mt-10">
                      {(typePdf=== "mobile" && urlClientSig) && (
                        <div tw="w-full">
                          <HeaderContainer>
                            <div tw="px-4 pt-6 pb-8">
                              <div tw="leading-6 font-medium text-2xl text-black">
                                VISA CHAUFFEUR
                              </div>
                            </div>
                          </HeaderContainer>
                          <div tw="border-t border-gray-600 px-2 py-0 sm:py-3 sm:px-6 text-lg">
                            <dl tw="divide-y divide-gray-200">
                              <div>
                                <dd tw="mt-2 md:mt-0 md:col-span-7">
                                  <div tw="flex flex-col text-black pb-2 gap-2 max-h-2">
                                    <img
                                      tw="object-fill rounded-lg mx-auto [min-height: 150px]"
                                      src={urlTechnicianSig}
                                      alt="signature chauffeur"
                                    />
                                  </div>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      )}
                      {(typePdf=== "mobile" && urlTechnicianSig) && (
                        <div tw="w-full">
                          <HeaderContainer>
                            <div tw="px-4 pt-6 pb-8">
                              <div tw="leading-6 font-medium text-2xl text-black">
                                VISA CLIENT{" "}
                                <span tw=" text-base text-gray-700">Bon pour acceptation,</span>
                              </div>
                            </div>
                          </HeaderContainer>
                          <div tw="border-t border-gray-600 px-2 py-0 sm:py-3 sm:px-6 text-lg">
                            <dl tw="divide-y divide-gray-200">
                              <div>
                                <dd tw="mt-2 md:mt-0 md:col-span-7">
                                  <div tw="flex flex-col text-black pb-2 gap-2 max-h-2">
                                    <img
                                      tw="object-fill rounded-lg mx-auto [min-height: 150px]"
                                      src={urlClientSig}
                                      alt="signature client"
                                    />
                                  </div>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      )}
                    </div>
                    <div tw="absolute bottom-0 w-full">
                      <img
                        tw="object-fill rounded-lg mx-auto [min-height: 150px]"
                        src={siteFooter}
                        alt="site-footer"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPdfTemplate;
